import React, { useState, useEffect } from "react";
//table imports
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { API_URL, TODAY_DATE } from "../../Constents";
import { Modal, Spinner } from "react-bootstrap";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { useAlert } from "react-alert";
const DocumentsDetailsUpdateRequest = () => {
  const [docData, setDocData] = useState();
  const [err, setErr] = useState();
  const [details, setDetails] = useState({
    startDate: "",
    endDate: "",
    status: "pending",
  });
  const [show, setShow] = useState(false);
  const [nameForModal, setNameForModal] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [dataForAction, setDataForAction] = useState({
    url: "",
    user_id: null,
    document_id: null,
  });
  const [showFile, setShowFile] = useState(false);
  const [fileURL, setFileURL] = useState();
  const alert = useAlert();
  const dateLast =
    details.startDate && details.endDate === "" ? TODAY_DATE : details.endDate;
  const dateStart =
    details.startDate === "" && details.endDate !== ""
      ? TODAY_DATE
      : details.startDate;

  const fetchData = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      updated_codes: details.status,
      start_date: dateStart,
      last_date: dateLast,
    });

    const url = API_URL + "/get-requested-documents/";

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: headersList,
        body: bodyContent,
      });
      const data = await res.json();
      console.log(data);
      setDocData(data);
    } catch (err) {
      console.error(err);
      setErr(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    details.startDate,
    details.endDate,
    details.issue,
    details.status,
    errorMessage,
    show,
  ]);

  console.log(docData);
  console.log(details);

  if (!docData) return <h1>Loading...</h1>;

  if (err) return <h1>Some error occured</h1>;

  const { SearchBar } = Search;

  const columns = [
    { dataField: "employee_name", text: "Raised By", sort: true },
    { dataField: "document_name", text: "Doc Name", sort: true },
    { dataField: "document_codes", text: "Doc Type", sort: true },

    {
      dataField: "created_at",
      text: "Raised On",
      sort: true,
    },

    { dataField: "description", text: "Description", sort: true },

    {
      dataField: "updated_codes",
      text: "Status",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.updated_codes === "pending")
          return (
            <button
              className="btn btn-link"
              onClick={() => {
                setShow(true);
                setNameForModal(row.employee_name);
                setDataForAction({
                  url: row.document_file,
                  user_id: row.employee,
                  document_id: row.id,
                });
              }}
            >
              Pending
            </button>
          );
        if (row.updated_codes === "approved")
          return <p className="msg_success">Approved</p>;
        if (row.updated_codes === "rejected")
          return <p className="msg_fail">Rejected</p>;
      },
    },
    {
      dataField: "document_file",
      text: "File",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.document_file == null || row.document_file === "")
          return "No Attachement";
        else
          return (
            <button
              className="btn btn-link"
              onClick={() => {
                setShowFile(true);
                setFileURL(row.document_file);
              }}
            >
              <BsBoxArrowUpRight />
            </button>
          );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  const updateRequestStatusHandler = async (actionType) => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const bodyContent = new FormData();
    bodyContent.append("document_file", dataForAction.url);
    bodyContent.append("updated_codes", actionType);

    const url =
      API_URL +
      "/get-requested-documents/?user_id=" +
      dataForAction.user_id +
      "&document_id=" +
      dataForAction.document_id;

    try {
      const res = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });
      const data = await res.json();
      console.log(data);
      setShow(false);
      if (data.status) {
        setShow(false);
        alert.success(data.message);
      }
      if (data.status === false) {
        alert.error(data.message);
        setShow(false);
      }
    } catch (err) {
      console.log(err);
      alert.error("Internal Server Error");
    }
  };

  console.log(dataForAction);

  return (
    <div className="container">
      <div className=" all_form_spacing">
        <div className="row mb-3">
          <div className="col-md-9"></div>
          <div className="col-md-3">
            {errorMessage !== "" ? (
              <p className="msg_fail mt-3">{errorMessage}</p>
            ) : (
              ""
            )}
          </div>
        </div>

        <ToolkitProvider
          keyField="id"
          data={docData.data}
          columns={columns}
          bootstrap4
          search
        >
          {(props) => (
            <React.Fragment>
              <div className="container">
                <div className="row align-items-end">
                  <div className="col mb-3">
                    <SearchBar srText="Search Table" {...props.searchProps} />
                  </div>
                  <div className="col mb-3">
                    <label htmlFor="dob">Start Date</label>
                    <input
                      className="form-select form-control"
                      type="date"
                      id="StartDate"
                      name="StartDate"
                      value={details.startDate}
                      max={TODAY_DATE}
                      onChange={(e) => {
                        setDetails({ ...details, startDate: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col mb-3">
                    <label htmlFor="dob">End Date</label>
                    <input
                      className="form-select form-control"
                      type="date"
                      id="birthday"
                      name="birthday"
                      value={details.endDate}
                      max={TODAY_DATE}
                      onChange={(e) => {
                        setDetails({ ...details, endDate: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col mb-3">
                    <label htmlFor="designition">Filter By Status</label>
                    <select
                      className="form-select form-control"
                      id="inputGroupSelect02"
                      onChange={(e) => {
                        setDetails({ ...details, status: e.target.value });
                      }}
                    >
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                      <option value="">All</option>
                    </select>
                  </div>
                </div>
              </div>
              <BootstrapTable
                pagination={pagination}
                filter={filterFactory()}
                {...props.baseProps}
                noDataIndication={() => {
                  return <h3>No data available </h3>;
                }}
                condensed
                hover
                bordered={false}
                tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </div>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          {/* <Modal.Title>Approve The request</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center mb-3">
            <h3>
              Approve <i>{nameForModal}</i>'s Request ?
            </h3>{" "}
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-success btn-lg me-3"
              onClick={() => updateRequestStatusHandler("approved")}
            >
              Approve
            </button>
            <button
              className="btn btn-danger btn-lg"
              onClick={() => updateRequestStatusHandler("rejected")}
            >
              Reject
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal size="xl" show={showFile} onHide={() => setShowFile(false)}>
        <Modal.Header closeButton>Attached File</Modal.Header>
        <Modal.Body>
          <div className="text-center">
            {fileURL && fileURL !== null ? (
              <img src={fileURL} alt="WFH_File img-fluid" />
            ) : (
              <Spinner />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DocumentsDetailsUpdateRequest;
