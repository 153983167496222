import React, { useState, useEffect } from "react";

//table imports
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "./LeaveSetting.css";

import { BiSearch, BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { BsCalendarCheck } from "react-icons/bs";
// import { MdOutlineExpandMore } from "react-icons/md";
import { Modal } from "react-bootstrap";

import LoadingGifs from "../../../images/YAAMS-gif.gif";
import { API_URL } from "../../../Constents";
import CreateLeaveType from "./CreateLeaveType";
import DeleteLeaveFromTheTable from "./DeleteLeaveFromTheTable";
import EditLeaveType from "./EditLeaveType";

const AllLeaveListTable = () => {
  const [leaveData, setLeaveData] = useState();
  const [modalState, setModalState] = useState({
    addLeaveModal: false,
    editLeaveModal: false,
    deleteLeaveModal: false,
  });
  const [deleteLeaveID, setDeleteLeaveID] = useState(null);
  const [editLeaveID, setEditLeaveID] = useState(null);
  // const [expand, setExpand] = useState(false);
  const [LeaveDetails, setLeaveDetails] = useState({
    name: "",
    code: "",
  });

  const fetchData = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/fetch-leave-type-admin/";

    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setLeaveData(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    modalState.deleteLeaveModal,
    modalState.addLeaveModal,
    modalState.editLeaveModal,
  ]);

  if (!leaveData)
    return (
      <div className="loadingGif">
        <img src={LoadingGifs} alt="Loading Gif" />
      </div>
    );

  console.log(leaveData);
  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return row.name + "(" + row.code + ")";
      },
    },
    {
      dataField: "employee_type",
      text: "Employee Type",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.employee_type) return row.employee_type.toString();
        else return "NA";
      },
      // formatter: (cell, row, rowIndex, formatExtraData) => {
      //   var arr = [];
      //   row.employee_type.map((i) => arr.push(i.label));
      //   return arr.toString();
      //   if (arr.length <= 3) {
      //     return arr.toString();
      //   } else {
      //     if (expand) {
      //       return arr.toString();
      //     } else {
      //       return (
      //         <>
      //           {arr.slice(0, 3).toString()}{" "}
      //           <button onClick={() => setExpand(true)}>
      //             <MdOutlineExpandMore size={20} />
      //           </button>
      //         </>
      //       );
      //     }
      //   }
      // },
    },
    { dataField: "quantity", text: "No. Of Leaves", sort: true },
    {
      dataField: "available_for",
      text: "Carry Forward",

      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.carry_forward_duration === null) return "No";
        else return row.maximum_carry_forward + " Leaves";
      },
    },
    {
      dataField: "available_for",
      text: "Duration",

      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.carry_forward_duration === null) return "NA";
        else return row.carry_forward_duration;
      },
    },
    {
      dataField: "description",
      text: "Description",
    },

    {
      dataField: "",
      text: "Edit",

      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <BiEdit
            onClick={() => {
              setModalState({ ...modalState, editLeaveModal: true });
              setEditLeaveID(row.id);
              setLeaveDetails({ name: row.name, code: row.code });
            }}
          />
        );
      },
    },
    {
      dataField: "",
      text: "Delete",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <AiFillDelete
            color="dd3944"
            onClick={() => {
              setModalState({ ...modalState, deleteLeaveModal: true });
              setDeleteLeaveID(row.id);
              setLeaveDetails({ name: row.name, code: row.code });
            }}
          />
        );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  return (
    <div className="container">
      <h2>Leave Settings</h2>
      <hr />
      <div className="d-flex align-items-start">
        {" "}
        <BsCalendarCheck size={20} className="me-3" /> <h5>Leave</h5>
      </div>
      <p> Create and edit leaves with their respective settings</p>

      <ToolkitProvider
        keyField="id"
        data={leaveData.data.org_leave}
        columns={columns}
        bootstrap4
        search
      >
        {(props) => (
          <React.Fragment>
            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="position-relative newsearch_box">
                  <SearchBar
                    className="newSearchWrapper"
                    placeholder="Search Here..."
                    srText=""
                    {...props.searchProps}
                  />
                  <BiSearch />
                </div>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-2">
                <button
                  className="departmentSettingBtn formSelectPlus "
                  onClick={() =>
                    setModalState({ ...modalState, addLeaveModal: true })
                  }
                >
                  Create Leave
                </button>
              </div>
            </div>

            <BootstrapTable
              pagination={pagination}
              filter={filterFactory()}
              {...props.baseProps}
              noDataIndication={() => {
                return <h3>No data available </h3>;
              }}
              condensed
              bordered={false}
              tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
            />
          </React.Fragment>
        )}
      </ToolkitProvider>

      <Modal
        show={modalState.addLeaveModal}
        onHide={() => setModalState({ ...modalState, addLeaveModal: false })}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateLeaveType
            leaveData={leaveData.data}
            close={() => setModalState({ ...modalState, addLeaveModal: false })}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={modalState.editLeaveModal}
        onHide={() => setModalState({ ...modalState, editLeaveModal: false })}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditLeaveType
            leaveData={leaveData.data}
            id={editLeaveID}
            close={() =>
              setModalState({ ...modalState, editLeaveModal: false })
            }
            leaveName={LeaveDetails}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={modalState.deleteLeaveModal}
        onHide={() => setModalState({ ...modalState, deleteLeaveModal: false })}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DeleteLeaveFromTheTable
            id={deleteLeaveID}
            modalClose={() =>
              setModalState({ ...modalState, deleteLeaveModal: false })
            }
            leaveName={LeaveDetails}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AllLeaveListTable;
