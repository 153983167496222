import React, { useState, useEffect } from "react";
import { API_URL } from "../../Constents";
import { Modal, ListGroup } from "react-bootstrap";
import LoadingGifs from "../../images/YAAMS-gif.gif";
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

const UserHelp = () => {
  const [ticketData, setTicketData] = useState();
  const [err, setErr] = useState();
  const [show, setShow] = useState(false);
  const [resolvedDescription, setResolvedDescription] = useState(" ");
  const [ResponseData, setResponseData] = useState("");

  const id = window.location.href.split("/").reverse()[0];
  const history = useHistory();

  useEffect(() => {
    var myHeaders = new Headers();
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    myHeaders.append("Authorization", "Token " + userData.seucrity_token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(API_URL + "/ticket-details/?ticket_id=" + id, requestOptions)
      .then((response) => response.json())
      .then((result) => setTicketData(result))
      .catch((error) => setErr(error));
  }, [id]);

  const resolveTicketHandler = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      ticket_status: "resolved",
      description: resolvedDescription,
    });

    try {
      const url =
        API_URL +
        "/update-ticket-status/?ticket_id=" +
        ticketData.data.ticket_id;
      const res = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });
      if (res.status === "400") setErr("Bad Request");
      const data = await res.json();
      setResponseData(data);
      setResolvedDescription("");
    } catch (err) {
      console.error(err);
      setErr(err);
    }
  };

  if (!ticketData)
    return (
      <div className="loadingGif">
        <img src={LoadingGifs} alt="Loading Gif" />
      </div>
    );

  if (err) return <h1>Some error occured</h1>;

  console.log(resolvedDescription);

  return (
    <div className="maincontent">
      <div className="highlight all_form_spacing">
        <div
          className="d-flex  mt-3"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <h1 className="mb-4" style={{ color: "red" }}>
            Ticket Details of {ticketData.data.employee_name} 👨‍🔧
          </h1>
          <button
            onClick={() => history.goBack()}
            className="departmentBtnSecondary"
          >
            {" "}
            <IoIosArrowBack size={20} /> Back
          </button>
        </div>
        {/* <h1 className="mb-4">
          Ticket Details of {ticketData.data.employee_name} 👨‍🔧
        </h1> */}

        <ListGroup>
          <ListGroup.Item>
            {" "}
            <h3>
              ❌{" "}
              {ticketData.data.title_name === "attendance_related"
                ? "Attendece Related"
                : ticketData.data.title_name}{" "}
            </h3>
          </ListGroup.Item>
          <ListGroup.Item>
            <h4>
              📅 Raised on {ticketData.data.generated_on} at{" "}
              {ticketData.data.generated_at}
            </h4>
          </ListGroup.Item>
          <ListGroup.Item>
            <h4> 📧 {ticketData.data.employee_email}</h4>
          </ListGroup.Item>
          <ListGroup.Item>
            <h4> 🎟 {ticketData.data.ticket_id}</h4>
          </ListGroup.Item>
          <ListGroup.Item>
            <h4>
              {} 📁{" "}
              {ticketData.data.file === null ? (
                "No File is added"
              ) : (
                // <a href={ticketData.data.file} download="ticket">
                //   View File
                // </a>
                <button
                  className="btn btn-link"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <h4>View File</h4>
                </button>
              )}
            </h4>
          </ListGroup.Item>
        </ListGroup>
        <input
          placeholder="Description of ticket ..It is optional"
          className="newSearch mt-5"
          onChange={(e) => setResolvedDescription(e.target.value)}
          style={{ width: "100%" }}
        />

        <div className="text-center">
          <button
            className="btn btn-success resolvedBtn btn-lg"
            type="button"
            onClick={resolveTicketHandler}
            value={resolvedDescription}
          >
            Issue Resolved
          </button>
          {ResponseData && ResponseData.status === true ? (
            <p className="msg_success mt-4">{ResponseData.message}</p>
          ) : ResponseData.status === false ? (
            <p className="msg_fail mt-4">{ResponseData.message}</p>
          ) : (
            ""
          )}
          {err ? (
            <p className="msg_fail mt-4">
              There is an error in updating response
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h3>Ticket Uploaded File of {ticketData.data.employee_name}</h3>
          <hr />
          <div className="text-center mt-5">
            <img
              src={ticketData.data.file}
              alt="ticket"
              className="container-fluid"
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserHelp;
