import "./ApprovedMailMessage.css";

import { Link } from "react-router-dom";

import { BASE_URL } from "../../../Constents";
import logo from "../../../images/YAAMS_bg_Img.png";

const ApprovedMailMessage = () => {
  return (
    <>
      <div className="maincontent">
        <div className="row highlight all_form_spacing">
          <div className="col-md-6  align-self-center">
            <img src={logo} alt="YAAMS logo" className=" img-fluid" />
          </div>
          <div className=" mt-5 col-md-6 align-self-center text-center">
            <div className=" ">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 130.2 130.2"
                className="approve_reject_svg"
              >
                <circle
                  className="path circle"
                  fill="none"
                  stroke="#73AF55"
                  stroke-width="6"
                  stroke-miterlimit="10"
                  cx="65.1"
                  cy="65.1"
                  r="62.1"
                />
                <polyline
                  className="path check"
                  fill="none"
                  stroke="#73AF55"
                  stroke-width="6"
                  stroke-linecap="round"
                  stroke-miterlimit="10"
                  points="100.2,40.2 51.5,88.8 29.8,67.5 "
                />
              </svg>

              <h3 className="mt-4">
                Leave request has been{" "}
                <span className="text-success">Approved</span>
              </h3>
              <p className="m-0">
                Mail and notification has been sent to the employee
              </p>
              <Link className="btn btn-outline-success mt-4" to={BASE_URL}>
                Go to Home Page
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovedMailMessage;
