import { useEffect, useState } from "react";
import {
  API_URL,
  FORMATTED_MINOR_DATE,
  RETIRING_AGE,
  TODAY_DATE,
} from "../../Constents";
import UploadEmployeeExcel from "../Global Setting/UploadEmployeeExcel";
import { AiFillPlusSquare } from "react-icons/ai";
import { useAlert } from "react-alert";
import { MultiSelect } from "react-multi-select-component";

export default function AddEmployee() {
  const [managerList, setManagerList] = useState([]);
  const [roleList, setRollList] = useState([]);
  const [LocationData, setLocationData] = useState([]);
  const [AllLables, setAllLables] = useState([]);
  const [selectedLocationAddress, setSelectedLocationAddress] = useState([]);
  const [QRMultiselect, setQRMultiselect] = useState([]);
  const [details, setDetails] = useState({
    first_name: "",
    personal_email: "",
    official_email: "",
    mobile_number: null,
    middle_name: "",
    last_name: "",
    is_manager: false,
    designation: "",
    reporting_to: "",
    date_of_birth: null,
    aadhar_number: null,
    pan_number: null,
    date_of_joining: null,
    department_assigned: null,
    is_staff: null,
  });
  // const [response, setResponse] = useState();
  // const [show, setShow] = useState(true);
  const [addEmployees, setAddEmployees] = useState(false);
  const [touched, setTouched] = useState({
    first_name: "",
  });
  const [DepartmentList, setDepartmentList] = useState([]);
  const alert = useAlert();

  const formattedLocationList = [];
  if (LocationData) {
    LocationData.map((list) => {
      return formattedLocationList.push({
        label: list.sub_name,
        value: list.id,
      });
    });
  }

  const formattedLableList = [];
  console.log(AllLables);
  if (AllLables) {
    AllLables.map((list) => {
      return formattedLableList.push({
        label: list.name + "(" + list.location_name + ")",
        value: list.id,
      });
    });
  }

  //Fetch All locations (Has all lat lng)

  const fetchAllLocations = async () => {
    const url = API_URL + "/fetch-all-locations/";

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headerList,
      });
      const data = await res.json();
      setLocationData(data.data);
    } catch (err) {
      alert.error("Error in fetching the QRs");
      console.error(err);
    }
  };

  //Fetch All QRS

  const fetchAllLableLocations = async (locationsArr) => {
    const locIDs = [];
    locationsArr.map((i) => locIDs.push(i.value));
    console.log(locIDs);
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const url = API_URL + "/get-qr-with-label/";

    let bodyContent = JSON.stringify({});

    if (locIDs.length >= 1) {
      bodyContent = JSON.stringify({
        location_id: locIDs,
      });
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      const data = await response.json();
      setAllLables(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    postDetails(details);
  };

  const postDetails = (details) => {
    var myHeaders = new Headers();

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    myHeaders.append("Authorization", "Token " + userData.seucrity_token);

    myHeaders.append("Content-Type", "application/json");

    const PatchQRIDs = [];
    QRMultiselect.map((list) => PatchQRIDs.push(list.value));

    if (PatchQRIDs.length <= 0) {
      return alert.error("Please select at lease one labels");
    }

    var raw = JSON.stringify({
      first_name: details.first_name,
      personal_email: details.personal_email,
      official_email: details.official_email,
      mobile_number: details.mobile_number,
      middle_name: details.middle_name,
      last_name: details.last_name,
      aadhar_no: details.aadhar_no,
      pan_no: details.pan_no,
      designation: details.designation,
      reporting_to: details.reporting_to,
      date_of_birth: details.date_of_birth,
      aadhar_number: details.aadhar_number,
      pan_number: details.pan_number === "" ? null : details.pan_number,
      is_manager: details.is_manager,
      date_of_joining: details.date_of_joining,
      is_staff: details.is_staff,
      alloted_qr: PatchQRIDs,
    });

    console.log(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(API_URL + "/add-fetch-candidate-details/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === true) alert.success(result.message);
        if (result.status === false) alert.success(result.message);
        setDetails({
          first_name: "",
          personal_email: "",
          official_email: "",
          mobile_number: "",
          middle_name: "",
          last_name: "",
          is_manager: false,
          designation: "",
          reporting_to: "",
          date_of_birth: "",
          aadhar_number: "",
          pan_number: "",
          date_of_joining: "",
          department_assigned: "",
        });
        // window.location.reload();
      })
      .catch((error) => {
        alert.error("Internal Server Error");
        console.log("error", error);
      });
  };

  console.log(details);
  console.log(roleList);
  console.log(DepartmentList);
  // console.log(response);

  // fetch Managers starts
  const fetchManagers = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/role";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setManagerList(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchUserRoll = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/role?all_role=True";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setRollList(data.data);
    } catch (err) {
      console.error(err);
    }
  };
  // fetch dept starts
  const fetchDepartments = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/department-list";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setDepartmentList(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchManagers();
    fetchUserRoll();
    fetchDepartments();
    fetchAllLocations();
  }, []);

  useEffect(() => {
    fetchAllLableLocations(selectedLocationAddress);
  }, [selectedLocationAddress]);

  const assignRoleHandler = (e) => {
    console.log(e.target.value);
    setDetails({ ...details, designation: e.target.value });
  };

  // if (response && response.message === "string")
  //   console.log(typeof response.message === "string"); // don't remove

  return (
    <div>
      <div className="maincontent">
        <div className="highlight all_form_spacing">
          <div className="row ">
            <div className="col-md-6">
              <button
                className="mb-3 departmentBtn text-center"
                onClick={() => setAddEmployees(!addEmployees)}
              >
                Add Employees in Bulk <AiFillPlusSquare size={20} />
              </button>
            </div>
            <div className="col-md-6 mt-3">
              {addEmployees === true ? <UploadEmployeeExcel /> : ""}
            </div>
          </div>

          <hr />
          <form
            onSubmit={submitHandler}
            method="POST"
            name="contact-form"
            className="mt-3"
          >
            <h3 style={{ color: "black" }}>Add Employee Details</h3>
            <div className="row">
              <div className="col-md-4 mb-3">
                <label htmlFor="fname">
                  First Name <span className="msg_fail">*</span>
                </label>

                <input
                  type="text"
                  id="fname"
                  placeholder={
                    touched.first_name && !details.first_name
                      ? "Enter Your First Name Mandatory"
                      : "Enter Name"
                  }
                  name="fname"
                  onChange={(e) => {
                    setDetails({ ...details, first_name: e.target.value });
                  }}
                  className="form-control"
                  value={details.first_name}
                  onFocus={() => {
                    setTouched({ ...touched, first_name: true });
                  }}
                  pattern="^[A-Za-z]+$" /// edited regex
                  title="Enter only letters without space"
                  required
                />
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="mname">Middle Name</label>
                <input
                  type="text"
                  id="mname"
                  placeholder="Enter Your Middle Name"
                  onChange={(e) => {
                    setDetails({ ...details, middle_name: e.target.value });
                  }}
                  value={details.middle_name}
                  name="mname"
                  pattern="^[A-Za-z]+$" /// edited regex
                  title="Enter only letters without space"
                  className="form-control"
                />
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="lname">Last Name</label>
                <input
                  type="text"
                  id="lname"
                  placeholder="Enter Your Last Name"
                  pattern="^[A-Za-z]+$" /// edited regex
                  title="Enter only letters without space"
                  onChange={(e) => {
                    setDetails({ ...details, last_name: e.target.value });
                  }}
                  value={details.last_name}
                  name="lname"
                  className="form-control"
                />
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="email">
                  Personal Email <span className="msg_fail">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter Personal Email"
                  name="email"
                  className="form-control"
                  onChange={(e) => {
                    setDetails({ ...details, personal_email: e.target.value });
                  }}
                  value={details.personal_email}
                  required
                />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="oemail">
                  Official Email <span className="msg_fail">*</span>
                </label>
                <input
                  type="email"
                  id="oemail"
                  placeholder="Enter Official Email"
                  name="oemail"
                  className="form-control"
                  onChange={(e) => {
                    setDetails({ ...details, official_email: e.target.value });
                  }}
                  value={details.official_email}
                  required
                />
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="mobile">
                  Mobile Number <span className="msg_fail">*</span>
                </label>
                <input
                  type="text"
                  id="mobile"
                  placeholder="Enter Mobile Number"
                  name="mobile"
                  pattern="[6789][0-9]{9}"
                  title="Enter a valid 10 digit phone number"
                  onChange={(e) => {
                    setDetails({ ...details, mobile_number: e.target.value });
                  }}
                  value={details.mobile_number}
                  className="form-control"
                  required
                />
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="DOJ">
                  Date of Joining <span className="msg_fail">*</span>
                </label>
                <input
                  className="form-select form-control"
                  type="date"
                  id="DOJ"
                  name="DOJ"
                  required
                  max={TODAY_DATE}
                  value={details.date_of_joining}
                  onChange={(e) => {
                    setDetails({ ...details, date_of_joining: e.target.value });
                  }}
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="designition">
                  Select Location (It has Lat, lng)
                  <span className="msg_fail">*</span>
                </label>

                <MultiSelect
                  options={formattedLocationList}
                  value={selectedLocationAddress}
                  onChange={setSelectedLocationAddress}
                  labelledBy="Select"
                  required
                  valueRenderer={(selected) => {
                    if (!selected.length) {
                      return "No Location Selected";
                    }

                    return selected.map(({ label }) => "📫" + label);
                  }}
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="designition">
                  Select label<span className="msg_fail">*</span>
                </label>

                <MultiSelect
                  options={formattedLableList}
                  value={QRMultiselect}
                  onChange={setQRMultiselect}
                  labelledBy="Select"
                  required
                  valueRenderer={(selected) => {
                    if (!selected.length) {
                      return "No Employee selected";
                    }
                    return selected.map(({ label }) => "🀫" + label);
                  }}
                />
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="aadhar">Employee Aadhar No.</label>
                <input
                  type="text"
                  id="aadhar"
                  placeholder="Enter Employee Aadhar No."
                  onChange={(e) => {
                    setDetails({ ...details, aadhar_number: e.target.value });
                  }}
                  value={details.aadhar_number}
                  pattern="^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$"
                  title="Enter 12 digit number in aadhar specified format"
                  name="aadhar"
                  className="form-control"
                />
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="pnumber">PAN Card Number</label>
                <input
                  type="text"
                  id="pnumber"
                  placeholder="Enter Pan Card Number"
                  pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                  title="Enter PAN in correct format"
                  onChange={(e) => {
                    setDetails({ ...details, pan_number: e.target.value });
                  }}
                  value={details.pan_number}
                  name="pnumber"
                  className="form-control"
                />
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="designition">Manager</label>
                <select
                  className="form-select"
                  id="inputGroupSelect02"
                  onChange={(e) =>
                    setDetails({ ...details, reporting_to: e.target.value })
                  }
                >
                  <option hidden disabled selected>
                    {" "}
                    -- Select Manager --{" "}
                  </option>
                  {managerList && managerList.length > 0
                    ? managerList.map((mangerData) => (
                        <option key={mangerData.id} value={mangerData.id}>
                          {mangerData.full_name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="designition">Select Role</label>
                <select
                  className="form-select form-control"
                  id="inputGroupSelect02"
                  onChange={assignRoleHandler}
                >
                  <option hidden disabled selected>
                    {" "}
                    -- Select Designation --{" "}
                  </option>
                  {roleList && roleList.length > 0 && roleList !== undefined
                    ? roleList.map((roleListData) => (
                        <option key={roleListData.id}>
                          {roleListData.role_name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="designition">Select Team</label>
                <select
                  className="form-select form-control"
                  id="inputGroupSelect02"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      department_assigned: e.target.value,
                    })
                  }
                >
                  <option hidden disabled selected>
                    {" "}
                    -- Select Team --{" "}
                  </option>
                  {DepartmentList &&
                  DepartmentList.length > 0 &&
                  DepartmentList !== undefined
                    ? DepartmentList.map((Department) => (
                        <option key={Department.id} value={Department.id}>
                          {Department.department_name} (
                          {Department.department_id})
                        </option>
                      ))
                    : ""}
                </select>
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="dob">DOB</label>
                <input
                  className="form-select form-control"
                  type="date"
                  min={RETIRING_AGE}
                  max={FORMATTED_MINOR_DATE}
                  id="birthday"
                  name="birthday"
                  value={details.date_of_birth}
                  onChange={(e) => {
                    setDetails({ ...details, date_of_birth: e.target.value });
                  }}
                />
              </div>

              <div className="col-md-4 mb-3">
                <label>Is Manager ?</label>
                <br />
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={details.is_manager}
                    onChange={(e) =>
                      setDetails({ ...details, is_manager: e.target.checked })
                    }
                  />
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <label>Is Administrator ?</label>
                <br />
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={details.is_staff}
                    onChange={(e) => {
                      setDetails({ ...details, is_staff: e.target.checked });
                      if (e.target.checked)
                        alert.info(
                          "CAUTION: User will have the access to everything in the app"
                        );
                    }}
                  />
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
