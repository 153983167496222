import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

//import AddANewOfficeAddress from "../QR Code/AddANewOfficeAddress";
import ViewAndGenerateQR from "../QR Code/ViewAndGenerateQR";
import { API_URL } from "../../Constents";

const GlobalAttandanceSetting = () => {
  const [details, setDetails] = useState({
    check_in_time: null,
    check_out_time: null,
    is_round_off: false,
    round_off_time: null,
  });
  const [response, setResponse] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));

      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
      };

      const url = API_URL + "/find-shift/";
      try {
        const res = await fetch(url, { method: "GET", headers: headersList });
        const data = await res.json();
        setDetails({
          check_in_time: data.data.check_in_time,
          check_out_time: data.data.check_out_time,
          is_round_off: data.data.is_round_off,
          round_off_time: data.data.round_off_time,
        });
      } catch (err) {
        console.err(err);
      }
    })();
  }, []);

  const attandanceSettingHandler = (e) => {
    e.preventDefault();

    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));

      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        check_in_time: details.check_in_time,
        check_out_time: details.check_out_time,
        is_round_off: details.is_round_off,
        round_off_time:
          details.round_off_time === "" ? null : details.round_off_time,
      });

      const url = API_URL + "/attendance-general-settings/";

      try {
        const res = await fetch(url, {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        });

        const data = await res.json();
        setResponse(data);
        // setDetails({
        //   check_in_time: "",
        //   check_out_time: "",
        //   is_round_off: false,
        //   round_off_time: "",
        // });
      } catch (err) {
        console.error(err);
        setResponse({
          status: false,
          message: err,
        });
      }
    })();
  };

  console.log(details);
  console.log(response);
  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <h3 style={{ color: "red" }}>Organization's Check-In Setting</h3>
        </div>
        <div className="col-md-4">
          <button className="btn btn-warning" onClick={() => setShow(true)}>
            Set Timing
          </button>
        </div>
      </div>

      <hr />
      <ViewAndGenerateQR />

      <div className="row align-items-center w-100">
        <div className="row">
          {/* <div className="col-md-8">
            <h3 style={{ color: "red" }}>Add A New Office Location</h3>
          </div> */}
          {/* <div className="col-md-4">
            <button className="btn btn-warning" onClick={() => setShow(true)}>
              Add Location
            </button>
          </div> */}
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "red" }}>
          Check-In Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={attandanceSettingHandler} className="m-2">
            {/* <h3 style={{ color: "red" }}></h3> */}
            <div className="row">
              <div className="col-md-4 col-lg- mt-4">
                <label htmlFor="carryduration">Check-in Time</label>
                <input
                  type="time"
                  id="carryduration"
                  placeholder="Enter Check-in Time"
                  name="carryduration"
                  style={{ width: "220px" }}
                  className="form-control checkinTime"
                  required
                  value={details.check_in_time}
                  onChange={(e) =>
                    setDetails({ ...details, check_in_time: e.target.value })
                  }
                />
              </div>
              <div className="col-md-4 col-lg- mt-4 mx-5">
                <label htmlFor="carryduration">Check-out Time</label>
                <input
                  type="time"
                  id="carryduration"
                  placeholder="Enter Check-in Time"
                  name="carryduration"
                  style={{ width: "220px"}}
                  className="form-control"
                  value={details.check_out_time}
                  onChange={(e) =>
                    setDetails({ ...details, check_out_time: e.target.value })
                  }
                  required
                />
              </div>
            </div>

            <div className="col-md-4 col-lg- mt-4">
              <input
                type="checkbox"
                id="carry"
                name="carry"
                className="me-2"
                checked={details.is_round_off}
                onChange={(e) => {
                  setDetails((prevState) => ({
                    ...prevState,
                    is_round_off: e.target.checked,
                  }));
                  if (e.target.checked === false)
                    setDetails((prevState) => ({
                      ...prevState,
                      round_off_time: "",
                    }));
                }}
              />
              <label for="leave1"> Round-off</label>
            </div>
            <div className="col-md-4 col-lg- mt-4">
              <label htmlFor="carryleave">Round-off Time</label>
              <input
                type="number"
                min="1"
                max="60"
                step="1"
                id="carryleave"
                placeholder="In minutes"
                name="carryleave"
                className="form-control mb-3"
                required
                value={details.round_off_time}
                onChange={(e) =>
                  setDetails({ ...details, round_off_time: e.target.value })
                }
                disabled={!details.is_round_off}
              />
            </div>
            {response && response.status === true ? (
              <b style={{ color: "green" }}>{response.message}</b>
            ) : response && response.status === false ? (
              <b style={{ color: "red" }}>{response.message}</b>
            ) : (
              ""
            )}

            <div className="text-end w-100 my-4">
              <button className="save_btn ms-4" type="submit" onClick={() => setShow(false)}>
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
        {/* <Modal.Footer style={{ justifyContent: "center" }}>
          <button
            className="modalBtn"
            onClick={() => setShow(false)}
            style={{ width: "30%", fontSize: "20px" }}
          >
            Close
          </button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default GlobalAttandanceSetting;
