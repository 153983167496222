import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_URL, BASE_URL } from "../../Constents";
import { useAlert } from "react-alert";
const FetchBirthdayList = () => {
  const [birthdayList, setBirthdayList] = useState([]);
  // const [wishResponse, setWishResponse] = useState({});
  const alert = useAlert();
  const fetchList = async () => {
    const url = API_URL + "/fetch-birthday-list/";
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    try {
      const res = await fetch(url, { method: "GET", headers: headersList });
      const data = await res.json();
      setBirthdayList(data.data);
    } catch (err) {
      alert.error("There is an error in fetching birthday list");
      // setErr(err);
    }
  };

  const sendWishesHandler = async (id) => {
    console.log(id);

    const url = API_URL + "/send-wishes/";
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      user_id: id,
    });

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: headersList,
        body: bodyContent,
      });
      const data = await res.json();
      //setWishResponse(data);
      if (data.status) alert.success(data.message);
      else alert.error(data.message);
    } catch (error) {
      alert.error("Internal server error");
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  console.log(birthdayList);

  return (
    <div className="highlight">
      {/* {wishResponse && wishResponse.status === true ? (
        <p className="msg_success">{wishResponse.message}</p>
      ) : wishResponse.status === false ? (
        <p className="msg_fail">{wishResponse.message}</p>
      ) : (
        ""
      )} */}
      <h4>
        <Link to={BASE_URL + "#"}>Upcoming Events</Link>
      </h4>
      <div className="overflow-events">
        {birthdayList.length === 0
          ? "No Upcoming Events 😔"
          : birthdayList.map((events, index = 0) => {
              index++;
              if (events.type === "birthday") {
                return (
                  <div className="onevent" key={index}>
                    <p>
                      <b>{events.date_of_birth}</b>
                      <br />
                      {events.first_name}'s Birthday 🎉
                    </p>
                    <Link onClick={() => sendWishesHandler(events.id)}>
                      Send Wish
                    </Link>
                  </div>
                );
              } else {
                return (
                  <div className="onevent" key={index}>
                    <p>
                      <b>{events.date_of_joining}</b>
                      <br />
                      {events.first_name}'s Work Anniversary 💼
                    </p>
                    <Link onClick={() => sendWishesHandler(events.id)}>
                      Send Wish
                    </Link>
                  </div>
                );
              }
            })}
      </div>
    </div>
  );
};
export default FetchBirthdayList;
