//This is a long component I know but the requirements changes everyday soooooo, I implemeted like this.
// Sorry for not using useReducer
// all the functions and variable are self exlanatory

import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";

// table imports
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Icon imports
import { BsBoxArrowUpRight } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { AiFillFlag } from "react-icons/ai";

import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";

import {
  API_URL,
  TODAY_DATE,
  BASE_URL,
  ONE_WEEK_DATE,
  ONE_MONTH_DATE,
  LAST_SIX_MONTHS,
  YESTERDAY_DATE,
} from "../../Constents";

import LoadingGifs from "../../images/YAAMS-gif.gif";

import FormattedToNameFormat from "../Hooks/FormattedToNameFormat";
import ModalEmpoyeeDetails from "../Modals/ModalEmployeeDetails";
import UpdateAttendance from "../NavBarComp/UpdateAttendance";

const Attendence = () => {
  const [attendance, setAttendence] = useState();
  const [err, setErr] = useState();
  const [startDate, setStartDate] = useState(TODAY_DATE);
  const [endDate, setEndDate] = useState(TODAY_DATE);
  const [departmentList, setDepartmentList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [allLocationList, setAllLocationList] = useState([]);
  const [departmentID, setDepartmentID] = useState("");
  const [timeForUpdate, setTimeForUpdate] = useState("");
  const [checkinDate, setCheckinDate] = useState("");
  const [showEmp, setShowEmp] = useState(false);
  const [departmentMultiSelectList, setDepartmentMultiSelectList] = useState(
    []
  );
  const [employeeMultiSelectList, setEmployeeMultiSelectList] = useState([]);
  const [locationMultiSelectList, setLocationMultiSelectList] = useState([]);
  //const [radioDataForEmployees, setRadioDataForEmployees] = useState({});
  const [PDFlink, setPDFlink] = useState(null);
  const [toggleDateView, setToggleDateView] = useState(false);
  const [show, setShow] = useState(false);
  const [modalId, setModalId] = useState();
  const [toggleUpdateAttandanceFunction, setToggleUpdateAttandanceFunction] =
    useState(false);
  const [attendanceMsg, setAttendanceMsg] = useState("");
  const [attendanceType, setAttendanceType] = useState("present_count");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var fileName = "flaviant_" + startDate + ".csv";
  const formattedDept = [];
  departmentList.map((list) => {
    return formattedDept.push({
      label: list.department_name + " (" + list.department_id + ")",
      value: list.id,
    });
  });

  const formattedLocation = [];
  allLocationList.map((list) => {
    return formattedLocation.push({
      label: list.sub_name,
      value: list.id,
    });
  });

  const formattedEmployee = [];
  employeeList.map((list) => {
    return formattedEmployee.push({
      label: list.full_name + "-" + list.id + " (" + list.official_email + ")",
      value: list.id,
    });
  });

  const fetchData = useCallback(async () => {
    if (show) return;

    const deptIDlist = [];
    const locationIDlist = [];

    locationMultiSelectList.map((list) => {
      return locationIDlist.push(list.value);
    });

    departmentMultiSelectList.map((list) => {
      return deptIDlist.push(list.value);
    });

    if (startDate === endDate) {
      const url =
        API_URL +
        "/fetch-today-attendance/?start_date=" +
        startDate +
        "&last_date=" +
        endDate +
        "&attendance_status=" +
        attendanceType;

      const userData = JSON.parse(window.sessionStorage.getItem("user"));

      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        department_array: deptIDlist,
        location_array: locationIDlist,
      });

      try {
        const response = await fetch(url, {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        });
        const result = await response.json();
        console.log("-------------", result);
        setAttendence(
          result.data.map((datadb, index) => {
            // console.log(datadb.delay_by);

            return {
              ...datadb,
              index: index + 1,
              check_in: !datadb.check_in
                ? "--:--"
                : moment(datadb.check_in).format("h:mm a"),
              check_in2: !datadb.check_in
                ? "--:--"
                : moment(datadb.check_in).format("hh:mm"),
              check_in_Date: !datadb.check_in
                ? "--:--"
                : moment(datadb.check_in).format("YYYY-MM-DD"),

              check_out: !datadb.check_out
                ? "--:--"
                : // : moment(datadb.check_out).format("MMMM Do, h:mm a"),
                  moment(datadb.check_out).format("h:mm a"),

              attendance_status:
                datadb.attendance_status === "evening_leave_count"
                  ? "Half Day (Evening)"
                  : datadb.attendance_status === "morning_leave_count"
                  ? "Half Day (Morning)"
                  : datadb.attendance_status === null
                  ? "Not Punched In"
                  : datadb.attendance_status === "leave_count"
                  ? "On Leave"
                  : datadb.attendance_status === "present_count"
                  ? "On Time"
                  : datadb.delay_by &&
                    datadb.delay_by.split(":")[1] === "00" &&
                    datadb.delay_by.split(":")[0] === "00" &&
                    datadb.attendance_status === "late_count"
                  ? "On Time"
                  : datadb.attendance_status &&
                    datadb.attendance_status === "late_count" &&
                    datadb.delay_by.split(":")[0] === "00"
                  ? datadb.delay_by && datadb.delay_by.split(":")[1] + "m late"
                  : datadb.delay_by.split(":")[0] +
                    "hr " +
                    datadb.delay_by.split(":")[1] +
                    "m late",

              // attendance_status:
              //   datadb.early_by === null && datadb.delay_by === null
              //     ? "NA"
              //     : datadb.attendance_status === "present_count" &&
              //       datadb.early_by.split(":")[1] === "00" &&
              //       datadb.early_by.split(":")[0] === "00"
              //     ? "On Time"
              //     : datadb.attendance_status === "present_count" &&
              //       datadb.early_by.split(":")[0] === "00"
              //     ? "+" + datadb.early_by.split(":")[1] + "m"
              //     : datadb.attendance_status === "present_count"
              //     ? "+" +
              //       datadb.early_by.split(":")[0] +
              //       "hr " +
              //       datadb.early_by.split(":")[1] +
              //       "m"
              //     : datadb.delay_by.split(":")[1] === "00" &&
              //       datadb.delay_by.split(":")[0] === "00" &&
              //       datadb.attendance_status === "late_count"
              //     ? "On Time"
              //     : datadb.delay_by.split(":")[0] === "00"
              //     ? "-" + datadb.delay_by.split(":")[1] + "m"
              //     : "-" +
              //       datadb.delay_by.split(":")[0] +
              //       "hr " +
              //       datadb.delay_by.split(":")[1] +
              //       "m",

              break_duration:
                datadb.break_duration === null
                  ? "Not Taken"
                  : datadb.break_duration,

              total_working_hour:
                datadb.total_working_hour === null
                  ? "Not Checked out"
                  : datadb.total_working_hour,
              full_name:
                datadb.full_name === null
                  ? datadb.first_name
                  : datadb.full_name,
              attendance_color: datadb.attendance_status,
            };
          })
        );
      } catch (err) {
        console.error(err);
        setErr(err);
      }
    }
  }, [
    startDate,
    show,
    endDate,
    locationMultiSelectList,
    departmentMultiSelectList,
    attendanceType,
    toggleUpdateAttandanceFunction,
  ]);

  const exportAttendanceData = async (docType) => {
    setAttendanceMsg("We're preparing your file please wait !!!!");

    const deptIDlist = [];
    const employeeIDlist = [];
    const locationIDlist = [];

    departmentMultiSelectList.map((list) => {
      return deptIDlist.push(list.value);
    });

    employeeMultiSelectList.map((list) => {
      return employeeIDlist.push(list.value);
    });

    locationMultiSelectList.map((list) => {
      return locationIDlist.push(list.value);
    });

    const bodyContent = JSON.stringify({
      start_date: startDate,
      last_date: endDate,
      department_id: deptIDlist,
      user_id: employeeIDlist,
      location_id: locationIDlist,
      // type: docType,
    });

    const url = API_URL + "/attendance-pdf-download/";

    try {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: {
          Authorization: "Token " + userData.seucrity_token,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();

      setPDFlink(data.data);
      setAttendanceMsg(
        "PDF Exported, this will reflect in the downloads section"
      );
    } catch (err) {
      console.error(err);
      setAttendanceMsg("There was an error in exporting the file!!!!");
    }
  };

  // fetch dept starts
  const fetchDepartments = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/department-list";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setDepartmentList(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchAllLocations = async () => {
    const url = API_URL + "/fetch-all-locations/";

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headerList,
      });
      const data = await res.json();

      setAllLocationList(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchEmployeeList = async () => {
    const deptIDlist = [];
    departmentMultiSelectList.map((list) => {
      return deptIDlist.push(list.value);
    });
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const url = API_URL + "/employee-list-dashboard/";

    const bodyContent = JSON.stringify({
      department_array: deptIDlist,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headerList,
        body: bodyContent,
      });

      const data = await response.json();
      setEmployeeList(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchEmployeeList();
  }, [departmentMultiSelectList]);

  useEffect(() => {
    fetchDepartments();
    fetchAllLocations();
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (PDFlink === null) {
      return;
    } else {
      window.open(PDFlink);
    }
  }, [PDFlink]);

  if (err) return <h1>Some error occured</h1>;

  console.log(attendance);
  console.log(attendanceType);

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "employee_id",
      text: "#",
      sort: true,

      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="d-flex justify-content-center mt-2">
            <Link
              to={BASE_URL + "UpdateUser/" + row.employee_id}
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title={row.employee_id}
              style={{ textDecoration: "none" }}
            >
              <FaEdit />
            </Link>
          </div>
        );
      },
    },
    {
      dataField: "full_name",
      text: "Name",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <Button
            variant="link"
            style={{ color: "#3E3E3E", textDecoration: "none" }}
            onClick={() => {
              setModalId(row.employee_id);
              setShowEmp(true);
            }}
          >
            {row.full_name}
            {/* {FormattedToNameFormat(row.full_name)} */}
            <BsBoxArrowUpRight
              style={{
                fontSize: "10px",
                marginLeft: "3px",
                marginBottom: "10px",
                color: "#3E3E3E",
                fontWeight: "600",
              }}
            />
          </Button>
        );
      },
    }, //filter: textFilter() },
    // { dataField: "check_in", text: "PunchIn", sort: true },
    {
      dataField: "check_in",
      text: "PunchIn",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <p className="m-0">
              {row.check_in}{" "}
              {row.updated_by === null ? (
                ""
              ) : (
                <div
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title={
                    "Updated by " +
                    row.updated_by +
                    " " +
                    "from " +
                    moment(row.created_at).format("h:mm a")
                  }
                  style={{ display: "inline" }}
                >
                  <AiFillFlag color="red" />
                </div>
              )}
            </p>
            {row.attendance_status === null ||
            row.attendance_status === "Not Punched In" ? (
              ""
            ) : (
              <p style={{ fontSize: 10 }} className="m-0">
                (
                {row.gate_label === null && row.working_from_home === true
                  ? "WFH"
                  : row.gate_label === null && row.working_from_home === false
                  ? "Not Available"
                  : row.gate_label}
                )
              </p>
            )}
          </>
        );
      },
    },

    {
      dataField: "check_out",
      text: "PunchOut",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <p className="m-0">{row.check_out}</p>{" "}
            {row.attendance_status === null ||
            row.attendance_status === "Not Punched In" ? (
              ""
            ) : (
              <p style={{ fontSize: 10 }} className="m-0">
                (
                {row.gate_label_out === null && row.working_from_home === true
                  ? "WFH"
                  : (row.gate_label_out === null &&
                      row.working_from_home === false) ||
                    row.atten
                  ? "Not Available"
                  : row.gate_label_out}
                )
              </p>
            )}
          </>
        );
      },
    },

    {
      dataField: "break_duration",
      text: "Break",
      sort: true,
    },
    {
      dataField: "total_working_hour",
      text: "Hours",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        //console.log(cell, rowIndex, formatExtraData);
        if (row.total_working_hour === null) return "Not Checked out";
        else return row.total_working_hour;
      },
    },
    // {
    //   dataField: "working_from_home",
    //   text: "WFH",
    //   sort: true,
    //   formatter: (cell, row, rowIndex, formatExtraData) => {
    //     //console.log(cell, rowIndex, formatExtraData);
    //     if (row.working_from_home === true)
    //       return <p style={{ color: "green" }}>Yes</p>;
    //     else return "No";
    //   },
    // },

    {
      dataField: "attendance_status",
      text: "Status",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (
          row.attendance_color === "present_count" ||
          row.attendance_status === "On Time"
        )
          return (
            <div>
              <Button
                variant="link"
                style={{ color: "green", textDecoration: "none" }}
                className="p-0"
                onClick={() => {
                  handleShow();
                  setModalId(row.id);
                  setTimeForUpdate(row.check_in2);
                  setCheckinDate(row.check_in_Date);
                }}
              >
                {row.attendance_status}
                <BsBoxArrowUpRight
                  style={{
                    fontSize: "10px",
                    marginLeft: "3px",
                    marginBottom: "10px",
                    color: "green",
                    fontWeight: "600",
                  }}
                />
              </Button>
            </div>
          );
        else if (row.attendance_status === "Not Punched In") {
          return <p style={{ color: "orange" }}>{row.attendance_status}</p>;
        } else if (row.attendance_status === "On Leave") {
          return <p style={{ color: "blue" }}>{row.attendance_status}</p>;
        } else
          return (
            <div>
              <Button
                variant="link"
                className="p-0"
                style={{ color: "red", textDecoration: "none" }}
                onClick={() => {
                  handleShow();
                  setModalId(row.id);
                  setTimeForUpdate(row.check_in2);
                  setCheckinDate(row.check_in_Date);
                }}
              >
                {row.attendance_status}
                <BsBoxArrowUpRight
                  style={{
                    fontSize: "10px",
                    marginLeft: "3px",
                    marginBottom: "10px",
                    color: "red",
                    fontWeight: "600",
                  }}
                />
              </Button>
            </div>
          );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50,
    lastPageText: "End",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  const updateAttandanceHandler = () => {
    setToggleUpdateAttandanceFunction(true);
    setShow(false);
  };

  const assignDepartmentHandler = (depID) => {
    setDepartmentID(depID);
  };

  return !attendance ? (
    <div className="loadingGif">
      <img src={LoadingGifs} alt="Loading Gif" />
    </div>
  ) : (
    <div className="maincontent">
      <div className="highlight all_form_spacing">
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <UpdateAttendance
              id={modalId}
              time={timeForUpdate}
              date={checkinDate}
              setToggle={() => setToggleUpdateAttandanceFunction(false)}
              toggle={toggleUpdateAttandanceFunction}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={updateAttandanceHandler}>
              Update Time
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal size="xl" show={showEmp} onHide={() => setShowEmp(false)}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <ModalEmpoyeeDetails id={modalId} />
          </Modal.Body>
        </Modal>
        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={attendance}
          columns={columns}
          ignoreHeader
          exportCSV={{
            fileName: fileName,
          }}
          search
        >
          {(props) => (
            <React.Fragment>
              <div className="container">
                <div className="d-flex justify-content-between">
                  <h2 style={{ color: "#dd3944" }}>Attendance</h2>

                  <select
                    className="form-select w-25"
                    onChange={(e) => setAttendanceType(e.target.value)}
                  >
                    <option value="present_count">Punched In Employees</option>
                    <option value="leave_count">Employees On Leave</option>
                    <option value="not_checked_in">
                      Not Checked In Employees
                    </option>
                    <option value="All">All Employees</option>
                  </select>
                </div>

                <div className="row">
                  <div className="col-md-3 mt-1">
                    {/* <div className="newSearchContainer">
                      <SearchBar
                        srText="Search Table"
                        {...props.searchProps}
                        className="newSearch"
                        style={{ marginTop: "-5px" }}
                      />
                    </div> */}
                    <div className="position-relative newsearch_box mt-4">
                      <SearchBar
                        className="newSearchWrapper"
                        placeholder="Search Here..."
                        srText=""
                        {...props.searchProps}
                      />
                      <BiSearch />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="endDate" className="">
                      Select Location
                    </label>
                    <MultiSelect
                      options={formattedLocation}
                      value={locationMultiSelectList}
                      onChange={setLocationMultiSelectList}
                      labelledBy="Select"
                      //shouldToggleOnHover
                      valueRenderer={(selected) => {
                        if (!selected.length) {
                          return "No Location selected";
                        }

                        return selected.map(({ label }) => "✔️ " + label);
                      }}
                    />
                  </div>

                  {toggleDateView === true ? (
                    <>
                      <div className="col-md-3">
                        <label htmlFor="dob" className="mb-2">
                          Start Date
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          id="StartDate"
                          name="StartDate"
                          value={startDate}
                          max={TODAY_DATE}
                          onChange={(e) => {
                            setStartDate(e.target.value);
                          }}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="endDate" className="mb-2">
                          End Date
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          id="enddate"
                          name="endDate"
                          value={endDate}
                          max={TODAY_DATE}
                          onChange={(e) => {
                            setEndDate(e.target.value);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row align-items-end my-2 mb-3">
                  <div className="col-md-3">
                    <lable>Select Duration</lable>
                    <select
                      className="form-select mt-1"
                      onChange={(e) => {
                        if (e.target.value === "cd") {
                          setToggleDateView(true);
                        } else {
                          setStartDate(() => e.target.value);
                          setToggleDateView(false);
                        }

                        if (e.target.value === "td") {
                          setStartDate(TODAY_DATE);
                          setEndDate(TODAY_DATE);
                        }

                        if (e.target.value === "yd") {
                          setStartDate(YESTERDAY_DATE);
                          setEndDate(YESTERDAY_DATE);
                        }

                        //setEndDate(() => e.target.value);
                      }}
                    >
                      <option disabled selected>
                        Select Duration
                      </option>
                      <option value="td">Today</option>
                      <option value="yd">Yesterday</option>
                      <option value={ONE_WEEK_DATE}>This Week (7 Days)</option>
                      <option value={ONE_MONTH_DATE}>
                        This Month (30 Days)
                      </option>
                      <option value={LAST_SIX_MONTHS}>Last 6 Month</option>
                      <option value="cd">Custom Date</option>
                    </select>
                  </div>

                  <div className="col-md-3">
                    <label htmlFor="">Select Department</label>
                    <MultiSelect
                      options={formattedDept}
                      value={departmentMultiSelectList}
                      onChange={setDepartmentMultiSelectList}
                      //labelledBy="Select"
                      //shouldToggleOnHover
                      valueRenderer={(selected) => {
                        if (!selected.length) {
                          return "No Department selected";
                        }

                        return selected.map(({ label }) => "✔️ " + label);
                      }}
                    />
                  </div>

                  <div className="col-md-3">
                    <label htmlFor="">Select Employees</label>
                    <MultiSelect
                      options={formattedEmployee}
                      value={employeeMultiSelectList}
                      onChange={setEmployeeMultiSelectList}
                      //labelledBy="Select"
                      // shouldToggleOnHover
                      valueRenderer={(selected) => {
                        if (!selected.length) {
                          return "No Employee Selected";
                        }

                        return selected.map(({ label }) => "✔️ " + label);
                      }}
                    />
                  </div>

                  <div className="dropdown col-md-3">
                    <div className="d-grid gap-2">
                      <button
                        className="btn btn-outline-warning dropdown-toggle mt-3"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Export As
                      </button>

                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => exportAttendanceData("PDF")}
                          >
                            As PDF
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {attendanceMsg === "" ? (
                  ""
                ) : (
                  <p className="msg_fail">{attendanceMsg}</p>
                )}
                <BootstrapTable
                  pagination={pagination}
                  filter={filterFactory()}
                  {...props.baseProps}
                  bordered={false}
                  noDataIndication={() => {
                    return <h3>No data available for this date</h3>;
                  }}
                  condensed
                />
              </div>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
};

export default Attendence;
