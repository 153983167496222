import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";

const RoleList = () => {
  const [list, setList] = useState([]);
  const userData = JSON.parse(window.sessionStorage.getItem("user"));

  useEffect(() => {
    (async () => {
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
      };

      try {
        const url = API_URL + "/role?all_role=True";
        const res = await fetch(url, { method: "GET", headers: headersList });
        const data = await res.json();
        setList(data.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  console.log(list);

  return (
    <div className="container">
      <h3>Role List for {userData.data.first_name}</h3>
      <div className="data_table mt-4">
        <div className="table_fil d-flex justify-content-between">
          <table>
            <tbody>
              <tr>
                <th>#</th>
                <th>Role Name</th>
                <th>Role ID</th>
              </tr>
              {list && list.length > 0
                ? list.map((roles, index = 0) => {
                    index++;
                    return (
                      <tr>
                        <td>{index} </td>
                        <td>{roles.role_name} </td>
                        <td>{roles.role_id} </td>
                      </tr>
                    );
                  })
                : "Loading..."}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RoleList;
