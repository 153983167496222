import { Tabs, Tab } from "react-bootstrap";
import { FcNext, FcPrevious } from "react-icons/fc";
import { useState } from "react";

const OrganizationSettings = () => {
  //   const [tabSelector, setTabSelector] = useState(1);
  //   console.log(typeof tabSelector);
  const [key, setKey] = useState(1);

  console.log(typeof key);

  return (
    <div className="container">
      <div className="highlight all_form_spacing">
        <h3 style={{ color: "red", marginBottom: 20 }}>
          Organization Quick Settings
        </h3>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(+k)}
          // className="mb-3 updateUserTabs"
          className="mb-3"
        >
          <Tab eventKey={1} title="1. Add Admin">
            {" "}
            <p>1</p>
          </Tab>
          <Tab eventKey={2} title="2. Create Teams/Departments">
            <p>2</p>
          </Tab>
          <Tab eventKey={3} title="3. Create Roles & Designition">
            <p>3</p>
          </Tab>
          <Tab eventKey={4} title="4. Add Office Addresses">
            <p>4</p>
          </Tab>
          <Tab eventKey={5} title="5. Add Gates">
            <p>5</p>
          </Tab>
          <Tab eventKey={6} title="6. Generate QR">
            <p>6</p>
          </Tab>
          <Tab eventKey={7} title="7. Create Shifts">
            <p>7</p>
          </Tab>
          <Tab eventKey={8} title="8. Create Leave Rules">
            <p>8</p>
          </Tab>
        </Tabs>
        <div className="d-flex justify-content-around">
          <button
            className="btn btn-primary"
            onClick={() => setKey((state) => state - 1)}
            disabled={key === 1}
          >
            <FcPrevious /> Previous
          </button>
          <button
            className="btn btn-primary"
            onClick={() => setKey((state) => state + 1)}
            disabled={key === 8}
          >
            Next <FcNext />
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrganizationSettings;
