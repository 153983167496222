import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function VacanciesInfo({ dashboardData }) {
  console.log(dashboardData);
  return (
    <>
      <h2 className="ms-4 mb-2" style={{ color: "#DD3974" }}>
        Recruitments
      </h2>

      <div className="row recruitparent">
        <div className="col-md-6 ">
          <Link style={{ textDecoration: "none" }} to={"/vacancy/All"}>
            <div className="maincontent ">
              <div className="recruit_highlight ">
                {dashboardData && dashboardData.length !== 0 ? (
                  <>
                    <h1>{dashboardData[0].vacancy_all}</h1>
                  </>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-between align-items-center">
                  <h3>All Vacancies</h3>

                  <BsArrowRight size={"30"} />
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-md-6">
          <Link style={{ textDecoration: "none" }} to={"/vacancy/Active"}>
            <div className="maincontent">
              <div className="recruit_highlight">
                {dashboardData && dashboardData.length !== 0 ? (
                  <>
                    <h1>{dashboardData[0].vacancy_active}</h1>
                  </>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3>Active Vacancies</h3>
                  </div>
                  <div>
                    <BsArrowRight size={"30"} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-6">
          <Link style={{ textDecoration: "none" }} to={"/vacancy/Expired"}>
            <div className="maincontent">
              <div className="recruit_highlight">
                {dashboardData && dashboardData.length !== 0 ? (
                  <>
                    <h1>{dashboardData[0].vacancy_expire}</h1>
                  </>
                ) : (
                  ""
                )}
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h3>Expired Vacancies</h3>
                  </div>
                  <div>
                    <BsArrowRight size={"30"} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}
