import { useState, useEffect } from "react";
import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { API_URL } from "../../Constents";
import LoadingGifs from "../../images/YAAMS-gif.gif";

import { BiSearch } from "react-icons/bi";

import ReverseDateString from "../Hooks/ReverseDateString";

const ExEmployeeList = () => {
  const [exEmployeeData, setExEmployeeData] = useState();

  const [err, setErr] = useState();

  const fetchExEmployeeData = async () => {
    const url = API_URL + "/ex-employee-list/";

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerlist = {
      Authorization: "Token " + userData.seucrity_token,
    };

    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headerlist,
      });
      const data = await res.json();
      setExEmployeeData(data);
    } catch (err) {
      setErr(err);
    }
  };

  useEffect(() => {
    fetchExEmployeeData();
  }, []);

  console.log(exEmployeeData);

  console.log(err);
  if (!exEmployeeData)
    return (
      <div className="loadingGif">
        <img src={LoadingGifs} alt="Loading Gif" />
      </div>
    );
  if (err) return <h1>Some error occured</h1>;

  const { SearchBar } = Search;

  const columns = [
    { dataField: "employee_name", text: "Name", sort: true },
    { dataField: "aadhar_number", text: "Aadhar", sort: true },
    {
      dataField: "date_of_joining",
      text: "DOJ",
      sort: true,
      formatter: (row) => ReverseDateString(row),
    },
    {
      dataField: "date_of_leaving",
      text: "DOL",
      sort: true,
      formatter: (row) => ReverseDateString(row),
    },
    {
      dataField: "reason_of_leaving",
      text: "Reason For Leaving",
      sort: true,
      formatter: (row) => {
        if (row === null || row === "") return "No Reason Entered";
        else return row;
      },
    },
    {
      dataField: "served_notice_period",
      text: "Notice Period Served",
      sort: true,
      formatter: (row) => {
        if (row === true) return <p style={{ color: "green" }}>Yes</p>;
        if (row === false) return <p style={{ color: "red" }}>Yes</p>;
      },
    },
    {
      dataField: "company_asset_submited",
      text: "Asset Submited",
      sort: true,
      formatter: (row) => {
        if (row === true) return <p style={{ color: "green" }}>Yes</p>;
        if (row === false) return <p style={{ color: "red" }}>No</p>;
      },
    },
    {
      dataField: "expirence_letter_given",
      text: "Issued Exp Letter",
      sort: true,
      formatter: (row) => {
        if (row === true) return <p style={{ color: "green" }}>Yes</p>;
        if (row === false) return <p style={{ color: "red" }}>No</p>;
      },
    },
    {
      dataField: "third_party_qualtiy_verification",
      text: "3rd Party Verification",
      sort: true,
      formatter: (row) => {
        if (row === true) return <p style={{ color: "green" }}>Done</p>;
        if (row === false) return <p style={{ color: "red" }}>Not Done</p>;
      },
    },
    {
      dataField: "can_be_rehired",
      text: "Can Be Rehired",
      sort: true,
      formatter: (row) => {
        if (row === true) return <p style={{ color: "green" }}>Yes</p>;
        if (row === false) return <p style={{ color: "red" }}>No</p>;
      },
    },
    {
      dataField: "remarks",
      text: "Remarks",
      sort: true,
      formatter: (row) => {
        if (row === null || row === "") return "No Remarks";
        else return row;
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: "End",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  return (
    <div className="maincontent ">
      <div className="highlight all_form_spacing">
        <h2 style={{ color: "#DD3944" }} className="mx-2">
          Ex-Employees
        </h2>
        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={exEmployeeData.data}
          columns={columns}
          ignoreHeader
          //   exportCSV={{
          //     fileName: "Leave.csv",
          //   }}
          search
        >
          {(props) => (
            <React.Fragment>
              <div className="container">
                <div className="row">
                  <div className="col-md-3 mb-2">
                    {/* <SearchBar
                      srText="Search Table"
                      {...props.searchProps}
                      className="newSearch"
                      style={{ border: "none" }}
                    /> */}
                    <div className="position-relative newsearch_box">
                      <SearchBar
                        className="newSearchWrapper"
                        placeholder="Search Here..."
                        srText=""
                        {...props.searchProps}
                      />
                      <BiSearch />
                    </div>
                  </div>
                </div>

                <BootstrapTable
                  pagination={pagination}
                  filter={filterFactory()}
                  // bordered={false}
                  {...props.baseProps}
                  condensed
                  bordered={false}
                  noDataIndication={() => {
                    return <h3>No data</h3>;
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
};

export default ExEmployeeList;
