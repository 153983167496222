import { useEffect, useState, useCallback } from "react";
import userdummyDP from "../../images/DummyUserDP.png";
import { Link } from "react-router-dom";
import { API_URL, BASE_URL } from "../../Constents";
import { BsBoxArrowUpRight } from "react-icons/bs";
import FormattedToNameFormat from "../Hooks/FormattedToNameFormat";
import UserLeaveInformation from "../User/UserLeaveInformation";

const ModalEmpoyeeDetails = (props) => {
  const [employeeData, setEmployeeData] = useState({});
  const [err, setErr] = useState();
  const { id } = props;

  const fetchUserDetails = useCallback(async () => {
    const url = API_URL + "/fetch-update-delete/?user_id=" + id;
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setEmployeeData(data.data);
    } catch {
      setErr(err);
    }
  }, [err, id]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  console.log(employeeData);

  // if(!employeeData) {
  //   return <Spinner animation="grow"  />
  // }

  return (
    <>
      <div className="modal-content">
        <div className="modal-body cust_padding">
          <form
            method="post"
            autoComplete="off"
            id="create_agent_frm"
            noValidate="novalidate"
          >
            <div className="row">
              <div className="modal_head justify-content-end align-items-center d-flex">
                <div className="close-btn text-dark text-end">
                  <i
                    className="fa-solid fa-x"
                    data-bs-dismiss="modal"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>

              <div className="emp_profile_info d-flex justify-content-between">
                <div className="emp_det d-flex align-items-center">
                  <img
                    src={
                      employeeData && employeeData.profile_image === null
                        ? userdummyDP
                        : employeeData.profile_image
                    }
                    alt="DP"
                    className="profile_img me-3"
                  />

                  <div className="empl d-flex flex-column">
                    <h6 className="emp_name">
                      {employeeData && employeeData.full_name
                        ? FormattedToNameFormat(employeeData.full_name)
                        : ""}
                    </h6>
                    <p className="m-0">{employeeData.role_assigned_name}</p>
                  </div>
                </div>

                <div className="empl d-flex flex-column">
                  <Link
                    to={BASE_URL + "ModalEmployeeMoreDetails/" + props.id}
                    className="more_details"
                  >
                    <p className="m-0">
                      See More Details <BsBoxArrowUpRight size={15} />{" "}
                    </p>
                  </Link>
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="bg-white">
                  <ul
                    className="nav nav-tabs nav-fill"
                    id="myTab"
                    role="tablist"
                  >
                    {/* <li className="nav-item" role="presentation">
                      {" "}
                      <button
                        className="nav-link active"
                        id="faq_tab_1-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#faq_tab_1"
                        type="button"
                        role="tab"
                        aria-controls="faq_tab_1"
                        aria-selected="true"
                      >
                        <div className="d-flex flex-column lh-lg">
                          {" "}
                          <i className="bx bxs-plane-alt"></i>
                          <span>Overview</span>{" "}
                        </div>
                      </button>{" "}
                    </li> */}
                    <li className="nav-item" role="presentation">
                      {" "}
                      <button
                        className="nav-link active"
                        id="faq_tab_2-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#faq_tab_2"
                        type="button"
                        role="tab"
                        aria-controls="faq_tab_2"
                        aria-selected="false"
                      >
                        <div className="d-flex flex-column lh-lg">
                          {" "}
                          <i className="bx bxs-shopping-bag"></i>{" "}
                          <span>Basic info</span>{" "}
                        </div>
                      </button>{" "}
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      {" "}
                      <button
                        className="nav-link"
                        id="faq_tab_4-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#faq_tab_4"
                        type="button"
                        role="tab"
                        aria-controls="faq_tab_4"
                        aria-selected="false"
                      >
                        <div className="d-flex flex-column lh-lg">
                          {" "}
                          <i className="bx bxs-plane-alt"></i>
                          <span>Leave Data</span>{" "}
                        </div>
                      </button>{" "}
                    </li> */}
                    <li className="nav-item" role="presentation">
                      {" "}
                      <button
                        className="nav-link"
                        id="faq_tab_3-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#faq_tab_3"
                        type="button"
                        role="tab"
                        aria-controls="faq_tab_3"
                        aria-selected="false"
                      >
                        <div className="d-flex flex-column lh-lg">
                          {" "}
                          <i className="bx bx-check-circle"></i>{" "}
                          <span>Organisation</span>{" "}
                        </div>
                      </button>{" "}
                    </li>
                  </ul>
                  <div className="tab-content my-3" id="myTabContent">
                    {/* <div
                      className="tab-pane fade active show"
                      id="faq_tab_1"
                      role="tabpanel"
                      aria-labelledby="faq_tab_1-tab"
                    >
                      <div className="container scroll-y p-0">
                        <div className="contact_info my-3">
                          <p className="contact_info_head mb-3">
                            Contact Information
                          </p>

                          <div className="contact_info_row d-flex justify-content-between">
                            <div className="col1 d-flex align-items-center">
                              <i className="fa-solid fa-phone me-3"></i>
                              <p className="info_subtxt m-0">
                                Work Phone
                                <br />
                                <span className="contact_highlight blazing_red">
                                  <a
                                    className="cta_link"
                                    href={"tel:" + employeeData.mobile_number}
                                  >
                                    {employeeData.mobile_number}
                                  </a>
                                </span>
                              </p>
                            </div>

                            <div className="col1 w-45 d-flex align-items-center">
                              <i className="fa-solid fa-envelope me-3"></i>
                              <p className="info_subtxt m-0">
                                Email
                                <br />
                                <span className="contact_highlight blazing_red">
                                  <a
                                    className="cta_link"
                                    href={
                                      "mailto:" + employeeData.official_email
                                    }
                                  >
                                    {employeeData.official_email}
                                  </a>
                                </span>
                              </p>
                            </div>

                            <div className="col1 w-25 d-flex align-items-center">
                              <i className="fa-solid fa-people-group me-3"></i>
                              <p className="info_subtxt m-0">
                                Team
                                <br />
                                {employeeData.department_assigned_name}
                              </p>
                            </div>
                          </div>

                          <div className="contact_info_row mt-4 d-flex justify-content-between">
                            <div className="col1 d-flex align-items-center">
                              <i className="fa-solid fa-phone me-3"></i>
                              <p className="info_subtxt m-0">
                                Personal Phone
                                <br />
                                <span className="contact_highlight blazing_red">
                                  <a
                                    className="cta_link"
                                    href={"tel:" + employeeData.mobile_number}
                                  >
                                    {employeeData.mobile_number}
                                  </a>
                                </span>
                              </p>
                            </div>

                            <div className="col1 w-45 d-flex align-items-center">
                              <i className="fa-solid fa-location-dot me-3"></i>
                              <p className="info_subtxt m-0">
                                Office Location
                                <br />
                                <span className="contact_highlight">
                                  {employeeData.name}
                                </span>
                              </p>
                            </div>

                            <div className="col1 w-25 d-flex align-items-center">
                              <i className="fa-solid fa-user me-3"></i>
                              <p className="info_subtxt m-0">
                                Job Title
                                <br />
                                {employeeData.designation}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div
                      className="tab-pane fade active show"
                      id="faq_tab_2"
                      role="tabpanel"
                      aria-labelledby="faq_tab_2-tab"
                    >
                      <div className="container p-0 scroll-y">
                        <div className="contact_info my-3">
                          <p className="contact_info_head mb-3">
                            Contact Information
                          </p>

                          <div className="contact_info_row d-flex justify-content-between">
                            <div className="col-md-4  d-flex align-items-center">
                              <i className="fa-solid fa-envelope me-3"></i>
                              <p className="info_subtxt m-0">
                                Email
                                <br />
                                <span className="contact_highlight blazing_red">
                                  <a
                                    className="cta_link"
                                    href={
                                      "mailto:" + employeeData.official_email
                                    }
                                  >
                                    {employeeData.official_email}
                                  </a>
                                </span>
                              </p>
                            </div>
                            <div className="col-md-4 d-flex align-items-center">
                              <i className="fa-solid fa-phone me-3"></i>
                              <p className="info_subtxt m-0">
                                Phone Number
                                <br />
                                <span className="contact_highlight blazing_red">
                                  <a
                                    className="cta_link"
                                    href={"tel:" + employeeData.mobile_number}
                                  >
                                    {employeeData.mobile_number}
                                  </a>
                                </span>
                              </p>
                            </div>

                            <div className="col-md-4 w-25 d-flex align-items-center">
                              <i className="fa-solid fa-people-group me-3"></i>
                              <p className="info_subtxt m-0">
                                Department
                                <br />
                                {employeeData.department_assigned_name}
                              </p>
                            </div>
                          </div>

                          <div className="contact_info_row mt-4 d-flex justify-content-between">
                            <div className="col-md-4 d-flex align-items-center">
                              <i className="fa-solid fa-phone me-3"></i>
                              <p className="info_subtxt m-0">
                                Personal Email
                                <br />
                                <span className="contact_highlight blazing_red">
                                  <a
                                    className="cta_link"
                                    href={
                                      "mailto::" + employeeData.personal_email
                                    }
                                  >
                                    {employeeData.personal_email}
                                  </a>
                                </span>
                              </p>
                            </div>

                            <div className="col-md-4  d-flex align-items-center">
                              <i className="fa-solid fa-location-dot me-3"></i>
                              <p className="info_subtxt m-0">
                                Office Location
                                <br />
                                <span className=" ">{employeeData.name}</span>
                              </p>
                            </div>

                            <div className="col-md-4 w-25 d-flex align-items-center">
                              <i className="fa-solid fa-user me-3"></i>
                              <p className="info_subtxt m-0">
                                Job Title
                                <br />
                                {employeeData.designation}
                              </p>
                            </div>
                          </div>

                          {/* <div className="emp_det mt-5 d-flex align-items-center">
                            <img
                              src="./images/Ellipse 681.png"
                              alt=""
                              className="profile_img me-3"
                            />

                            <div className="empl d-flex flex-column">
                              <h6 className="mngr_name">
                                {employeeData.reporting_to}
                              </h6>
                              <p className="m-0 desig">
                                Chief Product Officer (CPO)
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    {/* <div
                      className="tab-pane fade"
                      id="faq_tab_4"
                      role="tabpanel"
                      aria-labelledby="faq_tab_4-tab"
                    >
                      <UserLeaveInformation id={id} />
                    </div> */}

                    <div
                      className="tab-pane fade"
                      id="faq_tab_3"
                      role="tabpanel"
                      aria-labelledby="faq_tab_3-tab"
                    >
                      <div className="container p-0 mt-4">
                        <p className="teah_head m-0">
                          {employeeData.first_name}'s Team
                        </p>

                        <div className="team_name mt-3 d-flex align-items-center">
                          <div className="team_img me-1">
                            <img
                              src="./images/team.png"
                              alt=""
                              className="team_imgs"
                            />
                          </div>

                          <p className="team_n m-0">
                            {employeeData.department_assigned_name} Team
                          </p>
                        </div>
                      </div>

                      <div className="container p-0 mt-4">
                        <p className="teah_head m-0">
                          {employeeData.first_name} works with
                        </p>
                        <div className="team_members mt-3 d-flex justify-content-between">
                          <div className="member1 d-flex">
                            <img
                              src="./images/Ellipse 681.png"
                              alt=""
                              className="profile_img me-3"
                            />
                            <div className="empl d-flex flex-column">
                              <h6 className="team_mem_name">Rahul A.</h6>
                              <p className="m-0 desig_alt">
                                Chief Product Officer (CPO)
                              </p>
                            </div>
                          </div>

                          <div className="member1 d-flex">
                            <img
                              src="./images/Ellipse 681.png"
                              alt=""
                              className="profile_img me-3"
                            />
                            <div className="empl d-flex flex-column">
                              <h6 className="team_mem_name">Sidharth T.</h6>
                              <p className="m-0 desig_alt">
                                Sr. UI/UX Designer
                              </p>
                            </div>
                          </div>

                          <div className="member1 d-flex">
                            <img
                              src="./images/Ellipse 681.png"
                              alt=""
                              className="profile_img me-3"
                            />
                            <div className="empl d-flex flex-column">
                              <h6 className="team_mem_name">Anjali R.</h6>
                              <p className="m-0 desig_alt">
                                Jr. UI/UX Designer
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="team_members mt-4 d-flex justify-content-between">
                          <div className="member1 d-flex">
                            <img
                              src="./images/Ellipse 681.png"
                              alt=""
                              className="profile_img me-3"
                            />
                            <div className="empl d-flex flex-column">
                              <h6 className="team_mem_name">Harsh V.</h6>
                              <p className="m-0 desig_alt">Graphics Designer</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ModalEmpoyeeDetails;
