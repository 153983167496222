import React, { useState } from "react";
import { API_URL } from "../../Constents";
import Logo from "../../images/forgotimg.png";

const ForgetPassword = () => {
  const [resetEmail, setResetEmail] = useState("");
  const [response, setResonse] = useState("");

  const resetPasswordHandler = (e) => {
    e.preventDefault();
    (async () => {
      const headersList = {
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        email: resetEmail,
      });

      const url = API_URL + "/forget-password/";

      try {
        const res = await fetch(url, {
          method: "POST",
          headers: headersList,
          body: bodyContent,
        });
        const data = await res.json();
        console.log(data);
        setResonse(data);
      } catch (err) {
        console.error(err);
      }
    })();
  };

  console.log(response);

  return (
    <>
      <div className="container-fluid text-center py-5 forgotMainWrapper ">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <img src={Logo} alt="mainImg" className="mt-5" />
            <form onSubmit={resetPasswordHandler}>
              <div className="passwordMiddleWrapper">
                <h5>Enter your email to reset your password </h5>

                <input
                  type="email"
                  required
                  placeholder="Enter Email"
                  className="form-control input"
                  onChange={(e) => setResetEmail(e.target.value)}
                />
              </div>
              <button type="submit">Submit</button>
            </form>
            {response && response.status ? (
              <div
                style={{
                  marginTop: "10px",
                  padding: "10px",
                  background: "white",
                  boxShadow: "1px 2px 9px #F4AAB9",
                }}
              >
                <h4 style={{ color: "green" }}>{response.message}</h4>
              </div>
            ) : response && !response.status ? (
              <div
                style={{
                  marginTop: "10px",
                  padding: "10px",
                  background: "white",
                  boxShadow: "1px 2px 9px #F4AAB9",
                }}
              >
                <h4 style={{ color: "red" }}>{response.message}</h4>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
