const Accounts = () => {
    return (
      <>
        <h1 className="highlight"> You're in accounts </h1>
            
      </>
    );
  };
  
  export default Accounts;
  