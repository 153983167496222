import { Route, Switch } from "react-router-dom";
import { useState } from "react";

import { BASE_URL } from "../Constents";

import NavBar from "./NavBar";
import Header from "./Header";
import AddEmployee from "./NavBarComp/AddEmployee";
import Accounts from "./NavBarComp/Accounts";
import Attendence from "./NavBarComp/Attendence";
import Help from "./NavBarComp/Help";
import Leaves from "./NavBarComp/Leaves";
import Setting from "./NavBarComp/Setting";
import Settings from "./NavBarComp/Settings";
import Tasks from "./NavBarComp/Taskes";
import EmployeeList from "./NavBarComp/EmployeeList";
import MainContentDash from "./MainContentDash";
import UpdateUser from "./UpdateUser";
import UpdateAttendance from "./NavBarComp/UpdateAttendance";
import UserHelp from "./NavBarComp/UserHelp";
import DepartmentMain from "./Departments/DepartmentMain";
import DepartmentList from "./Departments/DepartmentList";
import ModalEmployeeMoreDetails from "./Modals/ModalEmployeeMoreDetails";
import GenerateQR from "./QR Code/GenerateQR";
import NotYetInList from "./Attendance/NotYetInList";
import WorkFromHome from "./NavBarComp/WorkFromHome";
import CompOffLeaves from "./Leaves/CompOffLeaves";
import CreateGeneralLeaveCategory from "./Leaves/CreateGeneralLeaveCategory";
import ExEmployeeList from "./User/ExEmployeeList";
import Login from "./Login";
import ForgetPassword from "./ForgetPassowrd/ForgetPassword";
import EnterForgetPasswordDetails from "./ForgetPassowrd/EnterForgetPasswordDetails";
import NoInternetConnectionHandler from "./CompParts/NoInternetConnectionHandler";
import RequestsAndReviews from "./CompParts/RequestsAndReviews";
import Error from "./Error/Error";
import ChangePassword from "./ForgetPassowrd/ChangePassword";
import EventsList from "./Events/EventsList";
import CreateEvents from "./Events/CreateEvents";
import { ErrorBoundary } from "./Error/ErrorBoundary";
import NotFoundPage from "./Error/NotFoundPage";
import OrganizationSettings from "./Global Setting/OrganizationSettings";
import ApproveOrRejectLeaveFromEmail from "./Leaves/Leave And Mail Setting/ApproveOrRejectLeaveFromEmail";
import ViewAndGenerateQR from "./QR Code/ViewAndGenerateQR";
import ApprovedMailMessage from "./Leaves/Leave And Mail Setting/ApprovedMailMessage";
import RejectedMailMessage from "./Leaves/Leave And Mail Setting/RejectedMailMessage";
import AllAttendancePDFDownloads from "./downloads/AllAttendancePDFDownloads";
import NotificationsTable from "./Events/Notifications/NotificationsTable";
import CreateNotifications from "./Events/Notifications/CreateNotifications";
import Vacancy from "./CoreHr/Recruitments/Vacancy/Vacancy";
import CoreHrDash from "./CoreHr/CoreHrDash";
import CreateVacancy from "./CoreHr/Recruitments/Vacancy/CreateVacancy";
import Appraisals from "./CoreHr/Appraisals/Appraisals";
import Candidates from "./CoreHr/Recruitments/Candidates/Candidates";
import OnHoldApplicants from "./CoreHr/Recruitments/Candidates/OnHoldApplicants";
import CandidateJobAcceptanceForm from "./CoreHr/Recruitments/Candidates/CandidateDocuments/CandidateJobAcceptanceForm";
import AddEmployeeForAppraisal from "./CoreHr/Appraisals/AddEmployeeForAppraisal";
import EmployeeFeedbackForm from "./CoreHr/Appraisals/EmployeeFeedbackForm";
import Appreciation from "./CoreHr/Appreciation/Appreciation";
import FullAndFinal from "./CoreHr/FnF/FullAndFinal";
import RemarksForFnFEmp from "./CoreHr/FnF/RemarksForFnFEmp";
import CandidatePersonalDetails from "./CoreHr/Recruitments/Candidates/CandidateDocuments/ApplicantDocumentUpload";
import ReviewCandidateDetails from "./CoreHr/Recruitments/Candidates/ReviewCandidateDetails";
import ApplicantDocumentUpload from "./CoreHr/Recruitments/Candidates/CandidateDocuments/ApplicantDocumentUpload";
import LossOfPayOverview from "./Leaves/LoP/LossOfPayOverview";

const Layout = ({ loginData, logout, login, isLayout }) => {
  const [toggle, setToggle] = useState(true);
  const toggleState = () => {
    setToggle(!toggle);
  };

  // There are different routes for isLayout=== true (authticated user)
  // and for isLayout===false there are different routes (unauthenticated user or not logged in user)

  return (
    <>
      {!isLayout ? (
        <Switch>
          <Route exact path={BASE_URL}>
            <Login login={login} />
          </Route>
          <Route path={BASE_URL + "ForgetPassword"}>
            <ForgetPassword />
          </Route>
          <Route path={BASE_URL + "ForgetPasswordDetails"}>
            <EnterForgetPasswordDetails />
          </Route>
          <Route path={BASE_URL + "ApproveRejectLeave"}>
            <ApproveOrRejectLeaveFromEmail />
          </Route>
          <Route path={BASE_URL + "ApprovedMailMessage"}>
            <ApprovedMailMessage />
          </Route>
          <Route path={BASE_URL + "RejectedMailMessage"}>
            <RejectedMailMessage />
          </Route>

          {/* <Route path={BASE_URL + "CandidatePersonalDetails"}>
            <CandidatePersonalDetails />
          </Route> */}
          <Route path={BASE_URL + "ApplicantDocumentUpload"}>
            <ApplicantDocumentUpload />
          </Route>
          <Route path={BASE_URL + "CandidateJobAcceptanceForm"}>
            <CandidateJobAcceptanceForm />
          </Route>
          <Route path={BASE_URL + "EmployeeFeedbackForm"}>
            <EmployeeFeedbackForm />
          </Route>
          <Route path={BASE_URL + "RemarksForFnFEmp"}>
            <RemarksForFnFEmp />
          </Route>
          <Route path={BASE_URL + "*"}>
            <NotFoundPage />
          </Route>
          {/* <Redirect to={BASE_URL} /> */}
        </Switch>
      ) : (
        <div className="wrapper">
          <NavBar loginData={loginData} toggle={toggle} />

          <div id="content">
            <Header
              logout={logout}
              loginData={loginData}
              toggleState={toggleState} // to toggle hamburger
            />

            <NoInternetConnectionHandler>
              <ErrorBoundary>
                <Switch>
                  <Route exact path={BASE_URL}>
                    <MainContentDash />
                  </Route>
                  <Route path={BASE_URL + "Accounts"}>
                    <Accounts />
                  </Route>
                  <Route path={BASE_URL + "AddEmployee"}>
                    <AddEmployee />
                  </Route>
                  <Route path={BASE_URL + "Attendence"}>
                    <Attendence />
                  </Route>
                  <Route path={BASE_URL + "Help"}>
                    <Help />
                  </Route>
                  <Route path={BASE_URL + "Leaves"}>
                    <Leaves />
                  </Route>
                  <Route path={BASE_URL + "Setting"}>
                    <Setting />
                  </Route>
                  <Route path={BASE_URL + "Settings"}>
                    <Settings />
                  </Route>
                  <Route path={BASE_URL + "Tasks"}>
                    <Tasks />
                  </Route>
                  <Route path={BASE_URL + "EmployeeList"}>
                    <EmployeeList />
                  </Route>

                  <Route path={BASE_URL + "UpdateUser"}>
                    <UpdateUser />
                  </Route>
                  <Route path={BASE_URL + "UpdateAttendance"}>
                    <UpdateAttendance />
                  </Route>
                  <Route path={BASE_URL + "UserHelp"}>
                    <UserHelp />
                  </Route>
                  <Route path={BASE_URL + "DepartmentMain"}>
                    <DepartmentMain />
                  </Route>
                  <Route path={BASE_URL + "DepartmentList"}>
                    <DepartmentList />
                  </Route>
                  <Route path={BASE_URL + "ModalEmployeeMoreDetails"}>
                    <ModalEmployeeMoreDetails />
                  </Route>
                  <Route path={BASE_URL + "GenerateQR"}>
                    <GenerateQR />
                  </Route>
                  <Route path={BASE_URL + "NotYetInList"}>
                    <NotYetInList />
                  </Route>
                  <Route path={BASE_URL + "WorkFromHome"}>
                    <WorkFromHome />
                  </Route>
                  <Route path={BASE_URL + "CompOffLeaves"}>
                    <CompOffLeaves />
                  </Route>
                  <Route path={BASE_URL + "CreateGeneralLeaveCategory"}>
                    <CreateGeneralLeaveCategory />
                  </Route>
                  <Route path={BASE_URL + "ExEmployeeList"}>
                    <ExEmployeeList />
                  </Route>
                  <Route path={BASE_URL + "requestsAndReviews"}>
                    <RequestsAndReviews />
                  </Route>
                  <Route path={BASE_URL + "Error"}>
                    <Error />
                  </Route>
                  <Route path={BASE_URL + "ChangePassword"}>
                    <ChangePassword />
                  </Route>
                  <Route path={BASE_URL + "eventsList"}>
                    <EventsList />
                  </Route>
                  <Route path={BASE_URL + "createEvents"}>
                    <CreateEvents />
                  </Route>
                  <Route path={BASE_URL + "notificationtable"}>
                    <NotificationsTable />
                  </Route>
                  <Route path={BASE_URL + "createnotification"}>
                    <CreateNotifications />
                  </Route>
                  <Route path={BASE_URL + "OrganizationSettings"}>
                    <OrganizationSettings />
                  </Route>
                  <Route path={BASE_URL + "ViewAndGenerateQR"}>
                    <ViewAndGenerateQR />
                  </Route>
                  <Route path={BASE_URL + "ApproveRejectLeave"}>
                    <ApproveOrRejectLeaveFromEmail />
                  </Route>
                  <Route path={BASE_URL + "ApprovedMailMessage"}>
                    <ApprovedMailMessage />
                  </Route>
                  <Route path={BASE_URL + "RejectedMailMessage"}>
                    <RejectedMailMessage />
                  </Route>
                  <Route path={BASE_URL + "YAAMSDownloads"}>
                    <AllAttendancePDFDownloads />
                  </Route>
                  <Route path={BASE_URL + "vacancy"}>
                    <Vacancy />
                  </Route>
                  <Route path={BASE_URL + "createVacancy"}>
                    <CreateVacancy />
                  </Route>
                  <Route path={BASE_URL + "Candidates"}>
                    <Candidates />
                  </Route>
                  <Route path={BASE_URL + "ReviewCandidateDetails"}>
                    <ReviewCandidateDetails />
                  </Route>
                  <Route path={BASE_URL + "Appraisals"}>
                    <Appraisals />
                  </Route>
                  <Route path={BASE_URL + "coreHrDashboard"}>
                    <CoreHrDash />
                  </Route>
                  <Route path={BASE_URL + "Appreciation"}>
                    <Appreciation />
                  </Route>
                  <Route path={BASE_URL + "AddEmployeeForAppraisal"}>
                    <AddEmployeeForAppraisal />
                  </Route>
                  <Route path={BASE_URL + "FullAndFinal"}>
                    <FullAndFinal />
                  </Route>
                  <Route path={BASE_URL + "LossOfPay"}>
                    <LossOfPayOverview />
                  </Route>
                  <Route path={BASE_URL + "*"}>
                    <NotFoundPage />
                  </Route>
                </Switch>
              </ErrorBoundary>
            </NoInternetConnectionHandler>
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
