import { useEffect, useState } from "react";
import { API_URL, TODAY_DATE } from "../../Constents";
import { useAlert } from "react-alert";

const DeleteUser = (props) => {
  const [details, setDetails] = useState({
    date_of_joining: null,
    date_of_leaving: null,
    served_notice_period: null,
    expirence_letter_given: null,
    third_party_qualtiy_verification: null,
    company_asset_submited: null,
    reason_of_leaving: "",
    remarks: "",
    can_be_rehired: null,
    aadhar_number: null,
  });

  const alert = useAlert();

  const submitHandler = (e) => {
    e.preventDefault();

    (async () => {
      const url = API_URL + "/fetch-update-delete/?user_id=" + props.id;
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        date_of_joining: details.date_of_joining,
        date_of_leaving: details.date_of_leaving,
        served_notice_period: details.served_notice_period,
        expirence_letter_given: details.expirence_letter_given,
        third_party_qualtiy_verification:
          details.third_party_qualtiy_verification,
        company_asset_submited: details.company_asset_submited,
        reason_of_leaving: details.reason_of_leaving,
        can_be_rehired: details.can_be_rehired,
        aadhar_number: details.aadhar_number,
        remarks: details.remarks,
      });

      try {
        const res = await fetch(url, {
          method: "DELETE",
          body: bodyContent,
          headers: headersList,
        });
        const data = await res.json();
        console.log(data);
        props.hideModal();
        if (data.status === true) alert.success(data.message);
        if (data.status === false) alert.error(data.message);
      } catch (err) {
        console.error(err);
        props.hideModal();
        alert.error("Internal Server Error");
      }
    })();
  };

  useEffect(() => {
    (async () => {
      const url = API_URL + "/fetch-update-delete/?user_id=" + props.id;
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: headersList,
        });
        const data = await res.json();
        console.log(data);
        setDetails({
          date_of_joining: data.data.date_of_joining,
          aadhar_number: data.data.aadhar_number,
        });
      } catch (err) {
        alert.error("There is an error in fetching the employee details");
      }
    })();
  }, []);

  console.log(details);

  return (
    <div>
      <div className="maincontent">
        <form
          onSubmit={submitHandler}
          className="border border-3 p-5"
          method="POST"
          name="contact-form"
        >
          <h3>Mark {props.name} as an Ex Employee </h3>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label htmlFor="JD">
                Joining Date <span className="msg_fail">*</span>
              </label>
              <input
                className="form-select form-control"
                type="date"
                max={TODAY_DATE}
                id="JD"
                name="JD"
                value={details.date_of_joining}
                onChange={(e) => {
                  setDetails({ ...details, date_of_joining: e.target.value });
                }}
                required
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="LD">
                Leaving Date <span className="msg_fail">*</span>
              </label>
              <input
                className="form-select form-control"
                type="date"
                id="LD"
                name="LD"
                // min={TODAY_DATE}
                value={details.date_of_leaving}
                onChange={(e) => {
                  setDetails({ ...details, date_of_leaving: e.target.value });
                }}
                required
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="aadhar">
                Aadhar Number <span className="msg_fail">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                id="aadhar"
                name="aadhar"
                value={details.aadhar_number}
                placeholder="Enter Employee Aadhar No."
                pattern="^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$"
                title="Enter 12 digit aadhar number in correct format"
                onChange={(e) => {
                  setDetails({ ...details, aadhar_number: e.target.value });
                }}
                required
              />
            </div>

            <div className="col-md-4 mb-3">
              <label>Notice Period Served ?</label>
              <br />
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  value="true"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      served_notice_period: e.target.value,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="false"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      served_notice_period: e.target.value,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  No
                </label>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <label>Experience Letter Given ?</label>
              <br />
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault2"
                  id="flexRadioDefault3"
                  value="true"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      expirence_letter_given: e.target.value,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="flexRadioDefault3">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault2"
                  id="flexRadioDefault4"
                  value="false"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      expirence_letter_given: e.target.value,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="flexRadioDefault4">
                  No
                </label>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <label>Third Party Quality Verification Done ?</label>
              <br />
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault3"
                  id="flexRadioDefault5"
                  value="true"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      third_party_qualtiy_verification: e.target.value,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="flexRadioDefault5">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault3"
                  id="flexRadioDefault6"
                  value="false"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      third_party_qualtiy_verification: e.target.value,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="flexRadioDefault6">
                  No
                </label>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <label>Company Assets Submitted ?</label>
              <br />
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault4"
                  id="flexRadioDefault7"
                  value="true"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      company_asset_submited: e.target.value,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="flexRadioDefault7">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault4"
                  id="flexRadioDefault8"
                  value="false"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      company_asset_submited: e.target.value,
                    })
                  }
                />
                <label className="form-check-label" htmlFor="flexRadioDefault8">
                  No
                </label>
              </div>
            </div>

            <div className="col-md-4 mb-3">
              <label>Can Be Rehired ?</label>
              <br />
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault5"
                  id="flexRadioDefault9"
                  value="true"
                  onChange={(e) =>
                    setDetails({ ...details, can_be_rehired: e.target.value })
                  }
                />
                <label className="form-check-label" htmlFor="flexRadioDefault9">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault5"
                  id="flexRadioDefault10"
                  value="false"
                  onChange={(e) =>
                    setDetails({ ...details, can_be_rehired: e.target.value })
                  }
                />
                <label
                  className="form-check-label"
                  htmlFor="flexRadioDefault10"
                >
                  No
                </label>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <label>Reason For Leaving</label>
              <textarea
                className="form-control"
                onChange={(e) =>
                  setDetails({ ...details, reason_of_leaving: e.target.value })
                }
              ></textarea>
            </div>
            <div className="col-md-4 mb-3">
              <label>Remarks</label>
              <textarea
                className="form-control"
                onChange={(e) =>
                  setDetails({ ...details, remarks: e.target.value })
                }
              ></textarea>
            </div>
          </div>
          <button type="submit" className="btn btn-danger btn-lg">
            Mark {props.name} As an Ex Employee
          </button>
        </form>
      </div>
    </div>
  );
};

export default DeleteUser;
