import dummyUserDP from "../images/DummyUserDP.png";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BASE_URL } from "../Constents";
import { AiOutlineUser, AiOutlineLogout } from "react-icons/ai";
import { RiLockPasswordFill } from "react-icons/ri";

const Header = (props) => {
  const userData = JSON.parse(window.sessionStorage.getItem("user"));
  console.log(userData.data.profile_image);

  return (
    <>
      <div id="content">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <button
              type="button"
              className="hamburger"
              onClick={props.toggleState}
            >
              <FaBars />
            </button>

            <div className="btn-group header_profile">
              <Link
                type="button"
                className="dropdown-toggle_alt"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {userData &&
                (userData.data.profile_image === null ||
                  userData.data.profile_image === "") ? (
                  <img
                    src={dummyUserDP}
                    style={{ width: "100%" }}
                    alt="Dummy Profile"
                  />
                ) : (
                  <img
                    src={userData.data.profile_image}
                    style={{ width: "100%" }}
                    alt="Profile DP"
                  />
                )}
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                <button className="dropdown-item" type="button">
                  <AiOutlineUser size={20} />{" "}
                  <b>
                    {userData !== null && userData.data
                      ? userData.data.first_name
                      : ""}
                  </b>
                </button>

                <Link
                  className="dropdown-item"
                  type="button"
                  to={BASE_URL + "ChangePassword"}
                >
                  <RiLockPasswordFill size={20} /> Change Password?
                </Link>

                <button
                  className="dropdown-item"
                  type="button"
                  onClick={props.logout}
                >
                  <AiOutlineLogout size={20} /> Logout
                </button>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
