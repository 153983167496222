import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useAlert } from "react-alert";

import AddInterviewerFrom from "./AddInterviewerFrom";

import { API_URL, TODAY_DATE } from "../../../../Constents";

function ScheduleCandidateInterview({
  clickedRowData,
  closeScheduleCandidateInterviewModal,
}) {
  // const [inputFields, setInputFields] = useState([
  //   {
  //     fullName: "",
  //     email: "",
  //     phoneNo: null,
  //   },
  // ]);

  const [interviewDetails, setInterviewDetails] = useState({
    interview_url: "",
    interview_date: "",
    interview_start_time: "",
    interview_end_time: "",
  });

  const [Interviewer, setInterviewer] = useState([]);
  const [InterviewerMultiselect, setInterviewerMultiselect] = useState([]);
  const [addInterviewerForm, setAddInterviewerForm] = useState(false);
  const [datafromChiled, setDatafromChiled] = useState();

  const alert = useAlert();

  // const [addInterview]

  // const addInputField = () => {
  //   setInputFields([
  //     ...inputFields,
  //     {
  //       fullName: "",
  //       email: "",
  //       phoneNo: null,
  //     },
  //   ]);
  // };
  // const removeInputFields = (index) => {
  //   const rows = [...inputFields];
  //   rows.splice(index, 1);
  //   setInputFields(rows);
  // };
  // const handleChange = (index, evnt) => {
  //   const { name, value } = evnt.target;
  //   const list = [...inputFields];
  //   list[index][name] = value;
  //   setInputFields(list);
  // };

  const fetchAllInterviewer = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/interviewer/";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      setInterviewer(data.data);
      console.log(data);
    } catch (err) {
      console.error(err);
    }
  };

  const formattedInterviewerList = [];
  if (Interviewer) {
    Interviewer.map((list) => {
      return formattedInterviewerList.push({
        label: list.name,
        value: list.id,
      });
    });
  }

  const interviewScheduleHandler = async (e) => {
    e.preventDefault();
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const interviewerIDs = [];
    InterviewerMultiselect.map((list) => interviewerIDs.push(list.value));

    console.log(interviewerIDs);

    if (interviewerIDs.length <= 0) {
      return alert.error("Please select atleast one interviewer");
    }

    const bodyContent = JSON.stringify({
      applicant: clickedRowData.id,
      interviewer: interviewerIDs,
      interview_url: interviewDetails.interview_url,
      interview_date: interviewDetails.interview_date,
      interview_start_time: interviewDetails.interview_start_time,
      interview_end_time: interviewDetails.interview_end_time,
    });

    const url = API_URL + "/interview/";

    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      if (data.status) {
        alert.success(data.message);
        closeScheduleCandidateInterviewModal();
      }
      if (data.status === false) alert.error(data.message);
      console.log(data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  useEffect(() => {
    fetchAllInterviewer();
  }, [datafromChiled]);

  console.log(interviewDetails);
  return (
    <>
      <div className="setting_header ps-3">
        <form action="" onSubmit={interviewScheduleHandler}>
          <div className="text-center">
            <h5 style={{ color: "#DD3974" }}>
              Schedule an Interview for <i>{clickedRowData.name}</i>
            </h5>
          </div>

          {/* {inputFields.map((data, index) => {
          const { fullName, email, phoneNo } = data;
          return (
            <div className="row " key={index}>
              <div className="col-md-4">
                <label for="" className="form-label">
                  Interviewer's Name
                </label>
                <input
                  type="text"
                  onChange={(evnt) => handleChange(index, evnt)}
                  value={fullName}
                  name="fullName"
                  className="form-control"
                  placeholder="Full Name"
                />
              </div>
              <div className="col-md-4">
                <label for="" className="form-label">
                  Interviewer's Email
                </label>
                <input
                  type="email"
                  onChange={(evnt) => handleChange(index, evnt)}
                  value={email}
                  name="email"
                  className="form-control"
                  placeholder="email"
                />
              </div>

              <div className="col-md-4">
                <label for="" className="form-label">
                  Interviewer's Ph. No.
                </label>
                <input
                  type="number"
                  onChange={(evnt) => handleChange(index, evnt)}
                  value={phoneNo}
                  name="phoneNo"
                  className="form-control"
                  placeholder="phone number"
                />
              </div>

              <div className="col">
                {inputFields.length !== 1 ? (
                  <h5
                    className="text-danger p-0 m-0 "
                    onClick={removeInputFields}
                    style={{ cursor: "pointer" }}
                  >
                    x
                  </h5>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })} */}

          {/* <p
          className="text-success my-2"
          onClick={addInputField}
          style={{ cursor: "pointer" }}
        >
          Add New Interviewer +
        </p> */}
          <div className="row mb-3">
            <div className="col-md-6">
              <MultiSelect
                options={formattedInterviewerList}
                value={InterviewerMultiselect}
                onChange={setInterviewerMultiselect}
                labelledBy="Select"
                required
                valueRenderer={(selected) => {
                  if (!selected.length) {
                    return "No Interviewer Selected";
                  }
                  return selected.map(({ label }) => "👨‍💼" + label);
                }}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label for="" className="form-label">
                Select Interview Start Time
              </label>
              <input
                type="time"
                className="form-control"
                required
                placeholder="Enter Phone Number"
                pattern="[6789][0-9]{9}"
                title="Enter a valid 10 digit phone number"
                onChange={(e) => {
                  setInterviewDetails((prevState) => ({
                    ...prevState,
                    interview_start_time: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="col-md-6">
              <label for="" className="form-label">
                Select Interview End Time
              </label>
              <input
                type="time"
                className="form-control"
                placeholder="Enter Phone Number="
                onChange={(e) => {
                  setInterviewDetails((prevState) => ({
                    ...prevState,
                    interview_end_time: e.target.value,
                  }));
                }}
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label for="" className="form-label">
                Select Interview Date
              </label>
              <input
                type="date"
                className="form-control"
                required
                min={TODAY_DATE}
                onChange={(e) => {
                  setInterviewDetails((prevState) => ({
                    ...prevState,
                    interview_date: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="col-md-6">
              <label for="" className="form-label">
                Enter Meeting Invite
              </label>
              <input
                type="text"
                className="form-control"
                required
                pattern="^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$"
                title="Enter a valid URL"
                placeholder="Enter Meet, Teams etc link"
                onChange={(e) => {
                  setInterviewDetails((prevState) => ({
                    ...prevState,
                    interview_url: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
          <div>
            <button className="btn btn-danger me-3" type="submit">
              Save & Exit
            </button>
          </div>
        </form>
        <button
          className="btn btn-light cancelBtnPositionInterview"
          onClick={closeScheduleCandidateInterviewModal}
        >
          Cancel
        </button>
      </div>
      <div className="row">
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-link"
            onClick={() => setAddInterviewerForm(!addInterviewerForm)}
          >
            Add Interviewer Details In List
          </button>
        </div>
      </div>
      {addInterviewerForm ? (
        <AddInterviewerFrom refresh={setDatafromChiled} />
      ) : (
        ""
      )}
    </>
  );
}
export default ScheduleCandidateInterview;
