// import React from "react";
// import UserDetails from "./UserDetails";
// import OrganisationRoles from "./OrganisationRoles";
// import OrgTerritories from "./OrgTerritories";
// import OrgUsers from "./OrgUsers";
// import OrgCreateRoles from "./OrgCreateRoles";
import CreateRole from "../CreateRole";
import SetupDefaultEmailAddress from "../SetupDefaultEmailAddress";

// Don't remove comments

export default function OrganisationSettings() {
  return (
    <>
      <h2 className="tab_heading mt-5 mb-3">Organization Settings</h2>

      <div className="row">
        <div className="col-md-2 pe-0 br">
          <ul
            className="nav nav-tabs flex-column"
            id="myTabLeave"
            role="tablist"
          >
            {/* <li className="nav-item" role="presentation">
              <button
                className="inner nav-link active"
                id="users-tab"
                data-bs-toggle="tab"
                data-bs-target="#users"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Users
              </button>
            </li> */}
            <li className="nav-item" role="presentation">
              <button
                className="inner nav-link active "
                id="roles-tab"
                data-bs-toggle="tab"
                data-bs-target="#roles"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Roles
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="inner nav-link mt-2"
                id="roles-tab"
                data-bs-toggle="tab"
                data-bs-target="#mail"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Mail Setup
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button
                className="inner nav-link"
                id="territories-tab"
                data-bs-toggle="tab"
                data-bs-target="#territories"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Territories
              </button>
            </li> */}
          </ul>
        </div>
        <div className="col-md-10 bt">
          <div className="tab-content" id="myTabContent">
            {/* Users */}
            {/* <div
              className="tab-pane fade show active"
              id="users"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <OrgUsers />
            </div> */}

            {/* Roles */}
            <div
              className="tab-pane fade show active"
              id="roles"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <CreateRole />

              {/* <OrgCreateRoles /> */}
            </div>

            {/* Territories */}
            {/* <div
              className="tab-pane fade"
              id="territories"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <OrgTerritories />
            </div> */}
            <div
              className="tab-pane fade"
              id="mail"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <SetupDefaultEmailAddress />

              {/* <OrgCreateRoles /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
