function timeFormatter(timeString) {
  if(!timeString) return "NA"
  let [hours, minutes] = timeString.split(":").map(Number);
  let period = "am";

  if (hours >= 12) {
    period = "pm";
    hours -= 12;
  }

  if (hours === 0) {
    hours = 12;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${period}`;
}

export default timeFormatter;
