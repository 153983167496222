import React, { useState, useEffect } from "react";
import { Rating } from "react-simple-star-rating";
import { API_URL } from "../../../Constents";
import { useAlert } from "react-alert";
export default function AppraisalSideDrawer(props) {
  // const [sideDrawerDetails, setsideDrawerDetails] = useState({});
  console.log(props.sideDrawerDetails);
  const alert = useAlert();

  console.log(props.sideDrawerDetails);
  return (
    <>
      <div className="container">
        {props.sideDrawerDetails.length > 0 ? (
          <>
            <h4 className=" mt-3">
              {props.sideDrawerDetails[0].employee.first_name}'s Feedback
              Details
            </h4>
          </>
        ) : (
          ""
        )}

        <hr />

        {props.sideDrawerDetails &&
        props.sideDrawerDetails.length > 0 &&
        props.sideDrawerDetails !== undefined
          ? props.sideDrawerDetails.map((data) => {
              console.log(data);
              return (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <Rating initialValue={data.feedback_rating} readonly />
                  </div>
                  <div className="form-control mt-2">
                    <p>{data.feedback}</p>
                  </div>
                  <h6 className="text-end">
                    Given by:{" "}
                    <span style={{ color: "#DD3974" }}>
                      {data.manager.full_name}
                    </span>
                  </h6>
                </>
              );
            })
          : ""}
      </div>
    </>
  );
}
