import React, { useState } from "react";
import { API_URL } from "../../../../../Constents";
import { useAlert } from "react-alert";
export default function ApplicantDocumentUpload() {
  const [isLoading, setIsLoading] = useState(true);

  const [candidateDetails, setCandidateDetails] = useState({
    dob: null,
    marital_status: "",
    pan_number: "",
    uidai_number: "",
    account_number: "",
    ifsc: "",
    bank_name: "",
  });

  const [filePaths, setFilePaths] = useState({
    profile_image_file: "",
    profile_image_path: "",
    pan_number_attachment_file: "",
    pan_number_attachment_path: "",
    uidai_number_attachment_file: "",
    uidai_number_attachment_path: "",
    account_attachment_file: "",
    account_attachment_path: "",
  });

  const alert = useAlert();

  const token = window.location.href.split("/").reverse()[0];

  const updatePanCardPathHandler = (data) => {
    setFilePaths((prevState) => ({
      ...prevState,
      pan_number_attachment_path: data.target.files[0],
      pan_number_attachment_file: data.target.value,
    }));
  };
  const updateProfilePicturePathHandler = (data) => {
    setFilePaths((prevState) => ({
      ...prevState,
      profile_image_path: data.target.files[0],
      profile_image_file: data.target.value,
    }));
  };
  const updateUIDAIPathHandler = (data) => {
    setFilePaths((prevState) => ({
      ...prevState,
      uidai_number_attachment_path: data.target.files[0],
      uidai_number_attachment_file: data.target.value,
    }));
  };
  const uploadBankPassbookHandler = (data) => {
    setFilePaths((prevState) => ({
      ...prevState,
      account_attachment_path: data.target.files[0],
      account_attachment_file: data.target.value,
    }));
  };
  //console.log(token);
  const candidateDetailsUploadHandler = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const headersList = {};
    const url = API_URL + "/applicant-personal-details/";

    const bodyContent = new FormData();
    bodyContent.append("dob", candidateDetails.dob);
    bodyContent.append("pan_number", candidateDetails.pan_number);
    bodyContent.append("uidai_number", candidateDetails.uidai_number);
    bodyContent.append("account_number", candidateDetails.account_number);
    // bodyContent.append("marital_status", candidateDetails.marital_status);
    bodyContent.append("marital_status", "unmarried");
    bodyContent.append("ifsc", candidateDetails.ifsc);
    bodyContent.append("bank_name", candidateDetails.bank_name);
    bodyContent.append("token", token);
    bodyContent.append(
      "profile_image",
      filePaths.profile_image_path,
      filePaths.profile_image_file
    );
    bodyContent.append(
      "pan_number_attachment",
      //   "c:UsersAdminDownloadsAvinash8489.pdf"

      filePaths.pan_number_attachment_path,
      filePaths.pan_number_attachment_file
    );
    bodyContent.append(
      "uidai_number_attachment",
      //   "c:UsersAdminDownloadsAvinash8489.pdf"
      filePaths.uidai_number_attachment_path,
      filePaths.uidai_number_attachment_file
    );
    bodyContent.append(
      "account_attachment",
      //   "c:UsersAdminDownloadsAvinash8489.pdf"
      filePaths.account_attachment_path,
      filePaths.account_attachment_file
    );

    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      const data = await response.json();
      console.log(data);
      if (data.status) {
        alert.success(data.message);
        setCandidateDetails();
        setFilePaths();
        setIsLoading(false);
      }
      if (data.status === false) {
        //alert.error(data.message);
        let errorMessages = "";

        for (let key in data.error) {
          console.log(key);
          if (data.error.hasOwnProperty(key)) {
            errorMessages += `${data.error[key][0]}\n`;
          }
        }
        console.log(errorMessages);
        alert.error(errorMessages);
      }
    } catch (err) {
      console.log(err);
      alert.error("Internal Server Error");
    }
  };
  const resetState = () => {
    setCandidateDetails();
    setFilePaths();
    setIsLoading(true);
  };
  // console.log(candidateDetails);
  return (
    <>
      <div className="container mt-5">
        <div className="col-md-6 offset-md-3">
          <div className="candidateHighlight position-relative">
            <div className="candidateMainContent">
              <div className="text-center">
                <h3>Personal Details</h3>
                <h5>Please enter the personal details carefully</h5>
              </div>
              <form className="mt-4" onSubmit={candidateDetailsUploadHandler}>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="fname">
                      Date of Birth <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="date"
                      id="dob"
                      // placeholder="Enter Your First Name (Mandatory)"
                      name="dob"
                      className="form-control"
                      required
                      onChange={(e) =>
                        setCandidateDetails((prevState) => ({
                          ...prevState,
                          dob: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="fname">
                      Upload Photo <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="file"
                      id=""
                      // placeholder="Enter Your First Name (Mandatory)"
                      name=""
                      className="form-control"
                      required
                      onChange={updateProfilePicturePathHandler}
                    />
                  </div>
                </div>
                <div className="row">
                  <p className="mb-0">Marital Status</p>
                  <div className="col-md-12">
                    <input
                      type="radio"
                      id="html"
                      name="unmarried"
                      value="unmarried"
                      onChange={(e) => {
                        setCandidateDetails((prevState) => ({
                          ...prevState,
                          marital_status: e.target.value,
                        }));
                      }}
                      className="me-1"
                      // checked={candidateDetails.marital_status==="unmarried"}
                    />
                    <label for="html">Unmarried</label>
                    <input
                      className="me-1"
                      type="radio"
                      id="html"
                      name="married"
                      value="married"
                      // checked={candidateDetails.marital_status==="married"}
                      onChange={(e) => {
                        setCandidateDetails((prevState) => ({
                          ...prevState,
                          marital_status: e.target.value,
                        }));
                      }}
                    />
                    <label for="html">Married</label>
                    <input
                      className="me-1"
                      type="radio"
                      id="html"
                      name="widowed"
                      value="widowed"
                      // checked={candidateDetails.marital_status==="married"}
                      onChange={(e) => {
                        setCandidateDetails((prevState) => ({
                          ...prevState,
                          marital_status: e.target.value,
                        }));
                      }}
                    />
                    <label for="html">Widowed</label>
                    <input
                      className="me-1"
                      type="radio"
                      id="html"
                      name="divorced"
                      value="divorced"
                      // checked={candidateDetails.marital_status==="married"}
                      onChange={(e) => {
                        setCandidateDetails((prevState) => ({
                          ...prevState,
                          marital_status: e.target.value,
                        }));
                      }}
                    />
                    <label for="html">Divorced</label>
                  </div>
                </div>
                <div className="row mb-3 mt-2">
                  <div className="col-md-6">
                    <label htmlFor="panno">
                      PAN Number <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="text"
                      id="pan"
                      pattern="[A-Z a-z]{5}[0-9]{4}[A-Z a-z]{1}" //Pan Number Regex
                      placeholder="Enter Your Pan Number"
                      name="pan"
                      className="form-control"
                      required
                      onChange={(e) =>
                        setCandidateDetails((prevState) => ({
                          ...prevState,
                          pan_number: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label htmlFor="pancard" className="w-auto">
                      Upload Pan Card <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="file"
                      id="pancard"
                      // placeholder="Enter Your First Name (Mandatory)"
                      name="pancard"
                      className="form-control"
                      required
                      onChange={updatePanCardPathHandler}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="panno">
                      UIDAI Number <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="number"
                      id="UIDAI"
                      placeholder="Enter Your UIDAI Number"
                      name="UIDAI"
                      className="form-control"
                      pattern="^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$"
                      required
                      onChange={(e) =>
                        setCandidateDetails((prevState) => ({
                          ...prevState,
                          uidai_number: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label htmlFor="pancard">
                      Upload UIDAI <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="file"
                      id="uploadUIDAI"
                      // placeholder="Enter Your First Name (Mandatory)"
                      name="uploadUIDAI"
                      className="form-control"
                      required
                      onChange={updateUIDAIPathHandler}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="panno">
                      Bank Name <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="text"
                      id="bank"
                      placeholder="Enter Your Bank"
                      name="bank"
                      className="form-control"
                      required
                      onChange={(e) =>
                        setCandidateDetails((prevState) => ({
                          ...prevState,
                          bank_name: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="panno">Bank Passbook</label>
                    <input
                      type="file"
                      id="bank"
                      placeholder="Enter Your Bank"
                      name="bank"
                      className="form-control"
                      required
                      onChange={uploadBankPassbookHandler}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="panno" className="w-auto" >
                      Enter Account Number <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="text"
                      id="accnumber"
                      placeholder="Enter Your Account Number"
                      name="accnumber"
                      className="form-control"
                      required
                      onChange={(e) =>
                        setCandidateDetails((prevState) => ({
                          ...prevState,
                          account_number: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="col-md-6 ">
                    <label htmlFor="pancard">
                      IFSC Code <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="text"
                      id="ifsc"
                      placeholder="Enter Bank IFSC Code"
                      name="ifsc"
                      className="form-control"
                      required
                      onChange={(e) =>
                        setCandidateDetails((prevState) => ({
                          ...prevState,
                          ifsc: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <button
                  className="btn btn-danger me-3"
                  type="submit"
                  disabled={isLoading === false}
                >
                  Submit
                </button>
                <button
                  className="btn btn-light personalDetailCancelBtn"
                  type="reset"
                  onClick={resetState}
                >
                  Reset
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
