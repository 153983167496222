import React from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";

import { API_URL } from "../../../../../Constents";

import { BsRecordCircleFill, BsFillEyeFill } from "react-icons/bs";
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import { FaLaptopCode } from "react-icons/fa";

export default function AllCandidateSideDrawer({
  selectedRowData,
  openEditCandidateModal,
  openScheduleCandidateInterviewModal,
  refreshTable,
  closeSideDrawer,
}) {
  const alert = useAlert();
  const history = useHistory();

  const changeApplicantStatusHandler = async (status) => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      applicant_status: status,
    });

    const URL = API_URL + "/applicant/?applicant_id=" + selectedRowData.id;

    try {
      const response = await fetch(URL, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      if (data.status) {
        alert.success(data.message);
        refreshTable(data);
        closeSideDrawer(false);
      }
      if (data.status === false) alert.error(data.message);
    } catch (err) {
      console.log(err);
      alert.error("Something Went Wrong");
    }
  };

  const generateOfferLetterHandler = async (app_id) => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      offer_letter_status: true,
    });

    const url = API_URL + "/applicant/?applicant_id=" + app_id;

    try {
      const response = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      if (data.status) {
        console.log(data);
        refreshTable(data);
        alert.success(data.message);
        closeSideDrawer(false);
      }
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.error(err);
      alert.error("internal Server Error");
    }
  };

  console.log(selectedRowData);
  return (
    <>
      <div className="maincontent">
        <div className="my-3">
          <h4 style={{ color: "#535353" }}>Status</h4>

          <button
            className=" btn btn-outline-success  me-2"
            onClick={() => changeApplicantStatusHandler("selected")}
            disabled={
              selectedRowData.applicant_status === null
                ? false
                : selectedRowData.applicant_status === "selected"
                ? true
                : false
            }
          >
            {" "}
            <AiFillCheckCircle /> Accept
          </button>

          <button
            className="btn btn-outline-info mx-2 "
            disabled={
              selectedRowData.applicant_status === null
                ? false
                : selectedRowData.applicant_status === "on_hold"
                ? true
                : false
            }
            onClick={() => changeApplicantStatusHandler("on_hold")}
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            {/* <FaDotCircle size={"12px"} /> OnHold */}
            <BsRecordCircleFill size={"12px"} className="me-1" /> On Hold
          </button>

          <button
            className=" btn btn-outline-danger mx-2 "
            onClick={() => changeApplicantStatusHandler("rejected")}
            disabled={
              selectedRowData.applicant_status === null
                ? false
                : selectedRowData.applicant_status === "rejected"
                ? true
                : false
            }
          >
            <AiFillCloseCircle style={{ lineHeight: 1 }} /> Reject
          </button>
        </div>

        <div className="my-5">
          <h4 style={{ color: "#535353" }}>Interview</h4>
          <div>
            <button
              className="btn btn-outline-primary btn-lg"
              onClick={openScheduleCandidateInterviewModal}
              disabled={selectedRowData.applicant_status === "selected"}
            >
              <FaLaptopCode />{" "}
              {selectedRowData.interview &&
              selectedRowData.interview.length === 0
                ? "Schedule an Interview"
                : "Reschedule an Interview"}
            </button>
          </div>
        </div>
        <div className="mb-4 ">
          <h4 style={{ color: "#535353" }} className="m-0 p-0">
            Offer Letter
          </h4>

          {selectedRowData.offer_letter_status ? (
            <button
              className="btn btn-outline-danger w-45 mt-2"
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => window.open(selectedRowData.offer_letter)}
            >
              {" "}
              <BsFillEyeFill className="me-1" /> View pdf
            </button>
          ) : (
            <button
              className="btn btn-outline-danger mt-2"
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              disabled={
                selectedRowData.applicant_status === "rejected" ||
                selectedRowData.applicant_status === "on_hold" ||
                selectedRowData.applicant_status === null
              }
              onClick={() => generateOfferLetterHandler(selectedRowData.id)}
            >
              {" "}
              <BsFillEyeFill className="me-1" /> Generate Offer Letter
            </button>
          )}
        </div>

        <div className="mb-4 ">
          <h4 style={{ color: "#535353" }} className="m-0 p-0">
            Personal Details
          </h4>
          <button
            onClick={() =>
              history.push({
                pathname: "/ReviewCandidateDetails",
                state: { details: selectedRowData },
              })
            }
            className="btn btn-outline-danger  mt-2"
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
            disabled={
              !selectedRowData.offer_letter_status ||
              selectedRowData.applicant_status === null ||
              selectedRowData.applicant_acceptance === false ||
              selectedRowData.confirm_personal_details !== "received"
            }
          >
            {" "}
            Confirm Documents
          </button>
        </div>
        <div className="mb-4 ">
          <h4 style={{ color: "#535353" }} className="m-0 p-0">
            Joining Letter
          </h4>
          <button
            className="btn btn-outline-danger w-45 mt-2"
            style={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            disabled={
              !selectedRowData.offer_letter_status ||
              selectedRowData.applicant_acceptance === false ||
              selectedRowData.confirm_personal_details !== "confirmed"
            }
            onClick={() => window.open(selectedRowData.joining_letter)}
          >
            {" "}
            <BsFillEyeFill className="me-1" /> View pdf
          </button>
        </div>

        <div className="col-md-12 mx-1 my-5">
          <button
            onClick={() => openEditCandidateModal()}
            className="btn btn-danger w-100"
          >
            Edit Applicant
          </button>
        </div>
      </div>
    </>
  );
}
