import React, { useState, useEffect } from "react";
import { API_URL } from "../../../../Constents";

import { useAlert } from "react-alert";

export default function CreateCandidate(props) {
  const [allJobsListForTable, setAllJobsListForTable] = useState([]);
  const [createCandidateData, setCreateCandidateData] = useState({
    name: "",
    email: "",
    alt_email: "",
    mobile_number: "",
    alt_mobile_number: "",
    expected_ctc: null,
    current_ctc: null,
    notice_period: null,
    created_by: null,
    vacancy: null,
    applicant_status: null,
    attachment_file: "",
    file_path: "",
  });

  const alert = useAlert();
  //console.log(createCandidateData);
  const uploadFilePathHandler = (data) => {
    setCreateCandidateData((prevState) => ({
      ...prevState,
      file_path: data.target.files[0],
      attachment_file: data.target.value,
    }));
  };

  const createCandidateDataHandler = async (e) => {
    e.preventDefault();
    const URL = API_URL + "/applicant/";

    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const bodyContent = new FormData();
    bodyContent.append("name", createCandidateData.name);
    bodyContent.append("email", createCandidateData.email);
    bodyContent.append("mobile_number", createCandidateData.mobile_number);
    bodyContent.append(
      "resume",
      createCandidateData.file_path,
      createCandidateData.attachment_file
    );
    bodyContent.append("vacancy", parseInt(createCandidateData.vacancy));
    bodyContent.append("current_ctc", createCandidateData.current_ctc);
    bodyContent.append("expected_ctc", createCandidateData.expected_ctc);
    bodyContent.append("notice_period", createCandidateData.notice_period);

    try {
      const response = await fetch(URL, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      const data = await response.json();
      if (data.status) {
        props.closeCandidateModal();
        alert.success(data.message);
      }
      console.log(data);
      if (data.status === false) {
        //alert.error(data.message);
        let errorMessages = "";

        for (let key in data.error) {
          console.log(key);
          if (data.error.hasOwnProperty(key)) {
            errorMessages += `${data.error[key][0]}\n`;
          }
          alert.error(errorMessages);
        }
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  const fetchAllJobs = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/admin-vacancy-crud/?is_active_status=true";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      setAllJobsListForTable(data.data);
    } catch (err) {
      console.error(err);
      alert.error("There is an error in fetching the vacancy list");
    }
  };

  useEffect(() => {
    fetchAllJobs();
  }, []);

  console.log(createCandidateData);
  return (
    <>
      <div className="setting_header ps-3">
        <form onSubmit={createCandidateDataHandler}>
          <div className="col-md-12 mb-3">
            <h5 style={{ color: "#DD3974" }}>Add Applicant</h5>
            <hr />
            <div className="row mb-3">
              <div className="col-md-6">
                <label for="" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  placeholder="Enter Name"
                  required
                  onChange={(e) => {
                    setCreateCandidateData((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="col-md-6">
                <label for="" className="form-label">
                  E-mail
                </label>
                <input
                  type="email"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  placeholder="Enter E-mail"
                  required
                  onChange={(e) => {
                    setCreateCandidateData((prevState) => ({
                      ...prevState,
                      email: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label for="" className="form-label">
                  Phone No.
                </label>
                <input
                  type="number"
                  className="form-control"
                  required
                  placeholder="Enter Phone Number"
                  onChange={(e) => {
                    setCreateCandidateData((prevState) => ({
                      ...prevState,
                      mobile_number: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="designition">Resume</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={uploadFilePathHandler}
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="">Current C.T.C</label>
                <input
                  type="number"
                  className="form-control "
                  id=""
                  aria-describedby=""
                  placeholder="Enter Current CTC (in LPA)"
                  onChange={(e) => {
                    setCreateCandidateData((prevState) => ({
                      ...prevState,
                      current_ctc: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="">Expected C.T.C</label>
                <input
                  type="number"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  placeholder="Enter Expected CTC (in LPA)"
                  onChange={(e) => {
                    setCreateCandidateData((prevState) => ({
                      ...prevState,
                      expected_ctc: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="">Notice Period</label>
                <input
                  type="number"
                  className="form-control "
                  id=""
                  aria-describedby=""
                  placeholder="Enter Notice Period in DAYS"
                  onChange={(e) => {
                    setCreateCandidateData((prevState) => ({
                      ...prevState,
                      notice_period: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="designition">Select Vacancy</label>
                <select
                  className="form-select form-control"
                  id="inputGroupSelect02"
                  onChange={(e) => {
                    setCreateCandidateData((prevState) => ({
                      ...prevState,
                      vacancy: +e.target.value,
                    }));
                  }}
                >
                  <option hidden disabled selected>
                    {" "}
                    -- Select Vacancy --{" "}
                  </option>
                  {allJobsListForTable &&
                  allJobsListForTable.length > 0 &&
                  allJobsListForTable !== undefined
                    ? allJobsListForTable.map((jobsList) => (
                        <option key={jobsList.id} value={jobsList.vacancy_id}>
                          {jobsList.title} ({jobsList.vacancy_id})
                        </option>
                      ))
                    : ""}
                </select>
              </div>
            </div>
          </div>

          <div>
            <button className="btn btn-danger me-3" type="submit">
              Save & Exit
            </button>
          </div>
        </form>
        <button
          className="btn btn-light cancelBtnPosition"
          onClick={props.closeCandidateModal}
        >
          Cancel
        </button>
      </div>
    </>
  );
}
