// Table import
import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import React from "react";
import { useAlert } from "react-alert";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { API_URL } from "../../Constents";
//import ModalEmpoyeeDetails from "../Modals/ModalEmployeeMoreDetails";
import ModalEmpoyeeDetails from "../Modals/ModalEmployeeDetails";
import { useInterval } from "../Hooks/useIntervel";
import timeFormatter from "../Hooks/timeFormatter";
import ReverseDateString from "../Hooks/ReverseDateString";

import { BsBoxArrowUpRight } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import LoadingGifs from "../../images/YAAMS-gif.gif";

const Leaves = () => {
  const [leaveData, setLeaveData] = useState([]);
  const [leaveName, setLeaveName] = useState();
  const [err, setErr] = useState();
  const [employeeId, setemployeeId] = useState();
  const [leaveId, setLeaveId] = useState();
  const [show, setShow] = useState(false);
  const [showEmp, setShowEmp] = useState(false);
  const [details, setDetails] = useState({
    startDate: "",
    endDate: "",
    status: "pending",
  });
  const [approvalComments, setApprovalComments] = useState("");
  const [approveStatus, setApproveStatus] = useState(null);
  const [leaveExportStatusMsg, setLeaveExportStatusMsg] = useState("");

  const handleCloseEmp = () => setShowEmp(false);
  const handleShowEmp = () => setShowEmp(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalID, setModalId] = useState("");
  const alert = useAlert();
  //const formattedTime = useFormattedTime();
  //const { reload } = props;

  const fetchLeaveData = async () => {
    //const url = API_URL + "/fetch-leave-admin/";
    const url = API_URL + "/get-leave/";
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const bodyContent = JSON.stringify({
      status: details.status,
      start_date: details.startDate,
      last_date: details.endDate,
    });

    console.log(bodyContent);

    const headerlist = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: headerlist,
        body: bodyContent,
      });
      const data = await res.json();
      console.log(data);
      setLeaveData(data?.data?.fetched_by_admin);
    } catch (err) {
      setErr(err);
      alert.error("Internal Server Error");
    }
  };

  const leaveActionHandler = async (leaveStatus) => {
    const url =
      API_URL +
      "/approve-leave/?leave_id=" +
      leaveId +
      "&" +
      "user_id=" +
      employeeId;

    console.log(url);
    const bodyContent = JSON.stringify({
      status: leaveStatus,
      approval_comment: approvalComments,
    });
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    try {
      const res = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });
      const data = await res.json();
      console.log(data);
      setApproveStatus(data);
      //props.setReload(data);
      handleClose();
    } catch (err) {
      console.log(err);
      alert.error("Internal Server Error");
    }
  };

  // useInterval(() => {
  //   fetchLeaveData();
  // }, 1000 * 10);

  useEffect(() => {
    fetchLeaveData();
  }, [show, details.startDate, details.endDate, details.status]);

  const allExportSwitchLeavesHandler = (exportCase) => {
    switch (exportCase) {
      case "1":
        ExportLeaveDataToSheet();
        break;
      case "2":
        ExportLeaveDataToPDF();
        break;
      case "3":
        ExportBalanceLeaveDataToPDF();
        break;
    }
  };

  const ExportBalanceLeaveDataToPDF = async () => {
    setLeaveExportStatusMsg(
      "We are preparing the balance leave PDF file please wait!!!"
    );
    const url = API_URL + "/leave-data-export/";

    try {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const res = await fetch(url, {
        method: "POST",

        headers: {
          Authorization: "Token " + userData.seucrity_token,
        },
      });
      const data = await res.json();
      console.log(data);
      setLeaveExportStatusMsg(
        "Balance Leave Data file exported successfully !!! "
      );
      window.open(data.data);
      window.location.reload();
    } catch (err) {
      console.error(err);
      setLeaveExportStatusMsg("There was an error in exporting the PDF file");
    }
  };

  const ExportLeaveDataToPDF = async () => {
    setLeaveExportStatusMsg("We're preparing your PDF file please wait!!!");
    const bodyContent = JSON.stringify({
      start_date: details.startDate,
      last_date: details.endDate,
      status: details.status,
    });

    const url = API_URL + "/export-leave-data-pdf/";

    console.log(url);
    try {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: {
          Authorization: "Token " + userData.seucrity_token,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      console.log(data);
      setLeaveExportStatusMsg("PDF File exported successfully!!! ");
      window.open(data.data);
      window.location.reload();
    } catch (err) {
      console.error(err);
      setLeaveExportStatusMsg("There was an error in exporting the PDF file");
    }
  };

  const ExportLeaveDataToSheet = async () => {
    setLeaveExportStatusMsg("We're preparing your excel file please wait!!!");
    const bodyContent = JSON.stringify({
      start_date: details.startDate,
      last_date: details.endDate,
      status: details.status,
    });

    const url = API_URL + "/export-leave-data/";

    console.log(url);
    try {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: {
          Authorization: "Token " + userData.seucrity_token,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      setLeaveExportStatusMsg("File Exportd Successfully!!!");
      window.open(data.data);
      window.location.reload();
    } catch (err) {
      console.error(err);
      setLeaveExportStatusMsg("There was an error in exporting the Excel");
    }
  };

  const allExportSwitchWorkingHoursHandler = (exportCase) => {
    switch (exportCase) {
      case "1":
        ExportWorkingHoursToSheet();
        break;
      case "2":
        console.log("PDF");
        ExportWorkingHoursToPDF();
        break;
    }
  };

  const ExportWorkingHoursToPDF = async () => {
    setLeaveExportStatusMsg(
      "We're preparing the total working hour PDF, please wait!!!"
    );
    const bodyContent = JSON.stringify({
      start_date: details.startDate,
      last_date: details.endDate,
    });

    const url = API_URL + "/export-sum-attendance-pdf/";

    console.log(url);
    try {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: {
          Authorization: "Token " + userData.seucrity_token,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      console.log(data);
      setLeaveExportStatusMsg("Total working hour excel exported successfully");
      window.open(data.data);
      window.location.reload();
    } catch (err) {
      console.error(err);
      setLeaveExportStatusMsg(
        "There was an error in exporting the total working hour PDF file"
      );
    }
  };

  const ExportWorkingHoursToSheet = async () => {
    setLeaveExportStatusMsg(
      "We're preparing the total working hour file, please wait!!!"
    );
    const bodyContent = JSON.stringify({
      start_date: details.startDate,
      last_date: details.endDate,
    });

    const url = API_URL + "/export-sum-attendance/";

    console.log(url);
    try {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: {
          Authorization: "Token " + userData.seucrity_token,
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      console.log(data);
      setLeaveExportStatusMsg("Total working hour excel exported successfully");
      window.open(data.data);
      window.location.reload();
    } catch (err) {
      console.error(err);
      setLeaveExportStatusMsg(
        "There was an error in exporting the total working hour excel file"
      );
    }
  };

  console.log(leaveData);
  if (!leaveData)
    return (
      <div className="loadingGif">
        <img src={LoadingGifs} alt="Loading Gif" />
      </div>
    );
  if (err) return <h1>Some error occured</h1>;

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "employee_name",
      text: "Name",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <div className="row align-items-center">
              <Button
                variant="link"
                style={{
                  color: "#3E3E3E",
                  textDecoration: "none",
                  whiteSpace: "nowrap",
                  textAlign: "left",
                }}
                onClick={() => {
                  console.log(row.id);
                  setModalId(row.employee);
                  handleShowEmp();
                }}
              >
                <span>
                  {row.employee_name}
                  <BsBoxArrowUpRight
                    style={{
                      fontSize: "10px",
                      marginLeft: "2px",
                      // marginBottom: "10px",
                      color: "#3E3E3E",
                      fontWeight: "600",
                    }}
                  />
                </span>
              </Button>
            </div>
          </>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.status === "approved")
          return <p style={{ color: "green" }}>Approved</p>;
        if (row.status === "canceled")
          return <p style={{ color: "red" }}>Cancelled</p>;
        else if (row.status === "rejected")
          return <p style={{ color: "red" }}>Rejected</p>;
        else if (row.status === "late")
          return <p style={{ color: "red" }}>Late Deduction</p>;
        else
          return (
            <>
              <div className="row align-items-center">
                <Button
                  variant="link"
                  style={{
                    color: "#3E3E3E",
                    textDecoration: "none",
                    whiteSpace: "nowrap",
                    textAlign: "left",
                  }}
                  onClick={() => {
                    handleShow();
                    setemployeeId(row.employee);
                    setLeaveId(row.id);
                    setLeaveName(row.employee_name);
                  }}
                >
                  {/* {row.status} */}
                  <span>
                    Pending
                    <BsBoxArrowUpRight
                      style={{
                        fontSize: "10px",
                        marginLeft: "3px",
                        marginBottom: "10px",
                        color: "blue",
                        fontWeight: "600",
                      }}
                    />
                  </span>
                </Button>
              </div>
            </>
          );
      },
    },
    { dataField: "leave_name", text: "Leave Type", sort: true },
    {
      dataField: "from_date",
      text: "from",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return ReverseDateString(row.from_date);
      },
    },
    {
      dataField: "to_date",
      text: "to",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return ReverseDateString(row.to_date);
      },
    },
    {
      dataField: "shift",
      text: "Half/Full",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.shift === "" || row.shift === null) return "Full Day";
        else if (row.shift === "custom") {
          return (
            <>
              <p className="m-0">Custom</p>
              <p style={{ fontSize: 10 }} className="m-0">
                {timeFormatter(row.from_time)} to {timeFormatter(row.to_time)}
              </p>
            </>
          );
        } else return <p>{row.shift}</p>;
      },
    },

    // { dataField: "paid_quantity", text: "Paid Quantity", sort: true },
    // { dataField: "unpaid_quantity", text: "Unpaid Quantity", sort: true },
    { dataField: "reason", text: "Reason", sort: true },
    { dataField: "remaining_leave", text: "Remaining", sort: true }, //filter: textFilter() },
    // { dataField: "paid_unpaid", text: "Type", sort: true },
    {
      dataField: "attachment",
      text: "Attachment",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.attachment === "" || row.attachment === null) return <p>NA</p>;
        else
          return (
            <button
              className="btn btn-link text-decoration-none p-0"
              onClick={() => window.open(row.attachment)}
            >
              View
            </button>
          );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: "End",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  return (
    <div className="maincontent">
      <div className="highlight all_form_spacing">
        {approveStatus &&
        approveStatus.status === true &&
        approveStatus.status !== undefined ? (
          <div className="alert alert-success" role="alert">
            {approveStatus.message}
          </div>
        ) : approveStatus &&
          approveStatus.status === false &&
          approveStatus.status !== undefined ? (
          <div className="alert alert-danger" role="alert">
            {approveStatus.message}
          </div>
        ) : (
          ""
        )}
        <h1 className="mx-2" style={{ color: "#DD3944" }}>
          Leaves
        </h1>
        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={leaveData}
          columns={columns}
          ignoreHeader
          exportCSV={{
            fileName: "Leave.csv",
          }}
          search
        >
          {(props) => (
            <React.Fragment>
              <div className="container">
                <div className="row">
                  <div className="col-md-3 mt-4">
                    {/* <SearchBar
                      srText="Search Table"
                      {...props.searchProps}
                      className="newSearch"
                      style={{ border: "none" }}
                    /> */}
                    <div className="position-relative newsearch_box">
                      <SearchBar
                        className="newSearchWrapper"
                        placeholder="Search Here..."
                        srText=""
                        {...props.searchProps}
                      />
                      <BiSearch />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="dob">Start Date</label>
                    <input
                      className="form-select form-control "
                      type="date"
                      id="StartDate"
                      name="StartDate"
                      value={details.startDate}
                      onChange={(e) => {
                        setDetails({ ...details, startDate: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="dob">End Date</label>
                    <input
                      className="form-select form-control"
                      type="date"
                      id="birthday"
                      name="birthday"
                      value={details.endDate}
                      onChange={(e) => {
                        setDetails({ ...details, endDate: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="designition">Select Status</label>
                    <select
                      className="form-select form-control"
                      id="inputGroupSelect02"
                      onChange={(e) => {
                        setDetails({ ...details, status: e.target.value });
                      }}
                    >
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                      <option value="canceled">Cancelled</option>
                      <option value="late">Late</option>
                      <option value="">All</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <select
                      className="form-control form-select"
                      onChange={(e) => {
                        allExportSwitchWorkingHoursHandler(e.target.value);
                      }}
                    >
                      <option selected disabled>
                        Export Working Hrs
                      </option>
                      <option value="1">As Excel</option>
                      <option value="2">As PDF</option>
                    </select>
                    {/* <button
                      type="button"
                      className="btn btn-secondary btn-lg"
                      onClick={ExportWorkingHoursToSheet}
                    >
                      Export Working Hours
                    </button> */}
                  </div>
                  <div className="col-md-3 mb-3">
                    <select
                      className="form-control form-select"
                      onChange={(e) => {
                        allExportSwitchLeavesHandler(e.target.value);
                      }}
                    >
                      <option selected disabled>
                        Export Leaves
                      </option>
                      <option value="1">As Excel</option>
                      <option value="2">As PDF</option>
                      <option value="3">
                        Employee Balance Leaves As Excel
                      </option>
                    </select>
                    {/* <button
                      type="button"
                      className="btn btn-secondary btn-lg"
                      onClick={ExportLeaveDataToSheet}
                    >
                      Export Leave
                    </button> */}
                  </div>
                </div>
                {leaveExportStatusMsg === "" ? (
                  ""
                ) : (
                  <p className="msg_fail">{leaveExportStatusMsg}</p>
                )}
                <BootstrapTable
                  pagination={pagination}
                  filter={filterFactory()}
                  {...props.baseProps}
                  bordered={false}
                  noDataIndication={() => {
                    return <h3>No data available for this filter</h3>;
                  }}
                  condensed
                />
              </div>
            </React.Fragment>
          )}
        </ToolkitProvider>
        <Modal size="xl" show={showEmp} onHide={handleCloseEmp}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <ModalEmpoyeeDetails id={modalID} />
          </Modal.Body>
        </Modal>

        <Modal size="lg" show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            {/* <Modal.Title>Leave Approve </Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center mb-3">
              <h2>
                Take Action on <i>{leaveName}</i>'s Leave
              </h2>
            </div>
            <div className="d-flex justify-content-center mb-3">
              <textarea
                type="text"
                className="form-control"
                placeholder="Approval/Rejection Comments (if Any)"
                style={{ width: "400px" }}
                onChange={(e) => setApprovalComments(e.target.value)}
              />
            </div>

            <div className="d-flex justify-content-center mb-3">
              <button
                className="btn btn-success btn-lg me-3"
                onClick={() => leaveActionHandler("approved")}
              >
                Approve
              </button>
              <button
                className="btn btn-danger btn-lg"
                onClick={() => leaveActionHandler("rejected")}
              >
                Reject
              </button>
            </div>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
};

export default Leaves;
