import { useEffect } from "react";
// import React from "react";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import { Doughnut } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";

import { API_URL } from "../../../Constents";
import { useState } from "react";

const ShowPollsResultsAndOptions = (props) => {
  const [pollOptionsAndResults, setPollOptionsAndResults] = useState({
    lables: [],
    numberOfVotes: [],
    question: "",
  });

  const fetchClickedPollResults = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/get-poll-result-admin/?question_id=" + props.id;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);

      const lables = [];
      const numberOfVotes = [];
      data.data.map((optionData) => {
        return lables.push(optionData.answer_text);
      });
      data.data.map((optionData) => {
        return numberOfVotes.push(optionData.counter);
      });

      setPollOptionsAndResults({
        lables: lables,
        numberOfVotes: numberOfVotes,
        question: data.data[0].question.question_text,
      });
      console.log(lables);
      console.log(numberOfVotes);
    } catch (err) {
      console.error(err);
      console.log(err);
    }
  };

  console.log(pollOptionsAndResults);

  // ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: pollOptionsAndResults.question,
      },
    },
  };

  const data = {
    labels: pollOptionsAndResults.lables,
    datasets: [
      {
        label:
          "Votes Polled" +
          " ( Total Votes " +
          pollOptionsAndResults.numberOfVotes.reduce(
            (partialSum, a) => partialSum + a,
            0
          ) +
          ")",
        data: pollOptionsAndResults.numberOfVotes,
        backgroundColor: "rgba(143, 143, 143, 0.5)",
      },
    ],
  };

  // const data = {
  //   labels: pollOptionsAndResults.lables,
  //   datasets: [
  //     {
  //       label: "# of Votes",
  //       data: pollOptionsAndResults.numberOfVotes,
  //       backgroundColor: [
  //         "rgba(255, 99, 132, 0.2)",
  //         "rgba(54, 162, 235, 0.2)",
  //         "rgba(255, 206, 86, 0.2)",
  //         "rgba(75, 192, 192, 0.2)",
  //       ],
  //       borderColor: [
  //         "rgba(255, 99, 132, 1)",
  //         "rgba(54, 162, 235, 1)",
  //         "rgba(255, 206, 86, 1)",
  //         "rgba(75, 192, 192, 1)",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  useEffect(() => {
    fetchClickedPollResults();
  }, []);

  return (
    <>
      {/* {pollOptionsAndResults.numberOfVotes.every((item) => item === 0) ? (
        <h3>No Votes are polled</h3>
      ) : (
        <Doughnut data={data} />
      )} */}
      <Bar options={options} data={data} />
    </>
  );
};

export default ShowPollsResultsAndOptions;
