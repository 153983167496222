//import OrgCheckinSetting from "./OrgCheckinSetting";
import DepartmentMain from "../../Departments/DepartmentMain";

export default function GeneralSettings() {
  return (
    <>
      <div>
        <h2 className="tab_heading  ">Teams Settings</h2>
        <hr />
        <DepartmentMain />
      </div>
    </>
  );
}
