import { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../Constents";
import { Button, Modal } from "react-bootstrap";
import ModalEmpoyeeDetails from "../Modals/ModalEmployeeDetails";
import { IoIosArrowBack } from "react-icons/io";
import AssignDepWiseWFH from "./AssignDepWiseWFH";
import AssignLeaveCategoryToDepartment from "../Leaves/AssignLeaveCategoryToDepartment";
import AssignShiftToDepartment from "./AssignShiftToDepartment";
import { BsBoxArrowUpRight } from "react-icons/bs";
import LoadingGifs from "../../images/YAAMS-gif.gif";
import EditDepartmentHead from "./EditDepartmentHead";

const DepartmentList = () => {
  const [deptList, setDeptList] = useState([]);
  const [err, setErr] = useState();

  const [leaveExportMsg, setLeaveExportMsg] = useState("");
  //Modal State

  const [modalShow, setModalShow] = useState({
    showForm: false,
    showFormLeave: false,
    showShiftForm: false,
    showDeptHead: false,
    showEmpDetails: false,
    empId: "",
  });
  const deptName = window.location.href.split("/").reverse()[0];

  const deptNameWOSpace1 = deptName.split("%20")[0];
  const deptNameWOSpace2 = deptName.split("%20")[1];
  //console.log(deptNameWOSpace1);

  const deptListFetch = async () => {
    const url = API_URL + "/fetch-teams/?department_id=" + deptName;
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    try {
      const res = await fetch(url, { method: "GET", headers: headerList });
      const data = await res.json();
      console.log(data);
      setDeptList(data);
    } catch (err) {
      setErr(err);
    }
  };

  useEffect(() => {
    deptListFetch();
  }, []);

  const departmentName =
    deptList && deptList.department_manual_id !== undefined
      ? deptList.department_manual_id.split("_")[1]
      : "";
  console.log(departmentName);

  const columns = [
    {
      dataField: "first_name",
      text: "Name",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <Button
            variant="link"
            style={{ color: "#3E3E3E", textDecoration: "none" }}
            onClick={() => {
              setModalShow({
                ...modalShow,
                showEmpDetails: true,
                empId: row.id,
              });
            }}
          >
            {row.first_name}
            <BsBoxArrowUpRight
              style={{
                fontSize: "10px",
                marginLeft: "3px",
                marginBottom: "10px",
                color: "black",
                fontWeight: "600",
              }}
            />
          </Button>
        );
      },
    },
    {
      dataField: "designation",
      text: "Role",
    },
    {
      dataField: "mobile_number",
      text: "Number",
    },
    {
      dataField: "official_email",
      text: "Email",
    },
    {
      dataField: "working_from_home",
      text: "Working From Home Today",
      formatter: (row) => {
        if (row === true) {
          return "Yes";
        }
        if (row === false) {
          return "No";
        }
      },
    },
  ];

  const history = useHistory();

  //console.log(modalID);

  if (deptList && !deptList.data)
    return (
      <div className="loadingGif">
        <img src={LoadingGifs} alt="loading Gif" />
      </div>
    );

  if (err) return <h1>Some error occured</h1>;

  const exportLeaveDataToSheet = async (type) => {
    setLeaveExportMsg("!!!!Please wait while we fetch the details!!!!");

    console.log(type);
    const url =
      API_URL + "/leave-data-export/?department_id=" + deptList.department_id;
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: headerList,
      });
      const data = await res.json();
      console.log(data);
      setLeaveExportMsg("!!!!Exported successfully");

      window.open(data.data, "_blank");
      window.location.reload();
    } catch (error) {
      setLeaveExportMsg(
        "!!!!Something must have happened on our side ..TRY AGAIN!!!!"
      );

      console.log(error);
      setErr(error);
    }
  };
  // setTimeout(() => {
  //   setLeaveExportMsg("");
  // }, 5000);
  return (
    <div className="container">
      <div className="highlight all_form_spacing">
        <h3 style={{ color: "#DD3974" }}>Teams Setting</h3>
        <div className="row">
          <div className="col-md-2 my-2">
            <button
              className="departmentSettingBtn formSelectPlus mt-3"
              // onClick={(e) => setShowForm(!showForm)}
              onClick={() => {
                setModalShow({
                  ...modalShow,
                  showForm: true,
                });
              }}
            >
              Assign WFH
            </button>
          </div>
          <div className="col-md-2 my-2">
            <button
              className="departmentSettingBtn mt-3 formSelectPlus"
              // onClick={(e) => setShowFormLeave(!showFormLeave)}
              onClick={() =>
                setModalShow({
                  ...modalShow,
                  showShiftForm: true,
                })
              }
            >
              Assign Shift
            </button>
          </div>
          <div className="col-md-2 my-2">
            <button
              className="departmentSettingBtn mt-3 formSelectPlus"
              // onClick={(e) => setShowShiftForm(!showShiftForm)}
              onClick={() => {
                setModalShow({
                  ...modalShow,
                  showFormLeave: true,
                });
              }}
            >
              Assign Leave Rule
            </button>
          </div>

          <div className="col-md-2 my-2">
            <select
              className="departmentSettingBtn mt-3 formSelectPlus"
              style={{ cursor: "pointer" }}
              onChange={(e) => {
                exportLeaveDataToSheet(e.target.value);
              }}
            >
              <option selected disabled>
                Remaining Leaves
              </option>
              <option value="excel">Export As Excel</option>
              <option value="pdf">Export As PDF</option>
            </select>
          </div>
          {leaveExportMsg === "" ? (
            ""
          ) : (
            <p className="msg_fail">{leaveExportMsg}</p>
          )}
          <div className="col-md-2 my-2">
            <button
              className="departmentSettingBtn mt-3 formSelectPlus"
              onClick={() => {
                setModalShow({
                  ...modalShow,
                  showDeptHead: true,
                });
              }}
            >
              Edit Team Head
            </button>
          </div>
        </div>
        {modalShow.showForm === true ? (
          <Modal
            show={modalShow.showForm}
            // onHide={() => setShowForm(false)}
            onHide={() => {
              setModalShow({
                ...modalShow,
                showForm: false,
              });
            }}
            size="lg"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <AssignDepWiseWFH
                deptID={deptList.department_id}
                departmentName={departmentName}
              />
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}
        {modalShow.showFormLeave === true ? (
          <Modal
            show={modalShow.showFormLeave}
            // onHide={() => setShowFormLeave(false)}
            onHide={() => {
              setModalShow({
                ...modalShow,
                showFormLeave: false,
              });
            }}
            size="lg"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <AssignLeaveCategoryToDepartment
                deptID={deptList.department_id}
                departmentName={departmentName}
              />
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}
        {modalShow.showShiftForm === true ? (
          <Modal
            show={modalShow.showShiftForm}
            // onHide={() => setShowShiftForm(false)}
            onHide={() => {
              setModalShow({
                ...modalShow,
                showShiftForm: false,
              });
            }}
            size="xl"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <AssignShiftToDepartment
                deptID={deptList.department_id}
                fn={departmentName}
                sn={deptNameWOSpace2}
                closeAssignShiftModal={() =>
                  setModalShow((prevState) => ({
                    ...prevState,
                    showShiftForm: false,
                  }))
                }
              />
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}
        {modalShow.showDeptHead === true ? (
          <Modal
            show={modalShow.showDeptHead}
            // onHide={() => setShowShiftForm(false)}
            onHide={() => {
              setModalShow({
                ...modalShow,
                showDeptHead: false,
              });
            }}
            // size="sm"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <EditDepartmentHead
                deptName={deptName}
                onClose={() => {
                  setModalShow({
                    ...modalShow,
                    showDeptHead: false,
                  });
                }}
              />
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}
        <div
          className="d-flex  mt-3"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <h3 className="mb-1" style={{ color: "red" }}>
            {" "}
            {departmentName} Team
          </h3>

          <Button
            onClick={() => history.goBack()}
            className="departmentBtnSecondary"
          >
            {" "}
            <IoIosArrowBack size={20} /> Back
          </Button>
        </div>
        <h6 style={{ color: "#5463FF" }} className="mb-3">
          ({deptList.department_manual_id})
        </h6>
        <BootstrapTable
          keyField="id"
          data={deptList.data}
          columns={columns}
          bordered={false}
          condensed
          noDataIndication={() => {
            return <h3>No data available for this team</h3>;
          }}
        />

        <Modal
          size="xl"
          show={modalShow.showEmpDetails}
          onHide={() => {
            setModalShow({
              ...modalShow,
              showEmpDetails: false,
            });
          }}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <ModalEmpoyeeDetails id={modalShow.empId} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default DepartmentList;
