import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_URL, BASE_URL } from "../../Constents";
import { BsBoxArrowUpRight } from "react-icons/bs";

const LeavesModalMoreDetails = (props) => {
  const [leaveData, setLeaveData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const url = API_URL + "/leave-basic-details/?user_id=" + props.userId;
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: { Authorization: "Token " + userData.seucrity_token },
        });
        const data = await res.json();
        console.log(data);
        setLeaveData(data.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(true);
      }
    })();
  }, []);

  // console.log(leaveData);

  if (leaveData && leaveData.length >= 0) {
    return (
      <div
        className="tab-pane fade"
        id="faq_tab_3"
        role="tabpanel"
        aria-labelledby="faq_tab_3-tab"
      >
        <table>
          <tbody>
            <tr>
              <th>S.No.</th>
              <th>Leave Type</th>
              <th>From</th>
              <th>To</th>
              <th>
                Reason{" "}
                <Link to={BASE_URL + "Leaves"}>
                  <BsBoxArrowUpRight color="black" />{" "}
                </Link>
              </th>
            </tr>

            {leaveData && leaveData.length >= 0 && !loading
              ? leaveData.map((leaveArr, index = 0) => {
                  index++;
                  return (
                    <tr key={index}>
                      <td>{index}</td>
                      <td>{leaveArr.leave_name}</td>
                      <td>{leaveArr.from_date}</td>
                      <td>{leaveArr.to_date}</td>
                      <td>{leaveArr.reason}</td>
                    </tr>
                  );
                })
              : "Loading"}
          </tbody>
        </table>
      </div>
    );
  }
};

export default LeavesModalMoreDetails;
