import { useState } from "react";
import { API_URL, TODAY_DATE } from "../../Constents";
const AssignDepWiseWFH = (props) => {
  const [details, setDetails] = useState({
    startDate: "",
    endDate: "",
  });
  const [responseData, setResponseData] = useState("");
  console.log(props.deptID);

  const assignWFHtoDepthandler = (e) => {
    e.preventDefault();

    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const url = API_URL + "/assign-wfh/?department_id=" + props.deptID;
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        from_date:
          details.endDate !== "" && details.startDate === ""
            ? TODAY_DATE
            : details.startDate,
        to_date:
          details.startDate !== "" && details.endDate === ""
            ? TODAY_DATE
            : details.endDate,
      });

      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      const data = await res.json();
      console.log(data);
      setResponseData(data);
    })();
  };

  console.log(details);

  return (
    <>
      {responseData && responseData.status === true ? (
        <div className="alert alert-success" role="alert">
          {responseData.message}
        </div>
      ) : responseData && responseData.status === false ? (
        <div className="alert alert-danger" role="alert">
          {responseData.message}
        </div>
      ) : (
        ""
      )}
      <div className="border border-3 p-5">
        <h5 style={{ color: "blue" }} className="mb-3">
          Assign WFH{" "}
          <span style={{ color: "blue" }}>to {props.departmentName} team</span>
        </h5>

        <form onSubmit={assignWFHtoDepthandler}>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label htmlFor="start">Start Date</label>
              <input
                name="start"
                type="date"
                className="form-control"
                min={TODAY_DATE}
                max="2023-12-31"
                onChange={(e) =>
                  setDetails({ ...details, startDate: e.target.value })
                }
                required
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="end">Start Date</label>
              <input
                name="end"
                type="date"
                className="form-control"
                min={TODAY_DATE}
                max="2023-12-31"
                onChange={(e) =>
                  setDetails({ ...details, endDate: e.target.value })
                }
                required
              />
            </div>
          </div>

          <button type="submit" className="departmentBtnSecondary">
            Assign WFH
          </button>
        </form>
      </div>
    </>
  );
};

export default AssignDepWiseWFH;
