import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";
import userdummyDP from "../../images/DummyUserDP.png";
import moment from "moment";
const ViewAttendanceInDetail = (prpos) => {
  const [attendanceData, setAttendanceData] = useState([]);

  //const id= console.log(window.location.href.split("/").reverse()[0]);
  const { userId } = prpos;

  useEffect(() => {
    (async () => {
      const url = API_URL + "/admin-attendance-details/?user_id=" + userId;
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: { Authorization: "Token " + userData.seucrity_token },
        });
        const data = await res.json();
        setAttendanceData(data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  console.log(attendanceData);

  return (
    <>
      <div className="row">
        <div className="modal_head justify-content-end align-items-center d-flex">
          <div className="close-btn text-dark text-end">
            <i
              className="fa-solid fa-x"
              data-bs-dismiss="modal"
              aria-hidden="true"
            ></i>
          </div>
        </div>

        <div className="emp_stats d-flex justify-content-between align-items-center">
          <div className="emp_det d-flex align-items-center">
            {prpos.data.profile_image === null ? (
              <img src={userdummyDP} alt="DP" className="profile_img me-3" />
            ) : (
              <img
                src={prpos.data.profile_image}
                alt="DP"
                className="profile_img me-3"
              />
            )}

            <div className="empl d-flex flex-column">
              <h6 className="emp_name">{prpos.data.first_name}</h6>
              <p className="m-0">{prpos.data.designation}</p>
            </div>
          </div>

          <div className="col1 d-flex">
            <p className="info_subtxt m-0">
              Work Phone
              <br />
              <span className="contact_highlight blazing_red">
                <a
                  className="cta_link"
                  href={"tel:" + prpos.data.mobile_number}
                >
                  {prpos.data.mobile_number}
                </a>
              </span>
            </p>
          </div>

          <div className="col1 d-flex">
            <p className="info_subtxt m-0">
              Email
              <br />
              <span className="contact_highlight blazing_red">
                <a
                  className="cta_link"
                  href={"mailto:" + prpos.data.personal_email}
                >
                  {prpos.data.personal_email}
                </a>
              </span>
            </p>
          </div>

          <div className="col1 d-flex">
            <p className="info_subtxt m-0">
              Employee Code
              <br />
              <span className="contact_highlight">{prpos.data.id}</span>
            </p>
          </div>

          <div className="col1 d-flex">
            <p className="info_subtxt m-0">
              Departement
              <br />
              <span className="contact_highlight">
                {prpos.data.department_assigned_name}
              </span>
            </p>
          </div>
        </div>

        <div className="tbl_options mt-3 d-flex align-items-center justify-content-end">
          <div className="input-group n_width">
            <select
              className="form-select form-control"
              id="inputGroupSelect02"
            >
              <option selected>Export As</option>
              <option value="1">.pdf</option>
              <option value="2">.xvs</option>
            </select>
          </div>
        </div>

        {attendanceData.status ? (
          <div className="emp_att_tbl mt-4">
            <table>
              <tbody>
                <tr>
                  <th>Date</th>
                  <th>Shift</th>
                  <th>In</th>
                  <th>Out</th>
                  <th>
                    Late
                    <br />
                    Entry
                  </th>
                  <th>
                    Early
                    <br />
                    Depart
                  </th>
                  <th>
                    Working
                    <br />
                    Hour
                  </th>
                  <th>
                    Over
                    <br />
                    Timing
                  </th>
                  <th>Status</th>
                </tr>

                {attendanceData.data.map((attendance) => {
                  return (
                    <tr key={attendance.check_in}>
                      <td>{attendance.for_date}</td>
                      <td>DF</td>
                      <td>
                        {attendance.check_in === null
                          ? "--:--"
                          : moment(attendance.check_in).format("h:mm:ss a")}
                      </td>
                      <td>
                        {attendance.check_out === null
                          ? "--:--"
                          : moment(attendance.check_out).format("h:mm:ss a")}
                      </td>
                      <td>{attendance.delay_by}</td>
                      <td>{attendance.early_depart}</td>
                      <td>
                        {attendance.total_working_hour === null
                          ? "NA"
                          : attendance.total_working_hour + "Hrs"}
                      </td>
                      <td>00:00 Hrs</td>
                      <td className={"status " + attendance.attendance_status}>
                        {attendance.attendance_status === "present_count"
                          ? "On Time"
                          : attendance.attendance_status === "absent_count"
                          ? "Absent"
                          : attendance.attendance_status === "late_count"
                          ? "Late"
                          : attendance.attendance_status === "leave_count"
                          ? "On Leave"
                          : attendance.attendance_status === "work_from_home"
                          ? "WFH"
                          : "Loading"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          "Loading"
        )}
      </div>
    </>
  );
};

export default ViewAttendanceInDetail;
