import React from "react";
import { useState } from "react";
import { API_URL } from "../../Constents";
import { useAlert } from "react-alert";
import { useEffect } from "react";

export default function EditDepartmentHead(props) {
  const [details, setDetails] = useState({
    empEmail: null,
  });
  const [employeeList, setEmployeeList] = useState([]);
  const alert = useAlert();
  console.log(props.deptName);
  const fetchAllEmployees = async () => {
    const url = API_URL + "/employee-list-dashboard/";

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
      "content-type": "application/json",
    };

    const bodyContent = JSON.stringify({
      department_array: [],
    });

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: headerList,
        body: bodyContent,
      });
      const data = await res.json();
      setEmployeeList(data.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchAllEmployees();
  }, []);

  console.log(employeeList);
  const editDeptHandler = async (e) => {
    e.preventDefault();
    try {
      const url = API_URL + "/departments/?department_id=" + props.deptName;
      const userData = JSON.parse(window.sessionStorage.getItem("user"));

      const headerList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };
      const bodyContent = JSON.stringify({
        department_lead: details.empEmail,
      });

      console.log(bodyContent);

      const res = await fetch(url, {
        method: "PATCH",
        headers: headerList,
        body: bodyContent,
      });
      const data = await res.json();
      if (data.status) {
        alert.success(data.message);
        props.onClose();
      } else {
        alert.error(data.message);
      }
      console.log(data);
    } catch (error) {
      console.error(error);
      alert.error("Internal Server Error");
    }
  };
  const empList = employeeList.map((employeeData) => (
    <option key={employeeData.id} value={employeeData.official_email}>
      {employeeData.first_name}
    </option>
  ));

  return (
    <>
      <div className="row">
        <form action="" onSubmit={editDeptHandler}>
          <div >
            <label htmlFor="fname" style={{ color: "red", fontWeight: "700" }} className='mb-3'>
              Edit Department Head
            </label>
            <input
              list="ice-cream-flavors"
              placeholder="Search Here"
              onChange={(e) => {
                setDetails({ ...details, empEmail: e.target.value });
              }}
              className="form-control"
              required
            />
            <datalist id="ice-cream-flavors">{empList}</datalist>
          </div>
          <div className="col-md-3 mt-3">
            <button
              type="submit"
              className="departmentBtn"
              style={{ padding: "10px" }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}