import { useState, useEffect } from "react";
import { useAlert } from "react-alert";

import { API_URL } from "../../../Constents";

const OrganizationTimeSetting = () => {
  const [checkinAndCheckoutTime, setCheckinAndCheckoutTime] = useState({
    check_in_time: "",
    check_out_time: "",
    is_round_off: false,
    round_off_time: 0,
    late_exempt: false,
    allowed_late_num_of_day: 0,
  });
  const alert = useAlert();

  console.log(checkinAndCheckoutTime);
  const changeOrganisationTimingHandler = async (e) => {
    e.preventDefault();

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    var orgId = userData.data.org;
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const url = API_URL + "/org-setting/" + orgId + "/";
    let bodyContent = JSON.stringify({
      check_in_time: checkinAndCheckoutTime.check_in_time,
      check_out_time: checkinAndCheckoutTime.check_out_time,
      late_exempt: checkinAndCheckoutTime.late_exempt,
      is_round_off: checkinAndCheckoutTime.is_round_off,
      // round_off_time: checkinAndCheckoutTime.round_off_time,
      // allowed_late_num_of_day: checkinAndCheckoutTime.allowed_late_num_of_day,
      round_off_time:
        checkinAndCheckoutTime.round_off_time === ""
          ? 0
          : checkinAndCheckoutTime.round_off_time,
      allowed_late_num_of_day:
        checkinAndCheckoutTime.allowed_late_num_of_day === ""
          ? 0
          : checkinAndCheckoutTime.allowed_late_num_of_day,
    });

    try {
      const response = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });
      const data = await response.json();
      console.log(data);
      if (data.status) {
        alert.success(data.message);
      }
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.err(err);
      alert.error("Internal Server Error");
    }
  };
  const organisationAttandanceSettingHandler = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    // console.log(userData);
    var orgId = userData.data.org;
    // console.log(orgId);
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const url = API_URL + "/org-setting/" + orgId + "/";
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      setCheckinAndCheckoutTime({
        check_in_time: data.data.check_in_time,
        check_out_time: data.data.check_out_time,
        is_round_off: data.data.is_round_off,
        late_exempt: data.data.late_exempt,
        round_off_time: data.data.round_off_time,
        allowed_late_num_of_day: data.data.allowed_late_num_of_day,
      });
      console.log(data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  useEffect(() => {
    organisationAttandanceSettingHandler();
  }, []);
  // console.log(checkinAndCheckoutTime);
  return (
    <div className="setting_header ps-3 py-4">
      <h3 style={{ color: "#dd3974" }}>
        Organisation's Checkin/Checkout Timing
      </h3>
      <form onSubmit={changeOrganisationTimingHandler}>
        <div className="row align-items-center w-100">
          <div className="col-md-4 col-lg- mt-4">
            <label htmlFor="carryduration">Check-in Time</label>
            <input
              type="time"
              id="carryduration"
              placeholder="Enter Check-in Time"
              name="carryduration"
              className="form-control"
              style={{ width: "240px", border: "none" }}
              required
              value={checkinAndCheckoutTime.check_in_time}
              onChange={(e) =>
                setCheckinAndCheckoutTime((prevState) => ({
                  ...prevState,
                  check_in_time: e.target.value,
                }))
              }
            />
          </div>

          <div className="col-md-4 col-lg- mt-4">
            <label htmlFor="carryduration">Check-out Time</label>
            <input
              type="time"
              id="carryduration"
              style={{ width: "240px", border: "none" }}
              placeholder="Enter Check-in Time"
              name="carryduration"
              className="form-control"
              value={checkinAndCheckoutTime.check_out_time}
              onChange={(e) =>
                setCheckinAndCheckoutTime((prevState) => ({
                  ...prevState,
                  check_out_time: e.target.value,
                }))
              }
              required
            />
          </div>

          <div className="col-md-2 col-lg- mt-4">
            <input
              type="checkbox"
              className="me-2"
              checked={checkinAndCheckoutTime.is_round_off}
              onChange={(e) => {
                setCheckinAndCheckoutTime((prevState) => ({
                  ...prevState,
                  is_round_off: e.target.checked,
                }));
                if (e.target.checked === false)
                  setCheckinAndCheckoutTime((prevState) => ({
                    ...prevState,
                    round_off_time: "",
                  }));
              }}
              name="round_off"
            />
            <label for="round_off"> Round-off</label>
          </div>

          <div className="col-md-2 col-lg- mt-4">
            <input
              type="checkbox"
              className="me-2"
              checked={checkinAndCheckoutTime.late_exempt}
              onChange={(e) => {
                setCheckinAndCheckoutTime((prevState) => ({
                  ...prevState,
                  late_exempt: e.target.checked,
                }));
                if (e.target.checked) {
                  setCheckinAndCheckoutTime((prevState) => ({
                    ...prevState,
                    allowed_late_num_of_day: 0,
                  }));
                }
              }}
              name="late-exempt"
            />
            <label for="late-exempt">Late Exempt</label>
          </div>

          <div className="col-md-4 col-lg- mt-4">
            <label htmlFor="carryleave">Round-off Time (in Minutes)</label>
            <input
              type="number"
              min="1"
              max="60"
              step="1"
              id="carryleave"
              placeholder="In minutes"
              className="form-control mb-3"
              required
              value={checkinAndCheckoutTime.round_off_time}
              onChange={(e) =>
                setCheckinAndCheckoutTime((prevState) => ({
                  ...prevState,
                  round_off_time: e.target.value,
                }))
              }
              disabled={!checkinAndCheckoutTime.is_round_off}
            />
          </div>

          {checkinAndCheckoutTime.late_exempt ? (
            <div className="col-md-4 mt-4">
              <p style={{ color: "red" }}>
                NOTE: No leave will be deducted on coming late
              </p>
            </div>
          ) : (
            <div className="col-md-4 col-lg- mt-4">
              <label htmlFor="carryleave">Allowed Late Entry(in days)</label>
              <input
                type="number"
                min="0"
                max="31"
                step="1"
                id="carryleave"
                placeholder="In days"
                className="form-control mb-3"
                required
                value={checkinAndCheckoutTime.allowed_late_num_of_day}
                onChange={(e) =>
                  setCheckinAndCheckoutTime((prevState) => ({
                    ...prevState,
                    allowed_late_num_of_day: e.target.value,
                  }))
                }
                //  disabled={!checkinAndCheckoutTime.late_exempt}
              />
            </div>
          )}

          <hr className="my-4" />

          <div className="new_leave_btn text-end w-100 my-4">
            <button className="save_btn ms-4" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OrganizationTimeSetting;
