import { useEffect, useState } from "react";
import { API_URL, TODAY_DATE } from "../../Constents";
import userdummyDP from "../../images/DummyUserDP.png";
import moment from "moment";
import { BsBoxArrowUpRight } from "react-icons/bs";
import LeavesModalMoreDetails from "./LeavesModalMoreDetails";
import DoughNutChart from "../CompParts/DoughNutChart";
import ViewAttendanceInDetail from "../CompParts/ViewAttendanceInDetail";
import { CURRENT_MONTH } from "../../Constents";
import DoughNutChartjs from "../CompParts/DoughNutChartjs";

const ModalEmployeeMoreDetails = () => {
  const [employeeData, setEmployeeData] = useState({});
  const [AttendanceSelector, setAttendanceSelector] = useState("");
  const [StartDateForAPI, setStartDateForAPI] = useState("");
  const [EndDateForAPI, setEndDateForAPI] = useState("");
  const [attendanceData, setAttendanceData] = useState({});
  const [pageForTable, setPageForTable] = useState(1);
  const [classForPagination, setClassForPagination] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(CURRENT_MONTH);
  const [selectedYear, setSelectedYear] = useState(0);
  const [waitmsg, setWaitMsg] = useState("");
  const [err, setErr] = useState();

  const id = window.location.href.split("/").reverse()[0];

  const fetchUserDetails = async () => {
    const url = API_URL + "/fetch-update-delete/?user_id=" + id;
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setEmployeeData(data.data);
    } catch {
      setErr(err);
    }
  };

  const fetchUserAttandance = async () => {
    const today = new Date();
    const todayDate = moment(today).format("YYYY-MM-DD");
    const dateLast =
      StartDateForAPI && !EndDateForAPI ? todayDate : EndDateForAPI;
    const dateStart =
      !StartDateForAPI && EndDateForAPI ? todayDate : StartDateForAPI;

    const url =
      API_URL +
      "/fetch-individual-attendance/?user_id=" +
      id +
      "&attendance_status=" +
      AttendanceSelector +
      "&start_date=" +
      dateStart +
      "&last_date=" +
      dateLast +
      "&page=" +
      pageForTable;
    // console.log(url);
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setAttendanceData(data);
    } catch {
      setErr(err);
    }
  };

  useEffect(() => {
    fetchUserDetails();
    fetchUserAttandance();
  }, [AttendanceSelector, StartDateForAPI, EndDateForAPI, pageForTable]);

  console.log(employeeData);

  const selectAttendanceStatusHandler = (e) => {
    setAttendanceSelector(e.target.value);
    console.log(e.target.value);
  };

  const selectStartDateHandler = (e) => {
    setStartDateForAPI(e.target.value);
  };

  const selectEndDateHandler = (e) => {
    setEndDateForAPI(e.target.value);
  };

  const addPage = () => {
    setPageForTable(pageForTable + 1);
  };

  const subPage = () => {
    setPageForTable(pageForTable - 1);
    if (pageForTable === 1) {
      setClassForPagination("disabled");
    }
  };

  const exportSingleAttendanceHandler = async (type) => {
    setWaitMsg("Please wait we are preparing your file");
    const todayDate = moment(TODAY_DATE).format("YYYY-MM-DD");
    const dateLast =
      StartDateForAPI && !EndDateForAPI ? todayDate : EndDateForAPI;
    const dateStart =
      !StartDateForAPI && EndDateForAPI ? todayDate : StartDateForAPI;
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      month: +selectedMonth,
      year: +selectedYear,
      type: type,
      start_date: dateStart,
      last_date: dateLast,
    });
    console.log(bodyContent);

    try {
      const url = API_URL + "/export-attendance/?user_id=" + id;
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      const data = await res.json();
      window.open(data.data, "_blank");
      window.location.reload();
    } catch (err) {
      console.error(err);
      setWaitMsg("There was an error in creating the file ");
    }
  };

  // console.log(attendanceData.data);
  // console.log(pageForTable);

  return (
    <div className="maincontent">
      <div className="highlight">
        <div className="emp_stats d-flex justify-content-between align-items-center">
          <div className="emp_det d-flex align-items-center">
            {employeeData.profile_image === null ? (
              <img src={userdummyDP} alt="DP" className="profile_img me-3" />
            ) : (
              <img
                src={employeeData.profile_image}
                alt="DP"
                className="profile_img me-3"
              />
            )}

            <div className="empl d-flex flex-column">
              <h6 className="emp_name">{employeeData.first_name}</h6>
              <p className="m-0">{employeeData.role_assigned_name}</p>
            </div>
          </div>

          <div className="col1 d-flex">
            <p className="info_subtxt m-0">
              Work Phone
              <br />
              <span className="contact_highlight blazing_red">
                <a
                  className="cta_link"
                  href={"tel:" + employeeData.mobile_number}
                >
                  {employeeData.mobile_number}
                </a>
              </span>
            </p>
          </div>

          <div className="col1 d-flex">
            <p className="info_subtxt m-0">
              Email
              <br />
              <span className="contact_highlight blazing_red">
                <a
                  className="cta_link"
                  href={"mailto:" + employeeData.official_email}
                >
                  {employeeData.official_email}
                </a>
              </span>
            </p>
          </div>

          <div className="col1 d-flex">
            <p className="info_subtxt m-0">
              Employee Code
              <br />
              <span className="contact_highlight">{employeeData.id}</span>
            </p>
          </div>

          <div className="col1 d-flex">
            <p className="info_subtxt m-0">
              Departement
              <br />
              <span className="contact_highlight">
                {employeeData.department_assigned_name}
              </span>
            </p>
          </div>
        </div>

        <div className="col-md-12 mt-4">
          <div className="bg-white">
            <ul
              className="nav nav-tabs light-bg nav-fill"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item cust_flex" role="presentation">
                {" "}
                <button
                  className="nav-link active"
                  id="faq_tab_1-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#faq_tab_1"
                  type="button"
                  role="tab"
                  aria-controls="faq_tab_1"
                  aria-selected="true"
                >
                  <div className="d-flex txt flex-column lh-lg">
                    {" "}
                    <i className="bx bxs-plane-alt"></i>
                    <span>Basic Details</span>{" "}
                  </div>
                </button>{" "}
              </li>
              <li className="nav-item cust_flex" role="presentation">
                {" "}
                <button
                  className="nav-link"
                  id="faq_tab_2-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#faq_tab_2"
                  type="button"
                  role="tab"
                  aria-controls="faq_tab_2"
                  aria-selected="false"
                >
                  <div className="d-flex txt flex-column lh-lg">
                    {" "}
                    <i className="bx bxs-shopping-bag"></i>
                    <span>Attendance</span>{" "}
                  </div>
                </button>{" "}
              </li>
              <li className="nav-item cust_flex" role="presentation">
                {" "}
                <button
                  className="nav-link"
                  id="faq_tab_3-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#faq_tab_3"
                  type="button"
                  role="tab"
                  aria-controls="faq_tab_3"
                  aria-selected="false"
                >
                  <div className="d-flex txt flex-column lh-lg">
                    {" "}
                    <i className="bx bx-check-circle"></i>
                    <span>Leaves</span>{" "}
                  </div>
                </button>
              </li>
              <li className="nav-item cust_flex" role="presentation">
                {" "}
                <button
                  className="nav-link"
                  id="faq_tab_4-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#faq_tab_4"
                  type="button"
                  role="tab"
                  aria-controls="faq_tab_4"
                  aria-selected="false"
                >
                  <div className="d-flex txt flex-column lh-lg">
                    {" "}
                    <i className="bx bxs-plane-alt"></i>
                    <span>Tasks</span>{" "}
                  </div>
                </button>{" "}
              </li>
            </ul>
            <div className="tab-content my-3" id="myTabContent">
              <div
                className="tab-pane fade active show"
                id="faq_tab_1"
                role="tabpanel"
                aria-labelledby="faq_tab_1-tab"
              >
                <div className="container p-0">
                  <div className="row">
                    <div className="col-md-7 mt-5 mb-3">
                      <div className="emp_personal d-flex">
                        <div className="edu me-5">
                          <p className="info_head">Education</p>
                          <p className="education m-0">
                            12th,{" "}
                            {employeeData && employeeData.educational
                              ? employeeData.educational.twelfth_board
                              : ""}
                          </p>
                          <p className="edu_loc">
                            <br />
                            {employeeData && employeeData.educational
                              ? employeeData.educational.twelfth_address
                              : ""}
                            <br />
                            121003 (ND)
                          </p>

                          <p className="education m-0">Bachelors'</p>
                          <p className="edu_loc">
                            {employeeData && employeeData.educational
                              ? employeeData.educational.graduation_college
                              : ""}
                            <br />
                            {employeeData && employeeData.educational
                              ? employeeData.educational.graduation_address
                              : ""}
                            {/* <br />
                            765563 (ND) */}
                          </p>
                        </div>

                        <div className="dob ms-5">
                          <p className="info_head">More Details</p>
                          <p className="education m-0">Date of Birth</p>
                          <p className="edu_loc">
                            {employeeData && employeeData.date_of_birth
                              ? employeeData.date_of_birth
                              : ""}
                          </p>

                          <p className="education m-0">Gender</p>
                          <p className="edu_loc">
                            {employeeData && employeeData.more_details
                              ? employeeData.more_details.gender
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-5 mt-5 mb-3">
                      <div className="emp_personal d-flex">
                        <div className="edu me-5">
                          <p className="info_head mb-1">Contact Details</p>
                          <p className="edu_loc">
                            Work: {employeeData.mobile_number}
                            <br />
                            Alternate:{" "}
                            {employeeData ? employeeData.mobile_number : ""}
                          </p>

                          <p className="info_head mb-1">Current Address</p>
                          <p className="edu_loc">
                            {employeeData && employeeData.current_address
                              ? employeeData.current_address.address_line_1
                              : ""}
                            ,
                            <br />
                            {employeeData && employeeData.current_address
                              ? employeeData.current_address.address_line_2
                              : ""}
                            ,{" "}
                            {employeeData && employeeData.current_address
                              ? employeeData.current_address.city
                              : ""}
                            ,
                            <br />
                            {employeeData && employeeData.current_address
                              ? employeeData.current_address.postal_code
                              : ""}
                          </p>

                          <p className="info_head mb-1">Permanent Address</p>
                          <p className="edu_loc">
                            {employeeData && employeeData.permanent_address
                              ? employeeData.permanent_address.address_line_1
                              : ""}
                            ,
                            <br />
                            {employeeData && employeeData.permanent_address
                              ? employeeData.permanent_address.address_line_2
                              : ""}
                            ,{" "}
                            {employeeData && employeeData.permanent_address
                              ? employeeData.permanent_address.city
                              : ""}
                            ,
                            <br />
                            {employeeData && employeeData.permanent_address
                              ? employeeData.permanent_address.postal_code
                              : ""}
                          </p>
                        </div>

                        <div className="dob">
                          <p className="info_head mb-1">More Details</p>
                          <p className="education m-0">Date of Birth</p>
                          <p className="edu_loc">
                            {" "}
                            {employeeData && employeeData.date_of_birth
                              ? employeeData.date_of_birth
                              : ""}
                          </p>

                          <p className="education m-0 mb-1">Gender</p>
                          <p className="edu_loc">
                            {" "}
                            {employeeData && employeeData.more_details
                              ? employeeData.more_details.gender
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade as"
                id="faq_tab_2"
                role="tabpanel"
                aria-labelledby="faq_tab_2-tab"
              >
                <div className="container p-0">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="filtrs d-flex justify-content-between">
                        <div className="input-group c_width mb-3">
                          <select
                            className="form-select form-control"
                            id="inputGroupSelect02"
                            onChange={selectAttendanceStatusHandler}
                          >
                            <option selected hidden disabled>
                              ---Select Atendance Type---
                            </option>
                            <option value="present_count">All Data</option>
                            <option value="late_count">Late</option>
                            <option value="absent_count">Absent</option>
                            <option value="leave_count">Leave</option>
                            <option value="work_from_home">
                              Work From Home
                            </option>
                          </select>
                        </div>

                        <div className="fltr_group d-flex justify-content-between">
                          <div className="date_picker position-relative me-3">
                            <input
                              className="dt_picker"
                              type="date"
                              id="startdate"
                              name="startdate"
                              onChange={selectStartDateHandler}
                            />

                            <p className="date_hd position-absolute">
                              Start Date
                            </p>
                          </div>

                          <div className="date_picker position-relative me-3">
                            <input
                              className="dt_picker"
                              type="date"
                              id="enddate"
                              name="enddate"
                              onChange={selectEndDateHandler}
                            />

                            <p className="date_hd position-absolute">
                              End Date
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="tbl_options mt-3 d-flex align-items-center justify-content-between">
                        <div class="more_dets">
                          <a
                            href="#"
                            className="det_link"
                            data-bs-toggle="modal"
                            data-bs-target="#emp_att"
                          >
                            <BsBoxArrowUpRight /> See Full Details
                          </a>
                        </div>

                        {waitmsg === "" ? (
                          ""
                        ) : (
                          <p style={{ color: "red" }}>{waitmsg}</p>
                        )}

                        <div className="d-flex justify-content-between">
                          <div className="input-group me-3">
                            <select
                              className="form-select form-control"
                              id="inputGroupSelect02"
                              onChange={(e) => setSelectedMonth(e.target.value)}
                            >
                              <option selected>MM</option>
                              <option value={1}>Jan</option>
                              <option value={2}>Feb</option>
                              <option value={3}>Mar</option>
                              <option value={4}>Apr</option>
                              <option value={5}>May</option>
                              <option value={6}>Jun</option>
                              <option value={7}>Jul</option>
                              <option value={8}>Aug</option>
                              <option value={9}>Sept</option>
                              <option value={10}>Oct</option>
                              <option value={11}>Nov</option>
                              <option value={12}>Dec</option>
                            </select>
                          </div>
                          <div className="input-group me-3">
                            <select
                              className="form-select form-control"
                              id="inputGroupSelect02"
                              onChange={(e) => setSelectedYear(e.target.value)}
                            >
                              <option selected disabled>
                                YYYY
                              </option>

                              <option value="2023">2023</option>
                            </select>
                          </div>
                          <div className="input-group me-3">
                            <select
                              class="form-select form-control"
                              id="inputGroupSelect02"
                              onChange={(e) =>
                                exportSingleAttendanceHandler(e.target.value)
                              }
                            >
                              <option selected>Export As</option>
                              <option value="pdf">PDF</option>
                              <option value="excel" disabled>
                                Excel
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-8 mb-3">
                      <div className="data_table">
                        <div className="table_fil d-flex justify-content-between">
                          <table>
                            <tbody>
                              <tr>
                                <th>S.No.</th>
                                <th>Date</th>
                                <th>In</th>
                                <th>Out</th>
                                <th>Status</th>
                              </tr>
                              {attendanceData.data &&
                              attendanceData.data.length >= 0 &&
                              attendanceData !== undefined
                                ? attendanceData.data.map(
                                    (userAttendance, index = 0) => {
                                      index++;
                                      return (
                                        <tr>
                                          <td>{index}</td>
                                          <td>
                                            {attendanceData &&
                                            attendanceData.data.length >= 0
                                              ? userAttendance.for_date
                                              : "loading"}
                                          </td>
                                          <td>
                                            {attendanceData &&
                                            attendanceData.data.length >= 0 &&
                                            userAttendance.check_in !== null
                                              ? moment(
                                                  userAttendance.check_in
                                                ).format("h:mm:ss a")
                                              : userAttendance.check_in === null
                                              ? "--:--"
                                              : "Loading"}
                                          </td>
                                          <td>
                                            {attendanceData &&
                                            attendanceData.data.length >= 0 &&
                                            userAttendance.check_out !== null
                                              ? moment(
                                                  userAttendance.check_out
                                                ).format("h:mm:ss a")
                                              : userAttendance.check_out ===
                                                null
                                              ? "--:--"
                                              : "Loading"}
                                          </td>
                                          <td
                                            className={
                                              "status " +
                                              userAttendance.attendance_status
                                            }
                                          >
                                            {attendanceData &&
                                            attendanceData.data.length >= 0 &&
                                            userAttendance.attendance_status ===
                                              "present_count"
                                              ? "On Time"
                                              : userAttendance.attendance_status ===
                                                "absent_count"
                                              ? "Absent"
                                              : userAttendance.attendance_status ===
                                                "late_count"
                                              ? "Late"
                                              : userAttendance.attendance_status ===
                                                "leave_count"
                                              ? "On Leave"
                                              : userAttendance.attendance_status ===
                                                "work_from_home"
                                              ? "WFH"
                                              : "Loading"}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                : "loading"}
                            </tbody>
                          </table>
                        </div>

                        <div className="table_pagination">
                          <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                              <li className={"page-item " + classForPagination}>
                                <button
                                  className="page-link"
                                  onClick={() => subPage()}
                                >
                                  Previous
                                </button>
                              </li>
                              {/* <li class="page-item">
                                <a className="page-link" href="#">
                                  1
                                </a>
                              </li> */}
                              <li className="page-item">
                                <button
                                  className="page-link"
                                  onClick={() => addPage()}
                                >
                                  Next
                                </button>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <div className="atten_percentage">
                        {/* <DoughNutChart
                          userId={id}
                          start={StartDateForAPI}
                          end={EndDateForAPI}
                        /> */}
                        <DoughNutChartjs
                          userId={id}
                          start={StartDateForAPI}
                          end={EndDateForAPI}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <LeavesModalMoreDetails userId={id} />

              <div
                className="tab-pane fade"
                id="faq_tab_4"
                role="tabpanel"
                aria-labelledby="faq_tab_4-tab"
              >
                <div className="container p-3">
                  <div className="row">
                    <table className="mt-3">
                      <tr>
                        <th>S. No.</th>
                        <th>Task</th>
                        <th>Sub Tasks</th>
                        <th>Project</th>
                        <th>Assigned Date</th>
                        <th>Assigned By</th>
                        <th>Status</th>
                        <th>Comments</th>
                      </tr>
                      <tr>
                        <td>1.</td>
                        <td>Update the images on the website.</td>
                        <td>
                          <i className="fas fa-tasks"></i> 2/5
                        </td>
                        <td>YAAMS</td>
                        <td>08-03-2022</td>
                        <td className="d-flex align-items-center">
                          <img
                            className="emp_img me-2"
                            src="./images/user.png"
                            alt="icon"
                          />
                          <p className="m-0">Shantanu A.</p>
                        </td>
                        <td>
                          <p className="t_status_pending">
                            <span className="p_dot"></span>Pending
                          </p>
                        </td>
                        <td>
                          <i className="far fa-comment"></i> 1
                        </td>
                      </tr>

                      <tr>
                        <td>2.</td>
                        <td>Frenn UI Design Changes</td>
                        <td>
                          <i className="fas fa-tasks"></i> 4/8
                        </td>
                        <td>Frenn</td>
                        <td>08-03-2022</td>
                        <td className="d-flex align-items-center">
                          <img
                            className="emp_img me-2"
                            src="./images/user.png"
                            alt="icon"
                          />
                          <p className="m-0">Shantanu A.</p>
                        </td>
                        <td>
                          <p className="t_status_pending">
                            <span className="p_dot"></span>Pending
                          </p>
                        </td>
                        <td>
                          <i className="far fa-comment"></i> 1
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="emp_att"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog custalt_modal_width modal-dialog-centered cust-width">
          <div className="modal-content">
            <div className="modal-body cust_padding">
              <form
                method="post"
                autoComplete="off"
                id="create_agent_frm"
                noValidate="novalidate"
              >
                <ViewAttendanceInDetail data={employeeData} userId={id} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEmployeeMoreDetails;
