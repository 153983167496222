import React, { useState, useEffect } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import moment from "moment";
import { useAlert } from "react-alert";

import { API_URL } from "../../Constents";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughNutChartjs = (props) => {
  const [doughNutData, setDoughNutData] = useState({});
  const [loading, setLoading] = useState(true);

  const { start, end, userId } = props;

  const alert = useAlert();

  const total =
    doughNutData.leave_count +
    doughNutData.present_count +
    doughNutData.late_count;

  const totalForAttendance =
    doughNutData.present_count + doughNutData.late_count;

  const data = {
    labels: [
      "Late " + "(" + doughNutData.late_count + ")",
      "On Leave " + "(" + doughNutData.leave_count + ")",
      "On Time " + "(" + doughNutData.present_count + ")",
    ],
    datasets: [
      {
        label: "# Count",
        data: [
          doughNutData.late_count,
          doughNutData.leave_count,
          doughNutData.present_count,
        ],
        backgroundColor: ["#f6ae3f", "#dd3944", "#0086b3"],
        borderColor: ["#ffffff", "#ffffff", "#ffffff"],
        borderWidth: 1,
      },
    ],
  };

  const fetchDataForDoughnut = async () => {
    setLoading(true);
    const today = new Date();
    const todayDate = moment(today).format("YYYY-MM-DD");
    const date = start && !end ? todayDate : end;

    const url =
      API_URL +
      "/fetch-single-total-attendance/?employee_id=" +
      userId +
      "&start_date=" +
      start +
      "&end_date=" +
      date;

    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    try {
      const res = await fetch(url, {
        method: "GET",
        headers: { Authorization: "Token " + userData.seucrity_token },
      });
      const data = await res.json();
      setDoughNutData(data.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      alert.error("Error in fetching chart data");
      setLoading(false);
    }
  };

  console.log(doughNutData);

  useEffect(() => {
    fetchDataForDoughnut();
  }, [start, end, userId]);

  if (loading) return <h3>Loading...</h3>;

  return (
    <>
      <Doughnut data={data} />
      <div className="legends px-5 d-flex flex-column mt-4">
        <p>
          <span className="p_dot ontime"></span> On Time -{" "}
          <span>
            {Math.round(
              (doughNutData.present_count / totalForAttendance) * 100
            ) + " %"}
          </span>
        </p>
        <p>
          <span className="p_dot lt"></span> Late -{" "}
          <span>
            {Math.round((doughNutData.late_count / totalForAttendance) * 100) +
              " %"}
          </span>
        </p>
        <p>
          <span className="p_dot"></span> Leave -{" "}
          <span>
            {Math.round((doughNutData.leave_count / total) * 100) + " %"}
          </span>
        </p>
      </div>
    </>
  );
};

export default DoughNutChartjs;
