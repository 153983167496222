import { API_URL, BASE_URL } from "../../Constents";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { MdAddCircle } from "react-icons/md";
import { useEffect, useState } from "react";

const EventsList = () => {
  const [list, setList] = useState([]);
  const [reload, setReload] = useState(false);

  const deleteEventHandler = async (id) => {
    console.log(id);

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/admin-fetch-events/?event_id=" + id;

    try {
      const res = await fetch(url, {
        method: "DELETE",
        headers: headersList,
      });
      const data = await res.json();
      console.log(data);
      setReload(true);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
      };
      const url = API_URL + "/admin-fetch-events/";

      try {
        const res = await fetch(url, {
          method: "GET",
          headers: headersList,
        });
        const data = await res.json();

        console.log(data);
        setList(data.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [reload]);

  console.log(list);

  return (
    <>
      <div className="maincontent">
        <div className="highlight">
          <div
            className="d-flex align-items-center mt-3 "
            style={{ justifyContent: "space-between" }}
          >
            <h1 style={{ color: "#DD3974" }}>All Events</h1>
            {/* <div className="col-md-3">
              <label htmlFor="" style={{ fontWeight: "600" }}>
                {" "}
                Select Employee Type
              </label>
              <select className="form-control form-select mt-2">
                <option>Upcoming Events</option>
                <option></option>
                <option></option>
              </select>
            </div> */}
            <div>
              <button className="btn btn-danger me-3">
                <Link
                  to={BASE_URL + "createEvents"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Create A New Event <MdAddCircle size={20} />
                </Link>
              </button>
            </div>
          </div>
          {/* <table class="table table-striped mt-3">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Event Name</th>
                <th>Date</th>
                <th>Time</th>
                <th>For</th>
                <th>Event Description</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>

             {
              list && list.length > 0 ?  : "No data"
             }
              
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
            </tbody>
          </table> */}
          <div className="table-responsive">
            <table className="mt-3 table table-striped">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Event Name</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>For</th>
                  <th>Event Description</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {list && list.length > 0 ? (
                  list.map((tabList, index = 0) => {
                    index++;
                    return (
                      <tr key={tabList.id}>
                        <td>{index}</td>
                        <td>{tabList.event_name}</td>
                        <td>
                          {tabList.event_on} to {tabList.end_on}{" "}
                        </td>
                        <td>
                          {tabList.event_time === null ||
                          tabList.event_time === ""
                            ? "--:--"
                            : tabList.event_time}{" "}
                          to{" "}
                          {tabList.event_time_to === null ||
                          tabList.event_time === ""
                            ? "--:--"
                            : tabList.event_time_to}{" "}
                        </td>
                        <td>
                          {tabList.department === null ||
                          tabList.department === ""
                            ? "All Employee"
                            : tabList.department.department_name}
                        </td>
                        <td>
                          {tabList.description === null
                            ? "No Description"
                            : tabList.description}
                        </td>
                        <td>
                          <button className="btn btn-link">
                            <AiFillDelete
                              color="red"
                              onClick={() => deleteEventHandler(tabList.id)}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <b>***No Current Events***</b>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsList;
// import { BASE_URL } from "../../Constents";
// import { Link } from "react-router-dom";

// const EventsList = () => {
//   return (
//     <div className="maincontent">

//       <Link to={BASE_URL + "createEvents"} className="btn btn-primary btn-lg">Create Events</Link>
//     </div>
//   );
// };

// export default EventsList;
