import React, { useState } from "react";
import { API_URL, TODAY_DATE } from "../../../../Constents";

import { useAlert } from "react-alert";

export default function DuplicateExistingVacancy({
  clickedRowData,
  closeDuplicateVacancyModal,
}) {
  const [startDate, setStartDate] = useState(clickedRowData.vacancy_start_date);
  const [endDate, setEndDate] = useState(clickedRowData.vacancy_end_date);

  const alert = useAlert();

  const duplicateVacancyHandler = async (e) => {
    e.preventDefault();
    const URL =
      API_URL +
      "/admin-duplicate-vacancy/?vacancy_id=" +
      clickedRowData.vacancy_id;
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      vacancy_start_date: startDate,
      vacancy_end_date: endDate,
    });

    try {
      const response = await fetch(URL, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      if (data.status) {
        closeDuplicateVacancyModal();
        alert.success(data.message);
      }
      console.log(data);
      if (data.status === false) alert.error(data.message);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  return (
    <>
      <div className="setting_header ps-3">
        <form onSubmit={duplicateVacancyHandler}>
          <div className="row">
            <h3>Duplicate Vacancy</h3>
            <hr />
            <div className="col-md-6">
              <label>Start Date</label>
              <input
                type="date"
                className="form-control mb-3"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                min={TODAY_DATE}
                required
              />
            </div>
            <div className="col-md-6">
              <label>End Date</label>
              <input
                type="date"
                className="form-control mb-3"
                value={endDate}
                min={TODAY_DATE}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </div>
          </div>

          <button type="submit" className="btn btn-warning">
            Duplicate
          </button>
        </form>
      </div>
    </>
  );
}
