import { useState, useEffect } from "react";

import { useAlert } from "react-alert";

import { API_URL } from "../../../Constents";

const RHHolidayList = () => {
  const [RHolidayList, setRHolidayList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const alert = useAlert();

  const fetehRHolidayList = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/get-restricted-holiday/";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);
      setRHolidayList(data.data);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert.error("There is an error in fetching the RH list");
      setIsLoading(true);
    }
  };

  useEffect(() => {
    fetehRHolidayList();
  }, []);

  return (
    <>
      {isLoading === true ? (
        <p>Loading...</p>
      ) : RHolidayList.length === 0 ? (
        <h3>No Holiday Added</h3>
      ) : (
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Date</th>
              <th scope="col">Holiday</th>
            </tr>
          </thead>
          <tbody>
            {RHolidayList.map((list, index) => (
              <tr>
                <th scope="row">{index + 1}</th>
                <td>{list.date}</td>
                <td>{list.holiday_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default RHHolidayList;
