import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiFillDelete, AiOutlineDownload } from "react-icons/ai";
import { MdOutlinePersonOff } from "react-icons/md";
import {
  BsBoxArrowUpRight,
  BsSearch,
  BsChevronCompactDown,
} from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import { useAlert } from "react-alert";

// table imports
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { API_URL, BASE_URL } from "../../Constents";
import DeleteUser from "../User/DeleteUser";
import LoadingGifs from "../../images/YAAMS-gif.gif";
import UploadEmployeeExcel from "../Global Setting/UploadEmployeeExcel";
import FormattedToNameFormat from "../Hooks/FormattedToNameFormat";
import ModalEmpoyeeDetails from "../Modals/ModalEmployeeDetails";

const EmployeeList = (props) => {
  const [employee, setEmployee] = useState();
  const [error, setError] = useState();
  const [departmentList, setDepartmentList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  // const [filePath, setFilePath] = useState({ file: "", path: "" }); // state for setting file path in upload excel
  const [name, setName] = useState("");
  //Modal States
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Modal for delete
  const [showDelete, setShowDelete] = useState(false);
  const [CheckState, setCheckState] = useState({ selected: [] });
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const [modalId, setModalId] = useState();
  const alert = useAlert();

  const departmentListSort = departmentList.sort((a, b) =>
    a.department_name > b.department_name
      ? 1
      : b.department_name > a.department_name
      ? -1
      : 0
  );

  //const departmentListSort = departmentList.sort((a,b) => a.department_name - b.department_name );  could be writen as
  const userData = JSON.parse(window.sessionStorage.getItem("user"));
  const orgName = userData.data.first_name;

  // fetch dept starts
  const fetchDepartments = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/department-list";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setDepartmentList(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchManagers = async () => {
    const url = API_URL + "/role";
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setManagerList(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDepartments();
    fetchManagers();
  }, []);

  const assignDepartmentHandler = async (depID, depName) => {
    if (CheckState.selected.length === 0) {
      alert.show("Please Select Atleast One Employee");
      return;
    }

    const bulkDeptAssign = CheckState.selected.map((uid) => {
      return {
        id: uid,
        department_assigned: depID,
        department_assigned_name: depName,
      };
    });

    const url = API_URL + "/assign-role-bulk/";

    const raw = JSON.stringify(bulkDeptAssign);

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const requestOptions = {
      method: "PUT",
      headers: headersList,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(url, requestOptions);
      const data = await res.json();
      alert.success("Department Assigned");
    } catch (err) {
      console.error(err);
      alert.error("Error in Assigining the Dept");
    }
  };

  const assignManagerHandler = async (manID) => {
    if (CheckState.selected.length === 0) {
      alert.show("Please Select Atleast One Employee");
      return;
    }

    const bulkMngAssign = CheckState.selected.map((uid) => {
      return { id: uid, reporting_to: manID };
    });

    const url = API_URL + "/assign-role-bulk/";

    const raw = JSON.stringify(bulkMngAssign);

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const requestOptions = {
      method: "PUT",
      headers: headersList,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(url, requestOptions);
      const data = await res.json();
      alert.success("Manager Assigned");
    } catch (err) {
      console.error(err);
      alert.error("There is an error in assigning Manager");
    }
  };

  const FetchEmployeeList = () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      department_array: [],
    });

    const requestOptions = {
      method: "POST",
      headers: headerList,
      redirect: "follow",
      body: bodyContent,
    };

    fetch(API_URL + "/employee-list-dashboard/", requestOptions)
      .then((response) => response.json())
      .then((result) => setEmployee(result))
      .catch((error) => setError(error));
  };

  useEffect(() => {
    FetchEmployeeList();
  }, [showDelete]);

  if (error) return <h1>Error occoured in Fetching Employee List </h1>;

  const { SearchBar } = Search;

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <>
        <Link
          onClick={handleClick}
          style={{ backgroundColor: "white", borderColor: "lightgray" }}
          className="btn btn-light "
        >
          Export Employee List
          <AiOutlineDownload style={{ marginLeft: "5px", color: "red" }} />
        </Link>
      </>
    );
  };

  const columns = [
    {
      dataField: "full_name",
      text: "Name",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="row d-flex">
            <Button
              variant="link"
              style={{
                color: "#3E3E3E",
                textDecoration: "none",
                textAlign: "left",
              }}
              onClick={() => {
                handleShow();
                setModalId(row.id);
              }}
            >
              {FormattedToNameFormat(row.full_name)}
              <BsBoxArrowUpRight
                style={{
                  fontSize: "10px",
                  marginLeft: "3px",
                  marginBottom: "10px",
                  color: "black",
                  fontWeight: "600",
                }}
              />
            </Button>
            {/* <Link
            // to={"/UpdateUser/" + row.id}
            onClick={handleShow}
           
          >
           
            {row.first_name}
          </Link> */}
          </div>
        );
      },
    }, //filter: textFilter() },
    { dataField: "official_email", text: "Email", sort: true },
    { dataField: "mobile_number", text: "Number", sort: true },
    { dataField: "dob", text: "DOB", sort: true },
    { dataField: "doj", text: "DOJ", sort: true },
    {
      dataField: "id",
      text: "Actions",
      sort: true,
      csvExport: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="d-flex align-items-center justify-content-left">
            <div className=" bd-highlight">
              <Button
                variant="link"
                style={{ color: "red" }}
                onClick={() => {
                  handleShowDelete();
                  setModalId(row.id);
                  setName(row.first_name);
                }}
              >
                <AiFillDelete />
              </Button>
            </div>
            <div className=" bd-highlight">
              <Link
                to={BASE_URL + "UpdateUser/" + row.id}
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title={row.id}
                style={{ textDecoration: "none" }}
              >
                <FaEdit />
              </Link>
            </div>
          </div>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  console.log(employee);

  return (
    <div className="maincontent">
      <div className="highlight all_form_spacing">
        <h2 style={{ color: "#000000" }}>Employee List</h2>
        {!employee ? (
          <div className="loadingGif">
            <img src={LoadingGifs} alt="Loading Gif" />
          </div>
        ) : (
          <ToolkitProvider
            keyField="id"
            data={employee.data}
            columns={columns}
            bootstrap4
            search
            exportCSV={{
              fileName: orgName + " EmployeeList.csv",
            }}
          >
            {(props) => (
              <>
                <div className="row ">
                  <div className="col-md-6 mb-2">
                    <UploadEmployeeExcel />
                  </div>
                  {/* <div className="col-md-2"></div> */}
                  <div className="col-md-3 text-end  mb-2">
                    <MyExportCSV {...props.csvProps} />
                  </div>
                  <div className="col-md-3 text-end  position-relative">
                    <SearchBar
                      // className="newSearch"
                      className="form-control mb-2"
                      // style={{ border: "none" }}
                      placeholder="Search Here"
                      srText=""
                      // style={{ backgroundColor: "#daeddf" }}
                      {...props.searchProps}
                    />
                    <BsSearch
                      style={{
                        position: "absolute",
                        top: "12px",
                        right: "20px",
                      }}
                    />
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6">
                    <button
                      className="btn btn-light  me-2 mb-2"
                      style={{
                        backgroundColor: "white",
                        borderColor: "lightgray",
                      }}
                      // role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Assign Department
                      <BsChevronCompactDown style={{ marginLeft: "5px" }} />
                    </button>

                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      {departmentListSort.map((departmentData) => (
                        <li>
                          <button
                            className="dropdown-item  employeeDropdown"
                            onClick={() =>
                              assignDepartmentHandler(
                                departmentData.id,
                                departmentData.department_name
                              )
                            }
                          >
                            {departmentData.department_name}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <button
                      className="btn btn-light me-2 mb-2"
                      style={{
                        backgroundColor: "white",
                        borderColor: "lightgray",
                      }}
                      // role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Assign Manager
                      <BsChevronCompactDown style={{ marginLeft: "5px" }} />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      {managerList.map((mangerData) => (
                        <li>
                          <button
                            className="dropdown-item  employeeDropdown me-3"
                            key={mangerData.id}
                            onClick={() => assignManagerHandler(mangerData.id)}
                          >
                            {mangerData.full_name === null
                              ? mangerData.first_name
                              : FormattedToNameFormat(mangerData.full_name)}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* <div className="col-md-4"></div> */}
                  <div className="col-md-6 text-end mb-2">
                    <Link
                      to={BASE_URL + "ExEmployeeList"}
                      style={{
                        backgroundColor: "white",
                        borderColor: "lightgray",
                      }}
                      className="btn btn-light   "
                    >
                      Ex Employees
                      <MdOutlinePersonOff
                        style={{ marginLeft: "10px", color: "red" }}
                      />
                    </Link>{" "}
                  </div>
                </div>

                <BootstrapTable
                  className="mt-2"
                  pagination={pagination}
                  filter={filterFactory()}
                  {...props.baseProps}
                  bordered={false}
                  condensed
                  noDataIndication={() => {
                    return <h3>No Employees Added</h3>;
                  }}
                  selectRow={{
                    mode: "checkbox",
                    // style: { display:"flex",alignItems:"center" },
                    clickToSelect: true,
                    selected: CheckState.selected,
                    onSelectAll: (isSelect, rows) => {
                      console.log(rows);
                      const ids = rows.map((r) => r.id);
                      if (isSelect) {
                        setCheckState(() => ({
                          selected: ids,
                        }));
                      } else {
                        setCheckState(() => ({
                          selected: [],
                        }));
                      }
                    },
                    onSelect: (row, isSelect) => {
                      if (isSelect) {
                        setCheckState(() => ({
                          selected: [...CheckState.selected, row.id],
                        }));
                      } else {
                        setCheckState(() => ({
                          selected: CheckState.selected.filter(
                            (x) => x !== row.id
                          ),
                        }));
                      }
                    },
                  }}
                />
              </>
            )}
          </ToolkitProvider>
        )}

        <Modal size="xl" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <ModalEmpoyeeDetails id={modalId} />
          </Modal.Body>
        </Modal>

        <Modal size="xl" show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Mark As Ex Employee</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <DeleteUser
              id={modalId}
              name={name}
              hideModal={handleCloseDelete}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default EmployeeList;
