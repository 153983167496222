import moment from "moment";
// For migrating the app on sub domain
//export const BASE_URL = "/attendly/";
export const BASE_URL = "/";

// Today Date
export const TODAY_DATE = moment(new Date()).format("YYYY-MM-DD");
export const userData = JSON.parse(window.sessionStorage.getItem("user"));

//Retiring Age
const maxDate = new Date().setDate(new Date().getDate() - 25567.5);
const maxResult = new Date(maxDate);
export const RETIRING_AGE = moment(maxResult).format("YYYY-MM-DD");

//Minor Date
const minDate = new Date().setDate(new Date().getDate() - 6574.5);
const minResult = new Date(minDate);
export const FORMATTED_MINOR_DATE = moment(minResult).format("YYYY-MM-DD");

//Yesterday Date
const yesterday = new Date(Date.now() - 864e5);
export const YESTERDAY_DATE = moment(yesterday).format("YYYY-MM-DD");

//Day before yesterday
const dayBeforeYesterday = new Date(Date.now() - 2 * 864e5);
export const DAY_BEFORE_YESTERDAY_DATE =
  moment(dayBeforeYesterday).format("YYYY-MM-DD");

// Current month Number
export const CURRENT_MONTH = new Date().getMonth() + 1;

//Last 7 days
const weekRangeDate = new Date(Date.now() - 6 * 864e5);
export const ONE_WEEK_DATE = moment(weekRangeDate).format("YYYY-MM-DD");

// Last six months
const lastSixMonths = new Date(Date.now() - 180 * 864e5);
export const LAST_SIX_MONTHS = moment(lastSixMonths).format("YYYY-MM-DD");

// Last 30 days
const oneMonth = new Date(Date.now() - 30 * 864e5);
export const ONE_MONTH_DATE = moment(oneMonth).format("YYYY-MM-DD");

export const APP_VERSION_NUMBER = "YAAMS v1.2023.7";

//For changing the API domain in the whole app
//export const API_URL = "http://139.59.79.190:8002"; // TEST
//export const API_URL = "http://165.22.212.20:8001"; // LIVEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE
//export const API_URL = "https://apistaging.yaams.net";
//export const API_URL = "https://apidev.yaams.net";
//export const API_URL = "https://bend.setuplive.yaams.net";
//export const API_URL = "https://api.yaams.net";
//export const API_URL = "http://127.0.0.1:8000";
export const API_URL = process.env.REACT_APP_API_URL;
