import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";
import { useAlert } from "react-alert";

const AddEmployeeOtherDetails = (props) => {
  const [details, setDetails] = useState({
    twelfth_board: "",
    twelfth_address: "",
    graduation_college: "",
    graduation_address: "",
    more_details: "",
    gender: null,
    contact_details: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    postal_code: "",
    address_line_1P: "",
    address_line_2P: "",
    cityP: "",
    postal_codeP: "",

    isChecked: false,
  });

  const [touched, setTouched] = useState({
    twelfth_board: "",
  });

  const userID = window.location.href.split("/").reverse()[0];
  const alert = useAlert();

  const submitHandler = (e) => {
    e.preventDefault();
    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));

      const headersListEdu = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        twelfth_board: details.twelfth_board,
        twelfth_address: details.twelfth_address,
        graduation_college: details.graduation_college,
        graduation_address: details.graduation_address,
        more_details: details.more_details,
        gender: details.gender,
        contact_details: [
          {
            office_phone: details.contact_details,
          },
          {
            personal_phone: details.contact_details,
          },
        ],
      });

      const url = API_URL + "/update-educational-detail/?user_id=" + userID;

      try {
        const res = await fetch(url, {
          method: "POST",
          body: bodyContent,
          headers: headersListEdu,
        });
        // if (!res.ok) setErr(res.status);
        const data = await res.json();
        // setFormResponse(data);
        if (data.status === true) alert.success(data.message);
        if (data.status === false) alert.error(data.message);
      } catch (err) {
        // setErr(err);
        alert.error("Internal Server Error");
      }
    })();

    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersListAdTemp = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContentAdTemp = JSON.stringify({
        address_line_1: details.address_line_1,
        address_line_2: details.address_line_2,
        city: details.city,
        postal_code: details.postal_code,
      });

      const urlAdtemp =
        API_URL + "/update-temporary-address/?user_id=" + userID;

      try {
        const resAdTemp = await fetch(urlAdtemp, {
          method: "POST",
          body: bodyContentAdTemp,
          headers: headersListAdTemp,
        });
        // if (!resAdTemp.ok) setErr(resAdTemp.status);
        const dataAdtemp = await resAdTemp.json();
       
      } catch (err) {
        // setErr(err);
        alert.error("Internal Server Error");
      }
    })();

    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersListAdPer = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContentAdTemp = JSON.stringify({
        address_line_1: details.address_line_1P,
        address_line_2: details.address_line_2P,
        city: details.cityP,
        postal_code: details.postal_codeP,
      });

      const urlAdtemp =
        API_URL + "/update-permanent-address/?user_id=" + userID;

      try {
        const resAdPer = await fetch(urlAdtemp, {
          method: "POST",
          body: bodyContentAdTemp,
          headers: headersListAdPer,
        });
        // if (!resAdPer.ok) setErr(resAdPer.status);
        const dataAdPer = await resAdPer.json();
        
      } catch (err) {
        console.error(err);
        alert.error("Internal Server Error");
      }
    })();
  };

  useEffect(() => {
    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
      };

      const url = API_URL + "/update-educational-detail/?user_id=" + userID;

      try {
        const res = await fetch(url, { method: "GET", headers: headersList });
        const resultData = await res.json();
        console.log(resultData);
        const { data, Current_address, Permanent_address } = resultData;

        setDetails({
          twelfth_board: data.twelfth_board,
          twelfth_address: data.twelfth_address,
          graduation_college: data.graduation_college,
          graduation_address: data.graduation_address,
          more_details: data.more_details,
          gender: data.gender,
          contact_details: data.contact_details[0].office_phone,
          address_line_1: Current_address.address_line_1,
          address_line_2: Current_address.address_line_2,
          city: Current_address.city,
          postal_code: Current_address.postal_code,

          address_line_1P: Permanent_address.address_line_1,
          address_line_2P: Permanent_address.address_line_2,
          cityP: Permanent_address.city,
          postal_codeP: Permanent_address.postal_code,
        });
      } catch (err) {
        console.error(err);
        alert.error("There is an error in fetching the details");
      }
    })();
  }, [userID]);

  return (
    <div>
      <div className="container">
        <form
          onSubmit={submitHandler}
          className="highlight all_form_spacing"
          method="POST"
          name="contact-form"
        >
          <h3 style={{ color: "red" }}>
            Add Educational & Other Details of {props.name}
          </h3>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label htmlFor="fname">12th Board Name</label>

              <input
                type="text"
                id="Schname"
                placeholder={
                  touched.twelfth_board && details.twelfth_board === ""
                    ? "Please Enter here"
                    : ""
                }
                name="fname"
                onChange={(e) => {
                  setDetails({ ...details, twelfth_board: e.target.value });
                }}
                onBlur={() => {
                  setTouched({ ...touched, twelfth_board: true });
                }}
                className={
                  touched.twelfth_board && details.twelfth_board === ""
                    ? "form-control req"
                    : "form-control"
                }
                value={details.twelfth_board}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="fname">School Address</label>

              <input
                type="text"
                id="fname"
                name="fname"
                onChange={(e) => {
                  setDetails({ ...details, twelfth_address: e.target.value });
                }}
                className="form-control"
                value={details.twelfth_address}
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="fname">Graduation College</label>

              <input
                type="text"
                id="fname"
                name="fname"
                onChange={(e) => {
                  setDetails({
                    ...details,
                    graduation_college: e.target.value,
                  });
                }}
                className="form-control"
                value={details.graduation_college}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="fname">Graduation College Address</label>

              <input
                type="text"
                id="fname"
                name="fname"
                onChange={(e) => {
                  setDetails({
                    ...details,
                    graduation_address: e.target.value,
                  });
                }}
                className="form-control"
                value={details.graduation_address}
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="mobile">Alternate Number</label>
              <input
                type="text"
                id="mobile"
                placeholder="Enter Mobile Number"
                name="mobile"
                pattern="[7-9]{1}[0-9]{9}"
                title="Please enter 10 digit mobile number"
                onChange={(e) => {
                  setDetails({ ...details, contact_details: e.target.value });
                }}
                value={details.contact_details}
                className="form-control"
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="fname">Additional Details</label>

              <input
                type="text"
                id="fname"
                name="fname"
                onChange={(e) => {
                  setDetails({ ...details, more_details: e.target.value });
                }}
                className="form-control"
                value={details.more_details}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>Gender</label>
              <br />
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  checked={details.gender === "Male"}
                  onChange={(e) => setDetails({ ...details, gender: "Male" })}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Male
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked={details.gender === "Female"}
                  onChange={(e) => setDetails({ ...details, gender: "Female" })}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  Female
                </label>
              </div>
            </div>
            <hr />

            <label class="form-check-label">Current Address</label>

            <div className="col-md-4 mb-3">
              <label htmlFor="fname">Address Line 1</label>

              <input
                type="text"
                id="fname"
                name="fname"
                onChange={(e) => {
                  setDetails({ ...details, address_line_1: e.target.value });
                }}
                className="form-control"
                value={details.address_line_1}
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="fname">Address Line 2</label>

              <input
                type="text"
                id="fname"
                name="fname"
                onChange={(e) => {
                  setDetails({ ...details, address_line_2: e.target.value });
                }}
                className="form-control"
                value={details.address_line_2}
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="fname">City</label>

              <input
                type="text"
                id="fname"
                name="fname"
                onChange={(e) => {
                  setDetails({ ...details, city: e.target.value });
                }}
                className="form-control"
                value={details.city}
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="mobile">PIN</label>
              <input
                type="text"
                id="pin"
                placeholder="Enter City Pin"
                name="pin"
                pattern="[0-9]{6}"
                title="Enter 6 digit pin"
                onChange={(e) => {
                  setDetails({ ...details, postal_code: e.target.value });
                }}
                value={details.postal_code}
                className="form-control"
              />
            </div>

            <div className="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckChecked"
                onChange={(e) => {
                  setDetails({ ...details, isChecked: e.target.checked });
                  if (e.target.checked === true) {
                    setDetails({
                      ...details,
                      address_line_1P: details.address_line_1,
                      address_line_2P: details.address_line_2,
                      cityP: details.city,
                      postal_codeP: details.postal_code,
                    });
                  } else {
                    setDetails({
                      ...details,
                      address_line_1P: "",
                      address_line_2P: "",
                      cityP: "",
                      postal_codeP: "",
                    });
                  }
                }}
              />
              <label class="form-check-label" for="flexCheckChecked">
                Permanent address (same as current)
              </label>
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="fname">Address Line 1</label>

              <input
                type="text"
                id="fname"
                name="fname"
                onChange={(e) => {
                  setDetails({ ...details, address_line_1P: e.target.value });
                }}
                className="form-control"
                value={
                  details.isChecked
                    ? details.address_line_1
                    : details.address_line_1P
                }
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="fname">Address Line 2</label>

              <input
                type="text"
                id="fname"
                name="fname"
                onChange={(e) => {
                  setDetails({ ...details, address_line_2P: e.target.value });
                }}
                className="form-control"
                value={
                  details.isChecked
                    ? details.address_line_2
                    : details.address_line_2P
                }
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="fname">City</label>

              <input
                type="text"
                id="fname"
                name="fname"
                onChange={(e) => {
                  setDetails({ ...details, cityP: e.target.value });
                }}
                className="form-control"
                value={details.isChecked ? details.city : details.cityP}
              />
            </div>

            <div className="col-md-4 mb-3">
              <label htmlFor="mobile">PIN</label>
              <input
                type="text"
                id="pin"
                placeholder="Enter City Pin"
                name="pin"
                pattern="[0-9]{6}"
                title="Enter 6 digit pin"
                onChange={(e) => {
                  setDetails({ ...details, postal_codeP: e.target.value });
                }}
                value={
                  details.isChecked ? details.postal_code : details.postal_codeP
                }
                className="form-control"
              />
            </div>
          </div>

          <div style={{ textAlign: "center" }} className="mb-3">
            <button type="submit" className="modalBtn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEmployeeOtherDetails;
