import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../Constents";
import AllAttandanceQR from "./AllAttandanceQR";
import { Modal } from "react-bootstrap";
import AddANewOfficeAddress from "./AddANewOfficeAddress";
import { useAlert } from "react-alert";

const GenerateQR = () => {
  const [details, setDetails] = useState({ lable: "", city: "" });
  const [LocationData, setLocationData] = useState([]);
  const [qrData, setQRdata] = useState({});
  const [show, setShow] = useState(false);
  const assignCityHandler = (e) => {
    setDetails({ ...details, city: e.target.value });
  };

  const alert = useAlert();

  const fetchAllLocations = async () => {
    const url = API_URL + "/fetch-all-locations/";

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headerList,
      });
      const data = await res.json();
      console.log(data);
      setLocationData(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const generateQRCOde = async (event) => {
    event.preventDefault();

    try {
      const url = API_URL + "/get-new-qr/";
      const userData = JSON.parse(window.sessionStorage.getItem("user"));

      const headerList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        label: details.lable,
        // branch: details.city,
        location: details.city,
      });
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headerList,
      });
      const data = await res.json();
      setQRdata(data);
      if (data.status === true) {
        alert.success(data.message);
        window.open(data.data.code);
        donwnloadQR(data.data.code, data.data.label);
      }
      if (data.status === false) alert.error(data.message);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  console.log(details);

  useEffect(() => {
    fetchAllLocations();
  }, []);

  const donwnloadQR = (url, QRLabel) => {
    console.log(url);
    const downFileName = "QR_for_" + QRLabel + ".pdf";
    // const  LableStr= toString(QRLabel);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downFileName);

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  console.log(qrData);
  const dyList = LocationData.map((locData, index = 0) => {
    index++;
    return (
      <option key={index} value={locData.id}>
        {locData.sub_name}
      </option>
    );
  });

  return (
    <div>
      <div className="maincontent">
        <form
          onSubmit={(event) => {
            generateQRCOde(event);
            //donwnloadQR();
          }}
          className="highlight all_form_spacing"
          method="POST"
          name="contact-form"
        >
          <h3 style={{ color: "red" }}>Generate Attendance QR</h3>
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="fname">Enter Gate</label>
              <input
                type="text"
                id="fname"
                placeholder="Enter Gate Lable (Mandatory)"
                name="lable"
                onChange={(e) => {
                  setDetails({ ...details, lable: e.target.value });
                }}
                className="form-control"
                value={details.lable}
                required
              />
            </div>

            <div className="col-md-4">
              <label htmlFor="designition">Select Address</label>
              <select
                className="form-select form-control"
                id="inputGroupSelect02"
                onChange={assignCityHandler}
              >
                <option value="none" selected disabled hidden></option>
                {dyList}
              </select>
              <p>
                Can't find address in the list ? Add one from{" "}
                <Link onClick={() => setShow(true)}>here</Link>.
              </p>
            </div>
            <div className="col-md-4 mt-4">
              <button type="submit" className="departmentBtnSecondary">
                Submit
              </button>
            </div>
          </div>

          <hr />
          {qrData.status === true ? (
            <div>
              <h1>Generated QR for {details.lable} </h1>
              <div>
                <img src={qrData.data.code} alt="QR" />
              </div>
            </div>
          ) : (
            ""
          )}
          <h3 style={{ color: "red" }}>All QR Codes</h3>
          <AllAttandanceQR />
        </form>
        <Modal show={show} onHide={() => setShow(false)} fullscreen>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "red" }}>
              Add n View Office Address{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddANewOfficeAddress />
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "center" }}>
            <button
              className="modalBtn"
              onClick={() => setShow(false)}
              style={{ width: "30%", fontSize: "20px" }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default GenerateQR;
