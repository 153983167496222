import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";

const EditLeaveForm = ({ name, id }) => {
  const [details, setDetails] = useState({
    name: "",
    validity_start_from: "",
    description: "",
    quantity: "",
    carry_forward_leaves: false,
    maximum_carry_forward: null,
    carry_forward_duration: null,
  });

  const [msgData, setMsgData] = useState({});

  console.log(name, id);

  const editLeaveHandler = (e) => {
    e.preventDefault();
    (async () => {
      const url = API_URL + "/admin-quantity-view/?leave_id=" + id;
      const userData = JSON.parse(window.sessionStorage.getItem("user"));

      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        carry_forward_leaves:
          details.carry_forward_leaves === ""
            ? null
            : details.carry_forward_leaves,
        maximum_carry_forward:
          details.maximum_carry_forward === ""
            ? null
            : details.maximum_carry_forward,
        carry_forward_leave_type: {
          name: details.name,
          code: details.name,
          description: details.description,
          validity_start_from: details.validity_start_from,
          quantity: details.quantity,
        },
        carry_forward_duration: details.carry_forward_duration,
      });

      try {
        const res = await fetch(url, {
          method: "PATCH",
          body: bodyContent,
          headers: headersList,
        });
        const data = await res.json();
        console.log(data);
        setMsgData(data);
      } catch (err) {
        console.error(err);
      }
    })();
  };

  useEffect(() => {
    (async () => {
      try {
        const userData = JSON.parse(window.sessionStorage.getItem("user"));
        const headersList = {
          Authorization: "Token " + userData.seucrity_token,
        };
        const url = API_URL + "/fetch-leave-type-admin/?leave_id=" + id;
        const res = await fetch(url, { method: "GET", headers: headersList });
        const data = await res.json();
        console.log(data);
        setDetails({
          ...details,
          name: data.data[0].carry_forward_leave_type.name,
          validity_start_from:
            data.data[0].carry_forward_leave_type.validity_start_from,
          description: data.data[0].carry_forward_leave_type.description,
          quantity: data.data[0].carry_forward_leave_type.quantity,
          carry_forward_leaves: data.data[0].carry_forward_leaves,
          maximum_carry_forward: data.data[0].maximum_carry_forward,
          carry_forward_duration: data.data[0].carry_forward_duration,
        });
      } catch (err) {
        console.error(err);
      }
    })();
  }, [id]);

  // const checkBoxHandler1 = (e) => {
  //   console.log(e.target.checked);
  //   setDetails({
  //     ...details,
  //     carry_forward_leaves: e.target.checked,
  //   });
  // };

  // const checkBoxHandler2 = (e) => {
  //   console.log(e.target.checked);
  //   if (!e.target.checked) {
  //     setDetails({
  //       ...details,
  //       maximum_carry_forward: "",
  //       carry_forward_duration: "",
  //     });
  //   }
  // };

  console.log(details);
  return (
    <>
      {msgData &&
      msgData.status === true &&
      msgData.status !== undefined &&
      msgData.status !== null ? (
        <div className="alert alert-success" role="alert">
          {msgData.message}, Please close the dialgoue
        </div>
      ) : msgData &&
        msgData.status === false &&
        msgData.status !== undefined &&
        msgData.status !== null ? (
        <div className="alert alert-danger" role="alert">
          {msgData.message}
        </div>
      ) : (
        ""
      )}
      <form name="contact-form" onSubmit={editLeaveHandler}>
        <div className="row">
          <div className="col-md-4 col-lg- mt-4">
            <label>Select employee type</label>
            <select
              className="form-select"
              onChange={(e) =>
                setDetails({
                  ...details,
                  validity_start_from: e.target.value,
                })
              }
              required
            >
              <option
                value="date_of_confirmation"
                selected={
                  details.validity_start_from === "date_of_confirmation"
                }
              >
                Full-Time Employee
              </option>
              <option
                value="intern"
                selected={details.validity_start_from === "intern"}
              >
                Intern
              </option>
              <option
                value="date_of_joining"
                selected={details.validity_start_from === "date_of_joining"}
              >
                Probation
              </option>
              <option
                value="notice_period"
                selected={details.validity_start_from === "notice_period"}
              >
                Notice Period
              </option>
              <option
                value="other"
                selected={details.validity_start_from === "other"}
              >
                Other
              </option>
            </select>
          </div>
          <div className="col-md-4 col-lg- mt-4">
            <label>Enter Leave Name </label>
            <input
              type="text"
              className="form-control"
              placeholder="Eg. Casual Leave(CL), Sick Leave(SL)"
              value={details.name}
              onChange={(e) => setDetails({ ...details, name: e.target.value })}
              required
            />
          </div>
          <div className="col-md-4 col-lg- mt-4">
            <label htmlFor="leaves">Enter No. of Leaves</label>
            <input
              type="number"
              id="mname"
              placeholder="Enter no. of leaves"
              name="leaves"
              className="form-control"
              value={details.quantity}
              onChange={(e) =>
                setDetails({ ...details, quantity: e.target.value })
              }
              min="0"
              max="100"
              required
            />
          </div>
          <div className="col-md-4 col-lg- mt-4">
            <label htmlFor="leaves">Leave Description (Optional)</label>
            <textarea
              type="textbox"
              id="mname"
              placeholder="Leave Description"
              name="leaves"
              className="form-control"
              value={details.description}
              onChange={(e) =>
                setDetails({ ...details, description: e.target.value })
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-lg- mt-4">
            <input
              className="me-2"
              type="checkbox"
              id="carry"
              name="carry"
              checked={details.carry_forward_leaves}
              // onChange={(e) => {
              //   checkBoxHandler1(e);
              //   checkBoxHandler2(e);
              // }}
              onChange={(e) => {
                setDetails((prevState) => ({
                  ...prevState,
                  carry_forward_leaves: e.target.checked,
                }));

                if (e.target.checked === false) {
                  setDetails((prevState) => ({
                    ...prevState,
                    carry_forward_duration: "",
                    maximum_carry_forward: "",
                  }));
                }
              }}
            />
            <label for="leave1"> Carry Forward</label>{" "}
            {/* <p style={{ color: "red" }}>
              *Please re enter all details carefully
            </p> */}
          </div>
        </div>
        {details.carry_forward_leaves === true ? (
          <div className="row">
            <div className="col-md-4 col-lg- mt-4">
              <label htmlFor="carryleave">
                Enter No. of Leaves to Carry Forward
              </label>
              <input
                type="number"
                id="carryleave"
                placeholder="Enter no. of leaves"
                name="carryleave"
                className="form-control"
                min="0"
                max="100"
                value={details.maximum_carry_forward}
                onChange={(e) => {
                  setDetails({
                    ...details,
                    maximum_carry_forward: e.target.value,
                  });
                }}
              />
            </div>
            <div className="col-md-4 col-lg- mt-4">
              <label htmlFor="carryduration">Carry Forward Duration</label>
              <input
                type="number"
                id="carryduration"
                placeholder="Enter no. of years"
                name="carryduration"
                className="form-control"
                min="0"
                max="10"
                value={details.carry_forward_duration}
                onChange={(e) =>
                  setDetails({
                    ...details,
                    carry_forward_duration: e.target.value,
                  })
                }
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <button type="submit" className="btn btn-warning btn-lg mt-4 mb-5">
          Edit {name}
        </button>
      </form>
    </>
  );
};

export default EditLeaveForm;
