import { useState } from "react";
import { useAlert } from "react-alert";

import { API_URL } from "../../../../Constents";

const AddInterviewerFrom = (props) => {
  const [interviewerDetails, setInterviewerDetails] = useState({
    name: "",
    email: "",
    number: "",
  });

  const alert = useAlert();

  const addInterviewerHander = async (e) => {
    e.preventDefault();

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      email: interviewerDetails.email,
      name: interviewerDetails.name,
      mobile_number: interviewerDetails.number,
    });

    const url = API_URL + "/interviewer/";

    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      if (data.status) {
        setInterviewerDetails({
          name: "",
          email: "",
          number: "",
        });
        alert.success(data.message);
        props.refresh(data);
      }
      if (data.status === false) alert.error(data.message);
      console.log(data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  return (
    <>
      <form onSubmit={addInterviewerHander}>
        <div className="row mt-4">
          <div className="col-md-4">
            {" "}
            <input
              type="text"
              className="form-control"
              placeholder="Interviewer Name"
              value={interviewerDetails.name}
              onChange={(e) =>
                setInterviewerDetails({
                  ...interviewerDetails,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="col-md-4">
            {" "}
            <input
              type="email"
              className="form-control"
              placeholder="Interviewer Email"
              value={interviewerDetails.email}
              onChange={(e) =>
                setInterviewerDetails({
                  ...interviewerDetails,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="col-md-4">
            {" "}
            <input
              type="number"
              className="form-control"
              pattern="[6789][0-9]{9}"
              title="Enter a valid 10 digit phone number"
              placeholder="Interviewer Phone"
              value={interviewerDetails.number}
              onChange={(e) =>
                setInterviewerDetails({
                  ...interviewerDetails,
                  number: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-3">
            {" "}
            <button className="btn btn-warning" type="submit">
              Add Interviewer
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddInterviewerFrom;
