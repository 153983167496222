import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";
//import AllAttandanceQR from './AllAttandanceQR';

const AddDepartments = () => {
  const [details, setDetails] = useState({ depName: "", empName: "" });
  const [employeeList, setEmployeeList] = useState([]);
  const [resData, setResData] = useState(null);
  const [err, setErr] = useState({ status: false, log: "" });

  const assignEmployeeHandler = (e) => {
    setDetails({ ...details, empName: e.target.value });
  };

  const fetchAllEmployees = async () => {
    const url = API_URL + "/employee-list-dashboard/";

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
      "content-type": "application/json",
    };

    const bodyContent = JSON.stringify({
      department_array: [],
    });

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: headerList,
        body: bodyContent,
      });
      const data = await res.json();
      setEmployeeList(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchAllEmployees();
  }, []);

  console.log(details);

  const addDepartmentHandler = async (event) => {
    event.preventDefault();

    try {
      const url = API_URL + "/departments/";
      const userData = JSON.parse(window.sessionStorage.getItem("user"));

      const headerList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        department_name: details.depName,
        department_lead: details.empName,
      });
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headerList,
      });
      const data = await res.json();
      setResData(data);
      setErr({ status: false, log: "Details" });
      setDetails({ depName: "", empName: "" });
    } catch (err) {
      //setErr({status: true, log: err.message});
      setErr({ status: true, log: resData.message });
    }
  };

  console.log(details);
  console.log(resData);

  //if(err) return <h1>{resData.message}</h1>;

  const empList = employeeList.map((employeeData) => (
    <option key={employeeData.id} value={employeeData.official_email}>
      {employeeData.first_name}
    </option>
  ));

  if (err.status) return <h1>Some error occured in creating Dept</h1>;

  // if(resData.status===false){
  //   return <h1>{resData.message}</h1>
  // }

  return (
    <div>
      <div className="maincontent border  rounded">
        {resData && resData.status === true ? (
          <b style={{ color: "green" }}>
            {resData.message}, Please assign departments to the Employees to see
            it in the list
          </b>
        ) : resData && resData.status === false ? (
          <b style={{ color: "red" }}>{resData.message}</b>
        ) : (
          ""
        )}
        <form
          onSubmit={(event) => {
            addDepartmentHandler(event);
            //donwnloadQR();
          }}
          className="mb-5 "
          method="POST"
          name="contact-form"
        >
          {/* <h3>Add Department</h3> */}
          <div className="row mt-4">
            <div className="col-md-4 mb-3">
              <label htmlFor="fname">
                Name <span className="msg_fail">*</span>
              </label>
              <input
                type="text"
                id="fname"
                placeholder="Enter Team Name (Mandatory)"
                name="depName"
                onChange={(e) => {
                  setDetails({ ...details, depName: e.target.value });
                }}
                className="form-control"
                //style={{ border: "none" }}
                value={details.depName}
                required
              />
            </div>

            <div className="col-md-3 mb-3">
              <label htmlFor="fname">
                Select Department Head <span className="msg_fail">*</span>
              </label>
              <input
                list="ice-cream-flavors"
                onChange={assignEmployeeHandler}
                className="form-control"
                placeholder="Enter Email ID"
              />
              <datalist id="ice-cream-flavors">{empList}</datalist>
            </div>
          </div>
          <button type="submit" className="btn btn-outline-danger btn-lg">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddDepartments;
