// Table import
import { useState, useEffect } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { API_URL } from "../../Constents";
//import ModalEmpoyeeDetails from "../Modals/ModalEmployeeMoreDetails";
import ModalEmpoyeeDetails from "../Modals/ModalEmployeeDetails";
import moment from "moment";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { AiOutlineDownload } from "react-icons/ai";
import LoadingGifs from "../../images/YAAMS-gif.gif";

const WorkFromHome = () => {
  const [WFHData, setWFHData] = useState();
  const [WFHName, setWFHName] = useState();
  const [err, setErr] = useState();
  const [WFHId, setWFHId] = useState();

  const [fileURL, setFileURL] = useState();

  const [details, setDetails] = useState({
    startDate: "",
    endDate: "",
    status: "pending",
  });

  const today = new Date();
  const todayDate = moment(today).format("YYYY-MM-DD");
  const dateLast =
    details.startDate && details.endDate === "" ? todayDate : details.endDate;
  const dateStart =
    details.startDate === "" && details.endDate !== ""
      ? todayDate
      : details.startDate;

  const [approveStatus, setApproveStatus] = useState(null);
  const handleCloseEmp = () => setShowEmp(false);
  const handleShowEmp = () => setShowEmp(true);
  const [showEmp, setShowEmp] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  const handleCloseFile = () => setShowFile(false);
  const handleShowFile = () => setShowFile(true);
  const [showFile, setShowFile] = useState(false);

  const [modalID, setModalId] = useState("");

  const fetchWFHData = async () => {
    const url =
      API_URL +
      "/request-for-wfh/?start_date=" +
      dateStart +
      "&last_date=" +
      dateLast +
      "&wfh_status=" +
      details.status;

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerlist = {
      Authorization: "Token " + userData.seucrity_token,
    };

    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headerlist,
      });
      const data = await res.json();
      setWFHData(data);
    } catch (err) {
      setErr(err);
    }
  };

  const WFHApproveAndRejectStatusHandler = async (status) => {
    console.log(status);
    handleClose(false);
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const bodyContent = JSON.stringify({
      request_status: status,
    });

    const url = API_URL + "/request-for-wfh/?wfh_id=" + WFHId;

    try {
      const res = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });
      const data = await res.json();
      console.log(data);
      setApproveStatus(data);
      handleClose();
    } catch (err) {
      setErr(err);
      console.error(err);
    }
  };
  console.log(WFHData);

  useEffect(() => {
    fetchWFHData();
  }, [show, details.startDate, details.endDate, details.status, approveStatus]);

  //   const ExportLeaveDataToSheet = async () => {
  //     const bodyContent = JSON.stringify({
  //       start_date: details.startDate,
  //       last_date: details.endDate,
  //       status: details.status,
  //     });

  //     const url = API_URL + "/export-leave-data/";

  //     console.log(url);
  //     try {
  //       const userData = JSON.parse(window.sessionStorage.getItem("user"));
  //       const res = await fetch(url, {
  //         method: "POST",
  //         body: bodyContent,
  //         headers: {
  //           Authorization: "Token " + userData.seucrity_token,
  //           "Content-Type": "application/json",
  //         },
  //       });
  //       const data = await res.json();
  //       console.log(data);
  //       window.open(data.data);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };

  // console.log(WFHData);
  // console.log(modalID);
  // console.log(details);
  // console.log(err);
  if (!WFHData)
    return (
      <div className="loadingGif">
        <img src={LoadingGifs} alt="Loading Gif" />
      </div>
    );
  if (err) return <h1>Some error occured</h1>;

  const { SearchBar } = Search;

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <>
        <Link
          onClick={handleClick}
          style={{ backgroundColor: "white", borderColor: "lightgray" }}
          className="btn btn-light "
        >
          Export CSV
          <AiOutlineDownload style={{ marginLeft: "5px", color: "red" }} />
        </Link>
      </>
    );
  };

  const columns = [
    {
      dataField: "raised_by_name",
      text: "Name",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <div className="row align-items-center ">
              <Button
                variant="link"
                style={{
                  color: "#3E3E3E",
                  textDecoration: "none",
                  whiteSpace: "nowrap",
                  textAlign: "left",
                }}
                onClick={() => {
                  setModalId(row.raised_by);
                  handleShowEmp();
                }}
              >
                {row.raised_by_name}

                <BsBoxArrowUpRight
                  style={{
                    fontSize: "10px",
                    marginLeft: "5px",
                    // marginBottom: "10px",
                    color: "black",
                    fontWeight: "600",
                  }}
                />
              </Button>
            </div>
          </>
        );
      },
    },
    {
      dataField: "request_status",
      text: "Status",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.request_status === "approved")
          return (
            <>
              <div className="row align-items-center">
                <Button
                  variant="link"
                  style={{
                    color: "green",
                    textDecoration: "none",
                    whiteSpace: "nowrap",
                    textAlign: "left",
                  }}
                  onClick={() => {
                    handleShow();
                    setWFHId(row.id);
                    setWFHName(row.raised_by_name);
                  }}
                >
                  Approved
                  <BsBoxArrowUpRight
                    style={{
                      fontSize: "10px",
                      marginLeft: "5px",
                      // marginBottom: "10px",
                      color: "green",
                      fontWeight: "600",
                    }}
                  />
                </Button>
              </div>
            </>
          );
        if (row.request_status === "rejected")
          return (
            <>
              <div className="row align-items-center">
                <Button
                  variant="link"
                  style={{
                    color: "red",
                    textDecoration: "none",
                    whiteSpace: "nowrap",
                    textAlign: "left",
                  }}
                  onClick={() => {
                    handleShow();
                    setWFHId(row.id);
                    setWFHName(row.raised_by_name);
                  }}
                >
                  Rejected
                  <BsBoxArrowUpRight
                    style={{
                      fontSize: "10px",
                      marginLeft: "5px",
                      // marginBottom: "10px",
                      color: "red",
                      fontWeight: "600",
                    }}
                  />
                </Button>
              </div>
            </>
          );
        if (row.request_status === "pending")
          return (
            <>
              <div className="row align-items-center">
                <Button
                  variant="link"
                  onClick={() => {
                    handleShow();
                    setWFHId(row.id);
                    setWFHName(row.raised_by_name);
                  }}
                  style={{
                    color: "#3E3E3E",
                    textDecoration: "none",
                    whiteSpace: "nowrap",
                    textAlign: "left",
                  }}
                >
                  Pending
                  <BsBoxArrowUpRight
                    style={{
                      fontSize: "10px",
                      marginLeft: "5px",
                      // marginBottom: "10px",
                      color: "#3E3E3E",
                      fontWeight: "600",
                    }}
                  />
                </Button>
              </div>
            </>
          );
      },
    },

    // { dataField: "from_date", text: "Date", sort: true,  formatter: (cell, row, rowIndex, formatExtraData) => { return <p>{row.from_date} To {row.to_date}</p>} },
    { dataField: "from_date", text: "From", sort: true },
    { dataField: "to_date", text: "To", sort: true },

    { dataField: "reason", text: "Reason", sort: true }, //filter: textFilter() },
    {
      dataField: "file",
      text: "File",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.file == null || row.file === "") return "No attachment";
        else
          return (
            <Button
              variant="link"
              onClick={() => {
                handleShowFile();
                setFileURL(row.file);
              }}
            >
              <BsBoxArrowUpRight />
            </Button>
          );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: "End",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  return (
    <div className="maincontent ">
      <div className="highlight all_form_spacing">
        {approveStatus &&
        approveStatus.status === true &&
        approveStatus.status !== undefined ? (
          <div className="alert alert-success" role="alert">
            {approveStatus.message}
          </div>
        ) : approveStatus &&
          approveStatus.status === false &&
          approveStatus.status !== undefined ? (
          <div className="alert alert-danger" role="alert">
            {approveStatus.message}
          </div>
        ) : (
          ""
        )}

        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={WFHData.data}
          columns={columns}
          ignoreHeader
          exportCSV={{
            fileName: "WorkFromHome_Date.csv",
          }}
          search
        >
          {(props) => (
            <React.Fragment>
              <div className="d-flex align-items-center  justify-content-between">
                <h2 style={{ color: "#dd3944" }}>Work From Home</h2>
                <MyExportCSV {...props.csvProps} />
              </div>

              <div className="container mt-2">
                <div className="row">
                  <div className="col-md-3 mt-4">
                    <div className="position-relative newsearch_box mt-1">
                      <SearchBar
                        className="newSearchWrapper"
                        placeholder="Search Here..."
                        srText=""
                        {...props.searchProps}
                      />
                      <BiSearch />
                    </div>
                    <div className="col-md-3 text-end  mb-2"></div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="dob" className="mb-2">
                      Start Date
                    </label>
                    <input
                      className="form-select form-control"
                      type="date"
                      id="StartDate"
                      name="StartDate"
                      value={details.startDate}
                      onChange={(e) => {
                        setDetails({ ...details, startDate: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="dob" className="mb-2">
                      End Date
                    </label>
                    <input
                      className="form-select form-control"
                      type="date"
                      id="birthday"
                      name="birthday"
                      value={details.endDate}
                      onChange={(e) => {
                        setDetails({ ...details, endDate: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="designition" className="mb-2">
                      Select Status
                    </label>
                    <select
                      className="form-select form-control"
                      id="inputGroupSelect02"
                      onChange={(e) => {
                        setDetails({ ...details, status: e.target.value });
                      }}
                    >
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                      <option value="">All</option>
                    </select>
                  </div>
                </div>
                {/* <div className="row">
                <div className="col-md-3 mb-3">
                  <button
                    type="button"
                    className="btn btn-secondary btn-lg"
                    // onClick={ExportLeaveDataToSheet}
                  >
                    Export Attendance
                  </button>
                </div>
              </div> */}
                <BootstrapTable
                  pagination={pagination}
                  filter={filterFactory()}
                  bordered={false}
                  {...props.baseProps}
                  condensed
                  noDataIndication={() => {
                    return <h3>No data available</h3>;
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </ToolkitProvider>
        <Modal size="xl" show={showEmp} onHide={handleCloseEmp}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <ModalEmpoyeeDetails id={modalID} />
          </Modal.Body>
        </Modal>

        <Modal size="xl" show={showFile} onHide={handleCloseFile}>
          <Modal.Header closeButton>Attached File</Modal.Header>
          <Modal.Body>
            <div className="text-center">
              {fileURL && fileURL !== null ? (
                <img src={fileURL} alt="WFH_File" />
              ) : (
                <Spinner />
              )}
            </div>
          </Modal.Body>
        </Modal>

        <Modal size="lg" show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            {/* <Modal.Title>WFH Action</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center mb-3">
              <h2>
                Take Action on <i>{WFHName}</i>'s Request ?
              </h2>{" "}
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-success btn-lg me-3"
                onClick={() => WFHApproveAndRejectStatusHandler("approved")}
              >
                Approve
              </button>
              <button
                className="btn btn-danger btn-lg"
                onClick={() => WFHApproveAndRejectStatusHandler("rejected")}
              >
                Reject
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default WorkFromHome;
