import CreateRole from "./CreateRole";
import SetupDefaultEmailAddress from "./SetupDefaultEmailAddress";

const GlobalGeneralSetting = () => {
  return (
    <>
      <CreateRole />
      <SetupDefaultEmailAddress/>
    </>
  );
};

export default GlobalGeneralSetting;
