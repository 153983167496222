import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { API_URL, BASE_URL } from "../../Constents";

import { BsCalendar3, BsListCheck } from "react-icons/bs";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaRegCalendarTimes, FaUsers } from "react-icons/fa";

const DashboardCards = () => {
  const [dashPercentage, setDashPercentage] = useState({});

  const currMon = new Date().toLocaleString("en-US", { month: "long" }); // Gets Current month eg "January"
  const currYr = new Date().getFullYear(); // gets current year in YYYY

  const fetchDashCardData = async () => {
    const url = API_URL + "/fetch-dashboard-percentage/";
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    try {
      const res = await fetch(url, { method: "GET", headers: headersList });
      const data = await res.json();

      setDashPercentage(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDashCardData();
  }, []);

  return (
    <>
      <div className="col-md-6">
        <Link to={BASE_URL + "Attendence"} style={{ textDecoration: "none" }}>
          <div
            className="highlight dashbox"
            style={{
              background:
                "linear-gradient(76.54deg, #8054F8 5.7%, #A697C6 58.26%, #D093EC 100%)",
              color: "#fff",
            }}
          >
            <h4>
              Attendance <AiOutlineArrowRight />
            </h4>
            <h6>
              {currMon} {currYr}
            </h6>
            <BsCalendar3 size={45} />
            <h1 className="percent">
              {Math.round(dashPercentage.attendance * 100) / 100}%
            </h1>
          </div>
        </Link>
      </div>
      <div className="col-md-6">
        <Link to={BASE_URL + "#"} style={{ textDecoration: "none" }}>
          <div
            className="highlight dashbox"
            style={{
              background: "linear-gradient(230deg, #fc5286, #fbaaa2)",
              color: "#fff",
            }}
          >
            <h4>
              Tasks <AiOutlineArrowRight />
            </h4>
            <h6>
              {currMon} {currYr}
            </h6>
            <BsListCheck size={45} />
            <h1 className="percent">
              {Math.round(dashPercentage.tasks * 100) / 100}%
            </h1>
          </div>
        </Link>
      </div>
      <div className="col-md-6">
        <Link to={BASE_URL + "Leaves"} style={{ textDecoration: "none" }}>
          <div
            className="highlight dashbox"
            style={{
              background: "linear-gradient(230deg, #ffc480, #ff763b)",
              color: "#fff",
            }}
          >
            <h4>
              Leaves <AiOutlineArrowRight />
            </h4>
            <h6>
              {currMon} {currYr}
            </h6>
            <FaRegCalendarTimes size={45} />
            <h1 className="percent">{dashPercentage.leaves}</h1>
          </div>
        </Link>
      </div>
      <div className="col-md-6">
        <Link to={BASE_URL + "EmployeeList"} style={{ textDecoration: "none" }}>
          <div
            className="highlight dashbox"
            style={{
              background:
                "linear-gradient(75.54deg, #4F91B6 5.83%, #90CEC7 57.33%, #8ADBD2 98.81%)",
              color: "#fff",
            }}
          >
            <h4>
              Employees <AiOutlineArrowRight />
            </h4>
            <h6>
              {currMon} {currYr}
            </h6>
            <FaUsers size={45} />
            <h1 className="percent">{dashPercentage.employees}</h1>
          </div>
        </Link>
      </div>
    </>
  );
};

export default DashboardCards;
