import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import { NavLink, Route } from "react-router-dom";

import CreateVacancy from "./CreateVacancy";
import AllVacancyList from "./AllVacancyList";
import ActiveVacancyList from "./ActiveVacancyList";
import ExpiredVacancyList from "./ExpiredVacancyList";
import EditExistingVacancy from "./EditExistingVacancy";
import ExtendExistingVacancy from "./ExtendExistingVacancy";
import ExpireExistingVacancy from "./ExpireExistingVacancy";
import DuplicateExistingVacancy from "./DuplicateExistingVacancy";
import ReOpenExpiredVacancy from "./ReOpenExpiredVacancy";

import { BASE_URL } from "../../../../Constents";

export default function Vacancy() {
  const [showVacancyModal, setshowVacancyModal] = useState({
    modalState: false,
  });

  const [editVacancyModalAndDetails, setEditVacancyModalAndDetails] =
    useState(false);
  const [extendVacancyModalAndDetails, setExtendVacancyModalAndDetails] =
    useState(false);
  const [expireVacancyModalAndDetails, setExpireVacancyModalAndDetails] =
    useState(false);
  const [duplicateVacancyModalAndDetails, setDuplicateVacancyModalAndDetails] =
    useState(false);
  const [reopenVacancyModalAndDetails, setReopenVacancyModalAndDetails] =
    useState(false);

  const [clickedRowData, setClickedRowData] = useState({});

  const closeVacancyModal = () => {
    setshowVacancyModal((prevState) => ({
      ...prevState,
      modalState: false,
    }));
  };
  const openVacancyModal = () => {
    setshowVacancyModal((prevState) => ({
      ...prevState,
      modalState: true,
    }));
  };

  const closeEditVacancyModal = () => {
    setEditVacancyModalAndDetails(false);
  };
  const openEditVacancyModal = () => {
    setEditVacancyModalAndDetails(true);
  };

  const closeExtendVacancyModal = () => {
    setExtendVacancyModalAndDetails(false);
  };
  const openExtendVacancyModal = () => {
    setExtendVacancyModalAndDetails(true);
  };

  const closeExpireVacancyModal = () => {
    setExpireVacancyModalAndDetails(false);
  };
  const openExpireVacancyModal = () => {
    setExpireVacancyModalAndDetails(true);
  };

  const closeDuplicateVacancyModal = () => {
    setDuplicateVacancyModalAndDetails(false);
  };
  const openDuplicateVacancyModal = () => {
    setDuplicateVacancyModalAndDetails(true);
  };

  const closeReopenVacancyModal = () => {
    setReopenVacancyModalAndDetails(false);
  };
  const openReopenVacancyModal = () => {
    setReopenVacancyModalAndDetails(true);
  };

  const active = {
    color: "#DD3944",
    background: "#f5f7ff",
  };

  return (
    <>
      <div className="maincontent">
        <div className="highlight">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 style={{ color: "#DD3974" }} className="mb-2">
              Vacancy
            </h2>
            <button
              className="departmentSettingBtn formSelectPlus w-25"
              onClick={openVacancyModal}
            >
              Create Vacancy
            </button>
          </div>
          <div className="row vacancyTabs">
            <div className="col-md-12">
              <ul className="vac_tabs">
                <li>
                  <NavLink
                    exact
                    to={BASE_URL + "vacancy/All"}
                    // activeStyle={active}
                  >
                    Vacancy (All)
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={BASE_URL + "vacancy/Active"}
                    //activeStyle={active}
                  >
                    Active
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={BASE_URL + "vacancy/Expired"}
                    //  activeStyle={active}
                  >
                    Expired
                  </NavLink>
                </li>
              </ul>
            </div>

            <hr />

            <Route path={BASE_URL + "vacancy/All"}>
              <AllVacancyList
                openEditVacancyModal={openEditVacancyModal}
                dataFromAllChiled={setClickedRowData}
                openExtendVacancyModal={openExtendVacancyModal}
                openExpireVacancyModal={openExpireVacancyModal}
                openDuplicateVacancyModal={openDuplicateVacancyModal}
                openReopenVacancyModal={openReopenVacancyModal}
                toggleOnCreatingVacancy={showVacancyModal}
              />
            </Route>
            <Route path={BASE_URL + "vacancy/Active"}>
              <ActiveVacancyList
                openEditVacancyModal={openEditVacancyModal}
                dataFromAllChiled={setClickedRowData}
                openExtendVacancyModal={openExtendVacancyModal}
                openExpireVacancyModal={openExpireVacancyModal}
              />
            </Route>
            <Route path={BASE_URL + "vacancy/Expired"}>
              <ExpiredVacancyList
                openEditVacancyModal={openEditVacancyModal}
                dataFromAllChiled={setClickedRowData}
                openDuplicateVacancyModal={openDuplicateVacancyModal}
                openReopenVacancyModal={openReopenVacancyModal}
              />
            </Route>
          </div>
        </div>
      </div>

      <Modal
        show={showVacancyModal.modalState}
        onHide={closeVacancyModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <CreateVacancy closeVacancyModal={closeVacancyModal} />
        </Modal.Body>
      </Modal>

      <Modal
        show={editVacancyModalAndDetails}
        onHide={closeEditVacancyModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <EditExistingVacancy
            clickedRowData={clickedRowData}
            closeEditVacancyModal={closeEditVacancyModal}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={extendVacancyModalAndDetails}
        onHide={closeExtendVacancyModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <ExtendExistingVacancy
            clickedRowData={clickedRowData}
            closeExtendVacancyModal={closeExtendVacancyModal}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={expireVacancyModalAndDetails}
        onHide={closeExpireVacancyModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <ExpireExistingVacancy
            clickedRowData={clickedRowData}
            closeExpireVacancyModal={closeExpireVacancyModal}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={duplicateVacancyModalAndDetails}
        onHide={closeDuplicateVacancyModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <DuplicateExistingVacancy
            clickedRowData={clickedRowData}
            closeDuplicateVacancyModal={closeDuplicateVacancyModal}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={reopenVacancyModalAndDetails}
        onHide={closeReopenVacancyModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <ReOpenExpiredVacancy
            clickedRowData={clickedRowData}
            closeReopenVacancyModal={closeReopenVacancyModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
