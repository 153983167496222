import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";
import { AiFillFile } from "react-icons/ai";
import { Button } from "react-bootstrap";

const UploadedDocList = ({ isToggle }) => {
  const [docList, setDocList] = useState([]);
  const fetchEmployeeDocList = async () => {
    const id = window.location.href.split("/").reverse()[0];

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/document-type-list/?user_id=" + id;

    try {
      const res = await fetch(url, { method: "GET", headers: headersList });
      const data = await res.json();
      setDocList(data.data);
    } catch (err) {
      console.error(err);
    }
  };
  console.log(docList);

  // const openInNewTab = (url) => {
  //   window.open(url, "_blank").focus();
  // };

  useEffect(() => {
    fetchEmployeeDocList();
  }, [isToggle]);
  return (
    <div className="maincontent highlight all_form_spacing">
      <h3 style={{ color: "red" }}>List of Uploaded Documents</h3>
      <hr />
      <ul className="list-group">
        {docList.length === 0 ? (
          <p>No documents uploaded</p>
        ) : (
          docList.map((resData) => {
            return (
              <li className="list-group-item d-inline-flex justify-content-start align-items-center">
                <Button
                  variant="link"
                  onClick={(e) =>
                    window.open(resData.document_file, "_blank").focus()
                  }
                  data-toggle="tooltip"
                  title="Click to view File"
                >
                  <AiFillFile size={50} />
                </Button>

                <h1>{resData.document_name}</h1>
              </li>

              // <div className="col-md-2" key={resData.document_code}>
              //   <AiFillFile/><p> {resData.document_name} </p>
              //   <img
              //     src={resData.document_file}
              //     alt={resData.document_name}
              //     className="img-fluid"
              //   />
              // </div>
            );
          })
        )}
      </ul>
    </div>
  );
};
export default UploadedDocList;
