// Contains UI for the Tabs to update the personal details of the employees
// Each Request is handled in the different components imported below

import { Tab, Tabs } from "react-bootstrap";

import MobileNumberUpdateRequest from "../User/MobileNumberUpdateRequest";
import BankDetailsUpdateRequest from "../User/BankDetailsUpdateRequest";
import AddressDetailsUpdateRequest from "../User/AddressDetailsUpdateRequest";
import DocumentsDetailsUpdateRequest from "../User/DocumentsDetailsUpdateRequest";

const RequestsAndReviews = () => {
  return (
    <div className="container">
      <div className="highlight all_form_spacing">
        <h1>Employee Requests</h1>
        <hr />
        <div className="vacancyTabs">
          <Tabs
            defaultActiveKey="personal"
            id="fill-tab-example"
            className="mb-3 nav nav-pills"
            fill
          >
            <Tab eventKey="personal" title="Mobile">
              <MobileNumberUpdateRequest />
            </Tab>
            <Tab eventKey="account" title="Bank Account">
              <BankDetailsUpdateRequest />
            </Tab>
            <Tab eventKey="Address" title="Address">
              <AddressDetailsUpdateRequest />
            </Tab>
            <Tab eventKey="documents" title="Documents">
              <DocumentsDetailsUpdateRequest />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default RequestsAndReviews;
