import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { API_URL } from "../../../../Constents";
import { useAlert } from "react-alert";
export default function RejectCandidateDetails(props) {
  const [remarkReason, setRemarkReason] = useState("");
  const [selected, setSelected] = useState([]);
  const alert = useAlert();
  const options = [
    { label: "Pan Card", value: "Pan_Card" },
    { label: "Profile Picture", value: "Profile_Picture" },
    { label: "UIDAI Details", value: "UIDAI_Details" },
    { label: "Bank Details", value: "Bank_Details" },
  ];
  const submitFeedbackHandler = async () => {
    const url =
      API_URL +
      "/applicant-personal-details/?id=" +
      props.applicantDetails.applicant;
    const selectedValueArr = [];
    selected.map((val) => selectedValueArr.push(val.value));
    console.log(selectedValueArr);
    if (selectedValueArr.length === 0 || remarkReason === "") {
      return alert.info("Please select atleast one reason ");
    }
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const bodyContent = JSON.stringify({
      rejected_field: selectedValueArr,
      remarks: remarkReason,
    });

    try {
      const response = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);
      if (data.status) {
        alert.success(data.message);
        props.closeRejectCandidateModal();
      }
      if (data.status === false) {
        alert.error(data.message);
        // props.closeRejectCandidateModal();
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  console.log(remarkReason);
  console.log(selected);
  return (
    <>
      <h3 className="text-center" style={{ color: "#DD3974" }}>
        Reason For Rejection
      </h3>
      <div className="row">
        <div className="col-md-6">
          <label htmlFor="">
            Select Reason<span className="msg_fail">*</span>
          </label>

          <MultiSelect
            options={options}
            value={selected}
            onChange={setSelected}
            labelledBy="Select"
          />
        </div>
      </div>

      <div className="col-md-6 mt-2">
        <label htmlFor="">Give Remark</label>
        <textarea
          name=""
          id=""
          cols="45"
          rows="0"
          onChange={(e) => {
            setRemarkReason(e.target.value);
          }}
        />
      </div>
      <div className="d-flex mt-2">
        <button
          className="btn btn-outline-warning"
          onClick={submitFeedbackHandler}
        >
          Submit Feedback
        </button>
      </div>
    </>
  );
}
