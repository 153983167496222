import { useState, useEffect } from "react";
import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { API_URL } from "../../../Constents";
import LoadingGifs from "../../../images/YAAMS-gif.gif";

import { BiSearch } from "react-icons/bi";

import ReverseDateString from "../../Hooks/ReverseDateString";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineDownload } from "react-icons/ai";

const LossOfPayHistoryModal = ({ employeeDetails, hideModal }) => {
  const [lossOfPayOverview, setLossOfPayOverview] = useState();
  const [err, setErr] = useState();

  const fetchLossOfPayUserHistory = async () => {
    const url =
      API_URL + `/Loss-Of-Pay/?employee_id=${employeeDetails.employee}`;

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerlist = {
      Authorization: "Token " + userData.seucrity_token,
    };

    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headerlist,
      });
      const data = await res.json();
      console.log(data);
      setLossOfPayOverview(data);
    } catch (err) {
      setErr(err);
    }
  };

  useEffect(() => {
    fetchLossOfPayUserHistory();
  }, []);

  if (!lossOfPayOverview)
    return (
      <div className="loadingGif">
        <img src={LoadingGifs} alt="Loading Gif" />
      </div>
    );
  if (err) return <h1>Some error occured</h1>;

  const { SearchBar } = Search;

  const columns = [
    { dataField: "employee_name", text: "Name", sort: true },
    {
      dataField: "employee_email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "from_date",
      text: "From",
      sort: true,
      formatter: (row) => ReverseDateString(row),
    },
    {
      dataField: "to_date",
      text: "To",
      sort: true,
      formatter: (row) => ReverseDateString(row),
    },
    {
      dataField: "leave_name",
      text: "Type",
    },
    {
      dataField: "leave_count",
      text: "LoP",
      sort: true,
      formatter: (row) => {
        if (row <= 1) {
          return <p>{row} Day</p>;
        } else return <p>{row} Days</p>;
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: "End",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <>
        <Link
          onClick={handleClick}
          style={{ backgroundColor: "white", borderColor: "lightgray" }}
          className="btn btn-light "
        >
          Export Excel
          <AiOutlineDownload style={{ marginLeft: "5px", color: "red" }} />
        </Link>
      </>
    );
  };

  return (
    <div className="container">
      <ToolkitProvider
        keyField="id"
        bootstrap4
        data={lossOfPayOverview.data}
        columns={columns}
        ignoreHeader
        exportCSV={{
          fileName: `${employeeDetails.employee_name}_LoP.csv`,
        }}
        search
      >
        {(props) => (
          <React.Fragment>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h2 style={{ color: "#DD3944" }}>
                    {employeeDetails.employee_name}'s LoP History
                  </h2>
                </div>
                <div className="col-md-2">
                  <MyExportCSV {...props.csvProps} />
                </div>
                <div className="col-md-4 mb-4">
                  <div className="position-relative newsearch_box">
                    <SearchBar
                      className="newSearchWrapper"
                      placeholder="Search Here..."
                      srText=""
                      {...props.searchProps}
                    />
                    <BiSearch />
                  </div>
                </div>
              </div>

              <BootstrapTable
                pagination={pagination}
                filter={filterFactory()}
                // bordered={false}
                {...props.baseProps}
                condensed
                bordered={false}
                noDataIndication={() => {
                  return <h3>No data</h3>;
                }}
              />
            </div>
          </React.Fragment>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default LossOfPayHistoryModal;
