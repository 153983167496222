import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { API_URL } from "../../Constents";

import { useAlert } from "react-alert";

export default function AssignWFHToUser({ name, id }) {
  const [WFHChecked, setWFHChecked] = useState();
  const [currentStatusFromTheAPI, setCurrentStatusFromTheAPI] = useState({});
  const [getUpdatedStatus, setGetUpdatedStatus] = useState({});

  const alert = useAlert();

  const assignWFHHandler = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/assign-wfh/?user_id=" + id;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);
      setCurrentStatusFromTheAPI(data.data);
      setWFHChecked(data.data.wfh_status_for_admin);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  const updateWFHStatusHandler2 = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/assign-wfh/?user_id=" + id;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headersList,
      });

      const data = await response.json();
      setGetUpdatedStatus(data);
      console.log(data);
      if (data.status === true) alert.success(data.message);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  const updateWFHStatusHandler = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    try {
      const url = API_URL + "/assign-wfh/?wfh_id=" + currentStatusFromTheAPI.id;
      const response = await fetch(url, {
        method: "PATCH",
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);
      if (data.status === true) alert.success(data.message);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  useEffect(() => {
    assignWFHHandler();
  }, [id, getUpdatedStatus]);

  console.log(WFHChecked);

  return (
    <>
      <form onSubmit="">
        <div className="row align-items-center w-100 justify-content-between">
          <div className="col-md-4  mt-2">
            <h3 style={{ color: "red" }}>Assign WFH to {name}</h3>
          </div>
          <div className="col-md-4 col-lg- mt-4 mb-3">
            <Form>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Activate WFH"
                checked={WFHChecked}
                onChange={(e) => {
                  setWFHChecked(e.target.checked);
                  if (!e.target.checked) {
                    updateWFHStatusHandler();
                  }
                  if (e.target.checked) {
                    updateWFHStatusHandler2();
                  }
                }}
              />
            </Form>
          </div>
          <div className="col-md-4"></div>
        </div>
      </form>
    </>
  );
}
