import React, { useState, useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { BiSearch } from "react-icons/bi";
import { API_URL } from "../../../Constents";
import { useAlert } from "react-alert";
import AddEmployeeDropdown from "./AddEmployeeDropdown";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../../Constents";

export default function AddEmployeeForAppraisal() {
  const [refreshTable, setRefreshTable] = useState();
  const [allAppraisalsListForTable, setAllAppraisalsListForTable] = useState(
    []
  );
  const alert = useAlert();
  const history = useHistory();
  const fetchEmployeeListForAppraisals = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/add-employee-for-appraisal/";
    const response = await fetch(url, {
      method: "GET",
      headers: headersList,
    });

    const data = await response.json();
    setAllAppraisalsListForTable(data.data);
    console.log(data);
  };
  const addEmployeeForAppraisalHandler = async (emp_id) => {
    console.log(emp_id);
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const url = API_URL + "/admin-appraisal/";
    const bodyContent = JSON.stringify({
      employee_id: emp_id,
    });

    const response = await fetch(url, {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    });

    try {
      const data = await response.json();
      if (data.status) {
        alert.success(data.message);
        setRefreshTable(data);
        history.push("/Appraisals");
      }
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });
  const columns = [
    {
      dataField: "employee.full_name",
      text: "Name of Employee(s)",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        // console.log(row.employee);
        return row.employee.full_name;
        // if (row && row.employee.full_name === "") return "NA";
        // if (row && row.employee.full_name) return row.employee.full_name;
      },
    },

    {
      dataField: "employee.official_email",
      text: "Employee's Email",

      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log(row.employee.first_name);
        return row.employee.official_email;
      },
    },
    {
      dataField: "employee.reporting_to.full_name",
      text: "Name of Manager",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row && row.employee.reporting_to === null) return "NA";
        if (row && row.employee.reporting_to)
          return row.employee.reporting_to.full_name;
      },
    },
    {
      dataField: "employee.department_assigned.department_name",
      text: "Department",

      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row && row.employee.department_assigned === null) return "NA";
        if (row && row.employee.department_assigned)
          return row.employee.department_assigned.department_name;
      },
    },
    {
      dataField: "employee.role_assigned.role_name",
      text: "Role Assigned",

      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log(row.role_assigned);
        if (row && row.employee.role_assigned === null) return "NA";
        if (row && row.employee.role_assigned)
          return row.employee.role_assigned.role_name;
      },
    },
    {
      dataField: "",
      text: "Action",

      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log(row);
        return (
          <>
            <button
              className="btn btn-outline-danger"
              onClick={() => {
                addEmployeeForAppraisalHandler(row.employee.id);
              }}
              disabled={row.is_active}
            >
              Start Now
            </button>
          </>
        );
      },
    },
  ];
  const { SearchBar } = Search;
  useEffect(() => {
    fetchEmployeeListForAppraisals();
  }, [refreshTable]);
  console.log(allAppraisalsListForTable);
  return (
    <>
      <div className="maincontent">
        <div className="highlight">
          <h3 style={{ color: "#DD3974" }} className="mb-3">
            Employees for Appraisal Consideration
          </h3>
          <ToolkitProvider
            keyField="id"
            data={allAppraisalsListForTable}
            columns={columns}
            bootstrap4
            search
          >
            {(props) => (
              <React.Fragment>
                <div className="container p-0">
                  <div className="row">
                    <div className="col-md-3 mb-2">
                      <div className="position-relative newsearch_box">
                        <SearchBar
                          className="newSearchWrapper"
                          placeholder="Search.."
                          srText=""
                          {...props.searchProps}
                        />
                        <BiSearch className="searchIcon" />
                      </div>
                    </div>
                    <div className="col-md-9 mb-2">
                      <AddEmployeeDropdown refreshTable={setRefreshTable} />
                    </div>
                  </div>
                </div>
                <BootstrapTable
                  pagination={pagination}
                  filter={filterFactory()}
                  {...props.baseProps}
                  noDataIndication={() => {
                    return <h3>No data available </h3>;
                  }}
                  condensed
                  bordered={false}
                  tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  //   rowEvents={rowEvents}
                />
              </React.Fragment>
            )}
          </ToolkitProvider>
        </div>
      </div>
    </>
  );
}
