import React, { useState, useEffect } from "react";
import Drawer from "react-modern-drawer";
//import styles 👇
import "react-modern-drawer/dist/index.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
// import { BsSearch, BsChevronCompactDown } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
// import { AiOutlineFilePdf } from "react-icons/ai";
// Side Drawer

import LoadingGifs from "../../../images/YAAMS-gif.gif";
import { API_URL } from "../../../Constents";
import { AiOutlineFilePdf } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import AddEmployeetoFNF from "./AddEmployeetoFNF";
import FnFSideDrawer from "./FnFSideDrawer";

export default function FullAndFinal() {
  const [openStartFnfModal, setOpenFnfAppraisalModal] = useState({
    modalState: false,
  });
  const [allEmpFnfList, setAllEmpFnfList] = useState([]);
  const [sideDrawerIsOpen, setsideDrawerIsOpen] = useState(false);
  const [selectRowDataFromTable, setSelectRowDataFromTable] = useState({});
  const [refreshTable, setRefreshTable] = useState();
  const alert = useAlert();

  const closeFnfModal = () => {
    setOpenFnfAppraisalModal((prevState) => ({
      ...prevState,
      modalState: false,
    }));
  };
  const openFnfModal = () => {
    setOpenFnfAppraisalModal((prevState) => ({
      ...prevState,
      modalState: true,
    }));
  };
  const toggleSideDrawer = () => {
    setsideDrawerIsOpen((prevState) => !prevState);
  };
  const { SearchBar } = Search;
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });
  const columns = [
    {
      dataField: "candidate.full_name",
      text: "Name of Employee(s)",
      sort: true,
    },
    {
      dataField: "notice_period",
      text: "Notice Period",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            {row.notice_period === null ? (
              <p>NA</p>
            ) : (
              <p>{row.notice_period} days</p>
            )}
          </>
        );
      },
    },

    {
      dataField: "resigning_date",
      text: "Resigning Date",
      sort: true,
    },

    {
      dataField: "candidate.designation",
      text: "Designation",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            {row.candidate.designation === null ? (
              <p>-</p>
            ) : (
              <p>{row.candidate.designation}</p>
            )}
          </>
        );
      },
    },
    {
      dataField: "clearance.clearance_status",
      text: "No Dues",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        //let cleared = true;

        // if (row.clearance_department.length === 0) {
        //   cleared = false;
        // } else {
        //   row.clearance_department.map((i) => {
        //     return (cleared = cleared && i.clearance_status);
        //   });
        // }
        if (row.status);
        if (row.status === false) {
          return (
            <>
              <p
                className="text-danger"
                style={{ cursor: "pointer" }}
                // onClick={toggleSideDrawer}
                onClick={() => {
                  toggleSideDrawer();
                  setSelectRowDataFromTable(row);
                }}
              >
                Pending
              </p>
            </>
          );
        }
        if (row.status) {
          return (
            <>
              <p
                className="text-success"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  toggleSideDrawer();
                  setSelectRowDataFromTable(row);
                }}
              >
                Cleared
              </p>
            </>
          );
        }
      },
    },
    {
      dataField: "fullandfinal_status",
      text: "Actions",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log(row);
        return (
          <>
            <div className="d-flex  align-items-center ">
              {row.fullandfinal_status && row.fullandfinal_letter !== null ? (
                <>
                  <p
                    // className="text-success"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      toggleSideDrawer();
                      setSelectRowDataFromTable(row);
                    }}
                  >
                    Relieving Letter Issued
                  </p>
                </>
              ) : (
                <button
                  className="btn btn-outline-danger me-2"
                  onClick={() => relievingLetterHandler(row.id, "Relieving")}
                  disabled={row.status === false}
                >
                  Relieving Letter
                </button>
              )}

              {row.experience_status && row.fullandfinal_letter !== null ? (
                <>
                  {" "}
                  <p
                    className="ms-2"
                    // className="text-success"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      toggleSideDrawer();
                      setSelectRowDataFromTable(row);
                    }}
                  >
                    Experience Letter Issued
                  </p>
                </>
              ) : (
                <>
                  {" "}
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => relievingLetterHandler(row.id, "Experience")}
                    disabled={row.status === false}
                  >
                    Experience Letter
                  </button>
                </>
              )}
            </div>
          </>
        );
      },
    },
  ];
  const fnfEmployeeListHandler = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    try {
      const url = API_URL + "/clearance/";
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      setAllEmpFnfList(data.data);
      if (data.status) {
        // alert.success(data.message);
        console.log(data);
      }
      if (data.status === false) {
        alert.error(data.message);
      }
      console.log(data);
    } catch (err) {
      console.error("Internal Server Error");
    }
  };
  useEffect(() => {
    fnfEmployeeListHandler();
  }, [openStartFnfModal.modalState, refreshTable]);
  console.log(allEmpFnfList);

  const relievingLetterHandler = async (clearance_id, type) => {
    console.log(clearance_id);
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    let bodyContent = JSON.stringify({});

    if (type === "Relieving") {
      bodyContent = JSON.stringify({
        fullandfinal_status: true,
      });
    }
    if (type === "Experience") {
      bodyContent = JSON.stringify({
        experience_status: true,
      });
    }

    const url = API_URL + "/clearance/?clearance_id=" + clearance_id;
    try {
      const response = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);
      if (data.status) {
        alert.success(data.message);
        setRefreshTable(data);
      }
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  console.log(allEmpFnfList);

  return (
    <>
      <div className="maincontent">
        <div className="highlight">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 className=" mb-2" style={{ color: "#000" }}>
              Full & Final(FnF)
            </h2>
            <button
              className="departmentSettingBtn formSelectPlus w-25"
              onClick={() => {
                openFnfModal();
              }}
              style={{ textDecoration: "none" }}
            >
              Add Employee
            </button>
          </div>
          <div className="mt-3">
            <ToolkitProvider
              keyField="id"
              data={allEmpFnfList}
              columns={columns}
              bootstrap4
              search
            >
              {(props) => (
                <React.Fragment>
                  <div className="container p-0">
                    <div className="row">
                      <div className="col-md-3 mb-2">
                        <div className="position-relative newsearch_box">
                          <SearchBar
                            className="newSearchWrapper"
                            placeholder="Search.."
                            srText=""
                            {...props.searchProps}
                          />
                          <BiSearch className="searchIcon" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <BootstrapTable
                    pagination={pagination}
                    filter={filterFactory()}
                    {...props.baseProps}
                    noDataIndication={() => {
                      return <h3>No data available </h3>;
                    }}
                    condensed
                    bordered={false}
                    tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    // rowEvents={rowEvents}
                  />
                </React.Fragment>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
      <Modal
        show={openStartFnfModal.modalState}
        onHide={closeFnfModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <AddEmployeetoFNF closeFnfModal={closeFnfModal} />
        </Modal.Body>
      </Modal>
      <Drawer
        open={sideDrawerIsOpen}
        onClose={toggleSideDrawer}
        direction="right"
        className="rightSideDrawer"
        size={"350px"}
        lockBackgroundScroll={true}
        style={{ overflowY: "scroll" }}
      >
        <FnFSideDrawer
          selectedRowData={selectRowDataFromTable}
          toggleSideDrawer={toggleSideDrawer}
          refreshTable={setRefreshTable}
        />
      </Drawer>
    </>
  );
}
