import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import moment from "moment";

import { AiFillFilePdf } from "react-icons/ai";
import { FcExpired, FcAddDatabase } from "react-icons/fc";

import { API_URL } from "../../Constents";

const AllAttendancePDFDownloads = () => {
  const [reportsData, setReportsData] = useState([]);

  const alert = useAlert();

  const fetchDownloadsData = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/fetch-download-file-link/";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      setReportsData(data.data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  useEffect(() => {
    fetchDownloadsData();
  }, []);

  return (
    <div className="maincontent">
      <div className="highlight all_form_spacing">
        <h3>YAAMS Downloads</h3>
        <table className="table mt-3">
          <thead>
            <tr>
              <th scope="col">S.No.</th>
              <th scope="col">Type</th>

              <th scope="col">Created On</th>

              <th scope="col">Expire On</th>
              {/* <th scope="col">By</th> */}
              <th scope="col">PDFs</th>
            </tr>
          </thead>
          {reportsData.length <=  0 ? (
            <td colspan="5" className="text-center display-6">***No Data***</td>
          ) : (
            <tbody>
              {reportsData.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <th scope="row">{index + 1}</th>
                    <td>{data.type}</td>

                    <td>
                      <FcAddDatabase size={25} />{" "}
                      {" " + moment(data.created_at).format("Do MMM, h:mm a")}
                    </td>

                    <td>
                      <FcExpired size={25} />{" "}
                      {" " + moment(data.expire_on).format("Do MMM, h:mm a")}
                    </td>
                    {/* <td>{data.by}</td> */}
                    <td>
                      <a
                        href={data.download_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <AiFillFilePdf color="red" size={25} />
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default AllAttendancePDFDownloads;
