import { useState } from "react";
import { API_URL } from "../../Constents";

const UpdateAttendance = (props) => {
  console.log(props.time);
  const [value, setSelectedValue] = useState(props.time);

  const id = props.id;
  console.log(id);
  console.log(props.toggle);
  console.log(value);

  var apiDateTime = props.date + " " + value;
  console.log(apiDateTime);

  if (props.toggle === true) {
    (() => {
      var myHeaders = new Headers();
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      myHeaders.append("Authorization", "Token " + userData.seucrity_token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        check_in: apiDateTime,
      });

      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(API_URL + "/update-attendance/?attendance_id=" + id, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          props.setToggle();
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    })();
  }

  return (
    <div className="container" style={{ textAlign: "center" }}>
      <h3>Update Check-In Time</h3>
      <input
        type="time"
        value={value}
        className="mt-4 inputTime"
        style={{ width: "240px", border: "none" }}
        onChange={(e) => {
          setSelectedValue(e.target.value);
        }}
      />
      {/* <DateTimePicker
        setSelectedValue={setSelectedValue}
        value={value}
        className="form-control"
      /> */}
    </div>
  );
};

export default UpdateAttendance;
