import { useState, useEffect } from "react";
import { API_URL } from "../../Constents";
import AssignWFHToUser from "./AssignWFHToUser";
import CreateLeaveCategory from "../Leaves/CreateLeaveCategory";
import CustomCheckinTimeForEmployee from "./CustomCheckinTimeForEmployee";
import OrgCheckinTimeForEmployee from "./OrgCheckinTimeForEmployee";
import DeptCheckinTimeForEmp from "./DeptCheckinTimeForEmp";
import { useAlert } from "react-alert";
const EmployeeCheckInSetting = (props) => {
  const [selectedCheckinSetting, setSelectedCheckinSetting] = useState("");
  const [checkinCheckoutInfo, setCheckinCheckoutInfo] = useState({});
  const alert = useAlert();
  const id = window.location.href.split("/").reverse()[0];

  console.log(
    "...........................................................................",
    selectedCheckinSetting
  );
  const changeEmployeeCheckinTime = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const url = API_URL + "/active-setting/?id=" + id;
    const bodyContent = JSON.stringify({
      active_setting: selectedCheckinSetting,
    });
    console.log(bodyContent + "body");
    try {
      const response = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);
      if (data.status) {
        // setSelectedCheckinSetting(Object.keys(data.data.active_setting)[0]);
        alert.success(data.message);
      }
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  // APi call for checkinTime of Employee
  const checkInAndCheckOutTimeHandler = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const url = API_URL + "/active-setting/?id=" + id;
    const response = await fetch(url, {
      method: "GET",
      headers: headersList,
    });

    try {
      const data = await response.json();
      console.log(data.data);
      if (data.data && data.data.active_setting) {
        setSelectedCheckinSetting(Object.keys(data.data.active_setting)[0]);
      }
      setCheckinCheckoutInfo(data.data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  useEffect(() => {
    checkInAndCheckOutTimeHandler();
  }, []);

  return (
    <>
      <div className="highlight all_form_spacing">
        <AssignWFHToUser name={props.name} id={id} />
        <CreateLeaveCategory name={props.name} leaverule={props.leaverule} />
        <hr />

        <div className="my-3 ">
          <h3 style={{ color: "#DD3974" }}>{props.name}'s</h3>
          <input
            type="radio"
            id="org_checkin_time"
            name="checkintime"
            value="OrgTime"
            className="me-2"
            checked={selectedCheckinSetting === "org"}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedCheckinSetting((prevState) => "org");
              }
            }}
          />
          <label for="org_checkin_time"> Organisation</label>
          <input
            type="radio"
            id="dept_checkin_time"
            name="checkintime"
            value="DeptTime"
            className="me-2 ms-3"
            checked={selectedCheckinSetting === "department"}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedCheckinSetting((prevState) => "department");
              }
            }}
          />
          <label for="dept_checkin_time">Department</label>

          <input
            type="radio"
            id="custom_checkin_time"
            name="checkintime"
            value="customCheckin"
            className="me-2 ms-3"
            checked={selectedCheckinSetting === "candidate"}
            onChange={(e) => {
              if (e.target.checked)
                setSelectedCheckinSetting((prevState) => "candidate");
            }}
          />
          <label for="custom_checkin_time">set Custom CheckIn Time</label>
        </div>
        {selectedCheckinSetting === "org" && (
          <OrgCheckinTimeForEmployee
            orgCheckInAndCheckOutTime={checkinCheckoutInfo}
            changeEmployeeCheckinTime={changeEmployeeCheckinTime}
          />
        )}
        {selectedCheckinSetting === "department" && (
          <DeptCheckinTimeForEmp
            deptCheckInAndCheckOutTime={checkinCheckoutInfo}
            changeEmployeeCheckinTime={changeEmployeeCheckinTime}
          />
        )}
        {selectedCheckinSetting === "candidate" && (
          <CustomCheckinTimeForEmployee
            email={props.email}
            name={props.name}
            changeEmployeeCheckinTime={changeEmployeeCheckinTime}
          />
        )}
      </div>
    </>
  );
};

export default EmployeeCheckInSetting;
