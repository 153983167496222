import React, { useState, useEffect } from "react";
import noInternetGIF from "../../images/no_internet.gif";

const NoInternetConnectionHandler = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <div className="maincontent">
        <div className="row highlight all_form_spacing">
          <div className="col-md-6  align-self-center">
            <img
              src={noInternetGIF}
              alt="YAAMS logo"
              height="500px"
              width="500px"
              className="img-fluid mb-3"
            />
          </div>
          <div className="mt-1 mb-5 col-md-6 align-self-center text-center">
            <h1 className="display-1 fw-bold">No Internet</h1>
            <h5 style={{ color: "red" }}>
              Please check connection and reload{" "}
            </h5>
          </div>
        </div>
      </div>
    );
  }
};

export default NoInternetConnectionHandler;
