//import { useState } from "react";

import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";
import UploadedDocList from "./UploadedDocList";

const UserAddDoc = (props) => {
  const [details, setDetails] = useState({
    docID: null,
    lable: null,
    file: null,
    path: null,
  });
  const [docType, setDocType] = useState([]);
  const [uploadRes, setUploadRes] = useState({});
  const id = window.location.href.split("/").reverse()[0];
  const [isToggle, setIstoggle] = useState(false);

  const postDocuments = async (event) => {
    event.preventDefault();
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const bodyContent = new FormData();
    bodyContent.append("document_file", details.file, details.path);
    bodyContent.append("document_name", details.lable);
    // bodyContent.append("document_name", details.lable);
    // bodyContent.append("document_file", details.path);

    const url =
      API_URL +
      "/document-request-view/?document_choice=" +
      details.docID +
      "&user_id=" +
      id;
   

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: headersList,
        body: bodyContent,
        redirect: "follow",
      });
      const data = await res.json();
      setUploadRes(data);
      setIstoggle(!isToggle);
    } catch (err) {
      console.error(err);
    }
  };

 

  const fetchDocTypeList = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/document-choice-list";

    try {
      const res = await fetch(url, { method: "GET", headers: headersList });
      const data = await res.json();
      setDocType(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDocTypeList();
  }, []);

  const dyList = docType.map((docTy) => (
    <option key={docTy.id} value={docTy.id}>
      {docTy.document_name}
    </option>
  ));

  return (
    <div className="container">
      <form
        onSubmit={(event) => {
          postDocuments(event);
        }}
        className="highlight all_form_spacing"
        method="POST"
        name="contact-form"
      >
        <h3 style={{ color: "red" }}>Upload {props.name}'s Documents</h3>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label htmlFor="designition">Select Type</label>
            <select
              className="form-select form-control"
              id="inputGroupSelect02"
              onChange={(e) => {
                setDetails({ ...details, docID: e.target.value });
              }}
            >
              <option value="none" selected disabled hidden>
                Select Document Type
              </option>
              {dyList}
            </select>
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="docType">Enter Document Name</label>
            <input
              type="text"
              id="docType"
              placeholder="Enter Document Name"
              name="lable"
              onChange={(e) => {
                setDetails({ ...details, lable: e.target.value });
              }}
              className="form-control"
              required
            />
          </div>
          <div className="col-md-4 mb-3">
            <label htmlFor="docType">Select File</label>
            <input
              type="file"
              id="docType"
              placeholder="Select File"
              name="lable"
              onChange={(data) => {
                setDetails({
                  ...details,
                  path: data.target.value,
                  file: data.target.files[0],
                });
              }}
              className="form-control"
              required
            />
          </div>
        </div>
        {/* <button type="submit" className="btn btn-primary">
          Submit
        </button> */}

        <div className="col-md-3 ">
          <button type="submit" className="departmentBtnSecondary">
            Submit
          </button>
        </div>
        {
          (uploadRes.status = true ? (
            <p>{uploadRes.message}</p>
          ) : (
            <p>{uploadRes.message}</p>
          ))
        }
      </form>
      <UploadedDocList isToggle={isToggle} />
    </div>
  );
};

export default UserAddDoc;
