import { Link } from "react-router-dom";
import { BASE_URL } from "../../Constents";
import logo from "../../images/YAAMS-gif.gif";
import { IoIosArrowBack } from "react-icons/io";

const NotFoundPage = () => {
  return (
    <div className="maincontent">
      <div className="row highlight all_form_spacing">
        <div className="col-md-6  align-self-center">
          <img
            src={logo}
            alt="YAAMS logo"
            height="500px"
            width="500px"
            className=" img-fluid"
          />
        </div>
        <div className=" mt-5 col-md-6 align-self-center text-center">
          <h1 className="display-1 fw-bold">404</h1>
          <p className="fs-3">
            {" "}
            <span className="text-danger">Opps!</span> Page not found.
          </p>
          <p className="lead">The page you’re looking for doesn't exist</p>
          <Link
            to={BASE_URL}
            className="departmentBtnSecondary"
            style={{ color: "white", textDecoration: "none" }}
          >
            <IoIosArrowBack /> Go Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
