import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useAlert } from "react-alert";

import { BsEye, BsEyeSlash } from "react-icons/bs";
import { AiOutlineUser, AiOutlineLock } from "react-icons/ai";

import { BASE_URL, APP_VERSION_NUMBER } from "../Constents";
import logoImg from "../images/logo.png";

import VersionModal from "./BeforeLogin/VersionModal";
import "./login.css";

export default function Login({ login }) {
  const [details, setDetails] = useState({ email: "", password: "" });
  const [eyeToggle, setEyeToggle] = useState(false); // State for eye button toggle
  const [pasteMsg, setPasteMsg] = useState(""); // For restricting copy paste on the field
  // Modal toggle state
  const [show, setShow] = useState(false);

  const alert = useAlert();

  const submitHandler = (event) => {
    event.preventDefault();
    login(details); // Pushing the login state up
  };

  if (!navigator.onLine) {
    alert.error("You're offline");
  }
  return (
    <>
      <div className="row loginContainer">
        <div className="col-md-8">
          <div className="bannerImage d-fle" />
        </div>
        <div className="col-md-4 loginHighlight  loginLeftContainer position-relative">
          <div className="versionYAAMS">
            <button
              className="btn btn-light versionYAAMS"
              onClick={() => setShow(true)}
            >
              {APP_VERSION_NUMBER}
            </button>
          </div>
          <div className="logo">
            <img src={logoImg} alt="YAAMS Logo" />
          </div>
          <form autoComplete onSubmit={submitHandler} className="logform">
            <div className=" loginWrapper">
              <h3 style={{ color: "red" }}>Login</h3>
              <div className="position-relative">
                <input
                  className="loginNewSearch"
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  onChange={(e) =>
                    setDetails({ ...details, email: e.target.value })
                  }
                  value={details.email}
                  required
                />

                <AiOutlineUser className="position-absolute userIcon" />
              </div>
              <div className="position-relative mt-2">
                <input
                  required
                  className="loginNewSearch"
                  type={eyeToggle === false ? "password" : "text"}
                  name="password"
                  placeholder={pasteMsg === "" ? "Enter Password" : pasteMsg}
                  onChange={(e) =>
                    setDetails({ ...details, password: e.target.value })
                  }
                  onPaste={(e) => {
                    // this event prevents copy paste
                    e.preventDefault();
                    setPasteMsg("Paste is not allowed (Enter Password)");
                    return false;
                  }}
                />

                <AiOutlineLock className="position-absolute userIcon" />
                {eyeToggle === false ? (
                  <BsEyeSlash
                    onClick={() => setEyeToggle(!eyeToggle)}
                    className="eyeIcon"
                  />
                ) : (
                  <BsEye
                    onClick={() => setEyeToggle(!eyeToggle)}
                    className="eyeIcon"
                  />
                )}

                {/* <BsEye />  */}
              </div>
              <div
                className="position-relative mt-2"
                style={{ textAlign: "right", width: "90%" }}
              >
                <Link
                  to={BASE_URL + "ForgetPassword"}
                  style={{ textDecoration: "none" }}
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="mt-2">
                <button className="loginButton">LOGIN</button>
              </div>
            </div>
          </form>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-60w"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>What's New?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <VersionModal />{" "}
              {/* Version modal on login page must be updated before release */}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}
