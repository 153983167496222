import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";

import { API_URL } from "../../../../Constents";
import ReverseDateString from "../../../Hooks/ReverseDateString";

import { BiSearch } from "react-icons/bi";
import { AiOutlineFilePdf } from "react-icons/ai";

import LoadingGifs from "../../../../images/YAAMS-gif.gif";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import moment from "moment";
// Side Drawer
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import VacancySideDrawer from "./VacancySideDrawer";
import VacancySideDrawerExpired from "./VacancySideDrawerExpired";

const ExpiredVacancyList = (props) => {
  const [allJobsListForTable, setAllJobsListForTable] = useState([]);
  const [sideDrawerIsOpen, setsideDrawerIsOpen] = useState(false);
  const [selectRowDataFromTable, setSelectRowDataFromTable] = useState({});
  const [isloading, setisloading] = useState(true);

  const alert = useAlert();
  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "vacancy_id",
      text: "Job ID",
      sort: true,
    },
    { dataField: "title", text: "Job Title", sort: true },
    {
      dataField: "vacancy_start_date",
      text: "Start Date",
      sort: true,
      formatter: (row) => {
        return ReverseDateString(row);
      },
    },

    {
      dataField: "vacancy_end_date",
      text: "End Date",
      sort: true,
      formatter: (row) => {
        return ReverseDateString(row);
      },
    },
    {
      dataField: "attachment",
      text: "JD",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <AiOutlineFilePdf
            color="red"
            size={22}
            onClick={() => window.open(row.attachment)}
          />
        );
      },
    },
    {
      dataField: "created_by",
      text: "Created By",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <div>
              <p className="m-0 p-0">{row.created_by.full_name}</p>
              <p className="m-0 p-0">{moment(row.created_at).format("lll")}</p>
            </div>
          </>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  const fetchAllJobs = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/admin-vacancy-crud/?is_active_status=false";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      setAllJobsListForTable(data.data);
      setisloading(false);
    } catch (err) {
      console.error(err);
      alert.error("There is an error in fetching the vacancy list");
      setisloading(true);
    }
  };

  useEffect(() => {
    fetchAllJobs();
  }, [
    props.openEditVacancyModal,
    props.openDuplicateVacancyModal,
    props.openExpireVacancyModal,
    props.openReopenVacancyModal,
    props.openExtendVacancyModal,
  ]);

  console.log(allJobsListForTable);
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log("Hello");
      console.log(row);
      toggleSideDrawer();
      setSelectRowDataFromTable(row);
      props.dataFromAllChiled(row);
    },
  };
  const toggleSideDrawer = () => {
    setsideDrawerIsOpen((prevState) => !prevState);
  };
  return (
    <div>
      {isloading ? (
        <div className="loadingGif">
          <img src={LoadingGifs} alt="Loading Gif" />
        </div>
      ) : (
        <ToolkitProvider
          keyField="vacancy_id"
          data={allJobsListForTable}
          columns={columns}
          bootstrap4
          search
        >
          {(props) => (
            <React.Fragment>
              <div className="container p-0">
                <div className="col-md-3 mb-2">
                  <div className="position-relative newsearch_box">
                    <SearchBar
                      className="newSearchWrapper"
                      placeholder="Search.."
                      srText=""
                      {...props.searchProps}
                    />
                    <BiSearch className="searchIcon" />
                  </div>
                </div>
              </div>
              <BootstrapTable
                pagination={pagination}
                filter={filterFactory()}
                {...props.baseProps}
                noDataIndication={() => {
                  return <h3>No data available </h3>;
                }}
                condensed
                bordered={false}
                tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                rowEvents={rowEvents}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      )}

      <Drawer
        open={sideDrawerIsOpen}
        onClose={toggleSideDrawer}
        direction="right"
        className="rightSideDrawer"
        size={"370px"}
      >
        <VacancySideDrawerExpired
          selectedRowData={selectRowDataFromTable}
          openDuplicateVacancyModal={props.openDuplicateVacancyModal}
          openReopenVacancyModal={props.openReopenVacancyModal}
        />
      </Drawer>
    </div>
  );
};

export default ExpiredVacancyList;
