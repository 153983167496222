import { useState, useEffect } from "react";
import { API_URL } from "../../../Constents";
import { useAlert } from "react-alert";
export default function RemarksForFnFEmp() {
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [remark, setRemark] = useState({
    token: "",
    clearance_status: true,
    remark: "",
  });
  const alert = useAlert();
  const token = window.location.href.split("/").reverse()[0];
  console.log(token);
  const fetchEmployeeDetails = async () => {
    //const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      "Content-Type": "application/json",
    };
    const url = API_URL + "/department-candidate-details/?token=" + token;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      setEmployeeDetails(data.data);
      console.log(data);
    } catch (err) {
      console.error(err);
    }
  };
  const submitRemarkForEmployeeHandler = async (e) => {
    e.preventDefault();
    // const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      "Content-Type": "application/json",
    };
    const url = API_URL + "/department-clearance/";
    const bodyContent = JSON.stringify({
      token: token,
      clearance_status: remark.clearance_status,
      remark: remark.remark,
    });
    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      let data = await response.json();
      if (data.status) {
        alert.success(data.message);
      }
      if (data.status === false) {
        alert.error(data.message);
      }
      console.log(data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  useEffect(() => {
    fetchEmployeeDetails();
  }, []);
  console.log(employeeDetails);
  return (
    <div className="container mt-5" >
      <div className="candidateMainContent">
        <div className="candidateHighlight">
          <h3 className="text-center text-danger">
            Remarks for{" "}
            {employeeDetails && employeeDetails.candidate ? (
              <>{employeeDetails.candidate.full_name}</>
            ) : (
              "-"
            )}{" "}
          </h3>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="d-flex">
                <label className="">Email : </label>
                {employeeDetails && employeeDetails.candidate ? (
                  <p>{employeeDetails.candidate.official_email}</p>
                ) : (
                  <p className="w-100">"No Name"</p>
                )}
              </div>
              <div className="d-flex">
                <label className="">Department : </label>
                {employeeDetails && employeeDetails.department ? (
                  <p>{employeeDetails.department.department_name}</p>
                ) : (
                  <p className="w-100">"No Name"</p>
                )}
              </div>
              <form action="" className="d-flex" onSubmit={submitRemarkForEmployeeHandler}>
                <label className="">Remark : </label>
                <div className="w-100">
                <textarea
                  className="form-control"
                  onChange={(e) => {
                    setRemark((prevState) => ({
                      ...prevState,
                      remark: e.target.value,
                    }));
                  }}
                  placeholder="Enter Remarks Here..."
                  required
                  cols={"10"}
                  rows={"10"}
                />
                <div className="text-center">
                  <button className="departmentBtn mt-4" type="submit">
                    Submit
                  </button>
                </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
