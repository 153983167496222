// Table import
import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import React from "react";
import moment from "moment";
import { useAlert } from "react-alert";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { API_URL } from "../../Constents";
//import ModalEmpoyeeDetails from "../Modals/ModalEmployeeMoreDetails";
import ModalEmpoyeeDetails from "../Modals/ModalEmployeeDetails";
// import { useInterval } from "../Hooks/useIntervel";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import LoadingGifs from "../../images/YAAMS-gif.gif";
const CompOffLeaves = () => {
  const [leaveData, setLeaveData] = useState();
  const [leaveName, setLeaveName] = useState();
  const [err, setErr] = useState();
  const [leaveId, setLeaveId] = useState();
  const [show, setShow] = useState(false);
  const [showEmp, setShowEmp] = useState(false);
  const [details, setDetails] = useState({
    startDate: "",
    endDate: "",
    status: "pending",
  });
  const [approveStatus, setApproveStatus] = useState(null);
  const handleCloseEmp = () => setShowEmp(false);
  const handleShowEmp = () => setShowEmp(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalID, setModalId] = useState("");

  const today = new Date();
  const todayDate = moment(today).format("YYYY-MM-DD");
  const dateLast =
    details.startDate && details.endDate === "" ? todayDate : details.endDate;
  const dateStart =
    details.startDate === "" && details.endDate !== ""
      ? todayDate
      : details.startDate;

  const alert = useAlert();

  //const { reload } = props;

  const fetchLeaveData = async () => {
    const url =
      API_URL +
      "/request-for-compoff/?start_date=" +
      dateStart +
      "&last_date=" +
      dateLast +
      "&comp_off_status=" +
      details.status;
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headerlist = {
      Authorization: "Token " + userData.seucrity_token,
    };
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headerlist,
      });
      const data = await res.json();
      console.log(data);
      setLeaveData(data);
    } catch (err) {
      setErr(err);
    }
  };

  const rejectLeave = async () => {
    const url = API_URL + "/request-for-compoff/?compoff_id=" + leaveId;
    console.log(url);

    const bodyContent = JSON.stringify({ request_status: "rejected" });

    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    try {
      const res = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });
      const data = await res.json();
      console.log(data);
      //props.setReload(data);
      setApproveStatus(data);
      handleClose();
    } catch (err) {
      console.log(err);
      alert.error("Internal Server Error");
    }
  };

  const approveLeave = async () => {
    const url = API_URL + "/request-for-compoff/?compoff_id=" + leaveId;

    const bodyContent = JSON.stringify({ request_status: "approved" });
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    try {
      const res = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });
      const data = await res.json();

      setApproveStatus(data);
      //props.setReload(data);
      handleClose();
    } catch (err) {
      console.log(err);
      alert.error("Internal Server Error");
    }
  };

  // useInterval(() => {
  //   fetchLeaveData();
  // }, 1000 * 10);

  useEffect(() => {
    fetchLeaveData();
  }, [show, details.startDate, details.endDate, details.status]);

  // console.log(leaveData);
  // console.log(modalID);
  // console.log(details);
  if (!leaveData)
    return (
      <div className="loadingGif">
        <img src={LoadingGifs} alt="Loading Gif" />
      </div>
    );
  if (err) return <h1>Some error occured</h1>;

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "raised_by.first_name",
      text: "Name",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            <div className="row align-items-center">
              <Button
                variant="link"
                style={{ color: "#3E3E3E", textDecoration: "none" }}
                onClick={() => {
                  setModalId(row.raised_by.id);
                  handleShowEmp();
                }}
              >
                {row.raised_by.first_name}
                <BsBoxArrowUpRight
                  style={{
                    fontSize: "10px",
                    marginLeft: "5px",
                    // marginBottom: "10px",
                    color: "#3E3E3E",
                    fontWeight: "600",
                  }}
                />
              </Button>
            </div>
          </>
        );
      },
    },
    {
      dataField: "request_status",
      text: "Status",
      sort: true,

      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.request_status === "approved")
          return <p style={{ color: "green" }}>Approved</p>;
        else if (row.request_status === "rejected")
          return <p style={{ color: "red" }}>Rejected</p>;
        else
          return (
        <>
        <div className="row align-items-center">
        <Button
              variant="link"
              style={{ color: "blue", textDecoration: "none" }}
              onClick={() => {
                handleShow();

                setLeaveId(row.id);
                setLeaveName(row.raised_by.first_name);
              }}
            >
              {/* {row.status} */}
              Pending
              <BsBoxArrowUpRight
                style={{
                  fontSize: "10px",
                  marginLeft: "5px",
                  // marginBottom: "10px",
                  color: "#3E3E3E",
                  fontWeight: "600",
                }}
              />
            </Button>
        </div>
        </>
          );
      },
    },
    { dataField: "raised_on", text: "Raised On", sort: true },

    { dataField: "from_date", text: "from", sort: true },
    { dataField: "to_date", text: "to", sort: true },
    // {
    //   dataField: "shift",
    //   text: "Half/Full",
    //   sort: true,
    //   formatter: (cell, row, rowIndex, formatExtraData) => {
    //     if (row.shift === "") return "Full Day";
    //     else return "Half Day";
    //   },
    // },

    // { dataField: "paid_unpaid_quantity", text: "Number of Leaves", sort: true },
    { dataField: "reason", text: "Reason", sort: true },
    // { dataField: "remaining_leave", text: "Remaining", sort: true }, //filter: textFilter() },
    // { dataField: "type", text: "Type", sort: true },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: "End",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  return (
    <div className="maincontent">
      <div className="highlight all_form_spacing">
        {approveStatus &&
        approveStatus.status === true &&
        approveStatus.status !== undefined ? (
          <div className="alert alert-success" role="alert">
            {approveStatus.message}
          </div>
        ) : approveStatus &&
          approveStatus.status === false &&
          approveStatus.status !== undefined ? (
          <div className="alert alert-danger" role="alert">
            {approveStatus.message}
          </div>
        ) : (
          ""
        )}
        <h2 style={{ color: "#DD3944" }}>Compensatory Leave</h2>
        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={leaveData.data}
          columns={columns}
          ignoreHeader
          exportCSV={{
            fileName: "Leave.csv",
          }}
          search
        >
          {(props) => (
            <React.Fragment>
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    {/* <SearchBar
                      srText="Search Table"
                      {...props.searchProps}
                      className="newSearch"
                      style={{ border: "none" }}
                    /> */}
                    <div className="position-relative newsearch_box mt-4">
                      <SearchBar
                        className="newSearchWrapper"
                        placeholder="Search Here..."
                        srText=""
                        {...props.searchProps}
                      />
                      <BiSearch />
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="dob">Start Date</label>
                    <input
                      className="form-select form-control"
                      type="date"
                      id="StartDate"
                      name="StartDate"
                      value={details.startDate}
                      onChange={(e) => {
                        setDetails({ ...details, startDate: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="dob">End Date</label>
                    <input
                      className="form-select form-control"
                      type="date"
                      id="birthday"
                      name="birthday"
                      value={details.endDate}
                      onChange={(e) => {
                        setDetails({ ...details, endDate: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="designition">Select Status</label>
                    <select
                      className="form-select form-control"
                      id="inputGroupSelect02"
                      onChange={(e) => {
                        setDetails({ ...details, status: e.target.value });
                      }}
                    >
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                      <option value="">All</option>
                    </select>
                  </div>
                </div>

                <BootstrapTable
                  pagination={pagination}
                  filter={filterFactory()}
                  {...props.baseProps}
                  striped
                  hover
                  bordered={false}
                  noDataIndication={() => {
                    return <h3>No data available for this filter</h3>;
                  }}
                  condensed
                />
              </div>
            </React.Fragment>
          )}
        </ToolkitProvider>
        <Modal size="xl" show={showEmp} onHide={handleCloseEmp}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <ModalEmpoyeeDetails id={modalID} />
          </Modal.Body>
        </Modal>

        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>CompOff Approve </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center mb-3">
              <h2>
                Take Action on <i>{leaveName}</i>'s CompOff Leave ?
              </h2>
            </div>
            <div className="d-flex justify-content-center mb-3">
              <button
                className="btn btn-success btn-lg me-3"
                onClick={approveLeave}
              >
                Approve
              </button>
              <button className="btn btn-danger btn-lg" onClick={rejectLeave}>
                Reject
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default CompOffLeaves;
