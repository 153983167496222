import React from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import UpcomingInterviews from "./UpcomingInterviews";
export default function RecruitmentNoti() {
  return (
    <>
      <h2 className="ms-4 mb-4" style={{ color: "#DD3974" }}>
        Notifications
      </h2>

      <div className="highlight">
        {/* <h6 style={{ color: "#C4C4C4" }} className="mb-2">
            Upcoming Joinings <BsBoxArrowUpRight color="#DD3974" size={"12"} />
          </h6>
          <h5 className="m-0">John Doe</h5>
          <div className="d-flex justify-content-between ">
            <p className="m-0 " style={{ color: "#DD3974", fontWeight: "100" }}>
              Software Developer
            </p>
            <p className="m-0 " style={{ color: "#C4C4C4" }}>
              09/09/23
            </p>
          </div>

          <h5 className="m-0 mt-2">Jane Foster</h5>
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0 ">Content Writer</p>
            <p className="m-0 ">09/09/23</p>
          </div>
          <h6 style={{ color: "#C4C4C4" }} className="mb-2 mt-4">
            Generate Offer Letter{" "}
            <BsBoxArrowUpRight color="#DD3974" size={"12"} />
          </h6>
          <h5 className="m-0">John Doe</h5>
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0 " style={{ color: "#DD3974", fontWeight: "100" }}>
              Software Developer
            </p>
            <button className="btn btn-outline-danger btn-sm" style={{}}>
              Generate
            </button>
          </div>
          <h5 className="m-0">John Doe</h5>
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0 " style={{ color: "#DD3974", fontWeight: "100" }}>
              Software Developer
            </p>
            <button className="btn btn-outline-danger btn-sm" >
              Generate
            </button>
          </div> */}
        <UpcomingInterviews />
      </div>
    </>
  );
}
