import React, { useEffect, useState } from "react";
import AddEmployeeDropdown from "../Appraisals/AddEmployeeDropdown";
import { MultiSelect } from "react-multi-select-component";
import { API_URL } from "../../../Constents";
import { useAlert } from "react-alert";
export default function AddEmployeetoFNF(props) {
  const [employee, setEmployee] = useState([]);

  const [departmentList, setDepartmentList] = useState([]);
  const [departmentMultiSelectList, setDepartmentMultiSelectList] = useState(
    []
  );
  const [fnfDetails, setFnfDetails] = useState({
    resigning_date: "",
    notice_period: 0,
    candidate: null,
  });

  const alert = useAlert();

  const fetchEmployeeList = () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      department_array: [],
    });

    const requestOptions = {
      method: "POST",
      headers: headerList,
      redirect: "follow",
      body: bodyContent,
    };

    fetch(API_URL + "/employee-list-dashboard/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setEmployee(result.data);
      })
      .catch((error) => console.error(error));
  };

  //   Deaprtment Fetch Section

  const formattedDept = [];
  departmentList.map((list) => {
    return formattedDept.push({
      label: list.department_name + " (" + list.department_id + ")",
      value: list.id,
    });
  });
  const fetchDepartments = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/department-list";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setDepartmentList(data.data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  const initiateEmployeeFnf = async (e) => {
    e.preventDefault();

    if (departmentMultiSelectList.length === 0)
      return alert.info("Please select atlease one department");

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const formattedDeptArray = [];
    departmentMultiSelectList.map((list) => {
      return formattedDeptArray.push(list.value);
    });

    const bodyContent = JSON.stringify({
      resigning_date: fnfDetails.resigning_date,
      notice_period: +fnfDetails.notice_period,
      candidate: +fnfDetails.candidate,
      clearance_department: formattedDeptArray,
    });
    console.log(bodyContent);
    const url = API_URL + "/clearance/";
    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headerList,
      });

      const data = await response.json();
      console.log(data);
      // setFnfDetails(data.data);
      if (data.status) {
        props.closeFnfModal();
        alert.success(data.message);
      }
      if (data.status === false) {
        if (data && data.error && data.error.candidate) {
          // alert.error(data.error.candidate[0]);
          let errorMessages = "";

          for (let key in data.error) {
            console.log(key);
            if (data.error.hasOwnProperty(key)) {
              errorMessages += `${data.error[key][0]}\n`;
            }
            alert.error(errorMessages);
          }
        } else {
          alert.error(data.message);
        }
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
    // console.log(data);
  };
  // Department Fetch Section Ends
  useEffect(() => {
    fetchEmployeeList();
    fetchDepartments();
    // selectEmployeeFromDropdownForTable();
  }, []);
  console.log(fnfDetails);
  console.log(departmentMultiSelectList);

  return (
    <>
      <h4 className=" mx-2 mb-3" style={{ color: "#dd3974" }}>
        Add Employee to FNF
      </h4>
      <hr />
      <form onSubmit={initiateEmployeeFnf}>
        <div className="mx-2">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="">Select Employee</label>
              <select
                className="form-select form-control"
                id="inputGroupSelect02"
                onChange={(e) => {
                  setFnfDetails({ ...fnfDetails, candidate: e.target.value });
                }}
              >
                <option value="">Click Here To Select</option>
                {employee && employee.length > 0 && employee !== undefined
                  ? employee.map((empList) => {
                      //   console.log(empList);
                      return (
                        <>
                          <option key={empList.id} value={empList.id}>
                            {empList.full_name}
                          </option>
                        </>
                      );
                    })
                  : ""}
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="">Enter Notice Period</label>
              <input
                type="number"
                className="form-control "
                id=""
                aria-describedby=""
                placeholder="Enter Notice Period (in Days)"
                required
                // value={fnfDetails.notice_period}
                onChange={(e) =>
                  setFnfDetails((prevState) => ({
                    ...prevState,
                    notice_period: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <label htmlFor="">Enter Resigning Date</label>
              <input
                type="date"
                className="form-control "
                id=""
                aria-describedby=""
                required
                value={fnfDetails.resigning_date}
                onChange={(e) =>
                  setFnfDetails((prevState) => ({
                    ...prevState,
                    resigning_date: e.target.value,
                  }))
                }
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="">Clearance Department</label>
              <MultiSelect
                options={formattedDept}
                value={departmentMultiSelectList}
                onChange={setDepartmentMultiSelectList}
                //labelledBy="Select"
                //shouldToggleOnHover
                valueRenderer={(selected) => {
                  if (!selected.length) {
                    return "No Department selected";
                  }

                  return selected.map(({ label }) => "✔️ " + label);
                }}
              />
            </div>
          </div>
          <div className="my-3">
            <button className="btn btn-danger me-3" type="submit">
              Initiate FnF
            </button>
          </div>
        </div>
      </form>
      <button
        className="btn btn-light appraisalCancelBtn"
        onClick={props.closeFnfModal}
      >
        Cancel
      </button>
    </>
  );
}
