import React, { useState } from "react";
import { useAlert } from "react-alert";
import { API_URL } from "../../../../../Constents";

export default function CandidateJobAcceptanceForm() {
  const [checkAcceptBtn, setCheckAcceptBtn] = useState(false);

  const alert = useAlert();

  const token = window.location.href.split("/").reverse()[0];
  console.log(token);

  const candidateJobAcceptanceHandler = async () => {
    const headersList = {
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      acceptance: true,
      token: token,
    });

    const url = API_URL + "/confirmation/";
    if (checkAcceptBtn) {
      try {
        const response = await fetch(url, {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        });

        const data = await response.json();
        console.log(data)
        if (data.status) alert.success(data.message);
        if (data.status === false) alert.error(data.message);
      } catch (err) {
        console.error(err);
        alert.error("Internal Server Error");
      }
    } else {
      alert.info("Please Accept the T&C");
    }
  };

  return (
    <>
      <div className="container mt-5">
        <div className="col-md-6 offset-md-3">
          <div className="candidateMainContent">
            <div className="candidateOfferAccepttanceHighlight">
              <h3 className="text-center">Offer Acceptance</h3>
              <div
                className="mt-3"
                style={{ overflowY: "scroll", height: "550px" }}
              >
                <div className="text-left">
                  <p>Read all the conditions carefully</p>
                  <p>
                    <b>
                      <mark>Job duties and responsibilities:</mark>
                    </b>{" "}
                    You will be expected to perform the job duties and
                    responsibilities outlined in your offer letter and any
                    subsequent job description provided to you.
                  </p>
                  <p>
                    <b>
                      <mark>Employment status:</mark>
                    </b>{" "}
                    Your employment with the company is at-will, which means
                    either you or the company may terminate the employment
                    relationship at any time and for any reason, with or without
                    notice.
                  </p>
                  <p>
                    <b>
                      <mark>Compensation:</mark>
                    </b>{" "}
                    Your salary, benefits, and any other compensation are
                    outlined in your offer letter. The company reserves the
                    right to modify compensation and benefits at any time.
                  </p>
                  <p>
                    <b>
                      <mark>Confidentiality:</mark>
                    </b>{" "}
                    You will be required to sign a confidentiality agreement,
                    which will prohibit you from disclosing any confidential
                    information belonging to the company, both during and after
                    your employment.
                  </p>
                  <p>
                    <b>
                      {" "}
                      <mark>Non-compete:</mark>
                    </b>{" "}
                    You may be required to sign a non-compete agreement, which
                    will prohibit you from working for a competitor for a
                    certain period of time after your employment with the
                    company ends.
                  </p>
                  <p>
                    <b>
                      {" "}
                      <mark>Intellectual property:</mark>{" "}
                    </b>{" "}
                    Any work you create while employed with the company will be
                    considered the company's intellectual property.
                  </p>
                  <p>
                    {" "}
                    <b>
                      <mark>Background check:</mark>
                    </b>{" "}
                    Your employment offer is contingent upon successfully
                    passing a background check. Immigration status: Your
                    employment offer is contingent upon you being authorized to
                    work in the country where the position is located.
                  </p>
                  <p>
                    {" "}
                    <b>
                      <mark>Probationary period:</mark>
                    </b>{" "}
                    Your employment may be subject to a probationary period,
                    during which time the company may evaluate your job
                    performance and determine if you are a good fit for the
                    position.
                  </p>
                  <p>
                    {" "}
                    <b>
                      <mark>Code of conduct:</mark>
                    </b>{" "}
                    You will be expected to comply with the company's code of
                    conduct and any other policies or procedures established by
                    the company.
                  </p>
                  <div className="text-center align-center">
                    <input
                      type="checkbox"
                      id="accept"
                      name="accept"
                      value=""
                      className="me-2"
                      onClick={(e) => {
                        if (e.target.checked) {
                          setCheckAcceptBtn(true);
                        } else {
                          setCheckAcceptBtn(false);
                        }
                      }}
                    />
                    <label for="accept" className="w-auto">
                      I agree to all the terms and conditions
                    </label>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <button
                    className="btn btn-outline-success"
                    disabled={checkAcceptBtn ? false : true}
                    onClick={candidateJobAcceptanceHandler}
                  >
                    I accept
                  </button>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
}
