import React, { useEffect, useState } from "react";
import { API_URL } from "../../../Constents";
import { useAlert } from "react-alert";
export default function FnFSideDrawer(props) {
  console.log(props.selectedRowData);
  const [sideDrawerData, setSideDrawerData] = useState({});
  const alert = useAlert();
  const clearDuesAndReleaseLetterHandler = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      status: true,
    });
    const url =
      API_URL + "/clearance/?clearance_id=" + props.selectedRowData.id;
    const response = await fetch(url, {
      method: "PATCH",
      body: bodyContent,
      headers: headersList,
    });

    try {
      const data = await response.json();
      setSideDrawerData(data);
      if (data.status) {
        alert.success(data.message);
        props.toggleSideDrawer();
        props.refreshTable(data);
      }
      if (data.status === false) {
        alert.error(data.message);
      }
      console.log(data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  console.log(sideDrawerData);
  return (
    <>
      <div className="container my-2 overflow-auto">
        <div className="mt-4">
          <h4>
            Remarks,No Dues and Letters of{" "}
            {props.selectedRowData && props.selectedRowData.candidate ? (
              <>
                <span>{props.selectedRowData.candidate.full_name}</span>
              </>
            ) : (
              ""
            )}{" "}
          </h4>
          <hr />
        </div>
        <div className="d-flex justify-content-between mt-4">
          <button
            className="btn btn-outline-danger"
            onClick={() => window.open(props.selectedRowData.experience_letter)}
            disabled={props.selectedRowData.experience_letter === null}
          >
            View Ex. Letter
          </button>
          <button
            className="btn btn-outline-warning"
            onClick={() =>
              window.open(props.selectedRowData.fullandfinal_letter)
            }
            disabled={props.selectedRowData.fullandfinal_letter === null}
          >
            View Relieving Letter
          </button>
        </div>

        {props.selectedRowData &&
        props.selectedRowData.clearance_department &&
        props.selectedRowData.clearance_department.length !== 0 ? (
          props.selectedRowData.clearance_department.map((data) => {
            return (
              <>
                <div className="mt-4 ">
                  <p className="text-dark p-0 m-0">
                    <span className="me-1 text-dark">Department:</span>{" "}
                    {data.department.department_name}
                  </p>
                  <div className="mt-1">
                    <p className="text-dark p-0 m-0">Remarks</p>
                    {data.remark === null ? (
                      <>
                        <div className="form-control">
                          <h5 className="text-danger">NA</h5>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form-control">
                          <h5 className="">{data.remark}</h5>
                        </div>
                      </>
                    )}
                  </div>

                  <p
                    className=" p-0 m-0"
                    style={{
                      float: "right",
                    }}
                  >
                    Lead: {data.department.department_lead}
                  </p>
                  <hr className="mt-4" />
                </div>
              </>
            );
          })
        ) : (
          <>
            <div className=" text-center mt-5">
              <h5>No Data Available</h5>
            </div>
          </>
        )}
      </div>
      <div className="text-center my-3">
        <button
          className="departmentBtn"
          onClick={clearDuesAndReleaseLetterHandler}
          disabled={props.selectedRowData.status}
        >
          Clear Dues n Release Letters
        </button>
      </div>
    </>
  );
}
