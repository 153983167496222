import { useState, useEffect } from "react";
import React from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import { API_URL } from "../../../Constents";
import LoadingGifs from "../../../images/YAAMS-gif.gif";

import { BiSearch } from "react-icons/bi";

import ReverseDateString from "../../Hooks/ReverseDateString";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineDownload, AiOutlineHistory } from "react-icons/ai";
import { Modal } from "react-bootstrap";
import LossOfPayHistoryModal from "./LossOfPayHistoryModal";

const LossOfPayOverview = () => {
  const [lossOfPayOverview, setLossOfPayOverview] = useState();
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [err, setErr] = useState();

  const fetchLossOfPayList = async () => {
    const url = API_URL + "/Loss-Of-Pay/";

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerlist = {
      Authorization: "Token " + userData.seucrity_token,
    };

    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headerlist,
      });
      const data = await res.json();
      setLossOfPayOverview(data);
    } catch (err) {
      setErr(err);
    }
  };

  useEffect(() => {
    fetchLossOfPayList();
  }, []);

  if (!lossOfPayOverview)
    return (
      <div className="loadingGif">
        <img src={LoadingGifs} alt="Loading Gif" />
      </div>
    );
  if (err) return <h1>Some error occured</h1>;

  const { SearchBar } = Search;

  const columns = [
    { dataField: "employee_name", text: "Name", sort: true },
    {
      dataField: "employee_email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "from_date",
      text: "From",
      sort: true,
      formatter: (row) => ReverseDateString(row),
    },
    {
      dataField: "to_date",
      text: "To",
      sort: true,
      formatter: (row) => ReverseDateString(row),
    },
    {
      dataField: "leave_name",
      text: "Type",
    },
    {
      dataField: "leave_count",
      text: "LoP",
      sort: true,
      formatter: (row) => {
        if (row <= 1) {
          return <p>{row} Day</p>;
        } else return <p>{row} Days</p>;
      },
    },
    {
      dataField: "id",
      text: "Actions",
      sort: true,
      csvExport: false,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <button
            className="btn btn-link"
            data-toggle="tooltip"
            title="View History"
            onClick={() => {
              console.log(row);
              setEmployeeDetails(row);
              setShowHistoryModal(true);
            }}
          >
            <AiOutlineHistory size={20} />
          </button>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: "End",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <>
        <Link
          onClick={handleClick}
          style={{ backgroundColor: "white", borderColor: "lightgray" }}
          className="btn btn-light "
        >
          Export Excel
          <AiOutlineDownload style={{ marginLeft: "5px", color: "red" }} />
        </Link>
      </>
    );
  };

  console.log(employeeDetails);

  return (
    <div className="maincontent ">
      <div className="highlight all_form_spacing">
        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={lossOfPayOverview.data}
          columns={columns}
          ignoreHeader
          exportCSV={{
            fileName: `lossOfPay.csv`,
          }}
          search
        >
          {(props) => (
            <React.Fragment>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <h2 style={{ color: "#DD3944" }} className="mx-2">
                      Loss of Pay Overview
                    </h2>
                  </div>
                  <div className="col-md-2">
                    <MyExportCSV {...props.csvProps} />
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="position-relative newsearch_box">
                      <SearchBar
                        className="newSearchWrapper"
                        placeholder="Search Here..."
                        srText=""
                        {...props.searchProps}
                      />
                      <BiSearch />
                    </div>
                  </div>
                </div>

                <BootstrapTable
                  pagination={pagination}
                  filter={filterFactory()}
                  {...props.baseProps}
                  condensed
                  bordered={false}
                  noDataIndication={() => {
                    return <h3>No data</h3>;
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </ToolkitProvider>
      </div>

      <Modal
        size="xl"
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <LossOfPayHistoryModal
            employeeDetails={employeeDetails}
            hideModal={() => setShowHistoryModal(false)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LossOfPayOverview;
