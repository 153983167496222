import moment from "moment";

export default function VacancySideDrawerExpired({
  selectedRowData,
  openDuplicateVacancyModal,
  openReopenVacancyModal,
}) {
  return (
    <>
      <div className="maincontent">
        <div className="my-3 mx-3">
          <p style={{ color: "#535353" }} className="m-0 p-0">
            Team Name
          </p>
          <h5 className="m-0 p-0">
            {selectedRowData && selectedRowData.department
              ? selectedRowData.department.department_name
              : "Not Available"}
          </h5>
        </div>
        <div className="mb-4 mx-3">
          <p style={{ color: "#535353" }} className="m-0 p-0">
            No. of Vacancy
          </p>
          <h5>
            {selectedRowData && selectedRowData.num_of_vacancy
              ? selectedRowData.num_of_vacancy
              : "Not Available"}
          </h5>
        </div>
        <div className="mb-4 mx-3">
          <p style={{ color: "#535353" }} className="m-0 p-0">
            Qualification
          </p>
          <h5>
            {selectedRowData && selectedRowData.min_required_qualification
              ? selectedRowData.min_required_qualification
              : "Not Available"}
          </h5>
        </div>
        <div className="mb-4 mx-3">
          <p style={{ color: "#535353" }} className="m-0 p-0">
            C.T.C
          </p>
          <h5>
            {selectedRowData
              ? selectedRowData.salary_range_min + " LPA"
              : "Not Available"}{" "}
            to{" "}
            {selectedRowData
              ? selectedRowData.salary_range_max + " LPA"
              : "Not Available"}
          </h5>
        </div>
        <div className="mb-4 mx-3">
          <p style={{ color: "#535353" }} className="m-0 p-0">
            Job Title
          </p>
          <h5>
            {selectedRowData && selectedRowData.title
              ? selectedRowData.title
              : "Not Available"}
          </h5>
        </div>
        <div className="mb-4 mx-3">
          <p style={{ color: "#535353" }} className="m-0 p-0">
            Experience
          </p>
          <h5>
            {selectedRowData && selectedRowData.min_required_experience
              ? selectedRowData.min_required_experience
              : "Not Available"}
          </h5>
        </div>
        <div className="mb-5 mx-3">
          <p style={{ color: "#535353" }} className="m-0 p-0">
            Last Update
          </p>
          <h5>
            {selectedRowData && selectedRowData.updated_at
              ? moment(selectedRowData.updated_at).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )
              : "Not Available"}
          </h5>
        </div>
        <div className="row m-auto mb-5 ">
          <div className="col-md-6">
            <button
              className="btn btn-outline-success w-100"
              onClick={() => openDuplicateVacancyModal()}
            >
              Duplicate
            </button>
          </div>
          <div className="col-md-6">
            <button
              className="btn btn-outline-success w-100"
              onClick={() => openReopenVacancyModal()}
            >
              Reopen
            </button>
          </div>
        </div>

        {/* <div className="col-md-12 mx-2">
          <button
            className="btn btn-danger w-100"
            onClick={() => openEditVacancyModal()}
          >
            Reopen
          </button>
        </div> */}
      </div>
    </>
  );
}
