import React from "react";

export default function CreateNotificationOptions({
  setterFunc,
  notificationDetails,
}) {
  const currentdate = new Date();
  const minDate = currentdate.toISOString().slice(0, 16);

  return (
    <>
      <div className="ms-3 me-3">
        <div className="row">
          <div className="col-md-4">
            <div className="my-2">
              <label>
                Option 1<span className="msg_fail">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setterFunc({
                    ...notificationDetails,
                    option1: e.target.value,
                  });
                }}
                required
              />
            </div>
            <div className="my-2">
              <label>Option 3</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setterFunc({
                    ...notificationDetails,
                    option3: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <div className="my-2">
              <label>
                Option 2<span className="msg_fail">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setterFunc({
                    ...notificationDetails,
                    option2: e.target.value,
                  });
                }}
                required
              />
            </div>
            <div className="my-2">
              <label>Option 4</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  setterFunc({
                    ...notificationDetails,
                    option4: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="my-2">
              <label>
                End Date & Time<span className="msg_fail">*</span>
              </label>
              <input
                type="datetime-local"
                className="form-control"
                onChange={(e) => {
                  setterFunc({
                    ...notificationDetails,
                    endDateTime: e.target.value,
                  });
                }}
                min={minDate}
                required
              />
            </div>
            {/* <div className="my-2">
             <label>Time</label>
               <input type="time" className="form-control" />
             </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
