import { useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";

import { API_URL, BASE_URL } from "../../../Constents";

const ApproveOrRejectLeaveFromEmail = () => {
  const [comment, setComment] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const url = window.location.href.split("/").reverse();
  const name = url[0].split("%20");
  const URLToken = url[1];

  const alert = useAlert();
  const history = useHistory();

  let formattedName = "";
  name.map((i) => {
    return (formattedName = formattedName + i + " ");
  });

  const approveRejectLeaveFromMailHandler = async (status) => {
    setStatusMessage("Please wait..........");
    const headersList = {
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      token: URLToken,
      response: status,
      reason: comment,
    });

    console.log(bodyContent);

    const url = API_URL + "/approve-reject-leave-response/";

    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);

      if (data.status && status === "approved") {
        history.push(BASE_URL + "ApprovedMailMessage");
        return;
      }
      if (data.status && status === "rejected") {
        history.push(BASE_URL + "RejectedMailMessage");
        return;
      }

      if (data.status === false) {
        alert.error(data.message);
      }

      setStatusMessage("");
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  return (
    <div className="maincontent">
      <div className="row highlight all_form_spacing">
        <div className="leave_request">
          <img
            src="https://yaams.net/static/media/logo.be7acc95d2d4a86366db.png"
            className="img-fluid mb-4"
            alt=""
            style={{ width: "200px" }}
          />
          <div>
            <h3>Leave Approval Request</h3>
            <p>
              Leave request submitted by <b>{formattedName}</b>
            </p>

            <textarea
              className="form-control mt-4 mb-3"
              placeholder="Comments (optional)"
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
            <button
              className="btn btn-outline-success w-100 mb-3"
              onClick={() => approveRejectLeaveFromMailHandler("approved")}
            >
              Approve
            </button>
            <button
              className="btn btn-outline-danger w-100"
              onClick={() => approveRejectLeaveFromMailHandler("rejected")}
            >
              Reject
            </button>
          </div>
          <p className="mt-5 error-message-ui">{statusMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default ApproveOrRejectLeaveFromEmail;
