import { APP_VERSION_NUMBER } from "../../Constents";

const VersionModal = () => {
  return (
    <div className="version_control_scroll-y">
      <div>
        <div className="d-flex justify-content-between mt-3 ">
          <h5>{APP_VERSION_NUMBER}</h5>
          <p>
            (04<sup>th</sup> May 2023)
          </p>
        </div>
        <p
          style={{
            fontSize: "16px",
            textJustify: "inter-word",
            textAlign: "justify",
          }}
        >
          We are excited to announce the release of our latest update to YAAMS!
          With the new Core HR Section, we have added some amazing features that
          will make managing your HR processes easier than ever before. Here's
          what's new:
        </p>

        <ul className="list-group">
          <li className="list-group-item">
            Core HR Section - Our new section is designed to streamline your HR
            processes and make them more efficient.
          </li>
          <li className="list-group-item">
            {" "}
            Recruitment Management - You can now manage your recruitment process
            right from within YAAMS. This includes posting job openings,
            receiving applications, and scheduling interviews.
          </li>
          <li className="list-group-item">
            Interview Scheduling - With our interview scheduling tool, you can
            easily schedule interviews with candidates and keep track of their
            progress through the hiring process.
          </li>

          <li className="list-group-item">
            {" "}
            FNF Management - Managing your employee's FNF has never been easier.
            You can now keep track of all the necessary details and ensure that
            everything is done on time.
          </li>
          <li className="list-group-item">
            {" "}
            Appreciation Management - Show your employees some love with our new
            appreciation management feature. You can now easily recognize your
            employees for their hard work and dedication.
          </li>
          <li className="list-group-item">
            {" "}
            Appraisal Management - We have added timely appraisal management
            tools that allow you to conduct both financial and promotional
            appraisals on schedule.
          </li>
        </ul>
      </div>
      <div>
        <div className="d-flex justify-content-between mt-3 ">
          <h5>v1.2023.4</h5>
          <p>
            (05<sup>th</sup> April 2023)
          </p>
        </div>

        <ul className="list-group">
          <li className="list-group-item">Feed section in the Dashboard </li>
          <li className="list-group-item">Improvement In UI</li>

          <li className="list-group-item">Bug Fix</li>
        </ul>
      </div>
      <div>
        <div className="d-flex justify-content-between mt-3 ">
          <h5>v1.2023.3</h5>
          <p>
            (29<sup>th</sup> March 2023)
          </p>
        </div>

        <ul className="list-group">
          <li className="list-group-item">
            Notifications And Polls (Navigation Bar){" "}
          </li>
          <li className="list-group-item">
            User can create a poll and see results
          </li>
          <li className="list-group-item">User can create a notification</li>
          <li className="list-group-item">
            User is now able to see all Leave Balance and update it.
          </li>

          <li className="list-group-item">Bug Fix</li>
        </ul>
      </div>
      <div>
        <div className="d-flex justify-content-between mt-3 ">
          <h5> v1.2023.2</h5>
          <p>
            (03<sup>th</sup> March 2023)
          </p>
        </div>

        <ul className="list-group">
          <li className="list-group-item">Updated UI (New Settings Page)</li>
          <li className="list-group-item">New organization settings</li>
          <li className="list-group-item">Custom Leave Settings</li>
          <li className="list-group-item">YAAMS Downloads section</li>
          <li className="list-group-item">Bug Fix</li>
        </ul>
      </div>
      <div>
        <div className="d-flex justify-content-between mt-3 ">
          <h5>v1.2023.1</h5>
          <p>
            (17<sup>th</sup> Jan 2023)
          </p>
        </div>

        <ul className="list-group">
          <li className="list-group-item">
            Additional Managers in edit employee section
          </li>
          <li className="list-group-item">Multi location checkin checkout</li>
          <li className="list-group-item">New organization settings</li>
          <li className="list-group-item">
            Multiple location assignement in edit employee section
          </li>
          <li className="list-group-item">
            Email notification on applying leave
          </li>
          <li className="list-group-item">
            Functionality to take action on the applied leave (approve/reject)
          </li>
          <li className="list-group-item">Bug Fix</li>
        </ul>
      </div>

      <div>
        <div className="d-flex justify-content-between mt-3 ">
          <h5>v1.2022.4</h5>
          <p>
            (29<sup>th</sup> Dec 2022)
          </p>
        </div>

        <ul className="list-group">
          {/* <li className="list-group-item">
            Additional team member setting in edit employee button
          </li> */}
          <li className="list-group-item">Updated UI in the whole APP</li>
          <li className="list-group-item">
            Attendance update flag (hover for details)
          </li>
          <li className="list-group-item">Bug Fix</li>
        </ul>
      </div>

      <div>
        <div className="d-flex justify-content-between mt-3 ">
          <h5>v1.2022.3</h5>
          <p>
            (22<sup>nd</sup> Dec 2022)
          </p>
        </div>

        <ul className="list-group">
          <li className="list-group-item">
            WFH Activation Button in Edit Employee button
          </li>
          <li className="list-group-item">PunchOut Gate in Attendance</li>
          <li className="list-group-item">New UI in employee list</li>
          <li className="list-group-item">Bug Fix</li>
        </ul>
      </div>

      <div>
        <div className="d-flex justify-content-between mt-3 ">
          <h5>v1.2022.2</h5>
          <p>
            (11<sup>th</sup> Nov 2022)
          </p>
        </div>

        <ul className="list-group">
          <li className="list-group-item">New font in whole site</li>
          <li className="list-group-item">Bug Fix</li>
        </ul>
      </div>

      <div>
        <div className="d-flex justify-content-between mt-3 ">
          <h5>v1.2022.1</h5>
          <p>
            (04<sup>th</sup> Oct 2022)
          </p>
        </div>

        <ul className="list-group">
          <li className="list-group-item">New Login Page</li>
          <li className="list-group-item">New data in attendance</li>
          <li className="list-group-item">Attendace Export in new format</li>
          <li className="list-group-item">Bug Fix</li>
        </ul>
      </div>
    </div>
  );
};

export default VersionModal;
