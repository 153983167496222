import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useAlert } from "react-alert";
import { API_URL } from "../../../../Constents";
import { AiOutlineFilePdf } from "react-icons/ai";
import loadingImg from "../../../../images/DummyUserDP.png";
import Modal from "react-bootstrap/Modal";
import RejectCandidateDetails from "./RejectCandidateDetails";
export default function ReviewCandidateDetails(props) {
  const [applicantDetails, setApplicantDetails] = useState([]);
  const [rejectDetailsModalShow, setRejectDetailsModalShow] = useState(false);
  const location = useLocation();
  const alert = useAlert();
  //const { state: { appId } = {} } = useLocation();
  // console.log(props.location);
  //console.log(location.state.detals.id)
  //console.log(location.state.details.id);
  //console.log(location.state.details);
  const fetchApplicantDetails = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const url =
      API_URL + "/applicant-personal-details/?id=" + location.state.details.id;
    console.log(url);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);
      if (data.data[0]) {
        setApplicantDetails(data.data[0]);
      } else alert.error("No Data available for this candidate");

      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.log(err);
      alert.error("Internal Server Error");
    }
  };
  const confirmAppicantDocumentsHandler = async () => {
    const url =
      API_URL + "/applicant/?applicant_id=" + applicantDetails.applicant;
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      confirm_personal_details: "confirmed",
    });

    try {
      const response = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);
      if (data.status) {
        alert.success(data.message);
      }
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.log(err);
      alert.error("Internal Server Error");
    }
  };
  useEffect(() => {
    fetchApplicantDetails();
  }, [rejectDetailsModalShow]);
  console.log(applicantDetails);
  // console.log(applicantDetails.applicant);
  const history = useHistory();
  const closeRejectCandidateModal = () => {
    setRejectDetailsModalShow(false);
  };
  return (
    <>
      <div className="candidateMainContent">
        <div className="candidateHighlight">
          <div className="d-flex justify-content-between align-items-center">
            <h3 style={{ color: "#DD3974" }}> Review Test Details</h3>

            <button
              onClick={() => history.goBack()}
              className="departmentBtnSecondary"
            >
              {" "}
              <IoIosArrowBack size={20} /> Back
            </button>
          </div>
          <div className="mt-3">
            <div className="row ">
              <div className="col-md-2">
                {applicantDetails && applicantDetails.length !== 0 ? (
                  <>
                    <div className="circle_profile_img">
                      <img
                        src={applicantDetails.profile_image}
                        //src={loadingImg}
                        alt="no_img"
                        className="img-fluid "
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      // src={applicantDetails.profile_image}
                      src={loadingImg}
                      alt="no_img"
                      className="img-fluid"
                    />
                  </>
                )}
              </div>
              <div className="col-md-6 ">
                <h6>
                  Applicant Name{" "}
                  {location.state.details && location.state.details.name ? (
                    <>
                      <span style={{ color: "#000" }} className="me-5">
                        :
                      </span>

                      {location.state.details.name}
                    </>
                  ) : (
                    ":-"
                  )}
                </h6>
                <h6>
                  Applicant Email
                  {location.state.details && location.state.details.name ? (
                    <>
                      <span style={{ color: "#000" }} className="me-5">
                        :
                      </span>
                      {location.state.details.email}
                    </>
                  ) : (
                    ":-"
                  )}
                </h6>
                <h6>
                  Applicant Date of Birth
                  {applicantDetails && applicantDetails.length !== 0 ? (
                    <>
                      <span style={{ color: "#000" }} className="me-3">
                        :
                      </span>
                      {applicantDetails.dob}
                    </>
                  ) : (
                    ":-"
                  )}
                </h6>
                <h6>
                  Applicant Marital Status
                  {applicantDetails &&
                  applicantDetails.length !== 0 &&
                  applicantDetails.marital_status !== null ? (
                    <>
                      <span style={{ color: "#000" }} className="me-3">
                        :
                      </span>
                      {applicantDetails.marital_status}
                    </>
                  ) : (
                    "-"
                  )}
                </h6>
                <h6 className="d-flex align-items-center">
                  Applicant Pan Number{" "}
                  {applicantDetails && applicantDetails.length !== 0 ? (
                    <>
                      <span style={{ color: "#000" }} className="me-4">
                        :
                      </span>
                      {applicantDetails.pan_number}

                      <AiOutlineFilePdf
                        style={{ color: "#dd3974", cursor: "pointer" }}
                        className="ms-1"
                        onClick={() =>
                          window.open(applicantDetails.pan_number_attachment)
                        }
                      />
                    </>
                  ) : (
                    ":-"
                  )}{" "}
                </h6>
                <h6 className="d-flex align-items-center">
                  Applicant UIDAI Number
                  {applicantDetails && applicantDetails.length !== 0 ? (
                    <>
                      <span style={{ color: "#000" }} className="me-2">
                        :
                      </span>
                      {applicantDetails.uidai_number}
                      <AiOutlineFilePdf
                        style={{ color: "#dd3974", cursor: "pointer" }}
                        className="ms-1"
                        onClick={() =>
                          window.open(applicantDetails.uidai_number_attachment)
                        }
                      />
                    </>
                  ) : (
                    ":-"
                  )}
                </h6>
                <h6>
                  Applicant Bank Name
                  {applicantDetails && applicantDetails.length !== 0 ? (
                    <>
                      <span style={{ color: "#000" }} className="me-2">
                        :
                      </span>
                      {applicantDetails.bank_name}
                    </>
                  ) : (
                    ":-"
                  )}
                </h6>
                <h6>
                  Applicant Account Number
                  {applicantDetails && applicantDetails.length !== 0 ? (
                    <>
                      <span style={{ color: "#000" }} className="">
                        :
                      </span>
                      {applicantDetails.account_number}
                    </>
                  ) : (
                    ":NA"
                  )}
                </h6>
                <h6>
                  Applicant Account Details
                  {applicantDetails && applicantDetails.length !== 0 ? (
                    <>
                      <span
                        style={{ color: "#000", cursor: "pointer" }}
                        className=""
                        onClick={() =>
                          window.open(applicantDetails.account_attachment)
                        }
                      >
                        : <span className="ms-2">View Pass Book</span>
                        <AiOutlineFilePdf color="#dd3974" />
                      </span>
                    </>
                  ) : (
                    ":-"
                  )}
                </h6>
                <div className="d-flex align-items-center mt-4 justify-content-between">
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => setRejectDetailsModalShow(true)}
                    disabled={applicantDetails.rejected_field}
                  >
                    Reject
                  </button>
                  <button
                    className="btn btn-outline-success"
                    onClick={confirmAppicantDocumentsHandler}
                    disabled={
                      applicantDetails === undefined ||
                      applicantDetails.rejected_field
                    }
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={rejectDetailsModalShow}
        onHide={closeRejectCandidateModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <RejectCandidateDetails
            closeRejectCandidateModal={closeRejectCandidateModal}
            applicantDetails={applicantDetails}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
