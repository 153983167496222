import React, { useEffect } from "react";
import { BsTelephoneOutbound } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import moment from "moment";
import { API_URL } from "../../../Constents";
import { useAlert } from "react-alert";
export default function AppreciationSideDrawer(props) {
  console.log(props.selectedRowData);
  const alert = useAlert();
  const date = moment(props.selectedRowData.created_at);
  const formattedDate = date.format("DD-MM-YYYY");
  // console.log(formattedDate);
  const publishToFeedHandler = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const bodyContent = JSON.stringify({
      appreciation_id: props.selectedRowData.appreciation_id,
    });

    const url = API_URL + "/admin-push-appreciation-remark/";
    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      if (data.status) {
        alert.success(data.message);
      }
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  const generateAppreciationLetterHandler = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const bodyContent = JSON.stringify({
      appreciation_id: props.selectedRowData.appreciation_id,
    });
    console.log(bodyContent);
    const url = API_URL + "/generate-appreciation-letter/";
    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      if (data.status) {
        alert.success(data.message);
        props.refreshTable(data);
        props.sideDrawerClose();
      }
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  return (
    <>
      <div className="container">
        {props.selectedRowData && props.selectedRowData.employee ? (
          <>
            <h4 className="mt-4">
              {props.selectedRowData.employee.first_name}'s Appreciation Details
            </h4>
            <div className="d-flex justify-content-between mt-1 align-items-center">
              <div>
                <p className="p-0 m-0">
                  <BsTelephoneOutbound color="#DD3974" className="me-2" />
                  {props.selectedRowData.employee.mobile_number}
                </p>
              </div>

              <div>
                {" "}
                <p className="p-0 m-0">
                  <AiOutlineMail color="#DD3974" className="me-1" />
                  {props.selectedRowData.employee.official_email}
                </p>
              </div>
            </div>
            <hr />

            {props.selectedRowData.appreciation_remark === null ? (
              <div className="form-control mt-5">
                <p>Feedback Not Given yet</p>
              </div>
            ) : (
              <>
                <div className="form-control mt-5">
                  <p>{props.selectedRowData.appreciation_remark}</p>
                </div>
              </>
            )}
            <div className="mt-5 d-flex justify-content-between  align-items-center">
              <button
                className="btn btn-outline-warning"
                onClick={generateAppreciationLetterHandler}
                disabled={
                  props.selectedRowData.appreciation_attachment !== null
                }
              >
                Generate{" "}
              </button>
              <p className="p-0 m-0">Appreciation Letter</p>
              <button
                className="btn btn-outline-info"
                disabled={
                  props.selectedRowData.appreciation_attachment === null
                }
                onClick={() =>
                  window.open(props.selectedRowData.appreciation_attachment)
                }
              >
                View
              </button>
            </div>
            <div className="mt-5 d-flex justify-content-between  align-items-center">
              <p className="p-0 m-0">Publish to Feed</p>
              <button
                className="btn btn-outline-dark"
                onClick={publishToFeedHandler}
              >
                Publish
              </button>
            </div>
            <div className="mt-5">
              <p>
                Created At :{formattedDate}, by :
                {props.selectedRowData && props.selectedRowData.created_by ? (
                  <>{props.selectedRowData.created_by.full_name}</>
                ) : (
                  "-"
                )}
              </p>
            </div>
            <hr />
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
