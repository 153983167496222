import React, { useState, useEffect } from "react";
import { API_URL } from "../../../Constents";
import { useAlert } from "react-alert";
export default function StartAppraisals(props) {
  const [startAppraisalDetails, setStartAppraisalsDetails] = useState({
    appraisal_type: "financial",
    new_salary: null,
    new_designation: null,
    new_role: null,
    appraisal_effective_date: "",
    feedback_note: "",
  });
  const [designationList, setDesignationList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  console.log(props.appraisal_id);
  const alert = useAlert();
  const createAppraisalHandler = async (e) => {
    e.preventDefault();
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    let bodyContent = JSON.stringify({});

    if (startAppraisalDetails.appraisal_type === "financial") {
      bodyContent = JSON.stringify({
        appraisal_type: startAppraisalDetails.appraisal_type,
        new_salary: startAppraisalDetails.new_salary,
      });
    }

    if (startAppraisalDetails.appraisal_type === "promotional") {
      bodyContent = JSON.stringify({
        appraisal_type: startAppraisalDetails.appraisal_type,
        new_salary: startAppraisalDetails.new_salary,
        new_designation: startAppraisalDetails.new_designation,
        new_role: startAppraisalDetails.new_role,
        appraisal_effective_date:
          startAppraisalDetails.appraisal_effective_date,
        feedback_note: startAppraisalDetails.feedback_note,
      });
    }
    console.log(bodyContent);
    const url =
      API_URL + "/admin-appraisal/?appraisal_id=" + props.appraisal_id;

    try {
      const response = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });
      const data = await response.json();
      if (data.status) {
        alert.success(data.message);
        props.closeAppraisalModal();
      }
      if (data.status === false) alert.error(data.message);
      console.log(data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  const fetchUserDesignation = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/role?all_role=True";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setDesignationList(data.data);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchUserRoles = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/add-fetch-role-associate/";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setRoleList(data.data);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchUserDesignation();
    fetchUserRoles();
  }, []);
  console.log(roleList);
  console.log(startAppraisalDetails);
  return (
    <>
      <h4 className=" mx-2 mb-3" style={{ color: "#000" }}>
        Appraisals
      </h4>
      <hr />
      <form onSubmit={createAppraisalHandler}>
        <div className="mx-2">
          <h6 className="mb-2">Appraisal Type</h6>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              value="financial"
              checked={startAppraisalDetails.appraisal_type === "financial"}
              onClick={(e) => {
                setStartAppraisalsDetails((prevState) => ({
                  ...prevState,
                  appraisal_type: e.target.value,
                }));
              }}
            />
            <label className="form-check-label" for="inlineRadio1">
              Financial
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="promotional"
              checked={startAppraisalDetails.appraisal_type === "promotional"}
              onClick={(e) => {
                setStartAppraisalsDetails((prevState) => ({
                  ...prevState,
                  appraisal_type: e.target.value,
                }));
              }}
            />
            <label className="form-check-label" for="inlineRadio2">
              Promotional
            </label>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="">Enter C.T.C</label>
              <input
                type="number"
                className="form-control "
                id=""
                aria-describedby=""
                placeholder="Enter CTC"
                required
                onChange={(e) =>
                  setStartAppraisalsDetails((prevState) => ({
                    ...prevState,
                    new_salary: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          {startAppraisalDetails.appraisal_type === "financial" ? (
            <></>
          ) : (
            <>
              <div className="row my-2">
                <div className="col-md-6">
                  <label htmlFor="designition">Select Designation</label>
                  <select
                    className="form-select form-control"
                    id="inputGroupSelect02"
                    onChange={(e) =>
                      setStartAppraisalsDetails((prevState) => ({
                        ...prevState,
                        new_designation: e.target.value,
                      }))
                    }
                  >
                    <option hidden disabled selected>
                      {" "}
                      -- Select Designation --{" "}
                    </option>
                    {designationList &&
                    designationList.length > 0 &&
                    designationList !== undefined
                      ? designationList.map((roleListData) => (
                          <option key={roleListData.id} value={roleListData.id}>
                            {roleListData.role_name}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="designition">Select Role</label>
                  <select
                    className="form-select form-control"
                    id="inputGroupSelect02"
                    onChange={(e) =>
                      setStartAppraisalsDetails((prevState) => ({
                        ...prevState,
                        new_role: e.target.value,
                      }))
                    }
                  >
                    <option hidden disabled selected>
                      {" "}
                      -- Select Role --{" "}
                    </option>
                    {roleList && roleList.length > 0 && roleList !== undefined
                      ? roleList.map((roleListData) => (
                          <option key={roleListData.id} value={roleListData.id}>
                            {roleListData.role}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="">Effective Date</label>
                  <input
                    type="date"
                    className="form-control "
                    id=""
                    aria-describedby=""
                    placeholder="Enter Effective Date"
                    onChange={(e) =>
                      setStartAppraisalsDetails((prevState) => ({
                        ...prevState,
                        appraisal_effective_date: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </>
          )}
          {/* <div className="row">
            <div className="col-md-6">
              <label htmlFor="">Enter C.T.C</label>
              <input
                type="text"
                className="form-control "
                id=""
                aria-describedby=""
                placeholder="Enter CTC"
                required
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="">Effective Date</label>
              <input
                type="date"
                className="form-control "
                id=""
                aria-describedby=""
                placeholder="Enter Effective Date"
                required
              />
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-md-12">
              <label htmlFor="">Notes</label>
              <textarea className="form-control " />
            </div>
          </div> */}
          <div className="my-3">
            <button className="btn btn-danger me-3" type="submit">
              Save & Exit
            </button>
          </div>
        </div>
      </form>
      <button
        className="btn btn-light appraisalCancelBtn"
        onClick={props.closeAppraisalModal}
      >
        Cancel
      </button>
    </>
  );
}
