import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";
import { useAlert } from "react-alert";
//import { Alert } from "react-bootstrap";

// import AssignLeavesToUser from "../Leaves/AssignLeavesToUser";
// import AssignRHtoTheUser from "./AssignRHtoTheUser";

const CreateLeaveCategory = (props) => {
  const [leaveCategory, setLeaveCategory] = useState("");
  const [leaveList, setLeaveList] = useState();

  // const [show, setShow] = useState(true);
  // const [successData, setSuccessData] = useState("");

  const alert = useAlert();

  const userID = window.location.href.split("/").reverse()[0];

  const updateLeaveCategoryHandler = (event) => {
    event.preventDefault();

    (async () => {
      const url = API_URL + "/fetch-update-delete/?user_id=" + userID;

      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };
      const bodyContent = JSON.stringify({
        leave_category: leaveCategory,
      });

      try {
        const res = await fetch(url, {
          method: "PATCH",
          headers: headersList,
          body: bodyContent,
        });
        const data = await res.json();
        console.log(data);
        if (data.status) {
          alert.success(data.message);
        }
        if (data.status === false) {
          alert.error(data.message);
        }
        // setSuccessData(data);
        // setShow(true);
      } catch (err) {
        console.error(err);
        alert.error("Internal Server Errors");
      }
    })();
  };

  console.log(props.leaverule);

  useEffect(() => {
    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
      };

      const url = API_URL + "/leave-category-rule/";

      try {
        const res = await fetch(url, { method: "GET", headers: headersList });
        const data = await res.json();
        console.log(data);
        setLeaveList(data.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <>
      <hr />
      {/* {show && successData.status === true ? (
        <Alert variant="success" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{successData.message}</Alert.Heading>
        </Alert>
      ) : show && successData.status === false ? (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{successData.message}</Alert.Heading>
        </Alert>
      ) : (
        ""
      )} */}
      <form
        onSubmit={(event) => {
          updateLeaveCategoryHandler(event);
        }}
        method="POST"
        name="contact-form"
      >
        {/* <h3 style={{ color: "red" }}>Update Leave Type for {props.name}</h3> */}
        <div className="row">
          <div className="col-md-4 mb-3">
            <h3 style={{ color: "red" }}>
              {" "}
              Select a weekoff rule for {props.name}
            </h3>
          </div>
          <div className="col-md-4 mb-3">
            {/* <label htmlFor="designition">Select Weekoff Rule</label> */}
            <select
              className="form-select form-control"
              id="inputGroupSelect02"
              onChange={(e) => setLeaveCategory(e.target.value)}
              required
            >
              <option hidden disabled selected>
                Select Leave Type
              </option>
              {leaveList && leaveList.length >= 0
                ? leaveList.map((list) => {
                    return (
                      <option
                        value={list.id}
                        key={list.id}
                        selected={props.leaverule === list.id}
                      >
                        {list.leave_category}
                      </option>
                    );
                  })
                : ""}
            </select>
          </div>
          <div className="col-md-4">
            <button
              type="submit"
              className="departmentBtnSecondary align-middle"
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      {/* <AssignLeavesToUser name={props.name} />
      <AssignRHtoTheUser name={props.name} /> */}
    </>
  );
};

export default CreateLeaveCategory;
