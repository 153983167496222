import { useState } from "react";
import { API_URL } from "../../Constents";
import { Modal } from "react-bootstrap";
import RoleList from "./RoleList";

const CreateRole = () => {
  const [roleName, setRoleName] = useState("");
  const [responseData, setResponseData] = useState("");
  const [show, setShow] = useState(false);
  const userData = JSON.parse(window.sessionStorage.getItem("user"));

  console.log(roleName);

  const createRoleHandler = (event) => {
    event.preventDefault();
    (async () => {
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        role_name: roleName,
      });
      const url = API_URL + "/role/";

      try {
        const res = await fetch(url, {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        });

        const data = await res.json();
        console.log(data);
        setResponseData(data);
        setRoleName("");
      } catch (err) {
        console.error(err);
      }
    })();
  };

  return (
    <>
      <div className="container">
        <form
          onSubmit={(event) => {
            createRoleHandler(event);
          }}
        >
          <div className="row align-items-end">
            <div className="col-md-12 col-lg-12 mt-4">
              <h3 style={{ color: "red" }}>Create Roles for Organization</h3>
            </div>
            <div className="col-md-6 col-lg- mt-4">
              <h5>Add multiple roles for your organisation</h5>
            </div>
            <div className="col-md-3 col-lg- mt-4">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Role Name"
                onChange={(e) => setRoleName(e.target.value)}
                required
              />
            </div>
            <div className="col-md-3 col-lg- mt-4">
              <button className="btn btn-warning" type="submit">
                Create Roles
              </button>
            </div>
            <div className="col-md-3 mt-4"></div>
          </div>
        </form>
        <button className="save_btn" onClick={() => setShow(true)}>
          See roles
        </button>
        {responseData &&
        responseData !== undefined &&
        responseData !== null &&
        responseData.status === true ? (
          <p className="msg_success mt-3">
            *{responseData.data.role_name}: {responseData.message}
          </p>
        ) : responseData &&
          responseData !== undefined &&
          responseData !== null &&
          responseData.status === false ? (
          <p className="msg_fail mt-3">*{responseData.message}</p>
        ) : (
          ""
        )}

        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Role List: {userData.data.first_name}</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <RoleList />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CreateRole;
