import { useState } from "react";

import { useAlert } from "react-alert";

import { API_URL, TODAY_DATE } from "../../../../Constents";

const ReOpenExpiredVacancy = ({ closeReopenVacancyModal, clickedRowData }) => {
  const [startDate, setStartDate] = useState(clickedRowData.vacancy_start_date);
  const [endDate, setEndDate] = useState(clickedRowData.vacancy_end_date);

  const alert = useAlert();

  const reopenExistingVacancyHandler = async (e) => {
    e.preventDefault();
    const URL =
      API_URL + "/admin-vacancy-crud/?vacancy_id=" + clickedRowData.vacancy_id;
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    try {
      const bodyContent = new FormData();
      bodyContent.append("vacancy_end_date", endDate);
      bodyContent.append("vacancy_start_date", startDate);

      const response = await fetch(URL, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);
      if (data.status) {
        closeReopenVacancyModal();
        alert.success(data.message);
      }
      console.log(data);
      if (data.status === false) alert.error(data.message);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form onSubmit={reopenExistingVacancyHandler}>
      <div className="row">
        <h3>Reopen Vacancy</h3>
        <hr />
        <div className="col-md-6">
          <label>Start Date</label>
          <input
            type="date"
            className="form-control mb-3"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            min={TODAY_DATE}
            required
          />
        </div>
        <div className="col-md-6">
          <label>End Date</label>
          <input
            type="date"
            className="form-control mb-3"
            value={endDate}
            min={TODAY_DATE}
            onChange={(e) => setEndDate(e.target.value)}
            required
          />
        </div>
      </div>

      <button type="submit" className="btn btn-warning">
        Reopen
      </button>
    </form>
  );
};

export default ReOpenExpiredVacancy;
