import { useAlert } from "react-alert";

import { API_URL } from "../../../../Constents";

const ExpireExistingVacancy = ({ closeExpireVacancyModal, clickedRowData }) => {
  const alert = useAlert();

  const expireExistingVacancyHandler = async (e) => {
    e.preventDefault();
    const URL =
      API_URL + "/admin-vacancy-crud/?vacancy_id=" + clickedRowData.vacancy_id;
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    try {
      const bodyContent = new FormData();
      bodyContent.append("is_active", "false");

      const response = await fetch(URL, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);
      if (data.status) {
        closeExpireVacancyModal();
        alert.success(data.message);
      }
      console.log(data);
      if (data.status === false) alert.error(data.message);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div onSubmit={expireExistingVacancyHandler} className="text-center">
      <h3>Are you sure you want to expire this vacancy? </h3>

      <button
        type="submit"
        className="btn btn-outline-danger btn-lg"
        onClick={expireExistingVacancyHandler}
      >
        Yes, Expire
      </button>
    </div>
  );
};

export default ExpireExistingVacancy;
