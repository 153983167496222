import { useState } from "react";
import { Modal } from "react-bootstrap";
import AddANewOfficeAddress from "./AddANewOfficeAddress";
import NewGateLable from "./NewGateLable";
import LableandLoacationTable from "./LableandLoacationTable";

export default function ViewAndGenerateQR() {
  const [show, setShow] = useState({
    modalOne: false,
    modalTwo: false,
  });
  const [refreshDropDown, setRefreshDropdown] = useState(false);

  const toggleDropdownState = () => {
    setRefreshDropdown(!refreshDropDown);
  };

  return (
    <div className="setting_header ps-3 py-4">
      <div>
        <h3 style={{ color: "red" }}>Location Setting</h3>
        <div className="row">
          <div className="col-md-3 my-2">
            <button
              className="departmentSettingBtn formSelectPlus mt-3"
              onClick={() => setShow({ ...show, modalOne: true })}
            >
              Create Location
            </button>
          </div>
          <div className="col-md-3 my-2">
            <button
              className="departmentSettingBtn mt-3 formSelectPlus"
              onClick={() => setShow({ ...show, modalTwo: true })}
            >
              Create Label
            </button>
          </div>
        </div>
        <div>
          <LableandLoacationTable refresh={show} />
        </div>
      </div>
      <Modal
        show={show.modalOne}
        onHide={() => setShow({ ...show, modalOne: false })}
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "red" }}>
            Add Office Address{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddANewOfficeAddress
            location={toggleDropdownState}
            close={() =>
              setShow({
                ...show,
                modalOne: false,
              })
            }
          />
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <button
            className="modalBtn"
            onClick={() => setShow({ ...show, modalOne: false })}
            style={{ width: "30%", fontSize: "20px" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show.modalTwo}
        onHide={() => setShow({ ...show, modalTwo: false })}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "red" }}>Create Label</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewGateLable
            close={() => {
              setShow({ ...show, modalTwo: false });
            }}
          />
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <button
            className="modalBtn"
            onClick={() => setShow({ ...show, modalTwo: false })}
            style={{ width: "30%", fontSize: "20px" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
