import React, { useState, useEffect } from "react";
//table imports
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { API_URL, TODAY_DATE } from "../../Constents";
import { Modal, Spinner } from "react-bootstrap";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { AiFillFile } from "react-icons/ai";
import { useAlert } from "react-alert";
const AddressDetailsUpdateRequest = () => {
  const [ticketData, setTicketData] = useState();
  const [err, setErr] = useState();
  const [details, setDetails] = useState({
    startDate: "",
    endDate: "",
    status: "pending",
  });
  const [show, setShow] = useState(false);
  const [nameForModal, setNameForModal] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [ID, setID] = useState("");
  const [showFile, setShowFile] = useState(false);
  const [fileURL, setFileURL] = useState();
  const alert = useAlert();
  const dateLast =
    details.startDate && details.endDate === "" ? TODAY_DATE : details.endDate;
  const dateStart =
    details.startDate === "" && details.endDate !== ""
      ? TODAY_DATE
      : details.startDate;

  const fetchData = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url =
      API_URL +
      "/fetch-update-edit-request/?category=address_update&start_date=" +
      dateStart +
      "&last_date=" +
      dateLast +
      "&request_status=" +
      details.status;

    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      console.log(data);
      setTicketData(data);
    } catch (err) {
      console.error(err);
      setErr(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    details.startDate,
    details.endDate,
    details.issue,
    details.status,
    errorMessage,
    show,
  ]);

  if (!ticketData) return <h1>Loading...</h1>;

  if (err) return <h1>Some error occured</h1>;

  const { SearchBar } = Search;

  const columns = [
    { dataField: "employee.first_name", text: "Raised By", sort: true },

    {
      dataField: "address_line_1",
      text: "Updated Address",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <>{row.address_line_1 + "," + row.address_line_2}</>;
      },

      // style: (cell, row, rowIndex, colIndex) => {
      //   return { backgroundColor: "rgba(111, 1, 25, 0.25)" };
      // },
    }, //filter: textFilter() },
    {
      dataField: "raised_on",
      text: "Raised On",
      sort: true,
    },
    {
      dataField: "reason",
      text: "Reason",
      sort: true,
    },

    {
      dataField: "request_status",
      text: "Status",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log(row);
        console.log(row.employee.first_name);
        if (row.request_status === "pending")
          return (
            <button
              className="btn btn-link msg_fail text-decoration-none"
              onClick={() => {
                setShow(true);
                setNameForModal(row.employee.first_name);
                setID(row.id);
              }}
            >
              Pending
            </button>
          );
        if (row.request_status === "approved")
          return <p className="msg_success">Approved</p>;
        if (row.request_status === "rejected")
          return <p className="msg_fail">Rejected</p>;
      },
    },
    {
      dataField: "file",
      text: "File",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.attachement == null || row.attachement === "")
          return "No Attachement";
        else
          return (
            <button
              className="btn btn-link"
              onClick={() => {
                setShowFile(true);
                setFileURL(row.attachement);
              }}
            >
              <AiFillFile color="#DD3974" />
            </button>
          );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  const updateRequestStatusHandler = async (actionType) => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      request_status: actionType,
    });

    const url = API_URL + "/fetch-update-edit-request/?details_id=" + ID;

    try {
      const res = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });
      const data = await res.json();
      console.log(data);
      if (data.status) {
        setShow(false);
        alert.success(data.message);
      }
      if (data.status === false) {
        alert.error(data.message);
        setShow(false);
      }
    } catch (err) {
      console.log(err);
      alert.error("Internal Server Error");
    }
  };

  return (
    <div className="container">
      <div className=" all_form_spacing">
        <div className="row mb-3">
          <div className="col-md-9"></div>
          <div className="col-md-3">
            {errorMessage !== "" ? (
              <p className="msg_fail mt-3">{errorMessage}</p>
            ) : (
              ""
            )}
          </div>
        </div>

        <ToolkitProvider
          keyField="id"
          data={ticketData.data}
          columns={columns}
          bootstrap4
          search
        >
          {(props) => (
            <React.Fragment>
              <div className="container">
                <div className="row align-items-end">
                  <div className="col mb-3">
                    <SearchBar srText="Search Table" {...props.searchProps} />
                  </div>
                  <div className="col mb-3">
                    <label htmlFor="dob">Start Date</label>
                    <input
                      className="form-select form-control"
                      type="date"
                      id="StartDate"
                      name="StartDate"
                      value={details.startDate}
                      max={TODAY_DATE}
                      onChange={(e) => {
                        setDetails({ ...details, startDate: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col mb-3">
                    <label htmlFor="dob">End Date</label>
                    <input
                      className="form-select form-control"
                      type="date"
                      id="birthday"
                      name="birthday"
                      value={details.endDate}
                      max={TODAY_DATE}
                      onChange={(e) => {
                        setDetails({ ...details, endDate: e.target.value });
                      }}
                    />
                  </div>

                  <div className="col mb-3">
                    <label htmlFor="designition">Filter By Status</label>
                    <select
                      className="form-select form-control"
                      id="inputGroupSelect02"
                      onChange={(e) => {
                        setDetails({ ...details, status: e.target.value });
                      }}
                    >
                      <option value="pending">Pending</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                      <option value="">All</option>
                    </select>
                  </div>
                </div>
              </div>
              <BootstrapTable
                pagination={pagination}
                filter={filterFactory()}
                {...props.baseProps}
                noDataIndication={() => {
                  return <h3>No data available </h3>;
                }}
                condensed
                hover
                bordered={false}
                tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      </div>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          {/* <Modal.Title>Approve The request</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center mb-3">
            <h4>
              Approve <i>{nameForModal}</i>'s Request ?
            </h4>{" "}
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-success btn-lg me-3"
              onClick={() => updateRequestStatusHandler("approved")}
            >
              Approve
            </button>
            <button
              className="btn btn-danger btn-lg"
              onClick={() => updateRequestStatusHandler("rejected")}
            >
              Reject
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal size="xl" show={showFile} onHide={() => setShowFile(false)}>
        <Modal.Header closeButton>Attached File</Modal.Header>
        <Modal.Body>
          <div className="text-center">
            {fileURL && fileURL !== null ? (
              <img src={fileURL} alt="WFH_File" className="img-fluid" />
            ) : (
              <Spinner />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddressDetailsUpdateRequest;
