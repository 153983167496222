import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//table imports
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { API_URL, BASE_URL, TODAY_DATE } from "../../Constents";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import LoadingGifs from "../../images/YAAMS-gif.gif";
const Help = () => {
  const [ticketData, setTicketData] = useState();
  const [err, setErr] = useState();
  const [radioData, setRadioData] = useState({});
  const [errorlist, setErrorList] = useState([]);
  const [details, setDetails] = useState({
    startDate: "",
    endDate: "",
    issue: "",
    status: "pending",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const dateLast =
    details.startDate && details.endDate === "" ? TODAY_DATE : details.endDate;
  const dateStart =
    details.startDate === "" && details.endDate !== ""
      ? TODAY_DATE
      : details.startDate;

  const fetchData = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      start_date: dateStart,
      last_date: dateLast,
      ticket_type: details.issue,
      ticket_status: details.status,
    });

    const url = API_URL + "/filter-ticket/";

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: headersList,
        body: bodyContent,
      });
      const data = await res.json();
      console.log(data);
      setTicketData(data);
    } catch (err) {
      console.error(err);
      setErr(err);
    }
  };

  const fetchErrorList = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/error-list/";

    try {
      const res = await fetch(url, { method: "GET", headers: headersList });
      const data = await res.json();

      setErrorList(data.data);
    } catch (err) {
      console.error(err);
    }
  };
  const radioDataHandler = (e, id) => {
    if (e.currentTarget.checked) {
      setRadioData((prev) => ({ ...prev, [id]: true }));
    } else {
      setRadioData((prev) => ({ ...prev, [id]: false }));
    }
  };

  const bulkIssueResolveHandler = () => {
    const checkedArray = [];
    for (const props in radioData) {
      if (radioData[props] === true) {
        checkedArray.push({
          id: +props,
          ticket_status: "resolved",
        });
      }
    }
    console.log(checkedArray);

    if (checkedArray.length === 0) {
      setErrorMessage("Please Select Atleast one Checkbox");
    } else {
      (async () => {
        const userData = JSON.parse(window.sessionStorage.getItem("user"));
        const headersList = {
          Authorization: "Token " + userData.seucrity_token,
          "Content-Type": "application/json",
        };

        const bodyContent = JSON.stringify(checkedArray);
        const url = API_URL + "/bulk-update/";
        try {
          const res = await fetch(url, {
            method: "PATCH",
            body: bodyContent,
            headers: headersList,
          });
          if (res.status === 200) setErrorMessage("Status Has been resolved");
          else setErrorMessage("There was an error in updating");
        } catch (err) {
          console.error(err);
        }
      })();
    }
  };

  useEffect(() => {
    fetchErrorList();
  }, []);
  useEffect(() => {
    fetchData();
  }, [
    details.startDate,
    details.endDate,
    details.issue,
    details.status,
    errorMessage,
  ]);

  console.log(ticketData);
  console.log(details);

  if (!ticketData)
    return (
      <div className="loadingGif">
        <img src={LoadingGifs} alt="Loading Gif" />
      </div>
    );

  if (err) return <h1>Some error occured</h1>;

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div className="form-check d-flex justify-content-center">
            <input
              className="form-check-input"
              type="checkbox"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              onChange={(e, id = row.id) => radioDataHandler(e, id)}
            />
          </div>
        );
      },
    },
    { dataField: "employee_name", text: "Name", sort: true },
    {
      dataField: "title_name",
      text: "Issue",
      sort: true,

      // style: (cell, row, rowIndex, colIndex) => {
      //   return { backgroundColor: "rgba(111, 1, 25, 0.25)" };
      // },
    }, //filter: textFilter() },
    // {
    //   dataField: "employee_email",
    //   text: "Email",
    //   sort: true,
    // },

    {
      dataField: "generated_at",
      text: "Raised On",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log(row.account_number);
        return (
          <p>
            {row.generated_on} at {row.generated_at}
          </p>
        );
      },
    },
    { dataField: "generated_on", text: "Date", sort: true },
    {
      dataField: "ticket_status",
      text: "Status",
      sort: true,
      formatter: (row) => {
        if (row === "pending") return <p className="msg_fail">Pending</p>;
        if (row === "resolved") return <p className="msg_success">Resolved</p>;
      },
    },
    {
      dataField: "ticket_id",
      text: "Ticket",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <Link
            to={BASE_URL + "UserHelp/" + row.ticket_id}
            style={{ textDecoration: "none", color: "green" }}
          >
            {row.ticket_id}
            <BsBoxArrowUpRight
              style={{
                fontSize: "10px",
                marginLeft: "3px",
                marginBottom: "10px",
                color: "green",
                fontWeight: "600",
              }}
            />
          </Link>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  return (
    <div className="container">
      <div className="highlight all_form_spacing">
        <div className="row mb-3">
          <div className="col-md-9">
            {" "}
            <h2 style={{color:"#DD3944"}}> Ticket Raised </h2>
          </div>
          <div className="col-md-3">
            <button
              className="modalBtn"
              onClick={bulkIssueResolveHandler}
            >
              Mark As Resolved
            </button>
            {errorMessage !== "" ? (
              <p className="msg_fail mt-3">{errorMessage}</p>
            ) : (
              ""
            )}
          </div>

          {/* {checkedArray.length > 0 ? (
            <div className="col-md-3">
              <button className="btn btn-success btn-lg">
                Mark As Resolved
              </button>
            </div>
          ) : (
            ""
          )} */}
        </div>
        {/* <h1>
          {" "}
          Ticket Raised{" "}
          <button className="btn btn-primary">Issue Resolved</button>
        </h1> */}
        <ToolkitProvider
          keyField="id"
          data={ticketData.data}
          columns={columns}
          bootstrap4
          search
        >
          {(props) => (
            <React.Fragment>
              <div className="container">
                <div className="row align-items-end">
                  <div className="col mb-2">
                    {/* <SearchBar
                      srText=""
                      placeholder="Search Here..."
                      {...props.searchProps}
                      className="newSearch"
                      // style={{ border: "none" }}
                    /> */}
                        <div className="position-relative newsearch_box">
                    <SearchBar
                      className="newSearchWrapper"
                      placeholder="Search.."
                      srText=""
                      {...props.searchProps}
                    />
                    <BiSearch className="searchIcon"/>
                  </div>
                  </div>
                  <div className="col mb-3">
                    <label htmlFor="dob">Start Date</label>
                    <input
                      className="form-select form-control"
                      type="date"
                      id="StartDate"
                      name="StartDate"
                      value={details.startDate}
                      max={TODAY_DATE}
                      onChange={(e) => {
                        setDetails({ ...details, startDate: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col mb-3">
                    <label htmlFor="dob">End Date</label>
                    <input
                      className="form-select form-control"
                      type="date"
                      id="birthday"
                      name="birthday"
                      value={details.endDate}
                      max={TODAY_DATE}
                      onChange={(e) => {
                        setDetails({ ...details, endDate: e.target.value });
                      }}
                    />
                  </div>
                  <div className="col mb-3">
                    <label htmlFor="designition">Filter By Issue</label>
                    <select
                      className="form-select form-control"
                      id="inputGroupSelect02"
                      onChange={(e) => {
                        setDetails({ ...details, issue: e.target.value });
                      }}
                    >
                      <option value="">All</option>
                      {errorlist.map((list) => {
                        return (
                          <option value={list.error_type}>
                            {list.error_type}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col mb-3">
                    <label htmlFor="designition">Filter By Status</label>
                    <select
                      className="form-select form-control"
                      id="inputGroupSelect02"
                      onChange={(e) => {
                        setDetails({ ...details, status: e.target.value });
                      }}
                    >
                      <option value="pending">Pending</option>
                      <option value="resolved">Resolved</option>
                      <option value="">All</option>
                    </select>
                  </div>
                </div>
              </div>
              <BootstrapTable
                pagination={pagination}
                filter={filterFactory()}
                {...props.baseProps}
                noDataIndication={() => {
                  return <h3>No data available </h3>;
                }}
                condensed
                bordered={false}
                tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
              />
            </React.Fragment>
            // <div>
            //   <SearchBar {...props.searchProps} style={{ width: "30%" }} />
            //   <hr />

            // </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
};

export default Help;
