import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";

const ApplyLeaveCategoryGlobally = () => {
  const [leaveList, setLeaveList] = useState();
  const [leaveCategory, setLeaveCategory] = useState("");
  const [leaveApplyMsg, setLeaveApplyMsg] = useState("");

  useEffect(() => {
    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
      };

      const url = API_URL + "/leave-category-rule/";

      try {
        const res = await fetch(url, { method: "GET", headers: headersList });
        const data = await res.json();
        console.log(data);
        setLeaveList(data.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const applyLeaveCategoryHandler = (event) => {
    event.preventDefault();
    console.log("Submitted");

    (async () => {
      try {
        const url = API_URL + "/assign-leave-rule/";
        const userData = JSON.parse(window.sessionStorage.getItem("user"));

        const headersList = {
          Authorization: "Token " + userData.seucrity_token,
          "Content-Type": "application/json",
        };

        const bodyContent = JSON.stringify({
          leave_category: leaveCategory,
        });

        const res = await fetch(url, {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        });

        const data = await res.json();

        setLeaveApplyMsg(data);
        console.log(data);
      } catch (err) {
        setLeaveApplyMsg({
          status: false,
          message: err,
        });
        console.error(err);
      }
    })();
  };

  console.log(leaveCategory);

  return (
    <div className="container">
      {leaveApplyMsg.status === true ? (
        <p style={{ color: "green" }}>{leaveApplyMsg.message}</p>
      ) : leaveApplyMsg.status === false ? (
        <p style={{ color: "red" }}>{leaveApplyMsg.message}</p>
      ) : (
        ""
      )}
      <form
        onSubmit={(event) => {
          applyLeaveCategoryHandler(event);
        }}
      >
        <div className="row align-items-end">
          <div className="col-md-3 col-lg- mt-4">
            <h3 style={{ color: "red" }}>Weekoff Rule</h3>
          </div>
          <div className="col-md-3 col-lg- mt-4"></div>
          <div className="col-md-3 col-lg- mt-4">
            <select
              className="form-select form-control"
              id="inputGroupSelect02"
              onChange={(e) => setLeaveCategory(e.target.value)}
            >
              <option value="none" selected disabled>
                Select Rule
              </option>
              {leaveList && leaveList.length >= 0
                ? leaveList.map((list) => {
                    return (
                      <option value={list.id} key={list.id}>
                        {list.leave_category}
                      </option>
                    );
                  })
                : ""}
            </select>
          </div>
          <div className="col-md-3 col-lg- mt-4">
            <button className="btn btn-warning">Apply Rule</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ApplyLeaveCategoryGlobally;
