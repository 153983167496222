import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

import Layout from "./Components/Layout";

import { API_URL, BASE_URL } from "./Constents";

const App = () => {
  const [response, setResponse] = useState();
  const [isLoading, setIsLoading] = useState();

  const history = useHistory();
  const alert = useAlert();

  useEffect(() => {
    setIsLoading(true);
    const userData = window.sessionStorage.getItem("user");
    if (userData) {
      setResponse(JSON.parse(userData));
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, []);

  //You know what this function do ;)
  const loginFun = async (details) => {
    const headersList = {
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      email: details.email,
      password: details.password,
    });

    const url = API_URL + "/login/";

    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const result = await response.json();

      if (result.status === true && result.data.is_staff) {
        window.sessionStorage.setItem("user", JSON.stringify(result));
        alert.success(result.message);
      }
      console.log(result);
      setResponse(result);
    } catch (error) {
      alert.error("Internal Server Error");
      console.error(error);
    }
  };

  // This function updates the authentication token and removes it from the session storage
  const logoutFun = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/logout/";

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: headersList,
      });
      const data = await res.json();
      console.log(data);
    } catch (err) {
      console.error(err);
    }

    window.sessionStorage.removeItem("user");
    setResponse(null);

    history.push(BASE_URL); // for sending the user to the login page
  };

  if (response) {
    var logErr = response.status;
    var logMsg = response.message;
    if (response.data) {
      var user = response.data.is_staff;
      if (user === false) alert.info("Unauthorised Access");
    }
    if (logErr === false) alert.error(logMsg);
  }

  console.log(response);

  // isLayout is a boolean for user authentication. Only is_staff === true can acces the admin panel
  const isLayout =
    response &&
    response.status &&
    response.data.is_staff &&
    response.data.is_staff !== undefined &&
    response.data.is_staff !== null;

  return (
    <>
      {isLoading && <p>loading...</p>}
      <div className="non-printable">
        <Layout
          logout={logoutFun}
          loginData={response}
          login={loginFun}
          isLayout={isLayout}
        />
      </div>
    </>
  );
};

export default App;
