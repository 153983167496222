import { API_URL } from "../../../Constents";
import { useAlert } from "react-alert";

const DeleteNotificationsAndPoll = (props) => {
  const alert = useAlert();

  const deleteNotificationsAndPollHandler = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    if (props.deleteDataRowType.type === "Poll") {
      try {
        const response = await fetch(
          API_URL +
            "/admin-create-fetch-poll/?question_id=" +
            props.deleteDataRowType.id,
          {
            method: "DELETE",
            headers: headersList,
          }
        );

        const data = await response.json();
        if (data.status) {
          alert.success(data.message);
          props.close();
        } else alert.error(data.message);
      } catch (err) {
        console.error(err);
        alert.error("Internal Server Error");
      }
    } else {
      try {
        const response = await fetch(
          API_URL +
            "/admin-push-notification/?notification_id=" +
            props.deleteDataRowType.id,
          {
            method: "DELETE",
            headers: headersList,
          }
        );

        const data = await response.json();
        if (data.status) {
          alert.success(data.message);
          props.close();
        } else alert.error(data.message);
      } catch (err) {
        console.error(err);
        alert.error("Internal Server Error");
      }
    }
  };

  return (
    <>
      <h3 className="d-flex justify-content-center mb-3">
        Are You Sure You want to Delete?
      </h3>
      <div className="d-flex justify-content-center mb-3">
        <button
          className="btn btn-danger btn-lg me-3"
          onClick={deleteNotificationsAndPollHandler}
        >
          Yes, Delete
        </button>
        <button className="btn btn-info btn-lg" onClick={props.close}>
          No, Take Me Back
        </button>
      </div>
    </>
  );
};

export default DeleteNotificationsAndPoll;
