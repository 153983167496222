import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { API_URL } from "../../Constents";
import { Alert } from "react-bootstrap";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { Modal } from "react-bootstrap";
const CreateGeneralLeaveCategory = () => {
  const [details, setDetails] = useState({
    leave_category: null,
    is_friday: false,
    is_monday: false,
    is_saturday: false,
    is_sunday: false,
    is_thursday: false,
    is_tuesday: false,
    is_wednesday: false,
  });

  const [show, setShow] = useState(true);
  const [successData, setSuccessData] = useState("");
  const [condtionForAPI, setConditionForAPI] = useState("");
  const [leaveList, setLeaveList] = useState();

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const [showEdit, setShowEdit] = useState(false);
  const [leaveType, setLeaveType] = useState();

  const numberOfTrue = Object.values(details).filter(
    (item) => item === true
  ).length;

  const updateLeaveCategoryHandler = (event) => {
    event.preventDefault();
    if (numberOfTrue <= 6) {
      (async () => {
        const url = API_URL + "/leave-category-rule/";
        const userData = JSON.parse(window.sessionStorage.getItem("user"));
        const headersList = {
          Authorization: "Token " + userData.seucrity_token,
          "Content-Type": "application/json",
        };

        const bodyContent = JSON.stringify({
          leave_category: details.leave_category,
          is_sunday: details.is_sunday,
          is_monday: details.is_monday,
          is_tuesday: details.is_tuesday,
          is_wednesday: details.is_wednesday,
          is_thursday: details.is_thursday,
          is_friday: details.is_friday,
          is_saturday: details.is_saturday,
        });

        try {
          const res = await fetch(url, {
            method: "POST",
            headers: headersList,
            body: bodyContent,
          });
          const data = await res.json();
          console.log(data);
          setSuccessData(data);
          setShow(true);
          setShowEdit(false);
        } catch (err) {
          console.error(err);
        }
      })();
      setConditionForAPI("");
    } else {
      setConditionForAPI("There should be atleast one work day");
    }
  };

  useEffect(() => {
    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
      };

      const url = API_URL + "/leave-category-rule/";

      try {
        const res = await fetch(url, { method: "GET", headers: headersList });
        const data = await res.json();
        console.log(data);
        setLeaveList(data.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [show, showEdit, successData]);

  console.log(leaveList);

  return (
    <div className="setting_header ps-3 py-4">
      {show && successData.status === true ? (
        <Alert variant="success" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{successData.message}</Alert.Heading>
        </Alert>
      ) : show && successData.status === false ? (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{successData.message}</Alert.Heading>
        </Alert>
      ) : (
        ""
      )}
      <form
        onSubmit={(event) => {
          updateLeaveCategoryHandler(event);
        }}
        method="POST"
        name="contact-form"
      >
        <h2 style={{ color: "#DD3944" }}>Create week off Rule </h2>
        {condtionForAPI !== null && condtionForAPI !== "" && condtionForAPI ? (
          <p style={{ color: "red" }}>{condtionForAPI}</p>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-md-4 mb-3">
            <label htmlFor="fname">Enter Rule Name</label>
            <input
              type="text"
              id="fname"
              placeholder="Eg: L1, L2.. L10 etc "
              name="fname"
              onChange={(e) =>
                setDetails({ ...details, leave_category: e.target.value })
              }
              className="form-control"
              pattern="^[A-Z][0-9]*$"
              title="Eg: L1, L2, A10 .. -> Enter rule in Letter(in capitals) followed by a number"
              value={details.first_name}
              required
            />
          </div>

          <div className="col-md-1 mb-1">
            <label htmlFor="designition">Mon</label>

            <Form.Check
              aria-label="option 1"
              checked={details.is_monday === true}
              onChange={(e) =>
                setDetails({ ...details, is_monday: e.target.checked })
              }
            />
          </div>
          <div className="col-md-1 mb-1">
            <label htmlFor="designition">Tue</label>

            <Form.Check
              aria-label="option 1"
              checked={details.is_tuesday === true}
              onChange={(e) =>
                setDetails({ ...details, is_tuesday: e.target.checked })
              }
            />
          </div>
          <div className="col-md-1 mb-1">
            <label htmlFor="designition">Wed</label>

            <Form.Check
              aria-label="option 1"
              checked={details.is_wednesday === true}
              onChange={(e) =>
                setDetails({ ...details, is_wednesday: e.target.checked })
              }
            />
          </div>
          <div className="col-md-1 mb-1">
            <label htmlFor="designition">Thu</label>

            <Form.Check
              aria-label="option 1"
              checked={details.is_thursday === true}
              onChange={(e) =>
                setDetails({ ...details, is_thursday: e.target.checked })
              }
            />
          </div>
          <div className="col-md-1 mb-1">
            <label htmlFor="designition">Fri</label>

            <Form.Check
              aria-label="option 1"
              checked={details.is_friday === true}
              onChange={(e) =>
                setDetails({ ...details, is_friday: e.target.checked })
              }
            />
          </div>
          <div className="col-md-1 mb-1">
            <label htmlFor="designition">Sat</label>

            <Form.Check
              aria-label="option 1"
              checked={details.is_saturday === true}
              onChange={(e) =>
                setDetails({ ...details, is_saturday: e.target.checked })
              }
            />
          </div>
          <div className="col-md-1 mb-1">
            <label htmlFor="designition">Sun</label>

            <Form.Check
              aria-label="option 1"
              checked={details.is_sunday === true}
              onChange={(e) =>
                setDetails({ ...details, is_sunday: e.target.checked })
              }
            />
          </div>
        </div>

        <button type="submit" className="mb-3 btn btn-outline-danger btn-lg">
          Submit
        </button>
      </form>
      <div className="mt-4">
        <table class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col">Rule Name</th>
              <th scope="col">Mon</th>
              <th scope="col">Tue</th>
              <th scope="col">Wed</th>
              <th scope="col">Thu</th>
              <th scope="col">Fri</th>
              <th scope="col">Sat</th>
              <th scope="col">Sun</th>
              <th scope="col">Edit</th>
            </tr>
          </thead>
          <tbody>
            {leaveList && leaveList.length >= 0
              ? leaveList.map((list) => {
                  return (
                    <tr>
                      <th scope="row">{list.leave_category}</th>
                      <td>
                        {list.is_monday ? (
                          <p style={{ color: "red" }}>Off</p>
                        ) : (
                          <p style={{ color: "green" }}>Work</p>
                        )}
                      </td>
                      <td>
                        {list.is_tuesday ? (
                          <p style={{ color: "red" }}>Off</p>
                        ) : (
                          <p style={{ color: "green" }}>Work</p>
                        )}
                      </td>
                      <td>
                        {list.is_wednesday ? (
                          <p style={{ color: "red" }}>Off</p>
                        ) : (
                          <p style={{ color: "green" }}>Work</p>
                        )}
                      </td>
                      <td>
                        {list.is_thursday ? (
                          <p style={{ color: "red" }}>Off</p>
                        ) : (
                          <p style={{ color: "green" }}>Work</p>
                        )}
                      </td>
                      <td>
                        {list.is_friday ? (
                          <p style={{ color: "red" }}>Off</p>
                        ) : (
                          <p style={{ color: "green" }}>Work</p>
                        )}
                      </td>
                      <td>
                        {list.is_saturday ? (
                          <p style={{ color: "red" }}>Off</p>
                        ) : (
                          <p style={{ color: "green" }}>Work</p>
                        )}
                      </td>
                      <td>
                        {list.is_sunday ? (
                          <p style={{ color: "red" }}>Off</p>
                        ) : (
                          <p style={{ color: "green" }}>Work</p>
                        )}
                      </td>

                      <td>
                        <Button variant="link">
                          <BsBoxArrowUpRight
                            onClick={() => {
                              handleShowEdit();
                              setLeaveType(list.leave_category);
                            }}
                          />
                        </Button>
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
      </div>
      <Modal size="lg" show={showEdit} onHide={handleCloseEdit} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(event) => {
              updateLeaveCategoryHandler(event);
            }}
            method="POST"
            name="contact-form"
          >
            <h3>Edit Leave Category "{leaveType}" </h3>
            {condtionForAPI !== null &&
            condtionForAPI !== "" &&
            condtionForAPI ? (
              <p style={{ color: "red" }}>{condtionForAPI}</p>
            ) : (
              ""
            )}
            <div className="row">
              {/* {setDetails({ ...details, leave_category: leaveType })} */}

              <div className="col-md-1 mb-1">
                <label htmlFor="designition">Mon</label>

                <Form.Check
                  aria-label="option 1"
                  checked={details.is_monday === true}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      is_monday: e.target.checked,
                      leave_category: leaveType,
                    })
                  }
                />
              </div>
              <div className="col-md-1 mb-1">
                <label htmlFor="designition">Tue</label>

                <Form.Check
                  aria-label="option 1"
                  checked={details.is_tuesday === true}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      is_tuesday: e.target.checked,
                      leave_category: leaveType,
                    })
                  }
                />
              </div>
              <div className="col-md-1 mb-1">
                <label htmlFor="designition">Wed</label>

                <Form.Check
                  aria-label="option 1"
                  checked={details.is_wednesday === true}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      is_wednesday: e.target.checked,
                      leave_category: leaveType,
                    })
                  }
                />
              </div>
              <div className="col-md-1 mb-1">
                <label htmlFor="designition">Thu</label>

                <Form.Check
                  aria-label="option 1"
                  checked={details.is_thursday === true}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      is_thursday: e.target.checked,
                      leave_category: leaveType,
                    })
                  }
                />
              </div>
              <div className="col-md-1 mb-1">
                <label htmlFor="designition">Fri</label>

                <Form.Check
                  aria-label="option 1"
                  checked={details.is_friday === true}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      is_friday: e.target.checked,
                      leave_category: leaveType,
                    })
                  }
                />
              </div>
              <div className="col-md-1 mb-1">
                <label htmlFor="designition">Sat</label>

                <Form.Check
                  aria-label="option 1"
                  checked={details.is_saturday === true}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      is_saturday: e.target.checked,
                      leave_category: leaveType,
                    })
                  }
                />
              </div>
              <div className="col-md-1 mb-1">
                <label htmlFor="designition">Sun</label>

                <Form.Check
                  aria-label="option 1"
                  checked={details.is_sunday === true}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      is_sunday: e.target.checked,
                      leave_category: leaveType,
                    })
                  }
                />
              </div>
            </div>

            <button type="submit" className="mt-2 btn btn-outline-danger btn-lg">
              Submit
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateGeneralLeaveCategory;
