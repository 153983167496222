import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useAlert } from "react-alert";

import { AiOutlineArrowRight } from "react-icons/ai";
import { AiFillLock } from "react-icons/ai";

import ModalEmpoyeeDetails from "./Modals/ModalEmployeeDetails";
import FetchBirthdayList from "../Components/CompParts/FetchBirthdayList";
import DashboardCards from "./CompParts/DashboardCards";
import Feed from "./CompParts/Feed";

import { API_URL, BASE_URL } from "../Constents";

//import { useInterval } from "./Hooks/useIntervel";
import LoadingGifs from "../images/YAAMS-gif.gif";

const MainContentDash = () => {
  const [dashData, setDashData] = useState([]);

  // modal state
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalId, setModalId] = useState();

  const [statusErr, setStatusErr] = useState(null);
  const [pendingDataNumber, setPendingDataNumber] = useState();

  const alert = useAlert();

  const fetchDashBoardData = useCallback(async () => {
    const url = API_URL + "/fetch-dashboard-data/";
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      if (!res.ok) setStatusErr(res.status);
      const data = await res.json();
      setDashData(data.data);
    } catch (err) {
      alert.error("There is an error in fetching Dash Data");
    }
  }, []);

  // useInterval(() => {
  //   fetchDashBoardData(); // custom hook to refresh the dashboard
  // }, 1000 * 30);

  const fetchPendingRequests = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/total-pending-request/";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setPendingDataNumber(data.total_count);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDashBoardData();
    fetchPendingRequests();
  }, [fetchDashBoardData]);

  console.log(dashData);

  if (statusErr !== null && statusErr === 403)
    // when authtication token gets expired
    return (
      <div className="d-flex justify-content-center">
        <h1 className="d-flex justify-content-center">
          Your session has been expired please close the window{" "}
        </h1>
        <AiFillLock size={300} />
      </div>
    );

  return (
    <>
      <div className="maincontent">
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <DashboardCards />

              <div className="col-md-6">
                <div className="highlight whoinbox">
                  <h4>
                    <Link to={BASE_URL + "Attendence"}>
                      Who is in <AiOutlineArrowRight />
                    </Link>
                  </h4>
                  <p>
                    Not Yet In{" "}
                    {dashData && dashData.length > 0
                      ? "(" + dashData[3].not_yet_in + ")"
                      : "( )"}
                  </p>
                  {dashData && dashData.length > 0 ? (
                    <ul className="whoin orange">
                      {dashData[3].data.slice(0, 6).map((inPerson) => {
                        const name = inPerson.first_name
                          ? inPerson.first_name
                          : "N A";
                        const matches = name.match(/\b(\w)/g);
                        const acronym = matches.join("");

                        return (
                          <li
                            key={inPerson.id}
                            onClick={() => {
                              handleShow();
                              setModalId(inPerson.id);
                            }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title={inPerson.first_name}
                          >
                            {acronym}
                          </li>
                        );
                      })}
                      {dashData[3].not_yet_in > 6 ? (
                        <Link
                          to={BASE_URL + "NotYetInList"}
                          style={{ textDecoration: "none" }}
                        >
                          <li>{dashData[3].not_yet_in - 6 + "+"}</li>
                        </Link>
                      ) : (
                        ""
                      )}
                    </ul>
                  ) : (
                    <>
                      <div
                        className="loadingGif"
                        style={{ textAlign: "center" }}
                      >
                        <img
                          src={LoadingGifs}
                          alt="Loading Gif"
                          style={{ height: "50px", width: "50px" }}
                        />
                      </div>
                    </>
                  )}
                  <p>
                    Late Arrivals{" "}
                    {dashData && dashData.length > 0
                      ? "(" + dashData[1].late_count + ")"
                      : "( )"}
                  </p>
                  {dashData && dashData.length > 0 ? (
                    <ul className="whoin red">
                      {dashData[1].data.slice(0, 6).map((inPerson) => {
                        let acronym;
                        if (inPerson.first_name === null) {
                          acronym = "NA";
                        } else {
                          const name = inPerson.first_name
                            ? inPerson.first_name
                            : "N A";
                          const matches = name.match(/\b(\w)/g);
                          acronym = matches.join("");
                        }

                        return (
                          <li
                            key={inPerson.id}
                            onClick={() => {
                              handleShow();
                              setModalId(inPerson.employee_id);
                            }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title={inPerson.first_name}
                          >
                            {acronym}
                          </li>
                        );
                      })}
                      {dashData[1].late_count > 6 ? (
                        <Link
                          to={BASE_URL + "Attendence"}
                          style={{ textDecoration: "none" }}
                        >
                          <li>{dashData[1].late_count - 6 + "+"}</li>
                        </Link>
                      ) : (
                        ""
                      )}
                    </ul>
                  ) : (
                    <>
                      <div
                        className="loadingGif"
                        style={{ textAlign: "center" }}
                      >
                        <img
                          src={LoadingGifs}
                          alt="Loading Gif"
                          style={{ height: "50px", width: "50px" }}
                        />
                      </div>
                    </>
                  )}
                  <p>
                    On Time{" "}
                    {dashData && dashData.length > 0
                      ? "(" + dashData[0].present_count + ")"
                      : "( )"}{" "}
                  </p>
                  {dashData && dashData.length > 0 ? (
                    <ul className="whoin blue">
                      {dashData[0].data.slice(0, 6).map((inPerson) => {
                        const name = inPerson.first_name
                          ? inPerson.first_name
                          : "N A";
                        const matches = name.match(/\b(\w)/g);
                        const acronym = matches.join("");

                        return (
                          <li
                            key={inPerson.id}
                            onClick={() => {
                              handleShow();
                              setModalId(inPerson.employee_id);
                            }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title={inPerson.first_name}
                          >
                            {acronym}
                          </li>
                        );
                      })}
                      {dashData[0].present_count > 6 ? (
                        <Link
                          to={BASE_URL + "Attendence"}
                          style={{ textDecoration: "none" }}
                        >
                          <li>{dashData[0].present_count - 6 + "+"}</li>
                        </Link>
                      ) : (
                        ""
                      )}
                    </ul>
                  ) : (
                    <>
                      <>
                        <div
                          className="loadingGif"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            src={LoadingGifs}
                            alt="Loading Gif"
                            style={{ height: "50px", width: "50px" }}
                          />
                        </div>
                      </>
                    </>
                  )}{" "}
                </div>
              </div>

              <div className="col-md-6">
                <FetchBirthdayList />

                <div className="highlight">
                  <h4>
                    <Link to={BASE_URL + "requestsAndReviews"}>
                      Requests & Review <AiOutlineArrowRight />
                    </Link>
                  </h4>

                  <h4 className="text-info">
                    {pendingDataNumber} Reviews Pending
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <Feed />

            <div className="highlight whoinbox mt-4">
              <h4>
                <Link to={BASE_URL + "Leaves"}>
                  On Leave <AiOutlineArrowRight />
                </Link>
              </h4>
              <p>
                Today{" "}
                {dashData && dashData.length > 0
                  ? "(" + dashData[2].leave_count + ")"
                  : "( )"}
              </p>
              {dashData && dashData.length > 0 ? (
                <ul className="whoin red">
                  {dashData[2].data.map((inPerson) => {
                    const name = inPerson.employee_name
                      ? inPerson.employee_name
                      : "N A";
                    const matches = name.match(/\b(\w)/g);
                    const acronym = matches.join("");

                    return (
                      <li
                        key={inPerson.id}
                        onClick={() => {
                          handleShow();
                          setModalId(inPerson.employee);
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title={inPerson.employee_name}
                      >
                        {acronym}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <>
                  <div className="loadingGif" style={{ textAlign: "center" }}>
                    <img
                      src={LoadingGifs}
                      alt="Loading Gif"
                      style={{ height: "50px", width: "50px" }}
                    />
                  </div>
                </>
              )}
              <p>
                This Month{" "}
                {dashData && dashData.length > 0
                  ? "(" + dashData[4].current_month_leave_count + ")"
                  : "( )"}{" "}
              </p>
              {dashData && dashData.length > 0 ? (
                <ul className="whoin black">
                  {dashData[4].current_month_on_leave
                    .slice(0, 6)
                    .map((inPerson) => {
                      const name = inPerson.employee_name
                        ? inPerson.employee_name
                        : "N A";
                      const matches = name.match(/\b(\w)/g);
                      const acronym = matches.join("");

                      return (
                        <li
                          key={inPerson.id}
                          onClick={() => {
                            handleShow();
                            setModalId(inPerson.employee);
                          }}
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          title={inPerson.employee_name}
                        >
                          {acronym}
                        </li>
                      );
                    })}
                  {dashData[4].current_month_leave_count > 6 ? (
                    <Link
                      to={BASE_URL + "Leaves"}
                      style={{ textDecoration: "none" }}
                    >
                      <li>{dashData[4].current_month_leave_count - 6 + "+"}</li>
                    </Link>
                  ) : (
                    ""
                  )}
                </ul>
              ) : (
                <>
                  <div className="loadingGif" style={{ textAlign: "center" }}>
                    <img
                      src={LoadingGifs}
                      alt="Loading Gif"
                      style={{ height: "50px", width: "50px" }}
                    />
                  </div>
                </>
              )}{" "}
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton />
        <Modal.Body>
          <ModalEmpoyeeDetails id={modalId} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MainContentDash;
