import attendanceImage from "../../images/attendance.png";
import attendanceActive from "../../images/attendance_active.png";
import leaveImage from "../../images/leave.png";
import LeaveActive from "../../images/leave_active.png";
import TeamsSetting from "../../images/TeamsSetting.png";
import TeamsSettingActive from "../../images/TeamsSettingActive.png";

// import taskImage from "../../images/task.png";
// import taskActive from "../../images/task_active.png";
// import employeeImage from "../../images/employees.png";
// import employeeActive from "../../images/employee_active.png";
import general from "../../images/generalSetting.png";
import generalActive from "../../images/generalSetting_active.png";
// import LeaveGlobalSettingForm from "../Global Setting/LeaveGlobalSettingForm";
import { useState } from "react";
import CreateLeaveForm from "../Global Setting/CreateLeaveForm";
import CreatedLeaveList from "../Global Setting/CreatedLeaveList";
import ApplyLeaveCategoryGlobally from "../Global Setting/ApplyLeaveCategoryGlobally";
import GlobalAttandanceSetting from "../Attendance/GlobalAttandanceSetting";
import GlobalGeneralSetting from "../Global Setting/GlobalGeneralSetting";
import UploadRHForm from "../Global Setting/UploadRHForm";
import DepartmentMain from "../Departments/DepartmentMain";

const Setting = () => {
  const [showForm, setShowForm] = useState(false);
  const [reloadData, setReloadData] = useState({});
  const reloadOnAddition = (data) => {
    setReloadData(data);
  };

  return (
    <div className="maincontent">
      <div className="highlights">
        <div className="col-md-12 mt-4">
          <div className="bg-whites">
            <ul
              className="nav nav-tabs justify-content-between nav-fill"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item cust_flex" role="presentation">
                <button
                  className="nav-link active"
                  id="faq_tab_1-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#attendance"
                  type="button"
                  role="tab"
                  aria-controls="faq_tab_1"
                  aria-selected="true"
                >
                  <div className="d-flex txt flex-column lh-lg">
                    <img className="tab_img" src={attendanceImage} alt="att" />
                    <img
                      className="tab_img_active"
                      src={attendanceActive}
                      alt="active"
                    />
                    <span>Check-In</span>
                  </div>
                </button>
              </li>

              <li className="nav-item cust_flex" role="presentation">
                <button
                  className="nav-link"
                  id="faq_tab_2-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#leave"
                  type="button"
                  role="tab"
                  aria-controls="faq_tab_2"
                  aria-selected="false"
                >
                  <div className="d-flex txt flex-column lh-lg">
                    <img className="tab_img" src={leaveImage} alt="att" />
                    <img
                      className="tab_img_active"
                      src={LeaveActive}
                      alt="active"
                    />
                    <span>Leaves</span>
                  </div>
                </button>
              </li>

              <li className="nav-item cust_flex" role="presentation">
                {" "}
                <button
                  className="nav-link"
                  id="faq_tab_3-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#task"
                  type="button"
                  role="tab"
                  aria-controls="faq_tab_3"
                  aria-selected="false"
                >
                  <div className="d-flex txt flex-column lh-lg">
                    <img className="tab_img" src={TeamsSetting} alt="Task" />
                    <img
                      className="tab_img_active"
                      src={TeamsSettingActive}
                      alt="Task Active"
                    />
                    <span>Teams</span>{" "}
                  </div>
                </button>{" "}
              </li>
              {/* <li className="nav-item cust_flex" role="presentation">
              {" "}
              <button
                className="nav-link"
                id="faq_tab_4-tab"
                data-bs-toggle="tab"
                data-bs-target="#employee"
                type="button"
                role="tab"
                aria-controls="faq_tab_4"
                aria-selected="false"
              >
                <div className="d-flex txt flex-column lh-lg">
                  <img className="tab_img" src={employeeImage} alt="Employee" />
                  <img
                    className="tab_img_active"
                    src={employeeActive}
                    alt="Employee Active"
                  />
                  <span>Employees</span>{" "}
                </div>
              </button>{" "}
            </li> */}

              <li className="nav-item cust_flex" role="presentation">
                {" "}
                <button
                  className="nav-link"
                  id="faq_tab_5-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#general"
                  type="button"
                  role="tab"
                  aria-controls="faq_tab_5"
                  aria-selected="false"
                >
                  <div className="d-flex txt flex-column lh-lg">
                    <img className="tab_img" src={general} alt="Employee" />
                    <img
                      className="tab_img_active"
                      src={generalActive}
                      alt="Employee Active"
                    />
                    <span>General Setting</span>{" "}
                  </div>
                </button>{" "}
              </li>
            </ul>

            <div className="tab-content highlight my-3" id="myTabContent">
              <div
                className="tab-pane fade as"
                id="leave"
                role="tabpanel"
                aria-labelledby="faq_tab_1-tab"
              >
                <ApplyLeaveCategoryGlobally />

                <div className="container p-3">
                  <div className="row w-100">
                    <button
                      className="cust_leave_popup"
                      onClick={(e) => setShowForm(!showForm)}
                    >
                      <b>Create Leave Types for Organization</b>
                    </button>
                  </div>
                </div>
                {showForm ? <CreateLeaveForm reload={reloadOnAddition} /> : ""}
                <CreatedLeaveList reload={reloadData} />

                {/* <LeaveGlobalSettingForm /> */}
                <UploadRHForm />
              </div>

              <div
                className="tab-pane fade as active show"
                id="attendance"
                role="tabpanel"
                aria-labelledby="faq_tab_2-tab"
              >
                <div className="container p-3">
                  <div className="row">
                    <GlobalAttandanceSetting />{" "}
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade as"
                id="task"
                role="tabpanel"
                aria-labelledby="faq_tab_3-tab"
              >
                <div className="container p-3">
                  <div className="row">
                    <DepartmentMain />
                  </div>
                </div>
              </div>

              {/* <div
              className="tab-pane fade as"
              id="employee"
              role="tabpanel"
              aria-labelledby="faq_tab_4-tab"
            >
              <div className="container p-3">
                <div className="row">Employees Setting</div>
              </div>
            </div> */}
              <div
                className="tab-pane fade as"
                id="general"
                role="tabpanel"
                aria-labelledby="faq_tab_4-tab"
              >
                <div className="container p-3">
                  <div className="row">
                    <GlobalGeneralSetting />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="add_new_leave"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: "none" }}
        >
          <div className="modal-dialog modal_cust_width modal-dialog-centered cust-width">
            <div className="modal-content">
              <div className="modal-body cust_padding">
                <form
                  method="post"
                  autocomplete="off"
                  id="create_agent_frm"
                  novalidate="novalidate"
                >
                  <div className="row">
                    <div className="modal_head justify-content-end align-items-center d-flex">
                      <div className="close-btn text-dark text-end">
                        <i
                          className="fa-solid fa-x"
                          data-bs-dismiss="modal"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>

                    <div className="new_leave">
                      <h3 className="mb-4">Add New Leave</h3>
                      <div className="leaves_list mt-4">
                        <div className="row">
                          <p>Select leave types</p>
                          <div className="col-md-4">
                            <div className="list1 d-flex flex-column">
                              <div className="leave_type">
                                <input
                                  type="checkbox"
                                  id="priviledged"
                                  name="leave1"
                                />
                                <label for="leave1">
                                  {" "}
                                  Priviledged Leave (PL)
                                </label>
                              </div>
                              <div className="leave_type">
                                <input
                                  type="checkbox"
                                  id="priviledged"
                                  name="leave1"
                                />
                                <label for="leave1">
                                  {" "}
                                  Maternity Leave (PL)
                                </label>
                              </div>
                              <div className="leave_type">
                                <input
                                  type="checkbox"
                                  id="priviledged"
                                  name="leave1"
                                />
                                <label for="leave1">
                                  {" "}
                                  Paternity Leave (PL)
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="list1 d-flex flex-column">
                              <div className="leave_type">
                                <input
                                  type="checkbox"
                                  id="priviledged"
                                  name="leave1"
                                />
                                <label for="leave1"> Casual Leave (CL)</label>
                              </div>
                              <div className="leave_type">
                                <input
                                  type="checkbox"
                                  id="priviledged"
                                  name="leave1"
                                />
                                <label for="leave1">
                                  {" "}
                                  Compensatory Off (Comp Off)
                                </label>
                              </div>
                              <div className="leave_type">
                                <input
                                  type="checkbox"
                                  id="priviledged"
                                  name="leave1"
                                />
                                <label for="leave1"> Bereavement Leave</label>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="list1 d-flex flex-column">
                              <div className="leave_type">
                                <input
                                  type="checkbox"
                                  id="priviledged"
                                  name="leave1"
                                />
                                <label for="leave1"> Sick Leave (SL)</label>
                              </div>
                              <div className="leave_type">
                                <input
                                  type="checkbox"
                                  id="priviledged"
                                  name="leave1"
                                />
                                <label for="leave1"> Marriage Leave (PL)</label>
                              </div>
                              <div className="leave_type">
                                <input
                                  type="checkbox"
                                  id="priviledged"
                                  name="leave1"
                                />
                                <label for="leave1">
                                  {" "}
                                  Leave Withour Pay (LWP)
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="leave_btn my-4">
                              <label htmlFor="custleave">
                                Add Custom Leave Category
                              </label>
                              <input
                                type="text"
                                id="custleave"
                                placeholder="Enter leave name"
                                name="custleave"
                                className="form-control"
                                required
                              />
                            </div>
                          </div>

                          <hr />

                          <div className="new_leave_btn text-end w-100 my-4">
                            <a href="" className="save_btn">
                              Save & Next
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
