import { useState, useEffect } from "react";
import { API_URL } from "../../Constents";
import { useAlert } from "react-alert";
const AssignShiftToDepartment = (props) => {
  const [checkinAndCheckoutTime, setCheckinAndCheckoutTime] = useState({
    check_in_time: null,
    check_out_time: null,
    late_exempt: false,
    is_round_off: false,
    round_off_time: 15,
    allowed_late_num_of_day: 3,
  });

  const [apiMethodFlag, setApiMethodFlag] = useState("");
  var changeMethod;
  const alert = useAlert();
  const fetchDeptCheckinAndCheckoutTime = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const url = API_URL + "/department-setting/" + props.deptID + "/";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      if (response.status === 400) {
        setApiMethodFlag("POST");
      } else {
        setApiMethodFlag("PATCH");
      }
      console.log(response.status);
      const data = await response.json();

      if (data.status) {
        console.log(data);
        setCheckinAndCheckoutTime({
          check_in_time: data.data.check_in_time,
          check_out_time: data.data.check_out_time,
          late_exempt: data.data.late_exempt,
          is_round_off: data.data.is_round_off,
          round_off_time: data.data.round_off_time,
          allowed_late_num_of_day: data.data.allowed_late_num_of_day,
        });
      }
      if (data.status === false) {
        // if (data && data.error && data.error.candidate) {
        //   let errorMessages = "";

        //   for (let key in data.error) {
        //     console.log(key);
        //     if (data.error.hasOwnProperty(key)) {
        //       errorMessages += `${data.error[key][0]}\n`;
        //     }
        //     alert.error(errorMessages);
        //   }
        // } else {
        //   alert.error(data.message);
        // }
        alert.error(data.message);
      }
    } catch (err) {
      console.log(err);
      alert.error("Internal Server Error");
    }
  };
  const checkinAndCheckoutTimeUpdateHandler = async (e) => {
    e.preventDefault();
    console.log(apiMethodFlag);
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const bodyObj = {
      check_in_time: checkinAndCheckoutTime.check_in_time,
      check_out_time: checkinAndCheckoutTime.check_out_time,
      late_exempt: checkinAndCheckoutTime.late_exempt,
      is_round_off: checkinAndCheckoutTime.is_round_off,
      round_off_time:
        checkinAndCheckoutTime.round_off_time === ""
          ? 0
          : checkinAndCheckoutTime.round_off_time,
      allowed_late_num_of_day:
        checkinAndCheckoutTime.allowed_late_num_of_day === ""
          ? 0
          : checkinAndCheckoutTime.allowed_late_num_of_day,

      //department: props.deptID,
    };
    let url = "";
    console.log(apiMethodFlag);
    if (apiMethodFlag === "PATCH") {
      url = API_URL + "/department-setting/" + props.deptID + "/";
      console.log(bodyObj);
    } else {
      url = API_URL + "/department-setting/";
      bodyObj.department = props.deptID;
    }

    const bodyContent = JSON.stringify(bodyObj);

    try {
      const response = await fetch(url, {
        method: apiMethodFlag,
        body: bodyContent,
        headers: headersList,
      });
      const data = await response.json();
      console.log(data);
      if (data.status) {
        alert.success(data.message);
        props.closeAssignShiftModal();
      }
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (error) {
      console.error(error);
      alert.error("Internal Server Error");
    }
  };
  useEffect(() => {
    fetchDeptCheckinAndCheckoutTime();
  }, []);
  console.log(checkinAndCheckoutTime);
  return (
    <div className="border border-3">
      <form onSubmit={checkinAndCheckoutTimeUpdateHandler}>
        <div className="row align-items-center w-100 p-5">
          <h3>
            Custom Checkin Time for {props.fn} {props.sn}
          </h3>

          <div className="col-md-4 col-lg- mt-4">
            <label htmlFor="carryduration">Check-in Time</label>
            <input
              type="time"
              id="carryduration"
              placeholder="Enter Check-in Time"
              name="carryduration"
              style={{ width: "240px", border: "none" }}
              className="form-control"
              required
              value={checkinAndCheckoutTime.check_in_time}
              onChange={(e) =>
                setCheckinAndCheckoutTime((prevState) => ({
                  ...prevState,
                  check_in_time: e.target.value,
                }))
              }
            />
          </div>

          <div className="col-md-4 col-lg- mt-4">
            <label htmlFor="carryduration">Check-out Time</label>
            <input
              type="time"
              id="carryduration"
              placeholder="Enter Check-in Time"
              name="carryduration"
              style={{ width: "240px", border: "none" }}
              className="form-control"
              required
              value={checkinAndCheckoutTime.check_out_time}
              onChange={(e) =>
                setCheckinAndCheckoutTime((prevState) => ({
                  ...prevState,
                  check_out_time: e.target.value,
                }))
              }
            />
          </div>

          <div className="col-md-2 col-lg- mt-4">
            <input
              type="checkbox"
              id="carry"
              name="carry"
              className="me-2"
              checked={checkinAndCheckoutTime.is_round_off}
              onChange={(e) => {
                setCheckinAndCheckoutTime((prevState) => ({
                  ...prevState,
                  is_round_off: e.target.checked,
                }));
                if (e.target.checked === false)
                  setCheckinAndCheckoutTime((prevState) => ({
                    ...prevState,
                    round_off_time: "",
                  }));
              }}
            />
            <label for="leave1"> Round-off</label>
          </div>
          <div className="col-md-2 col-lg- mt-4">
            <input
              type="checkbox"
              className="me-2"
              checked={checkinAndCheckoutTime.late_exempt}
              onChange={(e) => {
                setCheckinAndCheckoutTime((prevState) => ({
                  ...prevState,
                  late_exempt: e.target.checked,
                }));
                if (e.target.checked) {
                  setCheckinAndCheckoutTime((prevState) => ({
                    ...prevState,
                    allowed_late_num_of_day: 0,
                  }));
                }
              }}
              name="late-exempt"
            />
            <label for="late-exempt">Late Exempt</label>
          </div>
          <div className="col-md-4 col-lg- mt-4">
            <label htmlFor="carryleave">Round-off Time(in Minutes)</label>
            <input
              type="number"
              min="1"
              max="60"
              step="1"
              id="carryleave"
              placeholder="In minutes"
              name="carryleave"
              className="form-control mb-3"
              required
              value={checkinAndCheckoutTime.round_off_time}
              onChange={(e) =>
                setCheckinAndCheckoutTime({
                  ...checkinAndCheckoutTime,
                  round_off_time: e.target.value,
                })
              }
              disabled={!checkinAndCheckoutTime.is_round_off}
            />
          </div>

          {checkinAndCheckoutTime.late_exempt ? (
            <div className="col-md-4 mt-4">
              <p style={{ color: "red" }}>
                NOTE: No leave will be deducted on coming late
              </p>
            </div>
          ) : (
            <div className="col-md-4 col-lg- mt-4">
              <label htmlFor="carryleave">Allowed Late Entry(in days)</label>
              <input
                type="number"
                min="0"
                max="31"
                step="1"
                id="carryleave"
                placeholder="In days"
                className="form-control mb-3"
                required
                value={checkinAndCheckoutTime.allowed_late_num_of_day}
                onChange={(e) =>
                  setCheckinAndCheckoutTime((prevState) => ({
                    ...prevState,
                    allowed_late_num_of_day: e.target.value,
                  }))
                }
                // disabled={!checkinAndCheckoutTime.late_exempt}
              />
            </div>
          )}

          <hr className="my-4" />

          <div className="new_leave_btn text-end w-100 my-4">
            <button className="departmentBtnSecondary" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AssignShiftToDepartment;
