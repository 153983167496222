import React, { useState } from "react";
import { API_URL } from "../../Constents";
import forgetPasswordImage from "../../images/ForgotEnterDetail.png";
import { useAlert } from "react-alert";

export default function EnterForgetPasswordDetails() {
  const alert = useAlert();

  const [formInput, setFormInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const [response, setResponse] = useState({});

  const url = window.location.href.split("/").reverse(); // to capture the current url in reverse order
  const userID = url[0];  // captures the last information
  const token = url[1]; // captures the second last information to be sent in the API for validation

  const [formError, setFormError] = useState({
    password: "",
    confirmPassword: "",
  });
  const handleUserInput = (name, value) => {
    setFormInput({
      ...formInput,
      [name]: value,
    });
  };
  

  // Custom form validaion for the form
  const validateFormInput = (event) => {
    event.preventDefault();
    let inputError = {
      password: "",
      confirmPassword: "",
    };

    if (!formInput.password) {
      setFormError({
        ...inputError,

        password: "Password should not be empty",
      });
      return;
    }

    if (formInput.confirmPassword !== formInput.password) {
      setFormError({
        ...inputError,
        confirmPassword: "Password and confirm password should be same",
      });

      return;
    }

    setFormError(inputError);

    //if there is no error this functions gets called

    (async () => {
      const apiURL = API_URL + "/forget-password/?user_id=" + userID;

      const headersList = {
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        hash: token,
        new_password: formInput.password,
      });

      try {
        const res = await fetch(apiURL, {
          method: "PUT",
          body: bodyContent,
          headers: headersList,
        });
        const data = await res.json();
        console.log(data);
        setResponse(data);
        if (data.status === true) alert.success(data.message);
        if (data.status === false) alert.error(data.message);
      } catch (err) {
        console.error(err);
        alert.error("Internal Server Error");
      }
    })();
  };

  console.log(response);

  return (
    <div className="container-fluid text-center py-5 forgotMainWrapper ">
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <img src={forgetPasswordImage} alt="PasswordImg" className="mt-5" />
          <form onSubmit={validateFormInput}>
            <div class="mb-3 form-group">
              <label
                for="exampleInputEmail1"
                class="form-label"
                style={{ textAlign: "left" }}
              >
                Password
              </label>
              <input
                name="password"
                type="password"
                value={formInput.password}
                onChange={({ target }) => {
                  handleUserInput(target.name, target.value);
                }}
                className="form-control"
                required

                // onBlur={validateInput}
              />
              <p className="error-message">{formError.password}</p>
            </div>

            <div class="mb-3 form-group">
              <label for="exampleInputPassword1" class="form-label">
                Confirm Password
              </label>
              <input
                value={formInput.confirmPassword}
                onChange={({ target }) => {
                  handleUserInput(target.name, target.value);
                }}
                name="confirmPassword"
                type="password"
                required
                className="form-control"
                id="exampleInputPassword1"
              />
              <p className="error-message">{formError.confirmPassword}</p>
            </div>

            <button
              type="submit"
              className="btn-success passowrdButton"
              value="submit"
            >
              Submit
            </button>
          </form>
          {response && response.status === true ? (
            <div
              style={{
                marginTop: "10px",
                padding: "10px",
                background: "white",
                boxShadow: "1px 2px 9px #F4AAB9",
              }}
            >
              <h4 style={{ color: "green" }}>{response.message}</h4>
            </div>
          ) : response && response.status === false ? (
            <div
              style={{
                marginTop: "10px",
                padding: "10px",
                background: "white",
                boxShadow: "1px 2px 9px #F4AAB9",
              }}
            >
              <h4 style={{ color: "red" }}>{response.message}</h4>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
