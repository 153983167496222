import { useState } from "react";
import { API_URL } from "../../Constents";

const CreateLeaveForm = (props) => {
  const [carryForwardForm, setCarryForwardForm] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    validity_start_from: "",
    description: "",
    quantity: "",
    carry_forward_leaves: false,
    maximum_carry_forward: null,
    carry_forward_duration: null,
  });
  const [response, setResponse] = useState("");

  const addLeaveHandler = (e) => {
    e.preventDefault();

    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        name: details.name,
        code: details.name,
        validity_start_from: details.validity_start_from,
        description: details.description,
        quantity: details.quantity,
        settings: {
          carry_forward_leaves: details.carry_forward_leaves,
          maximum_carry_forward: details.maximum_carry_forward,
          carry_forward_duration: details.carry_forward_duration,
        },
      });

      const url = API_URL + "/admin-quantity-view/";

      try {
        const res = await fetch(url, {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        });
        const data = await res.json();
        setResponse(data);
        props.reload(data.data);
        setDetails({
          name: "",
          validity_start_from: "",
          description: "",
          quantity: "",
          carry_forward_leaves: false,
          maximum_carry_forward: null,
          carry_forward_duration: null,
        });
        setCarryForwardForm(false);
      } catch (err) {
        console.error(err);
      }
    })();
  };

  console.log(details);
  console.log(response);
  return (
    <>
      <div className="container">
        {response !== "" && response.status === true ? (
          <h5 style={{ color: "red" }}>{response.message}</h5>
        ) : (
          ""
        )}
        <h3>Add a New Leave Type</h3>
        <form name="contact-form" method="POST" onSubmit={addLeaveHandler}>
          <div className="row">
            <div className="col-md-4 col-lg- mt-4">
              <label>Select employee type</label>
              <select
                className="form-select"
                onChange={(e) =>
                  setDetails({
                    ...details,
                    validity_start_from: e.target.value,
                  })
                }
                required
              >
                <option></option>
                <option value="date_of_confirmation">Full-Time Employee</option>
                <option value="intern">Intern</option>
                <option value="date_of_joining">Probation</option>
                <option value="notice_period">Notice Period</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="col-md-4 col-lg- mt-4">
              <label>Enter Leave Name </label>
              <input
                type="text"
                className="form-control"
                placeholder="Eg. Casual Leave(CL), Sick Leave(SL)"
                value={details.name}
                onChange={(e) =>
                  setDetails({ ...details, name: e.target.value })
                }
                required
              />
            </div>
            <div className="col-md-4 col-lg- mt-4">
              <label htmlFor="leaves">Enter No. of Leaves</label>
              <input
                type="number"
                id="mname"
                placeholder="Enter no. of leaves"
                name="leaves"
                className="form-control"
                value={details.quantity}
                onChange={(e) =>
                  setDetails({ ...details, quantity: e.target.value })
                }
                min="0"
                max="100"
                required
              />
            </div>
            <div className="col-md-4 col-lg- mt-4">
              <label htmlFor="leaves">Leave Description (Optional)</label>
              <textarea
                type="textbox"
                id="mname"
                placeholder="Leave Description"
                name="leaves"
                className="form-control"
                value={details.description}
                onChange={(e) =>
                  setDetails({ ...details, description: e.target.value })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-lg- mt-4">
              <input
                className="me-2"
                type="checkbox"
                id="carry"
                name="carry"
                checked={details.carry_forward_duration}
                onChange={(e) => {
                  setCarryForwardForm(!carryForwardForm);
                  setDetails({
                    ...details,
                    carry_forward_leaves: e.target.checked,
                  });
                }}
              />
              <label for="leave1"> Carry Forward</label>
            </div>
          </div>
          {carryForwardForm ? (
            <div className="row">
              <div className="col-md-4 col-lg- mt-4">
                <label htmlFor="carryleave">
                  Enter No. of Leaves to Carry Forward
                </label>
                <input
                  type="number"
                  id="carryleave"
                  placeholder="Enter no. of leaves"
                  name="carryleave"
                  className="form-control"
                  min="0"
                  max="100"
                  value={details.maximum_carry_forward}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      maximum_carry_forward: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="col-md-4 col-lg- mt-4">
                <label htmlFor="carryduration">Carry Forward Duration</label>
                <input
                  type="number"
                  id="carryduration"
                  placeholder="Enter no. of years"
                  name="carryduration"
                  className="form-control"
                  min="0"
                  max="10"
                  step="0.5"
                  value={details.carry_forward_duration}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      carry_forward_duration: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <button type="submit" className="btn btn-warning btn-lg mt-4 mb-5">
            Create New Leave
          </button>
        </form>

        <hr mt-5 />
      </div>
    </>
  );
};

export default CreateLeaveForm;
