import { useState, useEffect } from "react";
import CreateGeneralLeaveCategory from "../../Leaves/CreateGeneralLeaveCategory";
import ViewAndGenerateQR from "../../QR Code/ViewAndGenerateQR";
import OrganizationTimeSetting from "./OrganizationTimeSetting";
import UploadRHForm from "../../../Components/Global Setting/UploadRHForm";
import ApplyLeaveCategoryGlobally from "../ApplyLeaveCategoryGlobally";
import UploadHolidayForm from "../UploadHolidayForm";

export default function AttendanceSettings() {
  return (
    <>
      <h2 className="tab_heading mt-5 mb-3">Attendance Settings</h2>

      <div className="row">
        <div className="col-md-2 pe-0 br">
          <ul
            className="nav nav-tabs flex-column"
            id="myTabLeave"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="inner nav-link active"
                id="users-tab"
                data-bs-toggle="tab"
                data-bs-target="#lableAndQR"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                Lables And QR
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="inner nav-link mt-1"
                id="roles-tab"
                data-bs-toggle="tab"
                data-bs-target="#rule"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                Create & view Leave Rule
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="inner nav-link mt-1"
                id="territories-tab"
                data-bs-toggle="tab"
                data-bs-target="#timing"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Organization Timing
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="inner nav-link mt-1"
                id="RH-upload-tab"
                data-bs-toggle="tab"
                data-bs-target="#rhupload"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Upload Holiday Calendar
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="inner nav-link mt-1"
                id="leave-rule"
                data-bs-toggle="tab"
                data-bs-target="#leave_rule"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                Assign Week Off Rule
              </button>
            </li>
          </ul>
        </div>
        <div className="col-md-10 bt">
          <div className="tab-content" id="myTabContent">
            {/* Users */}
            <div
              className="tab-pane fade show active"
              id="lableAndQR"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <ViewAndGenerateQR />
            </div>
            {/* Roles */}
            <div
              className="tab-pane fade"
              id="rule"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <CreateGeneralLeaveCategory />
            </div>
            {/* Territories */}
            <div
              className="tab-pane fade"
              id="timing"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <OrganizationTimeSetting />
            </div>
            <div
              className="tab-pane fade"
              id="rhupload"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <UploadRHForm />
              <hr />
              <UploadHolidayForm />
            </div>

            {/* <div
              className="tab-pane fade"
              id="leave_rule"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <ApplyLeaveCategoryGlobally />
            </div> */}
            <div
              className="tab-pane fade"
              id="leave_rule"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <ApplyLeaveCategoryGlobally />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
