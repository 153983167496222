import { useState } from "react";

import { useAlert } from "react-alert";

import { API_URL } from "../../../../Constents";

const ExtendExistingVacancy = ({ closeExtendVacancyModal, clickedRowData }) => {
  const [extendDate, selectExtendDate] = useState(
    clickedRowData.vacancy_end_date
  );

  const alert = useAlert();

  const extendExistingVacancyHandler = async (e) => {
    e.preventDefault();
    const URL =
      API_URL + "/admin-vacancy-crud/?vacancy_id=" + clickedRowData.vacancy_id;
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    try {
      const bodyContent = new FormData();
      bodyContent.append("vacancy_end_date", extendDate);

      const response = await fetch(URL, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);
      if (data.status) {
        closeExtendVacancyModal();
        alert.success(data.message);
      }
      console.log(data);
      if (data.status === false) alert.error(data.message);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <form onSubmit={extendExistingVacancyHandler} className="text-center">
      <label>Enter Vacancy End Date</label>
      <input
        type="date"
        className="form-control mb-3"
        value={extendDate}
        onChange={(e) => selectExtendDate(e.target.value)}
        min={extendDate}
      />
      <button type="submit" className="btn btn-warning">
        Extend
      </button>
    </form>
  );
};

export default ExtendExistingVacancy;
