// import { BASE_URL } from "../../Constents";

import general from "../../images/new_images/general.svg";
import generalActive from "../../images/new_images/general_active.svg";
import org from "../../images/new_images/org.svg";
import orgActive from "../../images/new_images/org_active.svg";
import leave from "../../images/new_images/leave.svg";
import leaveActive from "../../images/new_images/leave_active.svg";
import attendance from "../../images/new_images/attendance.svg";
import attendanceActive from "../../images/new_images/attendace_active.svg";
import GeneralSettings from "../Global Setting/Organization Settings/GeneralSettings";
//import DepartmentMain from "../Departments/DepartmentMain";
import OrganisationSettings from "../Global Setting/Organization Settings/OrganisationSettings";
import AttendanceSettings from "../Global Setting/Organization Settings/AttendanceSettings";
// import LeaveSetting from "../Leaves/Global Leave Setting/LeaveSetting";
// import CreateLeaveType from "../Leaves/Global Leave Setting/CreateLeaveType";
import AllLeaveListTable from "../Leaves/Global Leave Setting/AllLeaveListTable";

// import { Switch, Route } from "react-router-dom";
export default function Settings() {
  return (
    <>
      <div className="maincontent">
        <div className="highlight">
          <h3>Settings</h3>
          {/* Tabs */}
          <div className="col-md-12 mt-4">
            <div className="bg-whites">
              <ul
                className="nav nav-tabs justify-content-between nav-fill"
                id="myTabLeave"
                role="tablist"
              >
                <li
                  className="nav-item cust_flex position-relative"
                  role="presentation"
                >
                  <button
                    className="gen_tab nav-link active"
                    id="faq_tab_1-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#gen_setting"
                    type="button"
                    role="tab"
                    aria-controls="faq_tab_1"
                    aria-selected="true"
                  >
                    <div className="d-flex txt flex-column">
                      <img src={general} alt="general" className="tab_img" />
                      <img
                        src={generalActive}
                        alt="generalActive"
                        className="tab_img_active"
                      />
                      <span className="tab_name mt-3">Teams Settings</span>
                    </div>
                  </button>
                </li>
                <li className="nav-item cust_flex" role="presentation">
                  {" "}
                  <button
                    className="nav-link org_tab"
                    id="faq_tab_2-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#org_setting"
                    type="button"
                    role="tab"
                    aria-controls="faq_tab_2"
                    aria-selected="false"
                  >
                    <div className="d-flex txt flex-column">
                      <img className="tab_img" src={org} alt="" />
                      <img className="tab_img_active" src={orgActive} alt="" />
                      <span className="tab_name mt-3">
                        Organization Settings
                      </span>{" "}
                    </div>
                  </button>{" "}
                </li>
                <li className="nav-item cust_flex" role="presentation">
                  {" "}
                  <button
                    className="nav-link leave_tab"
                    id="faq_tab_3-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#leave_setting"
                    type="button"
                    role="tab"
                    aria-controls="faq_tab_3"
                    aria-selected="false"
                  >
                    <div className="d-flex txt flex-column">
                      <img className="tab_img" src={leave} alt="" />
                      <img
                        className="tab_img_active"
                        src={leaveActive}
                        alt=""
                      />
                      <span className="tab_name mt-3">Leave Settings</span>{" "}
                    </div>
                  </button>{" "}
                </li>
                <li className="nav-item cust_flex" role="presentation">
                  {" "}
                  <button
                    className="nav-link att_tab"
                    id="faq_tab_4-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#att_setting"
                    type="button"
                    role="tab"
                    aria-controls="faq_tab_4"
                    aria-selected="false"
                  >
                    <div className="d-flex txt flex-column">
                      <img className="tab_img" src={attendance} alt="" />
                      <img
                        className="tab_img_active"
                        src={attendanceActive}
                        alt=""
                      />
                      <span className="tab_name mt-3">Attendance Settings</span>{" "}
                    </div>
                  </button>{" "}
                </li>
              </ul>
              <div className="tab-content my-3" id="myTabContent">
                <div
                  className="tab-pane fade as active show"
                  id="gen_setting"
                  role="tabpanel"
                  aria-labelledby="faq_tab_1-tab"
                >
                  <GeneralSettings />
                </div>
                <div
                  className="tab-pane fade as"
                  id="org_setting"
                  role="tabpanel"
                  aria-labelledby="faq_tab_2-tab"
                >
                  <OrganisationSettings />
                </div>
                <div
                  className="tab-pane fade as"
                  id="leave_setting"
                  role="tabpanel"
                  aria-labelledby="faq_tab_3-tab"
                >
                  {/* <h2 className="tab_heading mt-5 mb-3">Leave Settings</h2>
                  <div className="container p-3">
                    <div className="row"></div>
                  </div> */}
                  {/* <LeaveSetting /> */}
                  <AllLeaveListTable />
                  {/*                   
                  <Switch>
                    <Route path={BASE_URL + "settings/CreateLeaveType"}>
                      <CreateLeaveType />
                    </Route>

                    <Route path={BASE_URL + "settings"}>
                    
                    </Route>
                  </Switch> */}
                </div>
                <div
                  className="tab-pane fade as"
                  id="att_setting"
                  role="tabpanel"
                  aria-labelledby="faq_tab_4-tab"
                >
                  <AttendanceSettings />
                </div>
              </div>
            </div>
          </div>

          {/* Tabs Ends */}
        </div>
      </div>
    </>
  );
}
