import React, { useState } from "react";
import { API_URL } from "../../../Constents";
import { useAlert } from "react-alert";
export default function AddRemarkForAppreciation(props) {
  const [appreciation_remark, setAppreciation_remark] = useState("");
  const alert = useAlert();
  console.log(props);
  const AddRemarkForAppreciationHandler = async (e) => {
    e.preventDefault();
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      appreciation_remark: appreciation_remark,
    });
    const url =
      API_URL +
      "/add-fetch-update-appreciation/?appreciation_id=" +
      props.appreciationId;
    let response = await fetch(url, {
      method: "PATCH",
      body: bodyContent,
      headers: headersList,
    });

    try {
      const data = await response.json();
      console.log(data);
      if (data.status) {
        alert.success(data.message);
        props.hideRemarkModal();
      }
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  console.log(appreciation_remark);
  return (
    <>
      <div className="container">
        <form action="" onSubmit={AddRemarkForAppreciationHandler}>
          <label htmlFor="">Add Remark</label>
          <textarea
            className="form-control"
            col="15"
            rows={"8"}
            required
            onChange={(e) => setAppreciation_remark(e.target.value)}
          />
          <div className="mt-3 text-center">
            <button className="departmentBtnSecondary" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
