import { useState, useCallback, useEffect } from "react";

import { useAlert } from "react-alert";

import { BsBoxArrowUpRight } from "react-icons/bs";

import { API_URL } from "../../../../Constents";
import ReverseDateString from "../../../Hooks/ReverseDateString";

const UpcomingInterviews = () => {
  const [interviewScheduleList, setInterviewScheduleList] = useState([]);

  const alert = useAlert();

  const fetchInterviewScheduleList = useCallback(async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/fetch-corehr-upcoming-meeting/";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      if (data.status) {
        setInterviewScheduleList(data.data);
      }
      if (data.status === false) {
        alert.info(data.message);
      }
    } catch (err) {
      console.log(err);
      alert.error("Error in fetching the interview schedule");
    }
  }, []);

  useEffect(() => {
    fetchInterviewScheduleList();
  }, [fetchInterviewScheduleList]);

  if (interviewScheduleList.length === 0) {
    return (
      <>
        <h6 style={{ color: "#C4C4C4" }} className="mb-2">
          Upcoming Meeting
          {/* <BsBoxArrowUpRight color="#DD3974" size={"12"} /> */}
        </h6>
        <div className="d-flex">
          <h6 className="mt-3">No Upcoming Interviews </h6>
          <h2>💃🏻</h2>
        </div>
      </>
    );
  }

  return (
    <>
      {interviewScheduleList.map((candidate) => {
        return (
          <div key={candidate.id}>
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0 " style={{ fontWeight: "100" }}>
                {candidate.applicant.name}
              </p>
              <p className="m-0 " style={{ color: "#C4C4C4" }}>
                {ReverseDateString(candidate.interview_date)} |{" "}
                {candidate.interview_start_time}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ float: "left", width: "60%" }}>
                <p
                  className="m-0 "
                  style={{ color: "#DD3974", fontWeight: "100" }}
                >
                  {candidate.applicant.vacancy.title}
                </p>
              </div>
              <div style={{ float: "left", width: "40%" }}>
                <button
                  className="btn btn-link"
                  style={{ color: "#DD3974", textDecoration: "none" }}
                  onClick={() => window.open(candidate.interview_url)}
                >
                  Link
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default UpcomingInterviews;
