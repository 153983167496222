import React, { useEffect, useState } from "react";
import { API_URL } from "../../../Constents";
import { useAlert } from "react-alert";
export default function AddEmployeeDropdown(props) {
  const [employee, setEmployee] = useState([]);
  const [empId, setEmpId] = useState(null);
  const alert = useAlert();
  const fetchEmployeeList = () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      department_array: [],
    });

    const requestOptions = {
      method: "POST",
      headers: headerList,
      redirect: "follow",
      body: bodyContent,
    };

    fetch(API_URL + "/employee-list-dashboard/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setEmployee(result.data);
      })
      .catch((error) => console.error(error));
  };
  const selectEmployeeFromDropdownForTable = async (e) => {
    e.preventDefault();
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const bodyContent = JSON.stringify({
      employee: empId,
    });
    const url = API_URL + "/add-employee-for-appraisal/";
    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      // setEmpId(data);
      console.log(data);
      if (data.status) {
        alert.success(data.message);

        props.refreshTable(data);
      }
      if (data.status === false) alert.error(data.message);
    } catch (err) {
      alert.error("Internal Server Error");
      console.error(err);
    }
  };
  useEffect(() => {
    fetchEmployeeList();
    // selectEmployeeFromDropdownForTable();
  }, []);

  //   console.log(employee);
  console.log(empId);
  return (
    <form onSubmit={selectEmployeeFromDropdownForTable}>
      <div className="row">
        <div className="col-md-3">
          <select
            className="form-select form-control"
            id="inputGroupSelect02"
            onChange={(e) => {
              setEmpId(() => e.target.value);
            }}
          >
        
            <option value=""> Select Employees</option>
            {employee && employee.length > 0 && employee !== undefined
              ? employee.map((empList) => {
                  //   console.log(empList);
                  return (
                    <>
                      <option key={empList.id} value={empList.id}>
                        {empList.full_name}
                      </option>
                    </>
                  );
                })
              : ""}
          </select>
        </div>
        <div className="col-md-6">
          <button type="submit" className="btn btn-outline-danger">
            Add To Table Below
          </button>
        </div>
      </div>
    </form>
  );
}
