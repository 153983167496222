import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import { NavLink, Route } from "react-router-dom";

import { BASE_URL } from "../../../../Constents";
import AllApplicants from "./AllApplicants";
import ShortlistedApplicants from "./ShortlistedApplicants";
import RejectedApplicants from "./RejectedApplicants";
import CreateCandidate from "./CreateCandidate";
import OnHoldApplicants from "./OnHoldApplicants";
import EditExistingCandidate from "./EditExistingCandidate";
import ScheduleCandidateInterview from "./ScheduleCandidateInterview";

export default function Candidates() {
  const [showAddCandidateModal, setShowAddCandidateModal] = useState(false);
  const [editCandidateModalAndDetails, setEditCandidateModalAndDetails] =
    useState(false);
  const [
    scheduleCandidateInterviewModalAndDetails,
    setScheduleCandidateInterviewModalAndDetails,
  ] = useState(false);
  const [clickedRowData, setClickedRowData] = useState({});
  // const { SearchBar } = Search;

  const closeCandidateModal = () => {
    setShowAddCandidateModal(false);
  };
  const openCandidateModal = () => {
    setShowAddCandidateModal(true);
  };

  const closeEditCandidateModal = () => {
    setEditCandidateModalAndDetails(false);
  };
  const openEditCandidateModal = () => {
    setEditCandidateModalAndDetails(true);
  };
  const closeScheduleCandidateInterviewModal = () => {
    setScheduleCandidateInterviewModalAndDetails(false);
  };
  const openScheduleCandidateInterviewModal = () => {
    setScheduleCandidateInterviewModalAndDetails(true);
  };

  console.log(clickedRowData);

  return (
    <>
      <div className="maincontent">
        <div className="highlight">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 style={{ color: "#DD3974" }} className="mb-2">
              Candidates
            </h2>
            <button
              className="departmentSettingBtn formSelectPlus w-25"
              onClick={openCandidateModal}
            >
              Add Applicant
            </button>
          </div>
          <div className="row vacancyTabs">
            <div className="col-md-12">
              <ul className="vac_tabs">
                <li>
                  <NavLink
                    exact
                    to={BASE_URL + "Candidates/AllApplicants"}
                    // activeStyle={active}
                  >
                    All Applicants
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={BASE_URL + "Candidates/ShortlistedApplicants"}
                    //activeStyle={active}
                  >
                    Shortlisted Applicants
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={BASE_URL + "Candidates/RejectedApplicants"}
                    //  activeStyle={active}
                  >
                    Rejected Applicants
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to={BASE_URL + "Candidates/OnHoldApplicants"}
                    //  activeStyle={active}
                  >
                    On Hold Applicants
                  </NavLink>
                </li>
              </ul>
            </div>

            <hr />

            <Route path={BASE_URL + "Candidates/AllApplicants"}>
              <AllApplicants
                dataFromAllChiled={setClickedRowData}
                openEditCandidateModal={openEditCandidateModal}
                openScheduleCandidateInterviewModal={
                  openScheduleCandidateInterviewModal
                }
                toggleOnCreatingCandidate={showAddCandidateModal}
              />
            </Route>
            <Route path={BASE_URL + "Candidates/ShortlistedApplicants"}>
              <ShortlistedApplicants
                dataFromAllChiled={setClickedRowData}
                openEditCandidateModal={openEditCandidateModal}
                openScheduleCandidateInterviewModal={
                  openScheduleCandidateInterviewModal
                }
              />
            </Route>
            <Route path={BASE_URL + "Candidates/RejectedApplicants"}>
              <RejectedApplicants
                dataFromAllChiled={setClickedRowData}
                openEditCandidateModal={openEditCandidateModal}
                openScheduleCandidateInterviewModal={
                  openScheduleCandidateInterviewModal
                }
              />
            </Route>
            <Route path={BASE_URL + "Candidates/OnHoldApplicants"}>
              <OnHoldApplicants
                dataFromAllChiled={setClickedRowData}
                openEditCandidateModal={openEditCandidateModal}
                openScheduleCandidateInterviewModal={
                  openScheduleCandidateInterviewModal
                }
              />
            </Route>
          </div>
        </div>
      </div>

      {/* Create Vacancy Modal */}

      <Modal
        show={showAddCandidateModal}
        onHide={closeCandidateModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <CreateCandidate closeCandidateModal={closeCandidateModal} />
        </Modal.Body>
      </Modal>

      <Modal
        show={editCandidateModalAndDetails}
        onHide={closeEditCandidateModal}
        size="lg"
        z
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <EditExistingCandidate
            clickedRowData={clickedRowData}
            closeEditCandidateModal={closeEditCandidateModal}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={scheduleCandidateInterviewModalAndDetails}
        onHide={closeScheduleCandidateInterviewModal}
        size="lg"
        z
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <ScheduleCandidateInterview
            clickedRowData={clickedRowData}
            closeScheduleCandidateInterviewModal={
              closeScheduleCandidateInterviewModal
            }
          />
        </Modal.Body>
      </Modal>

      {/* Create Vacancy Modal Ends */}
    </>
  );
}
