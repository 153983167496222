import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";

import { API_URL } from "../../../../Constents";

import { BiSearch } from "react-icons/bi";
import { AiOutlineFilePdf } from "react-icons/ai";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

// Side Drawer
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

import AllCandidateSideDrawer from "./CandidateSideDrawer/AllCandidateSideDrawer";
import LoadingGifs from "../../../../images/YAAMS-gif.gif";

const AllApplicants = (props) => {
  const [allJobsListForTable, setAllJobsListForTable] = useState([]);
  const [sideDrawerIsOpen, setsideDrawerIsOpen] = useState(false);
  const [selectRowDataFromTable, setSelectRowDataFromTable] = useState({});
  const [loading, isLoading] = useState(true);
  const [refreshTable, setRefreshTable] = useState();
  const [allActiveJobsForFilter, setAllActiveJobsForFilter] = useState([]);
  const [jobFilterID, setJobFilterID] = useState("");

  const alert = useAlert();
  const { SearchBar } = Search;

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  const fetchAllAppicants = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    let url = "";
    if (jobFilterID !== "") {
      url = API_URL + "/applicant/?vacancy=" + jobFilterID;
    } else {
      url = API_URL + "/applicant/";
    }

    console.log(url);

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();

      setAllJobsListForTable(data.data.data);
      isLoading(false);
      console.log(data);
    } catch (err) {
      console.error(err);
      alert.error("There is an error in fetching the vacancy list");
      isLoading(true);
    }
  };
  const columns = [
    {
      dataField: "id",
      text: "Vacancy ID",
      sort: true,
    },
    { dataField: "name", text: "Name", sort: true },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },

    {
      dataField: "mobile_number",
      text: "Phone No.",
      sort: true,
    },

    {
      dataField: "applicant_status",
      text: "Status",
      sort: true,
      formatter: (row) => {
        if (row === null) return <p>NA</p>;
        if (row === "on_hold")
          return <p style={{ color: "orange" }}>On Hold</p>;
        if (row === "selected")
          return <p style={{ color: "green" }}>Selected</p>;
        if (row === "rejected") return <p style={{ color: "red" }}>Rejected</p>;
      },
    },
    {
      dataField: "vacancy",
      text: "Job Title",
      sort: true,
      formatter: (row) => {
        return row.title;
      },
    },
    {
      dataField: "resume",
      text: "Attachment",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <AiOutlineFilePdf
            color="#DD3974"
            size={22}
            onClick={() => window.open(row.resume)}
            type="button"
          />
        );
      },
    },
    {
      dataField: "current_ctc",
      text: "Current C.T.C",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>{row.current_ctc === null ? <p>NA</p> : <p>{row.current_ctc}</p>}</>
        );
      },

      sort: true,
    },
    {
      dataField: "expected_ctc",
      text: "Expected C.T.C",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            {row.expected_ctc === null ? <p>NA</p> : <p>{row.expected_ctc}</p>}
          </>
        );
      },
      sort: true,
    },
    {
      dataField: "notice_period",
      text: "Notice Period",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <>
            {row.notice_period === null ? (
              <p>NA</p>
            ) : (
              <p>{row.notice_period} days</p>
            )}
          </>
        );
      },
      sort: true,
    },
  ];

  const fetchAllJobs = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/admin-vacancy-crud/?is_active_status=true";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      //setAllJobsListForTable(data.data);
      setAllActiveJobsForFilter(data.data);
    } catch (err) {
      console.error(err);
      alert.error("There is an error in fetching the vacancy list");
    }
  };

  useEffect(() => {
    fetchAllJobs();
  }, []);

  useEffect(() => {
    fetchAllAppicants();
  }, [
    props.toggleOnCreatingCandidate,
    refreshTable,
    props.openEditCandidateModal,
    jobFilterID,
  ]);

  console.log(allJobsListForTable);
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      toggleSideDrawer();
      setSelectRowDataFromTable(row);
      props.dataFromAllChiled(row);
    },
  };
  const toggleSideDrawer = () => {
    setsideDrawerIsOpen((prevState) => !prevState);
  };
  return (
    <div>
      {loading ? (
        <>
          <div className="loadingGif">
            <img src={LoadingGifs} alt="Loading Gif" />
          </div>
        </>
      ) : (
        <ToolkitProvider
          keyField="vacancy_id"
          data={allJobsListForTable}
          columns={columns}
          bootstrap4
          search
        >
          {(props) => (
            <React.Fragment>
              <div className="row">
                <div className="position-relative newsearch_box col-md-3">
                  <SearchBar
                    className="newSearchWrapper"
                    placeholder="Search.."
                    srText=""
                    {...props.searchProps}
                  />
                  <BiSearch className="searchIcon me-2" />
                </div>
                <div className="col-md-3">
                  <select
                    className="form-select form-control"
                    id="inputGroupSelect02"
                    onChange={(e) => {
                      setJobFilterID(e.target.value);
                    }}
                  >
                    <option hidden disabled selected>
                      {" "}
                      Vacancy Filter
                    </option>
                    <option value=""> All</option>
                    {allActiveJobsForFilter &&
                    allActiveJobsForFilter.length > 0 &&
                    allActiveJobsForFilter !== undefined
                      ? allActiveJobsForFilter.map((jobsList) => (
                          <option key={jobsList.id} value={jobsList.vacancy_id}>
                            {jobsList.title}
                          </option>
                        ))
                      : ""}
                  </select>
                </div>
              </div>

              <BootstrapTable
                pagination={pagination}
                filter={filterFactory()}
                {...props.baseProps}
                noDataIndication={() => {
                  return <h3>No data available </h3>;
                }}
                condensed
                bordered={false}
                tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                rowEvents={rowEvents}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      )}
      <Drawer
        open={sideDrawerIsOpen}
        onClose={toggleSideDrawer}
        direction="right"
        className="rightSideDrawer"
        size={"380px"}
      >
        {/* <VacancySideDrawer selectedRowData={selectRowDataFromTable} /> */}
        <AllCandidateSideDrawer
          selectedRowData={selectRowDataFromTable}
          openEditCandidateModal={props.openEditCandidateModal}
          openScheduleCandidateInterviewModal={
            props.openScheduleCandidateInterviewModal
          }
          refreshTable={setRefreshTable}
          closeSideDrawer={setsideDrawerIsOpen}
        />
      </Drawer>
    </div>
  );
};

export default AllApplicants;
