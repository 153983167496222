import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAlert } from "react-alert";

import { AiOutlineArrowRight, AiFillPlusSquare } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";

import { API_URL, BASE_URL } from "../../Constents";
import AddDepartments from "./AddDepartments";

import LoadingGIF from "../../images/YAAMS-gif.gif";

const DepartmentMain = () => {
  const [deptMain, setDeptMain] = useState([]);
  const [departmentForm, setDepartmentForm] = useState(false);

  const alert = useAlert();

  const fetchDepartmentMain = async () => {
    const url = API_URL + "/department-count/";
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setDeptMain(data.data);
    } catch (err) {
      console.error(err);
      alert.error("There is an error in fetching the department list");
    }
  };

  useEffect(() => {
    fetchDepartmentMain();
  }, []);

  const depmain = deptMain.filter(
    (dep) => dep.department_assigned_name != null
  );

  return (
    <>
      <div>
        <div>
          <button
            className="mb-3 btn btn-outline-danger btn-lg"
            onClick={() => setDepartmentForm(!departmentForm)}
          >
            Add Team <AiFillPlusSquare />
          </button>
          {departmentForm === true ? <AddDepartments /> : ""}

          {/* <h2 style={{ color: "dd3944" }}>Teams</h2> */}
          <div className="row">
            {depmain && depmain.length > 0 ? (
              depmain.map((dept, index = 0) => {
                index++;

                return (
                  <div className="col-md-4" key={index}>
                    <Link
                      to={
                        BASE_URL +
                        "DepartmentList/" +
                        dept.department_assigned__id
                      }
                      style={{ textDecoration: "none", color: "#DB3F49" }}
                    >
                      <div
                        className="highlight dashbox"
                        style={{
                          background:
                            "linear-gradient(230deg; color: rgb(255, 255, 255)",
                        }}
                      >
                        <h4 className="text-dark">
                          {dept.department_assigned_name}{" "}
                          <AiOutlineArrowRight />
                        </h4>
                        <h6 className="text-dark">
                          <span style={{ color: "#DB3F49" }}> Head: </span>
                          {dept.department_assigned__department_lead === null
                            ? "Not Assigned"
                            : dept.department_assigned__department_lead}
                        </h6>
                        <HiUserGroup size={45} />
                        <h1 className="percent" style={{ color: "#000" }}>
                          {dept.emp_count_in_dep}
                        </h1>
                      </div>
                    </Link>
                  </div>
                );
              })
            ) : (
              <div style={{ textAlign: "center" }}>
                <img
                  src={LoadingGIF}
                  style={{ height: "400px", width: "400px" }}
                  alt="loading..."
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentMain;
