import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import { API_URL } from "../../../Constents";
import { useAlert } from "react-alert";
export default function EmployeeFeedbackForm() {
  const [employeeFeedbackData, setEmployeeFeedbackData] = useState({
    feedback_rating: 0,
    feedback: "",
  });
  const alert = useAlert();

  const token = window.location.href.split("/").reverse()[1];
  const name = window.location.href.split("/").reverse()[0].split("%20");

  let formattedName = "";
  name.map((i) => {
    return (formattedName = formattedName + i + " ");
  });

  console.log(token);

  const handleRating = (rate) => {
    setEmployeeFeedbackData((prevState) => ({
      ...prevState,
      feedback_rating: rate,
    }));
  };

  const employeeFeedbackFormHandler = async () => {
    const headersList = {
      "Content-Type": "application/json",
    };
    const url = API_URL + "/appraisal-feedback/";
    const bodyContent = JSON.stringify({
      token: token,
      feedback: employeeFeedbackData.feedback,
      feedback_rating: employeeFeedbackData.feedback_rating,
    });

    console.log(bodyContent);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      // setEmployeeFeedbackData(data);
      if (data.status) alert.success(data.message);
      if (data.status === false) alert.error(data.message);
      console.log(data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  console.log(employeeFeedbackData);
  return (
    <>
      <div className="container" style={{ marginLeft: "17%", marginTop: "6%" }}>
        <div className="candidateMainContent">
          <div className="candidateHighlight">
            <h3 className="text-center text-danger">
              Appraisal Feedback of  {formattedName}
            </h3>
            <div className="mt-3">
              <div className="row">
                <div className="col-md-3">
                  <h5 className="mt-2">Rate Employee:</h5>
                </div>
                <div className="col-md-3">
                  <Rating onClick={handleRating} transition allowFraction />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3">
                  <h5 className="mt-2">Special Notes:</h5>
                </div>
                <div className="col-md-6">
                  <textarea
                    className="form-control"
                    onChange={(e) => {
                      setEmployeeFeedbackData((prevState) => ({
                        ...prevState,
                        feedback: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-outline-success mt-4"
                  onClick={employeeFeedbackFormHandler}
                >
                  Submit Feedback
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
