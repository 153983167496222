import React from "react";
//table imports
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
//import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
// Table imports Ends
import { Modal } from "react-bootstrap";
import { API_URL } from "../../Constents";
import { useEffect, useState } from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { GoLinkExternal } from "react-icons/go";
import { useAlert } from "react-alert";
import { BiSearch, BiPrinter, BiDownload } from "react-icons/bi";
import { AiOutlineReload } from "react-icons/ai";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import loadingImage from "../../images/YAAMS-gif.gif";
import DownloadQRPDFfile from "./DownloadQRPDFfile";

export default function LableandLoacationTable(props) {
  const [labelData, setLabelData] = useState();
  const [modalShow, setModalShow] = useState({
    QrName: null,
    QrImage: null,
    open: false,
  });
  const [QRResponseData, setQRResponseData] = useState({});

  const { SearchBar } = Search;
  const alert = useAlert();
  const fetchQrListData = async () => {
    const url = API_URL + "/label-create-view";
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headerList,
      });
      const data = await res.json();
      console.log(data);
      setLabelData(data.data);
    } catch (error) {
      console.log(error);
      alert.error("Internal Server Error");
    }
  };

  const generateQrHandler = async (location, id) => {
    const url = API_URL + "/generate-new-qr-with-label/?label_id=" + id;
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const bodyContent = JSON.stringify({
      location_id: location,
    });

    console.log(bodyContent);
    try {
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headerList,
      });
      const data = await res.json();
      setQRResponseData(data);
      if (data.status === true) alert.success(data.message);
      if (data.status === false) alert.error(data.message);
    } catch (error) {
      console.log(error);
      alert.error("Internal Server Error");
    }
  };

  useEffect(() => {
    fetchQrListData();
  }, [QRResponseData, props.refresh]);

  const columns = [
    // {
    //   dataField: "id",
    //   text: "id",
    //   sort: true,
    // },
    {
      dataField: "location_name",
      text: "Location Name",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <button
            className="map_hyperlink_button"
            onClick={() =>
              window.open(
                "https://maps.google.com/?q=" +
                  row.latitude +
                  "," +
                  row.longitude,
                "_blank"
              )
            }
          >
            {row.location_name} <GoLinkExternal />
          </button>
        );
      },
    },
    {
      dataField: "name",
      text: "Lable Name",
      sort: true,
    },
    {
      dataField: "qr_code",
      text: "QR",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.qr_code === null) {
          return (
            <button
              className="btn btn-outline-warning"
              // style={{ color: "#3E3E3E", textDecoration: "none" }}
              onClick={() => {
                generateQrHandler(row.location, row.id);
              }}
            >
              Generate QR{" "}
            </button>
          );
        } else {
          return (
            <button
              className="btn btn-link"
              style={{ color: "#3E3E3E", textDecoration: "none" }}
              onClick={() => {
                setModalShow({
                  ...modalShow,
                  open: true,
                  QrName: row.name,
                  QrImage: row.qr_code,
                  qrLocationName: row.location_name,
                });
              }}
            >
              View & Print{" "}
              <BsBoxArrowUpRight
                style={{
                  fontSize: "10px",
                  marginLeft: "3px",
                  marginBottom: "10px",
                  color: "black",
                  fontWeight: "600",
                }}
              />
            </button>
          );
        }
      },
    },
    {
      dataField: "Created Info",
      text: "Created By/At",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return !row.qr_code ? (
          <p style={{ fontSize: "12px" }} className="justify-center">
            Please Generate Qr
          </p>
        ) : (
          <div>
            <p className="m-0">{row.created_by}</p>
            <p className="m-0" style={{ fontSize: "8px" }}>
              {row.created_at
                ? moment(row.created_at).format("MMMM Do YYYY, h:mm:ss a")
                : "NA"}
            </p>
          </div>
        );
      },
    },
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  console.log(labelData);
  //  const Time = moment(row.created_at).format("MMMM Do YYYY, h:mm:ss a");
  return (
    <>
      {labelData ? (
        <ToolkitProvider
          keyField="id"
          data={labelData}
          columns={columns}
          bootstrap4
          search
        >
          {(props) => (
            <React.Fragment>
              <div className="row non-printable">
                <div className="offset-md-9 col-md-3 mb-3">
                  <div className="position-relative newsearch_box">
                    <SearchBar
                      className="newSearchWrapper"
                      placeholder="Search Here..."
                      srText=""
                      {...props.searchProps}
                    />
                    <BiSearch />
                  </div>
                </div>
              </div>
              <div className="non-printable">
                <BootstrapTable
                  pagination={pagination}
                  filter={filterFactory()}
                  {...props.baseProps}
                  noDataIndication={() => {
                    return <h3>No data available </h3>;
                  }}
                  condensed
                  bordered={false}
                  tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                />
              </div>
            </React.Fragment>
          )}
        </ToolkitProvider>
      ) : (
        <div className="loadingGif">
          <img
            src={loadingImage}
            style={{ height: "300px", width: "300px" }}
            alt="loading..."
          />
        </div>
      )}
      <Modal
        show={modalShow.open}
        onHide={() => setModalShow({ ...modalShow, open: false })}
      >
        <Modal.Header closeButton className="non-printable"></Modal.Header>

        <Modal.Body className="p-1">
          <div className="qr_title text-center">
            <h2 className="m-0">{modalShow.QrName}</h2>
            <p className="m-0">{modalShow.qrLocationName}</p>
          </div>
          <div className="d-flex qr_box justify-content-center m-0">
            <img
              src={modalShow.QrImage}
              alt="QR Code"
              className="img-fluid"
              style={{ width: "90%" }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="non-printable">
          <button className="btn btn-warning" disabled>
            Replace QR
          </button>
          <button className="btn btn-secondary" onClick={() => window.print()}>
            <BiPrinter size={25} />
          </button>
          <PDFDownloadLink
            document={
              <DownloadQRPDFfile
                QrName={modalShow.QrName}
                QrImage={modalShow.QrImage}
                QrLocationName={modalShow.qrLocationName}
              />
            }
            fileName={modalShow.QrName}
          >
            {({ loading }) =>
              loading ? (
                <button className="btn btn-light">
                  <AiOutlineReload color="red" />
                </button>
              ) : (
                <button className="btn btn-secondary">
                  <BiDownload size={25} />
                </button>
              )
            }
          </PDFDownloadLink>
        </Modal.Footer>
      </Modal>
    </>
  );
}
