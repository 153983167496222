//I know this is code duplication, but I have to meet the deadline ;)

import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useAlert } from "react-alert";
import { API_URL } from "../../../Constents";
import { useEffect } from "react";

export default function EditLeaveType(props) {
  const [details, setDetails] = useState({
    leaveName: "",
    leaveCode: null,
    numOfLeaves: 0,
    duration: null,
    durationInHours: null,
    description: "",
    carryForwardLeaves: 0,
    carryForwredMonths: 1,
    customLeaveHours: 0,
  });

  const [selectedEmployee, setSelectedEmployee] = useState([]);

  const alert = useAlert();
  console.log(props);

  const editALeaveTypeHandler = async (e) => {
    e.preventDefault();
    const formattedLeaveTypeArray = [];
    selectedEmployee.map((i) => formattedLeaveTypeArray.push(i.value));
    if (formattedLeaveTypeArray.length === 0) {
      alert.error("Please select atleast one Employee Type");
      return;
    }

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const url = API_URL + "/admin-quantity-view/?leave_id=" + props.id;

    const bodyContent = JSON.stringify({
      name: details.leaveName,
      code: details.leaveCode,
      // type: "paid",
      // unit: "days",
      employee_type: formattedLeaveTypeArray,
      quantity: details.numOfLeaves,
      duration: details.customLeaveHours,
      description: details.description,
      maximum_carry_forward: details.carryForwardLeaves,
      carry_forward_duration: details.carryForwredMonths,
      validity_start_from: null,
      // organization_name: null,
      // organization: null,
      // org: null,
    });

    console.log(bodyContent);

    try {
      const response = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headerList,
      });

      const data = await response.json();
      console.log(data);
      if (data.status) {
        props.close();
        alert.success(data.message);
      } else {
        alert.error(JSON.stringify(data.message));
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  const fetchLeaveData = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/admin-quantity-view/?leave_id=" + props.id;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headerList,
      });

      const data1 = await response.json();
      const { data } = data1;
      const realData = data[0];
      console.log(realData);
      setDetails({
        leaveName: realData.name,
        leaveCode: realData.code,
        numOfLeaves: realData.quantity,
        description: realData.description,
        carryForwardLeaves: realData.maximum_carry_forward,
        carryForwredMonths: realData.carry_forward_duration,
        customLeaveHours: realData.duration,
      });
      setSelectedEmployee(realData.employee_type);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchLeaveData();
  }, []);

  console.log(details);

  return (
    <>
      <div className="setting_header ps-3">
        <form onSubmit={editALeaveTypeHandler}>
          <div className="col-md-12">
            <h5 style={{ color: "#DD3974" }}>
              Edit {props.leaveName.name + "(" + props.leaveName.code + ")"}
            </h5>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <label for="email" className="form-label">
                  Enter Leave Name
                </label>
                <input
                  type="check"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  placeholder="Enter Leave Name"
                  onChange={(e) =>
                    setDetails({ ...details, leaveName: e.target.value })
                  }
                  value={details.leaveName}
                  required
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label for="email" className="form-label">
                  Select Leave Code
                </label>
                <select
                  className="form-select"
                  onChange={(e) =>
                    setDetails({ ...details, leaveCode: e.target.value })
                  }
                  required
                >
                  <option hidden>---Select Leave Code---</option>
                  {props.leaveData.leave_type.map((i) => (
                    <option
                      value={i.value}
                      selected={i.value === details.leaveCode}
                    >
                      {i.label + "(" + i.value + ")"}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label for="team" className="form-label">
                  Select Employee Type
                </label>

                <MultiSelect
                  options={props.leaveData.employee_type_available}
                  value={selectedEmployee}
                  onChange={setSelectedEmployee}
                  labelledBy="Select"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <label for="reporting" className="form-label">
                  Enter No. of Leaves
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="reporting"
                  aria-describedby="emailHelp"
                  placeholder="Enter Numbers"
                  onChange={(e) =>
                    setDetails({ ...details, numOfLeaves: e.target.value })
                  }
                  value={details.numOfLeaves}
                  required
                />
              </div>
            </div>

            {details.leaveCode === "SB" ? (
              ""
            ) : (
              <div className="col-md-4">
                <label for="work_number" className="form-label">
                  Select Leave Duration
                </label>

                <div
                  onChange={(e) =>
                    setDetails({ ...details, duration: e.target.value })
                  }
                  className="d-flex"
                >
                  <div className="align-center col-md-4">
                    <input
                      type="checkbox"
                      id="Full"
                      required
                      checked
                      disabled
                    />
                    <label for="Full">Full</label>
                  </div>

                  {details.leaveCode === "CL" ||
                  details.leaveCode === "PL" ||
                  details.leaveCode === "SL" ||
                  details.leaveCode === "EL" ||
                  details.leaveCode === "BL" ||
                  details.leaveCode === "SB" ||
                  details.leaveCode === "OL" ? (
                    <div className="align-center col-md-4">
                      <input type="checkbox" value="half" checked disabled />
                      <label for="Half">Half Day</label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}

            {details.leaveCode === "CL" ||
            details.leaveCode === "PL" ||
            details.leaveCode === "SL" ||
            details.leaveCode === "EL" ||
            details.leaveCode === "BL" ||
            details.leaveCode === "SB" ||
            details.leaveCode === "LP" ||
            details.leaveCode === "OL" ? (
              <div className="col-md-4">
                <div className="mb-3">
                  <label for="team" className="form-label">
                    Custom Hours
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    value={details.customLeaveHours}
                    required
                    onChange={(e) =>
                      setDetails({
                        ...details,
                        customLeaveHours: e.target.value,
                        durationInHours: 0,
                      })
                    }
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="row">
            <div className="col-md-6">
              <label className="form-label">Description</label>
              <textarea
                id="w3review"
                name="w3review"
                className="form-control"
                onChange={(e) =>
                  setDetails({ ...details, description: e.target.value })
                }
                value={details.description}
                required
              />
            </div>
          </div>

          <h6
            for="role"
            className="form-label mb-3 mt-4"
            style={{ color: "black", fontWeight: "bold" }}
          >
            Leave Expiration Settings
          </h6>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label for="" className="form-label">
                No. of Leaves to carry forward
              </label>
              <input
                type="number"
                className="form-control"
                id="reporting"
                aria-describedby="emailHelp"
                placeholder="Enter No of Leave"
                onChange={(e) =>
                  setDetails({ ...details, carryForwardLeaves: e.target.value })
                }
                value={details.carryForwardLeaves}
              />
            </div>
            <div className="col-md-4">
              <label for="" className="form-label">
                Enter No of Months
              </label>
              <input
                type="number"
                className="form-control"
                id="reporting"
                aria-describedby="emailHelp"
                placeholder="Enter Here"
                onChange={(e) =>
                  setDetails({ ...details, carryForwredMonths: e.target.value })
                }
                value={details.carryForwredMonths}
              />
              {details.carryForwredMonths === "" ? (
                ""
              ) : (
                <p className="msg_fail">
                  This leave will expire in {details.carryForwredMonths}{" "}
                  month(s)
                </p>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className="save_btns d-flex justify-content-end">
              <button className="save_btn" type="submit">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
