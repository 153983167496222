export default function DeptCheckinTimeForEmp({
  deptCheckInAndCheckOutTime,
  changeEmployeeCheckinTime,
}) {
  return (
    <div className="row align-items-center w-100">
      <div className="d-flex">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">Check-in Time is</li>
          <li class="list-group-item">Check-out Time is</li>

          <li class="list-group-item">Allowed Late Entry</li>
          <li class="list-group-item">Round-off Time is</li>
        </ul>

        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            {" "}
            {deptCheckInAndCheckOutTime &&
            deptCheckInAndCheckOutTime.department_setting
              ? deptCheckInAndCheckOutTime.department_setting.check_in_time
              : "NA"}
          </li>
          <li class="list-group-item">
            {" "}
            {deptCheckInAndCheckOutTime &&
            deptCheckInAndCheckOutTime.department_setting
              ? deptCheckInAndCheckOutTime.department_setting.check_out_time
              : "NA"}
          </li>
          <li class="list-group-item">
            {deptCheckInAndCheckOutTime &&
            deptCheckInAndCheckOutTime.department_setting
              ? deptCheckInAndCheckOutTime.department_setting
                  .allowed_late_num_of_day
              : "NA"}{" "}
            days
          </li>
          <li class="list-group-item">
            {deptCheckInAndCheckOutTime &&
            deptCheckInAndCheckOutTime.department_setting
              ? deptCheckInAndCheckOutTime.department_setting.round_off_time
              : "NA"}{" "}
            minutes
          </li>
        </ul>
      </div>
      <div className="new_leave_btn text-start w-100">
        <button
          className="save_btn"
          type=""
          onClick={changeEmployeeCheckinTime}
        >
          Save
        </button>
      </div>
      <hr className="my-4" />
    </div>
  );
}
