import { useAlert } from "react-alert";

import { API_URL } from "../../../Constents";

const DeleteLeaveFromTheTable = (props) => {
  const alert = useAlert();

  const deleteLeaveFromTheTableHandler = async () => {
    console.log(props.id);
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/admin-quantity-view/?leave_id=" + props.id;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: headerList,
      });
      console.log(response);

      const data = await response.json();
      console.log(data);
      if (data.status) {
        alert.success(data.message);
        props.modalClose();
      } else alert.error(data.message);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  return (
    <div className="text-center">
      <h3 className="mb-4">
        Are you sure you want to delete{" "}
        <i>{props.leaveName.name + "(" + props.leaveName.code + ")"}</i>?
      </h3>

      <button
        className="btn btn-danger btn-lg"
        onClick={deleteLeaveFromTheTableHandler}
      >
        Yes, Delete{" "}
        <i>{props.leaveName.name + "(" + props.leaveName.code + ")"}</i>
      </button>
    </div>
  );
};

export default DeleteLeaveFromTheTable;
