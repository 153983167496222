import React, { useState, useEffect } from "react";
import Drawer from "react-modern-drawer";
//import styles 👇
import "react-modern-drawer/dist/index.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
// import { BsSearch, BsChevronCompactDown } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
// import { AiOutlineFilePdf } from "react-icons/ai";
import LoadingGifs from "../../../images/YAAMS-gif.gif";
import { API_URL } from "../../../Constents";
import StartAppraisals from "./StartAppraisals";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import AppraisalSideDrawer from "./AppraisalSideDrawer";
//import AddEmployeeDropdown from "./AddEmployeeDropdown";
export default function Appraisals() {
  const [openStartAppraisalModal, setOpenStartAppraisalModal] = useState({
    modalState: false,
  });
  const [sideDrawerDetails, setsideDrawerDetails] = useState({});
  const [allAppraisalsListForTable, setAllAppraisalsListForTable] = useState(
    []
  );

  const [appraisalID, setAppraisalID] = useState(null);
  const [selectRowDataFromTable, setSelectRowDataFromTable] = useState({});
  const [loading, isLoading] = useState(true);
  const [refreshTable, setRefreshTable] = useState();
  const [sideDrawerIsOpen, setsideDrawerIsOpen] = useState(false);

  const alert = useAlert();

  const toggleSideDrawer = () => {
    setsideDrawerIsOpen((prevState) => !prevState);
  };

  const { SearchBar } = Search;

  const closeAppraisalModal = () => {
    setOpenStartAppraisalModal((prevState) => ({
      ...prevState,
      modalState: false,
    }));
  };
  const openApprailsalModal = () => {
    setOpenStartAppraisalModal((prevState) => ({
      ...prevState,
      modalState: true,
    }));
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  const fetchAllAppraisalsList = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/admin-appraisal/";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();

      setAllAppraisalsListForTable(data.data);
      isLoading(false);
      console.log(data);
    } catch (err) {
      console.error(err);
      alert.error("There is an error in fetching the vacancy list");
      isLoading(true);
    }
  };

  const triggerMailHandler = async (emp_id, app_id) => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      employee_id: emp_id,
    });

    const url = API_URL + "/appraisal-feedback-form/?appraisal_id=" + app_id;
    console.log(url);
    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      if (data.status) {
        setRefreshTable(data);
        alert.success(data.message);
      }
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.error(err);
      alert.error("Something Went Wrong");
    }
  };

  const columns = [
    {
      dataField: "candidate.full_name",
      text: "Name of Employee(s)",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return row.candidate.full_name;
      },
    },
    {
      dataField: "due_days",
      text: "Appraisal Due In",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <row>{row.due_days + " Days"} </row>;
      },
    },
    {
      dataField: "email",
      text: "Last Appraisal/Joining Date",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.appraisal_effective_date === null) return row.candidate.doj;
        else return row.appraisal_effective_date;
      },
    },

    {
      dataField: "feedback_note",
      text: "Notes",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.feedback_status.length > 0) {
          return (
            <p
              style={{ color: "orange", cursor: "pointer" }}
              onClick={() => {
                toggleSideDrawer();
                setSelectRowDataFromTable(row);
                showEmployeeFeedbackHandler(row);
              }}
            >
              View
            </p>
          );
        } else {
          return <p>-</p>;
        }
      },
    },

    {
      dataField: "appraisal_status",
      text: "Status",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.mail_sent_status && row.feedback_status.length <= 0) {
          return <p>Waiting for feedback</p>;
        }
        if (row.mail_sent_status === false) {
          return (
            <button
              className="btn btn-outline-info"
              onClick={() => {
                triggerMailHandler(row.candidate.id, row.appraisal_id);
              }}
            >
              Initiate Process
            </button>
          );
        }
        if (row.mail_sent_status && row.feedback_status.length > 0) {
          return (
            <button
              className="btn btn-outline-warning"
              disabled={row.appraisal_status}
              onClick={() => {
                setAppraisalID(row.appraisal_id);
                openApprailsalModal();
              }}
            >
              Appraisal Form
            </button>
          );
        }
        // console.log(row);
        // if (row.appraisal_status === false)
        //   return (
        //     <button
        //       className="btn btn-outline-info"
        //       // onClick={openApprailsalModal}
        //       onClick={() => {
        //         triggerMailHandler(row.candidate.id, row.appraisal_id);
        //         setAppraisalID(row.appraisal_id);
        //       }}
        //     >
        //       Initiate Process
        //     </button>
        //   );
        // if (row.appraisal_status === true) return <p>Started</p>;
      },
    },
    {
      dataField: "appraisal_status",
      text: "",
      // sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.Appraisal_attachment && row.feedback_status.length !== 0)
          return (
            <button
              className="btn btn-outline-success"
              onClick={() => window.open(row.Appraisal_attachment)}
            >
              View Pdf
            </button>
          );
        if (row.feedback_status.length > 0) {
          return (
            <button
              disabled={!row.appraisal_status}
              className="btn btn-outline-danger"
              onClick={() => {
                generateOfferLetterHandler(row.appraisal_id);
              }}
            >
              Generate Appraisal Letter
            </button>
          );
        }
        if (row.feedback_status.length === 0) {
          return "FeedBack Not Given";
        }
      },
    },
  ];
  const generateOfferLetterHandler = async (app_id) => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const url = API_URL + "/generate-appraisal_pdf/";
    const bodyContent = JSON.stringify({
      appraisal_id: app_id,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);

      if (data.status) {
        setRefreshTable(data);
        alert.success(data.message);
      }
      if (data.status === false) alert.error(data.message);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  const showEmployeeFeedbackHandler = async (row) => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url =
      API_URL + "/appraisal-feedback/?appraisal_id=" + row.appraisal_id;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await response.json();
      setsideDrawerDetails(data.data);
      if (data.status === false) alert.error(data.message);
      console.log(data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  useEffect(() => {
    fetchAllAppraisalsList();
  }, [openStartAppraisalModal.modalState, refreshTable]);

  return (
    <>
      <div className="maincontent">
        <div className="highlight">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 className=" mb-2" style={{ color: "#000" }}>
              Appraisals
            </h2>
            <Link
              className="departmentSettingBtn formSelectPlus w-25"
              to={"/AddEmployeeForAppraisal"}
              style={{ textDecoration: "none" }}
            >
              Add Employee
            </Link>
          </div>
          <ToolkitProvider
            keyField="vacancy_id"
            data={allAppraisalsListForTable}
            columns={columns}
            bootstrap4
            search
          >
            {(props) => (
              <React.Fragment>
                <div className="container p-0">
                  <div className="row">
                    <div className="col-md-3 mb-2">
                      <div className="position-relative newsearch_box">
                        <SearchBar
                          className="newSearchWrapper"
                          placeholder="Search.."
                          srText=""
                          {...props.searchProps}
                        />
                        <BiSearch className="searchIcon" />
                      </div>
                    </div>
                    {/* <div className="col-md-6 mb-2">
                      <AddEmployeeDropdown />
                    </div> */}
                  </div>
                </div>
                <BootstrapTable
                  pagination={pagination}
                  filter={filterFactory()}
                  {...props.baseProps}
                  noDataIndication={() => {
                    return <h3>No data available </h3>;
                  }}
                  condensed
                  bordered={false}
                  tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                  // rowEvents={rowEvents}
                />
              </React.Fragment>
            )}
          </ToolkitProvider>
        </div>
      </div>
      <Modal
        show={openStartAppraisalModal.modalState}
        onHide={closeAppraisalModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <StartAppraisals
            closeAppraisalModal={closeAppraisalModal}
            appraisal_id={appraisalID}
          />
        </Modal.Body>
      </Modal>

      <Drawer
        open={sideDrawerIsOpen}
        onClose={toggleSideDrawer}
        direction="right"
        className="rightSideDrawer"
        size={"300px"}
      >
        <AppraisalSideDrawer
          sideDrawerDetails={sideDrawerDetails}
          // allAppraisalsListForTable={allAppraisalsListForTable}
        />
      </Drawer>
    </>
  );
}
