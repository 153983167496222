
import { useState } from "react";
import { API_URL } from "../../Constents";
import { useAlert } from "react-alert";

const UploadEmployeeExcel = () => {
  const [filePath, setFilePath] = useState({ file: "", path: "" });
  const [loadingMsg, setLoadingMsg] = useState("");
  const alert = useAlert();

  const updateFilePathHandler = (data) => {
    setFilePath({ file: data.target.files[0], path: data.target.value });
  };

  const fileUploadHandler = async () => {
    if (
      (filePath.file === "" && filePath.path === "") ||
      (filePath.file === undefined && filePath.path === "")
    ) {
      alert.info("Please select atleast one file");
    } else {
      setLoadingMsg("Your file is uploading Please wait!!!!!!");
      const userData = JSON.parse(window.sessionStorage.getItem("user"));

      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
      };

      const bodyContent = new FormData();

      bodyContent.append("file", filePath.file, filePath.value); /// impoertant

      const url = API_URL + "/upload-using-csv/";

      try {
        const res = await fetch(url, {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        });
        const data = await res.json();
        if (data.status === false) {
          setLoadingMsg("UNSUCCESSFUL!!!,  " + data.message);
          alert.error("UNSUCCESSFUL!!!,  " + data.message);
        } else {
          alert.success("File Upload Successfull !!!, " + data.message);
          setLoadingMsg("File Upload Successfull !!!, " + data.message);
        }
      } catch (err) {
        console.error(err);
        setLoadingMsg("There was an error in uploading the FIle!!!!");
        alert.error("There was an error in uploading the FIle!!!!");
      }
    }
  };

  return (
    <>
      {/* <div className="col-md-9">
        <div className="d-flex align-items-center"></div>
      </div> */}
      <div className="d-flex align-items-center">
        <input
          className="form-control"
          type="file"
          id="formFile"
          style={{ width: "240px" }}
          onChange={updateFilePathHandler}
          classes="table"
        />
        <button onClick={fileUploadHandler} className="uploadBtn mx-2">
          Upload
        </button>
        {loadingMsg === "" ? "" : <p className="msg_fail mt-3">{loadingMsg}</p>}
      </div>
    </>
  );
};

export default UploadEmployeeExcel;

