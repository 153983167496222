import { NavLink } from "react-router-dom";
import logo from "../images/logo.png";
import { FaTachometerAlt } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdNotifications } from "react-icons/io";
import { MdPeopleAlt, MdHelpCenter, MdFreeCancellation } from "react-icons/md";
import { AiFillCalendar, AiTwotoneSetting } from "react-icons/ai";
import { TiDownloadOutline } from "react-icons/ti";
import { BsCalendar4Event } from "react-icons/bs";
import { GrDocumentUser } from "react-icons/gr";
import { BASE_URL } from "../Constents";

const NavBar = (props) => {
  const active = {
    color: "#DD3944",
    background: "#f5f7ff",
  };

  const userData = JSON.parse(window.sessionStorage.getItem("user"));

  return (
    <nav id="sidebar" className={props.toggle ? null : "active"}>
      <div className="sidebar-header">
        <a href={BASE_URL}>
          <img src={logo} alt="Profile" />
        </a>
      </div>
      <ul className="list-unstyled components">
        <h6 className="ms-3 mb-3" style={{ color: "red" }}>
          {" "}
          {userData !== null && userData.data ? userData.data.first_name : ""}
          's Portal
        </h6>
        <li>
          <NavLink exact to={BASE_URL} activeStyle={active}>
            <FaTachometerAlt /> Dashboard
          </NavLink>
        </li>
        <li>
          <a
            href="#employees"
            data-bs-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle_alt"
          >
            <MdPeopleAlt /> Employees <IoMdArrowDropdown />
          </a>
          <ul className="collapse list-unstyled" id="employees">
            <li>
              <NavLink to={BASE_URL + "AddEmployee"} activeStyle={active}>
                Add Employee
              </NavLink>
            </li>
            <li>
              <NavLink to={BASE_URL + "EmployeeList"} activeStyle={active}>
                View Employee
              </NavLink>
            </li>
            <li>
              <NavLink to={BASE_URL + "ExEmployeeList"} activeStyle={active}>
                Ex-Employees
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <a
            href="#atendance"
            data-bs-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle_alt"
          >
            <AiFillCalendar /> Attendance <IoMdArrowDropdown />
          </a>
          <ul className="collapse list-unstyled" id="atendance">
            <li>
              <NavLink to={BASE_URL + "Attendence"} activeStyle={active}>
                View Attendance
              </NavLink>
            </li>
            <li>
              <NavLink to={BASE_URL + "WorkFromHome"} activeStyle={active}>
                Work From Home
              </NavLink>
            </li>
          </ul>
        </li>

        <li>
          <a
            href="#leaves"
            data-bs-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle_alt"
          >
            <MdFreeCancellation /> Leaves <IoMdArrowDropdown />
          </a>
          <ul className="collapse list-unstyled" id="leaves">
            <li>
              <NavLink to={BASE_URL + "Leaves"} activeStyle={active}>
                Leaves (Normal)
              </NavLink>
            </li>
            <li>
              <NavLink to={BASE_URL + "CompOffLeaves"} activeStyle={active}>
                Leaves (CompOff)
              </NavLink>
            </li>
            <li>
              <NavLink to={BASE_URL + "LossOfPay"} activeStyle={active}>
                Loss of Pay (LoP)
              </NavLink>
            </li>
          </ul>
        </li>

        {/* <li>
          <a
            href="#setting"
            data-bs-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle_alt"
          >
            <AiTwotoneSetting /> Setting <IoMdArrowDropdown />
          </a>
          <ul className="collapse list-unstyled" id="setting">
            <li>
              <NavLink
                to={BASE_URL + "CreateGeneralLeaveCategory"}
                activeStyle={active}
              >
                Week Off Rules
              </NavLink>
              <NavLink to={BASE_URL + "setting"} activeStyle={active}>
                Organization Setting
              </NavLink>
            </li>
          </ul>
        </li> */}

        <li>
          <NavLink to={BASE_URL + "settings"} activeStyle={active}>
            {" "}
            <AiTwotoneSetting /> Settings
          </NavLink>
        </li>
        <li>
          <NavLink to={BASE_URL + "eventsList"} activeStyle={active}>
            {" "}
            <BsCalendar4Event /> Events
          </NavLink>
        </li>
        <li>
          <NavLink to={BASE_URL + "notificationtable"} activeStyle={active}>
            {" "}
            <IoMdNotifications /> Notifications & Polls
          </NavLink>
        </li>
        <li>
          <NavLink to={BASE_URL + "YAAMSDownloads"} activeStyle={active}>
            {" "}
            <TiDownloadOutline /> Downloads
          </NavLink>
        </li>
        <li>
          <NavLink to={BASE_URL + "Help"} activeStyle={active}>
            {" "}
            <MdHelpCenter /> Help
          </NavLink>
        </li>
        <li>
          <a
            href="#core-hr"
            data-bs-toggle="collapse"
            aria-expanded="false"
            className="dropdown-toggle_alt"
          >
            <GrDocumentUser /> Core HR <IoMdArrowDropdown />
          </a>
          <ul className="collapse list-unstyled" id="core-hr">
            <li>
              <NavLink to={BASE_URL + "coreHrDashboard"} activeStyle={active}>
                Recruitments
              </NavLink>
            </li>
            <li>
              <NavLink to={BASE_URL + "Appraisals"} activeStyle={active}>
                Appraisals
              </NavLink>
            </li>
            <li>
              <NavLink to={BASE_URL + "Appreciation"} activeStyle={active}>
                Appreciation
              </NavLink>
            </li>
            <li>
              <NavLink to={BASE_URL + "FullAndFinal"} activeStyle={active}>
                FnF
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
