import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { API_URL } from "../../../Constents";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
// Side Drawer
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Modal from "react-bootstrap/Modal";
import { useAlert } from "react-alert";
import { BiSearch } from "react-icons/bi";
import { AiOutlineFilePdf } from "react-icons/ai";
import AppreciationSideDrawer from "./AppreciationSideDrawer";
import AddRemarkForAppreciation from "./AddRemarkForAppreciation";

export default function Appreciation() {
  const [employeeList, setEmployeeList] = useState([]);
  const [refreshTable, setRefreshTable] = useState();
  const [employeeMultiSelectList, setEmployeeMultiSelectList] = useState([]);
  const [allAppreciationList, setAllAppreciationList] = useState([]);
  const [appreciationId, setAppreciationId] = useState(null);
  const [sideDrawerIsOpen, setsideDrawerIsOpen] = useState(false);
  const [selectRowDataFromTable, setSelectRowDataFromTable] = useState({});
  const [extendVacancyModalAndDetails, setExtendVacancyModalAndDetails] =
    useState(false);
  const { SearchBar } = Search;
  const alert = useAlert();
  const formattedEmployee = [];
  employeeList.map((list) => {
    return formattedEmployee.push({
      label: list.full_name,
      value: list.id,
    });
  });

  const closeExtendVacancyModal = () => {
    setExtendVacancyModalAndDetails(false);
  };
  const openExtendVacancyModal = () => {
    setsideDrawerIsOpen(false);
    setExtendVacancyModalAndDetails(true);
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });
  const columns = [
    {
      dataField: "employee",
      text: "Name of Employee(s)",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return row.employee.full_name;
      },
    },
    {
      dataField: "department_assigned",
      text: "Department",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.employee.department_assigned === null) return "NA";
        if (row.employee.department_assigned !== null)
          return (
            <>
              <p>{row.employee.department_assigned.department_name}</p>
            </>
          );
      },
    },

    {
      dataField: "role_assigned",
      text: "Role",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log(row);
        if (row.employee.role_assigned === null) return "NA";
        if (row.employee.role_assigned !== null)
          return (
            <>
              <p>{row.employee.role_assigned.role_name}</p>
            </>
          );
      },
    },

    {
      dataField: "designation",
      text: "Designation",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.employee.designation === "") return "NA";
        if (row.employee.designation)
          return (
            <>
              <p>{row.employee.designation}</p>
            </>
          );
      },
    },
    {
      dataField: "feedback_status",
      text: "Remarks",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.feedback_status === false) {
          return (
            <>
              <button
                className="btn btn-outline-warning"
                onClick={() => {
                  openExtendVacancyModal();
                  setAppreciationId(row.appreciation_id);
                }}
              >
                Add Remark
              </button>
            </>
          );
        }
        if (row.feedback_status) {
          return (
            <>
              <p
                style={{ cursor: "pointer" }}
                className="text-danger"
                onClick={() => {
                  toggleSideDrawer();
                  setSelectRowDataFromTable(row);
                }}
              >
                View Remark
              </p>
            </>
          );
        }
      },
    },
    {
      dataField: "appreciation_attachment",
      text: "Attachment",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.appreciation_attachment === null) return "NA";
        if (row.appreciation_attachment)
          return (
            <>
              <AiOutlineFilePdf
                color="#dd3974"
                style={{ cursor: "pointer" }}
                size={22}
                onClick={() => window.open(row.appreciation_attachment)}
              />
            </>
          );
      },
    },
  ];

  const fetchEmployeeList = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const url = API_URL + "/employee-list-dashboard/";

    const bodyContent = JSON.stringify({
      department_array: [],
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headerList,
        body: bodyContent,
      });

      const data = await response.json();
      setEmployeeList(data.data);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchAllAppreciationList = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/add-fetch-update-appreciation/";
    const response = await fetch(url, {
      method: "GET",
      headers: headersList,
    });

    const data = await response.json();
    setAllAppreciationList(data.data);
    console.log(data);
  };
  const enrollEmployeeToAppreciationHandler = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const url = API_URL + "/add-fetch-update-appreciation/";

    const employeeIDlist = [];
    employeeMultiSelectList.map((list) => {
      return employeeIDlist.push(list.value);
    });

    if (employeeIDlist.length === 0)
      return alert.info("Please select atleast one employee");

    const bodyContent = JSON.stringify({
      employee_list: employeeIDlist,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      console.log(data);
      if (data.status) {
        setRefreshTable(data);
        alert.success(data.message);
      }
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  useEffect(() => {
    fetchAllAppreciationList();
  }, [refreshTable, extendVacancyModalAndDetails]);

  useEffect(() => {
    fetchEmployeeList();
  }, []);
  console.log(allAppreciationList);
  //   const rowEvents = {
  //     onClick: (e, row, rowIndex) => {
  //       console.log("Hello");
  //       console.log(row);
  //       toggleSideDrawer();
  //       setSelectRowDataFromTable(row);
  //     },
  //   };
  const toggleSideDrawer = () => {
    setsideDrawerIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <div className="maincontent">
        <div className="highlight">
          <h3 style={{ color: "#dd3974" }}>Appreciation</h3>

          <div className="row mt-3">
            <div className="col-md-3">
              <MultiSelect
                options={formattedEmployee}
                value={employeeMultiSelectList}
                onChange={setEmployeeMultiSelectList}
                //labelledBy="Select"
                // shouldToggleOnHover
                valueRenderer={(selected) => {
                  if (!selected.length) {
                    return "No Employee Selected";
                  }

                  return selected.map(({ label }) => "✔️ " + label);
                }}
              />
            </div>
            <div className="col-md-3">
              <button
                className="btn btn-outline-success"
                onClick={enrollEmployeeToAppreciationHandler}
              >
                Enroll Employee
              </button>
            </div>
          </div>
          <div className="mt-3">
            <ToolkitProvider
              keyField="vacancy_id"
              data={allAppreciationList}
              columns={columns}
              bootstrap4
              search
            >
              {(props) => (
                <React.Fragment>
                  <div className="container p-0">
                    <div className="row">
                      <div className="col-md-3 mb-2">
                        <div className="position-relative newsearch_box">
                          <SearchBar
                            className="newSearchWrapper"
                            placeholder="Search.."
                            srText=""
                            {...props.searchProps}
                          />
                          <BiSearch className="searchIcon" />
                        </div>
                      </div>
                      {/* <div className="col-md-6 mb-2">
                      <AddEmployeeDropdown />
                    </div> */}
                    </div>
                  </div>
                  <BootstrapTable
                    pagination={pagination}
                    filter={filterFactory()}
                    {...props.baseProps}
                    noDataIndication={() => {
                      return <h3>No data available </h3>;
                    }}
                    condensed
                    bordered={false}
                    tdStyle={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    // rowEvents={rowEvents}
                  />
                </React.Fragment>
              )}
            </ToolkitProvider>
          </div>
        </div>
      </div>
      <Drawer
        open={sideDrawerIsOpen}
        onClose={toggleSideDrawer}
        direction="right"
        className="rightSideDrawer"
        size={"350px"}
      >
        <AppreciationSideDrawer
          selectedRowData={selectRowDataFromTable}
          refreshTable={setRefreshTable}
          sideDrawerClose={toggleSideDrawer}
        />
      </Drawer>

      <Modal
        show={extendVacancyModalAndDetails}
        onHide={closeExtendVacancyModal}
        // size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <AddRemarkForAppreciation
            appreciationId={appreciationId}
            hideRemarkModal={closeExtendVacancyModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
