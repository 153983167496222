import { Link } from "react-router-dom";
import { API_URL, BASE_URL } from "../../../Constents";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAlert } from "react-alert";

import { AiFillDelete } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";

import moment from "moment";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import DeleteNotificationsAndPoll from "./DeleteNotificationsAndPoll";
import ShowPollsResultsAndOptions from "./ShowPollsResultsAndOptions";

export default function NotificationsTable() {
  const [notificationTableData, setNotificationTableData] = useState([]);
  const [showDelete, setShowDelete] = useState({
    modalState: false,
    id: null,
    type: null,
  });

  const [showResults, setShowResults] = useState({
    modalStateResults: false,
    ResultID: null,
  });

  const { SearchBar } = Search;
  const alert = useAlert();

  const columns = [
    {
      dataField: "question_text",
      text: "Name",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if (row.question_id) {
          return (
            <>
              <div className="m-0">{row.question_text}</div>
              <button
                className="m-0 btn btn-outline-info p-0"
                style={{ fontSize: 10 }}
                onClick={() =>
                  setShowResults({
                    ...showResults,
                    modalStateResults: true,
                    ResultID: row.question_id,
                  })
                }
              >
                Options & Result
              </button>
            </>
          );
        } else return row.question_text;
      },
    },
    { dataField: "type", text: "Type", sort: true },
    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      formatter: (row) =>
        row ? moment(row).format("MMMM Do, h:mm a") : "--:--",
    },
    {
      dataField: "poll_expiry",
      text: "Expired At",
      sort: true,
      formatter: (row) =>
        row ? moment(row).format("MMMM Do, h:mm a") : "--:--",
    },
    {
      dataField: "user",
      text: "Delete",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <button
            className="btn btn-link"
            onClick={() =>
              setShowDelete({
                ...showDelete,
                modalState: true,
                type: row.type,
                id: row.notification_id ? row.notification_id : row.question_id,
              })
            }
          >
            <AiFillDelete color="#dd3974" />
          </button>
        );
      },
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: "End",
    firstPageText: "First",
    nextPageText: "Next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  const fetchNotificationTableData = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/admin-push-notification/";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      setNotificationTableData(data.data);
      console.log(data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  useEffect(() => {
    fetchNotificationTableData();
  }, [showDelete.modalState]);

  console.log(showResults);

  return (
    <>
      <div className="maincontent">
        <div className="highlight">
          <div
            className="d-flex align-items-center mt-3 "
            style={{ justifyContent: "space-between" }}
          >
            <h1 style={{ color: "#DD3974" }}>Notification & Polls</h1>

            <div>
              <button className="btn btn-danger me-3">
                <Link
                  to={BASE_URL + "createnotification"}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Create Notification/Poll
                </Link>
              </button>
            </div>
          </div>
          <hr />
          <div className="row mt-2">
            <div className="col-md-6"></div>
            <ToolkitProvider
              keyField="id"
              bootstrap4
              data={notificationTableData}
              columns={columns}
              search
            >
              {(props) => (
                <React.Fragment>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-3 mb-2">
                        <div className="position-relative newsearch_box">
                          <SearchBar
                            className="newSearchWrapper"
                            placeholder="Search Here..."
                            srText=""
                            {...props.searchProps}
                          />
                          <BiSearch />
                        </div>
                      </div>
                    </div>

                    <BootstrapTable
                      pagination={pagination}
                      filter={filterFactory()}
                      // bordered={false}
                      {...props.baseProps}
                      condensed
                      bordered={false}
                      noDataIndication={() => {
                        return <h3>No data</h3>;
                      }}
                    />
                  </div>
                </React.Fragment>
              )}
            </ToolkitProvider>

            <Modal
              show={showDelete.modalState}
              onHide={() => setShowDelete({ ...showDelete, modalState: false })}
              centered
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <DeleteNotificationsAndPoll
                  close={() =>
                    setShowDelete({ ...showDelete, modalState: false })
                  }
                  deleteDataRowType={showDelete}
                />
              </Modal.Body>
            </Modal>

            <Modal
              show={showResults.modalStateResults}
              onHide={() =>
                setShowResults({ ...showResults, modalStateResults: false })
              }
              centered
              size="lg"
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <ShowPollsResultsAndOptions id={showResults.ResultID} />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}
