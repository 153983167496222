import React from "react";
import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";
import { useAlert } from "react-alert";
export default function NewGateLable(props) {
  const [LocationData, setLocationData] = useState([]);
  const [createLableData, setCreateLableData] = useState({
    location_id: null,
    lable_name: null,
  });
  const alert = useAlert();
  const fetchAllLocations = async () => {
    const url = API_URL + "/fetch-all-locations/";

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headerList,
      });
      const data = await res.json();
      setLocationData(data.data);
    } catch (err) {
      alert.error("Error in fetching the QRs");
      console.error(err);
    }
  };

  const createLableHandler = async (e) => {
    e.preventDefault();
    const url = API_URL + "/label-create-view/";
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const bodyContent = JSON.stringify({
      location: createLableData.location_id,
      name: createLableData.lable_name,
    });

    console.log(bodyContent);
    try {
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headerList,
      });
      const data = await res.json();
      if (data.status) {
        props.close();
        alert.success(data.message);
      } else {
        alert.error(data.message);
      }
    } catch (error) {
      console.error(error);
      alert.error("Internal Server Error");
    }
  };

  console.log(createLableData);

  useEffect(() => {
    fetchAllLocations();
  }, []);

  const locationList = LocationData.map((locData, index = 0) => {
    index++;
    return (
      <option
        key={index}
        value={locData.id}
        // selected={locData.id === details.alloted_location}
      >
        {locData.sub_name}
      </option>
    );
  });

  console.log(LocationData);

  return (
    <>
      <div className="border border-3 p-3">
        <form
          action=""
          // className="highlight all_form_spacing"
          method="POST"
          name="contact-form"
          onSubmit={createLableHandler}
        >
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="designition">Select Location (Lat, Long)</label>
              <select
                className="form-select form-control"
                id="inputGroupSelect02"
                onChange={(e) =>
                  setCreateLableData({
                    ...createLableData,
                    location_id: e.target.value,
                  })
                }
              >
                <option value="none" selected disabled hidden>
                  Select Location
                </option>
                {locationList}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="fname">Enter Gate/Label/Building</label>
              <input
                type="text"
                id="fname"
                placeholder="Eg. Forum Gate 2"
                name="lable"
                pattern="[a-zA-Z0-9 ]+"
                title="Please don't use special charecters"
                onChange={(e) => {
                  setCreateLableData({
                    ...createLableData,
                    lable_name: e.target.value,
                  });
                }}
                className="form-control"
                // value={details.lable}
                required
              />
            </div>

            {/* <div className="col-md-3 mt-4"></div> */}
            <div className="col-md-4 mt-4">
              <button type="submit" className="departmentBtnSecondary">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
