import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";
import { AiFillDelete } from "react-icons/ai";
import { BsPencilSquare } from "react-icons/bs";
import { Modal, Button } from "react-bootstrap";
import EditLeaveForm from "./EditLeaveForm";

const CreatedLeaveList = ({ reload }) => {
  const [responseData, setResponseData] = useState({});
  //Modal States
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [leaveDetails, setLeaveDetails] = useState({ id: null, name: null });
  const [leaveDetailsEdit, setLeaveDetailsEdit] = useState({
    id: null,
    name: null,
  });

  const deleteLeaveHandler = async () => {
    try {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
      };
      const url = API_URL + "/admin-quantity-view/?leave_id=" + leaveDetails.id;
      const res = await fetch(url, { method: "DELETE", headers: headersList });
      setShowDelete(false);
      const data = await res.json();

      setShowDelete(false);
      console.log(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const userData = JSON.parse(window.sessionStorage.getItem("user"));
        const headersList = {
          Authorization: "Token " + userData.seucrity_token,
        };
        const url = API_URL + "/fetch-leave-type-admin/";

        const res = await fetch(url, { method: "GET", headers: headersList });
        const data = await res.json();
        console.log(data);
        setResponseData(data.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [reload, showEdit, showDelete]);

  console.log(responseData);
  console.log(leaveDetails);

  return (
    <div className="container">
      <h3 style={{color: "red"}}>Added Leaves Type</h3>
      <div className="data_table mt-4">
        <div className="table_fil d-flex justify-content-between">
          <table>
            <tbody>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Employee Type</th>
                <th>No. Of leaves</th>
                <th>description </th>
                <th>Carry Forward Days</th>
                <th>Carry Forward Years</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
              {responseData && responseData.length > 0 ? (
                responseData.map((data, index = 0) => {
                  index++;
                  return (
                    <tr key={index}>
                      <td>{index}</td>
                      <td>{data.carry_forward_leave_type.name}</td>
                      <td>
                        {data.carry_forward_leave_type.validity_start_from ===
                        "date_of_confirmation"
                          ? "Employee"
                          : data.carry_forward_leave_type
                              .validity_start_from === "intern"
                          ? "Intern"
                          : data.carry_forward_leave_type
                              .validity_start_from === "notice_period"
                          ? "On Notice"
                          : data.carry_forward_leave_type
                              .validity_start_from === "date_of_joining"
                          ? "Probationer"
                          : "Others"}
                      </td>

                      <td>{data.carry_forward_leave_type.quantity}</td>
                      <td>{data.carry_forward_leave_type.description}</td>
                      <td>
                        {data.maximum_carry_forward === null
                          ? "Not Applicable"
                          : data.maximum_carry_forward + " Days"}{" "}
                      </td>
                      <td>
                        {data.carry_forward_duration === null
                          ? "Not Applicable"
                          : data.carry_forward_duration + " Years"}{" "}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => {
                            setShowEdit(true);
                            setLeaveDetailsEdit({
                              id: data.id,
                              name: data.carry_forward_leave_type.name,
                            });
                          }}
                        >
                          <BsPencilSquare />
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-link"
                          onClick={() => {
                            setShowDelete(true);
                            setLeaveDetails({
                              id: data.carry_forward_leave_type.id,
                              name: data.carry_forward_leave_type.name,
                            });
                          }}
                        >
                          <AiFillDelete  style={{color: "red"}}/>
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <h4>#No Leaves Added#</h4>
              )}
            </tbody>
          </table>
        </div>
        
      </div>
     
      <Modal
        show={showEdit}
        onHide={() => setShowEdit(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit <i>{leaveDetailsEdit.name} </i>
            <BsPencilSquare />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {/* {response !== "" && response.status === true ? (
              <h5 style={{ color: "red" }}>{response.message}</h5>
            ) : (
              ""
            )} */}

            <EditLeaveForm
              name={leaveDetailsEdit.name}
              id={leaveDetailsEdit.id}
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Are you sure you want to delete <i>{leaveDetails.name} </i> ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="secondary"
            size="lg"
            onClick={() => setShowDelete(false)}
            className="me-3"
          >
            Close
          </Button>
          <Button
            variant="danger"
            size="lg"
            onClick={() => {
              deleteLeaveHandler();
            }}
          >
            Delete <i>{leaveDetails.name} </i>
          </Button>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default CreatedLeaveList;
