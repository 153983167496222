import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";

const SetupDefaultEmailAddress = () => {
  const [details, setDetails] = useState({ email: "", API_key: "" });
  const [responseData, setResponseData] = useState({});

  const setUpDefaultEmailAddressHandler = async (event) => {
    event.preventDefault();
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const url = API_URL + "/mail-setup/";
    try {
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        default_email: details.email,
        api_key: details.API_key,
      });

      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });

      const data = await res.json();
      setResponseData(data);
    } catch (err) {
      console.error(err);
      setResponseData({
        status: false,
        message: "There was an error in Adding email id",
      });
    }
  };

  useEffect(() => {
    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const url = API_URL + "/mail-setup/";

      try {
        const headersList = {
          Authorization: "Token " + userData.seucrity_token,
        };
        const res = await fetch(url, { method: "GET", headers: headersList });
        const data = await res.json();
        console.log(data);
        setDetails({
          email: data.data.default_email,
          API_key: data.data.api_key,
        });
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  console.log(responseData);

  return (
    <>
      <div className="container">
        <form
          onSubmit={(event) => {
            setUpDefaultEmailAddressHandler(event);
          }}
        >
          <div className="row align-items-end">
            <div className="col-md-12 col-lg-12 mt-4">
              <h3 style={{color: "red"}}>Setup Default Mail Address for the Organization</h3>
            </div>

            <div className="col-md-3 col-lg- mt-4">
              <label>
                Default Email<span className="msg_fail">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter the default organization Email"
                value={details.email}
                onChange={(e) =>
                  setDetails({ ...details, email: e.target.value })
                }
                required
              />
            </div>

            <div className="col-md-3 col-lg- mt-4">
              <label>
                API Keys<span className="msg_fail">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter API key provied by the company"
                value={details.API_key}
                onChange={(e) =>
                  setDetails({ ...details, API_key: e.target.value })
                }
                required
              />
            </div>
            <div className="col-md-3 col-lg- mt-4">
              <button className="btn btn-warning" type="submit">
                Setup Mail
              </button>
            </div>
            <div className="col-md-3 mt-4"></div>
          </div>
        </form>
        {responseData &&
        responseData !== undefined &&
        responseData !== null &&
        responseData.status === true ? (
          <p className="msg_success mt-3">{responseData.message}</p>
        ) : responseData &&
          responseData !== undefined &&
          responseData !== null &&
          responseData.status === false ? (
          <p className="msg_fail mt-3">*{responseData.message}</p>
        ) : (
          ""
        )}
        <hr className="mt-4" />
      </div>
    </>
  );
};

export default SetupDefaultEmailAddress;
