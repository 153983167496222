import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";
import { useHistory } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import ModalEmpoyeeDetails from "../Modals/ModalEmployeeDetails";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import { IoIosArrowBack } from "react-icons/io";
import { BsBoxArrowUpRight } from "react-icons/bs";
const NotYetInList = () => {
  const [DashData, setDashData] = useState([]);

  //const [err, setErr] = useState();

  //Modal State
  const [modalID, setModalId] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      const url = API_URL + "/fetch-dashboard-data/";
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
      };
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: headersList,
        });
        // if (!res.ok) setStatusErr(res.status);
        const data = await res.json();
        console.log(data);
        setDashData(data.data[3].data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  const { SearchBar } = Search;

  const columns = [
    {
      dataField: "id",
      text: "ID",
    },

    {
      dataField: "first_name",
      text: "Name",
      sort: true,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log(cell, rowIndex, formatExtraData);

        return (
          <Button
            variant="link"
            style={{ color: "#3E3E3E", textDecoration: "none" }}
            onClick={() => {
              console.log(row.id);
              handleShow();
              setModalId(row.id);
            }}
          >
            {row.first_name}
            <BsBoxArrowUpRight
              style={{
                fontSize: "10px",
                marginLeft: "3px",
                marginBottom: "10px",
                color: "#3E3E3E",
                fontWeight: "600",
              }}
            />
          </Button>
        );
      },
    },
    {
      dataField: "designation",
      text: "Role",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log(cell, rowIndex, formatExtraData);
        if (row.designation === null || row.designation === "")
          return "Not Assigned";
        else return row.designation;
      },
    },
    {
      dataField: "mobile_number",
      text: "Number",
    },
    {
      dataField: "official_email",
      text: "email",
    },
  ];
  const history = useHistory();

  console.log(DashData);

  //const table_data = DashData[3].data;
  //   console.log(table_data);

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: "Last",
    firstPageText: "First",
    nextPageText: "next",
    prePageText: "Previous",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  return (
    <div className="container">
      <div className="highlight all_form_spacing">
        <div
          className="d-flex"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <h2 style={{ color: "#000000" }}>Employees Not Checked In</h2>
          <Button
            onClick={() => history.goBack()}
            className="departmentBtnSecondary"
          >
            {" "}
            <IoIosArrowBack size={20} /> Back
          </Button>
        </div>

        <ToolkitProvider
          keyField="id"
          data={DashData}
          columns={columns}
          bootstrap4
          search
        >
          {(props) => (
            <div>
              <SearchBar
                placeholder="Search Here..."
                className="form-control"
                srText=""
                style={{ width: "30%" }}
                {...props.searchProps}
              />
              <hr />
              <BootstrapTable
                pagination={pagination}
                {...props.baseProps}
                condensed
                bordered={false}
              />
            </div>
          )}
        </ToolkitProvider>
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <ModalEmpoyeeDetails id={modalID} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default NotYetInList;
