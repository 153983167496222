import React, { useState, useEffect } from "react";
import { API_URL, TODAY_DATE } from "../../../../Constents";

import { format } from "date-fns";
import { DateRange } from "react-date-range";
import moment from "moment";
import { useAlert } from "react-alert";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
export default function CreateVacancy(props) {
  const [createVacancyData, setCreateVacancyData] = useState({
    title: "",
    min_required_qualification: "",
    department: null,
    num_of_vacancy: 1,
    salary_range_min: null,
    salary_range_max: null,
    min_required_experience: 0,
    attachment_file: "",
    file_path: "",
  });
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [openDate, setOpenDate] = useState(false);
  const [DepartmentList, setDepartmentList] = useState([]);

  const alert = useAlert();

  const fetchDepartments = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/department-list";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setDepartmentList(data.data);
    } catch (err) {
      console.error(err);
      alert.error("Error in Fetching Team List");
    }
  };
  console.log(createVacancyData);
  console.log(date[0].endDate);
  const uploadFilePathHandler = (data) => {
    setCreateVacancyData((prevState) => ({
      ...prevState,
      file_path: data.target.files[0],
      attachment_file: data.target.value,
    }));
  };
  const createNewVacancyHandler = async (e) => {
    e.preventDefault();
    console.log(date);
    console.log(moment(date[0].startDate).format("YYYY-MM-DD"));
    const URL = API_URL + "/admin-vacancy-crud/";
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const bodyContent = new FormData();
    bodyContent.append("title", createVacancyData.title);
    bodyContent.append(
      "vacancy_start_date",
      moment(date[0].startDate).format("YYYY-MM-DD")
    );
    bodyContent.append(
      "vacancy_end_date",
      moment(date[0].endDate).format("YYYY-MM-DD")
    );
    bodyContent.append("department", createVacancyData.department);
    bodyContent.append("num_of_vacancy", createVacancyData.num_of_vacancy);
    bodyContent.append("salary_range_min", createVacancyData.salary_range_min);
    bodyContent.append("salary_range_max", createVacancyData.salary_range_max);
    bodyContent.append(
      "min_required_qualification",
      createVacancyData.min_required_qualification
    );
    bodyContent.append(
      "min_required_experience",
      createVacancyData.min_required_experience
    );
    bodyContent.append(
      "attachment",
      createVacancyData.file_path,
      createVacancyData.attachment_file
    );
    try {
      const response = await fetch(URL, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      const data = await response.json();
      if (data.status) {
        props.closeVacancyModal();
        alert.success(data.message);
      }
      console.log(data);
      if (data.status === false) alert.error(data.message);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  useEffect(() => {
    fetchDepartments();
  }, []);
  return (
    <>
      <div className="setting_header ps-3">
        <form onSubmit={createNewVacancyHandler}>
          <div className="col-md-12 mb-3">
            <h5
              style={{ color: "#DD3974" }}
              onClick={() => {
                setOpenDate(false);
              }}
            >
              Create Vacancy
            </h5>
            <hr />
            <div className="row mb-3">
              <div
                className="col-md-6"
                onClick={() => {
                  setOpenDate(false);
                }}
              >
                <label for="" className="form-label">
                  Enter Job Title
                </label>
                <input
                  type="check"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  placeholder="Enter Job Title"
                  required
                  title="Enter Only Text And Number"
                  // pattern="^[A-Za-z ]+$"
                  onChange={(e) => {
                    setCreateVacancyData((prevState) => ({
                      ...prevState,
                      title: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="col-md-6">
                <label for="" className="form-label">
                  Start Date and Expire Date
                </label>

                <span
                  className="form-control"
                  onClick={() => setOpenDate(!openDate)}
                >{`${format(date[0].startDate, "dd/MM/yyyy")} to ${format(
                  date[0].endDate,
                  "dd/MM/yyyy"
                )} `}</span>
                {openDate && (
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => setDate([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                    className="date"
                    required
                    minDate={new Date(TODAY_DATE)}
                  />
                )}
              </div>
            </div>
            <div
              className="row mb-3"
              onClick={() => {
                setOpenDate(false);
              }}
            >
              <div className="col-md-6">
                <label for="" className="form-label">
                  Upload Job Description
                </label>
                <input
                  type="file"
                  className="form-control"
                  required
                  onChange={uploadFilePathHandler}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="designition">Select Team</label>
                <select
                  className="form-select form-control"
                  id="inputGroupSelect02"
                  onChange={(e) => {
                    setCreateVacancyData((prevState) => ({
                      ...prevState,
                      department: e.target.value,
                    }));
                  }}
                >
                  <option hidden disabled selected>
                    {" "}
                    -- Select Team --{" "}
                  </option>
                  {DepartmentList &&
                  DepartmentList.length > 0 &&
                  DepartmentList !== undefined
                    ? DepartmentList.map((Department) => (
                        <option key={Department.id} value={Department.id}>
                          {Department.department_name} (
                          {Department.department_id})
                        </option>
                      ))
                    : ""}
                </select>
              </div>
            </div>
            <div
              className="row mb-3"
              onClick={() => {
                setOpenDate(false);
              }}
            >
              <div className="col-md-6">
                <label htmlFor="">Enter Vacancies</label>
                <input
                  type="number"
                  className="form-control form-select"
                  id=""
                  min="1"
                  max="100"
                  step="1"
                  aria-describedby=""
                  placeholder="Enter No of  Vacancies"
                  required
                  onChange={(e) => {
                    setCreateVacancyData((prevState) => ({
                      ...prevState,
                      num_of_vacancy: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="">Minimum Qualifications</label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  placeholder="Enter Min. Qualifications"
                  required
                  onChange={(e) => {
                    setCreateVacancyData((prevState) => ({
                      ...prevState,
                      min_required_qualification: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div
              className="row mb-3"
              onClick={() => {
                setOpenDate(false);
              }}
            >
              <div className="col-md-6">
                <label htmlFor="">Minimum Experience</label>
                <input
                  type="number"
                  className="form-control form-select"
                  id=""
                  min="1"
                  max="100"
                  step="1"
                  aria-describedby=""
                  placeholder="Enter Min. Experience"
                  required
                  onChange={(e) => {
                    setCreateVacancyData((prevState) => ({
                      ...prevState,
                      min_required_experience: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="">Salary (C.T.C in LPA)</label>
                <div className="d-flex">
                  <input
                    type="number"
                    placeholder="Min (LPA)"
                    className="form-control "
                    onChange={(e) => {
                      setCreateVacancyData((prevState) => ({
                        ...prevState,
                        salary_range_min: e.target.value,
                      }));
                    }}
                  />
                  <input
                    type="number"
                    placeholder="Max (LPA)"
                    className="form-control ms-2"
                    onChange={(e) => {
                      setCreateVacancyData((prevState) => ({
                        ...prevState,
                        salary_range_max: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div onClick={() => setOpenDate(false)}>
            <button className="btn btn-danger me-3" type="submit">
              Save & Exit
            </button>
          </div>
        </form>
        <button
          className="btn btn-light cancelBtnPosition"
          onClick={props.closeVacancyModal}
        >
          Cancel
        </button>
      </div>
    </>
  );
}
