import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
// import qrImage from "../../images/qrimage.png";
// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 10,
    paddingBottom: 15,
    paddingHorizontal: 15,
  },
  section: {
    textAlign: "center",
    marginBottom: 10,
  },
  topBorderParent: {
    borderWidth: 1,
    borderStyle: "dashed",
    marginHorizontal: 5,
    marginVertical: 5,
  },
  imageWrapper: {
    margin: "auto",
    // height: 400,
    width: "100%",
    textAlign: "center",
  },
  imageLogo: {
    // height: "60%",
    width: "60%",
    margin: "0 auto",
  },
});

// Create Document Component
const DownloadQRPDFfile = (props) => {
  console.log(props);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.topBorderParent}>
          <View style={styles.section}>
            <Text style={{ fontSize: "25px" }}>{props.QrName}</Text>
            <Text style={{ fontSize: "20px" }}>{props.QrLocationName}</Text>
          </View>
          <View style={styles.imageWrapper}>
            <Image
              style={styles.imageLogo}
              src={{
                uri: props.QrImage,
                headers: { "Cache-Control": "no-cache" },
                body: "",
              }}
              // src={qrImage}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DownloadQRPDFfile;
