import { useEffect, useState } from "react";
import { useAlert } from "react-alert";

import { API_URL } from "../../Constents";

const ChangeEmploymentStatus = (props) => {
  const [EmploymentStatus, setEmploymentStatus] = useState("");
  const [employeeTypeList, setEmployeeTypeList] = useState([]);
  // const [err, setErr] = useState({});
  // const [response, setResponse] = useState({});

  const id = window.location.href.split("/").reverse()[0];
  const alert = useAlert();

  const updateEmployeementStatusHandler = async (e) => {
    e.preventDefault();
    const url =
      API_URL +
      "/update-employe-status/?user_id=" +
      id +
      "&employee_status=" +
      EmploymentStatus;
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    try {
      const res = await fetch(url, {
        headers: headerList,
        method: "PATCH",
      });
      const data = await res.json();
      if (data.status) {
        alert.success(data.message);
      } else {
        alert.error(data.message);
      }
    } catch (error) {
      alert.error("There was an error in updating the employee staus");
      // setErr({
      //   status: false,
      //   message: "There was an error in updating the employee staus",
      // });
    }
  };

  const fetchData = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/fetch-leave-type-admin/";

    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      // setLeaveData(data);
      setEmployeeTypeList(data.data.employee_type_available);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function capitalizeFirstLetter(string) {
    if (string.length > 0)
      return string.charAt(0).toUpperCase() + string.slice(1);
    else return "NA";
  }

  return (
    <>
      <div className="highlight all_form_spacing">
        <form
          onSubmit={(event) => {
            updateEmployeementStatusHandler(event);
          }}
        >
          <h3 className="mb-3" style={{ color: "red" }}>
            Update Employment Status of {props.name}
          </h3>

          <div className="row">
            <div className="col-md-4 mb-3">
              <label htmlFor="designition">Select Employment Type</label>
              <select
                className="form-select"
                onChange={(e) => setEmploymentStatus(e.target.value)}
              >
                <option value="" selected disabled hidden>
                  Select Employee Type
                </option>
                {employeeTypeList.map((i, index) => {
                  return (
                    <option
                      key={index}
                      value={i.value}
                      selected={props.status === i.value}
                    >
                      {i.label ? capitalizeFirstLetter(i.label) : ""}
                    </option>
                  );
                })}
              </select>
              {/* <select
                className="form-select form-control"
                id="inputGroupSelect02"
                onChange={(e) => setEmploymentStatus(e.target.value)}
                required
              >
                <option value="" selected disabled hidden></option>
                <option
                  value="probation"
                  selected={props.status === "probation"}
                >
                  Probation
                </option>
                <option
                  value="notice_period"
                  selected={props.status === "notice_period"}
                >
                  Notice Period
                </option>
                <option
                  value="confirmed"
                  selected={props.status === "confirmed"}
                >
                  Employee
                </option>
              </select> */}
            </div>
            <div className="col-md-4 mt-4">
              <button type="submit" className="departmentBtnSecondary">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangeEmploymentStatus;
