import React, { useEffect, useState } from "react";
import { API_URL } from "../../Constents";
import { IoIosArrowBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

export default function CreateEvents() {
  const [details, setDetails] = useState({
    event_name: "",
    event_on: "",
    end_on: "",
    event_time: "",
    event_time_to: "",
    description: "",
    department: "",
  });

  const [departmentList, setDepartmentList] = useState([]);
  const history = useHistory();
  const alert = useAlert();

  const CreateEventHandler = async (e) => {
    e.preventDefault();
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify({
      event_name: details.event_name,
      event_on: details.event_on,
      end_on: details.end_on,
      event_time: details.event_time,
      event_time_to: details.event_time_to,
      description: details.description,
      department: details.department,
    });
    console.log(bodyContent);

    const url = API_URL + "/add-event/";

    try {
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      const data = await res.json();
      console.log(data);
      //setResponse(data);
      if (data.status === true) alert.success(data.message);
      if (data.status === false) alert.error(data.message);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  const fetchDepartments = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/department-list";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setDepartmentList(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, []);
  console.log(details);

  return (
    <>
      <div className="maincontent">
        <div className="highlight all_form_spacing">
          <div
            className="d-flex  mt-3"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h1 className="mb-3 ms-4" style={{ color: "red" }}>
              Create A New Event
            </h1>
            <button
              onClick={() => history.goBack()}
              className="departmentBtnSecondary"
            >
              {" "}
              <IoIosArrowBack size={20} /> Back
            </button>
          </div>

          <form onSubmit={CreateEventHandler}>
            <div className="row ms-3 me-3">
              <div>
                <label
                  htmlFor=""
                  style={{ fontWeight: "600" }}
                  className="mx-1"
                >
                  Enter name <span className="msg_fail">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter The Event Name"
                  className="form-control"
                  // style={{}}
                  onChange={(e) =>
                    setDetails({ ...details, event_name: e.target.value })
                  }
                  required
                />
                <label
                  htmlFor=""
                  className="mt-3"
                  style={{ fontWeight: "600" }}
                >
                  Enter Description
                </label>
                <textarea
                  type="text"
                  className="form-control mt-2"
                  style={{ height: "100px" }}
                  placeholder="Enter Event Description"
                  onChange={(e) =>
                    setDetails({ ...details, description: e.target.value })
                  }
                />
              </div>
              {/* close column */}

              <div className="col-md-6">
                <label
                  htmlFor=""
                  className="mt-3"
                  style={{ fontWeight: "600" }}
                >
                  Event Date(from) <span className="msg_fail">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) =>
                    setDetails((prevState) => ({
                      ...prevState,
                      event_on: e.target.value,
                    }))
                  }
                  required
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="" style={{ fontWeight: "600" }}>
                  Event Date(to) <span className="msg_fail">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) =>
                    setDetails({ ...details, end_on: e.target.value })
                  }
                  required
                />
              </div>
              <div className="col-md-6  mt-3">
                <label htmlFor="" style={{ fontWeight: "600" }}>
                  Event Time(from)
                </label>
                <input
                  type="time"
                  className="form-control "
                  style={{
                    width: "230px",
                    border: "none",
                    marginLeft: "-10px",
                  }}
                  onChange={(e) =>
                    setDetails({ ...details, event_time: e.target.value })
                  }
                />
              </div>
              <div className="col-md-6 mt-3">
                <label htmlFor="" style={{ fontWeight: "600" }}>
                  Event Time (to)
                </label>
                <input
                  type="time"
                  className="form-control"
                  style={{
                    width: "230px",
                    border: "none",
                    marginLeft: "-10px",
                  }}
                  onChange={(e) =>
                    setDetails({ ...details, event_time_to: e.target.value })
                  }
                />
              </div>
              <div className="col-md-6  mb-3 mt-3">
                <label htmlFor="" style={{ fontWeight: "600" }}>
                  {" "}
                  Select Team
                </label>
                <select
                  className="form-control form-select"
                  onChange={(e) =>
                    setDetails({ ...details, department: e.target.value })
                  }
                >
                  <option value="">All</option>
                  {departmentList.map((departmentData) => (
                    <option value={departmentData.id} key={departmentData.id}>
                      {departmentData.department_name}
                    </option>
                  ))}
                </select>
              </div>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#BDC3CB ",
                  marginLeft: "10px",
                }}
              />
              <div
                style={{ display: "flex", justifyContent: "right" }}
                className="mt-3"
              >
                <button className="departmentBtnSecondary" type="submit">
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
