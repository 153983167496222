import React, { useState } from "react";
import { API_URL } from "../../Constents";
import Logo from "../../images/ChangePassword.png";
import { BsEye, BsEyeSlash } from "react-icons/bs";
const ChangePassword = () => {
  const [responseData, setResponseData] = useState({});
  const [formInput, setFormInput] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [formError, setFormError] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
    API: "",
  });
  // const [handleToggle,setHandleToggle]=useState({
  //   old:false,
  //   new:false,
  //   confirm:false
  // })
  const [eyeToggle, setEyeToggle] = useState(false);
  const [newToggle, setNewToggle] = useState(false);
  const [confirmToggle, setConfirmToggle] = useState(false);
  const handleUserInput = (name, value) => {
    setFormInput({
      ...formInput,
      [name]: value,
    });
  };

  const validateFormInput = (event) => {
    event.preventDefault();
    const inputError = {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    };
    if (!formInput.oldPassword) {
      setFormError({
        ...inputError,
        password: "Old Password cannot be empty",
      });
      return;
    }
    if (!formInput.password) {
      setFormError({
        ...inputError,

        password: "Password should not be empty",
      });
      return;
    }

    if (formInput.confirmPassword !== formInput.password) {
      setFormError({
        ...inputError,
        confirmPassword: "New Password and Confirm Password should be same",
      });

      return;
    }
    if (formInput.confirmPassword === formInput.oldPassword) {
      setFormError({
        ...inputError,
        confirmPassword: "New password and old password should not be same",
      });

      return;
    }

    setFormError(inputError);
    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));

      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        old_password: formInput.oldPassword,
        new_password: formInput.password,
      });

      const url = API_URL + "/change-password/";

      try {
        const res = await fetch(url, {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        });
        const data = await res.json();
        console.log(data);
        setResponseData(data);
      } catch (err) {
        console.error(err);
        setFormError({
          ...inputError,
          API: "There was an Error in updating the password",
        });
      }
    })();
  };

  console.log(formInput);

  return (
    <div className="maincontent">
      <div className="highlight all_form_spacing">
        {responseData && responseData.status ? (
          <div className="alert alert-success" role="alert">
            {responseData.message}
          </div>
        ) : responseData.status === false ? (
          <div className="alert alert-danger" role="alert">
            {responseData.message}
          </div>
        ) : (
          ""
        )}
        <div className="container  p-5 changeMainWrapper">
          <div className="row">
            <div className="col-md-6">
              <img src={Logo} alt="PasswordImg" className="img-fluid" />
            </div>
            <div className="col-md-6">
              <h3 style={{ textAlign: "center" }}>Change Password</h3>
              <form
                onSubmit={validateFormInput}
                className="bg-white p-4 shadow mt-2"
              >
                <div className="mb-3 form-group">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label"
                    style={{ textAlign: "left" }}
                  >
                    Enter old Password
                  </label>
                  <div className="position-relative eye_icon">
                    <input
                      name="oldPassword"
                      // type={handleToggle.old=== "true" ? "password" :"text"}
                      type={eyeToggle === false ? "password" : "text"}
                      value={formInput.oldPassword}
                      onChange={({ target }) => {
                        handleUserInput(target.name, target.value);
                      }}
                      className="form-control"
                      required
                    />
                    {eyeToggle === false ? (
                      <BsEyeSlash onClick={() => setEyeToggle(!eyeToggle)} />
                    ) : (
                      <BsEye onClick={() => setEyeToggle(!eyeToggle)} />
                    )}
                  </div>
                  <p className="error-message">{formError.oldPassword}</p>
                </div>
                <div className="mb-3 form-group">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label"
                    style={{ textAlign: "left" }}
                  >
                    New Password
                  </label>
                  <div className="position-relative eye_icon">
                    <input
                      name="password"
                      type={newToggle === false ? "password" : "text"}
                      value={formInput.password}
                      onChange={({ target }) => {
                        handleUserInput(target.name, target.value);
                      }}
                      className="form-control"
                      required

                      // onBlur={validateInput}
                    />
                    {newToggle === false ? (
                      <BsEyeSlash onClick={() => setNewToggle(!newToggle)} />
                    ) : (
                      <BsEye onClick={() => setNewToggle(!newToggle)} />
                    )}
                  </div>
                  <p className="error-message">{formError.password}</p>
                </div>

                <div className="mb-3 form-group">
                  <label htmlFor="exampleInputPassword1" class="form-label">
                    Confirm Password
                  </label>
                  <div className="position-relative eye_icon">
                    <input
                      value={formInput.confirmPassword}
                      onChange={({ target }) => {
                        handleUserInput(target.name, target.value);
                      }}
                      name="confirmPassword"
                      type={confirmToggle === false ? "password" : "text"}
                      required
                      className="form-control"
                      id="exampleInputPassword1"
                    />
                    {confirmToggle === false ? (
                      <BsEyeSlash
                        onClick={() => setConfirmToggle(!confirmToggle)}
                      />
                    ) : (
                      <BsEye onClick={() => setConfirmToggle(!confirmToggle)} />
                    )}
                  </div>
                  <p className="error-message">{formError.confirmPassword}</p>
                </div>
                {formError.API === "" ? (
                  ""
                ) : (
                  <h1 className="error-message">{formError.API}</h1>
                )}

                <button
                  type="submit"
                  className="btn-success passowrdButton"
                  value="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
