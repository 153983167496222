import { useState } from "react";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";

import { API_URL } from "../../Constents";
import RHHolidayList from "./HolidayLists/RHHolidayList";

const UploadRHForm = () => {
  const [details, setDetails] = useState({
    file: null,
    path: null,
  });
  const [show, setShow] = useState(false);

  const alert = useAlert();

  const [loadingMsg, setLoadingMsg] = useState("");
  const uploadFileHandler = async (e) => {
    e.preventDefault();
    if (details.file === null)
      return alert.info("Please select the file first");
    setLoadingMsg("Your file is uploading Please wait!!!!!!");
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const bodyContent = new FormData();

    bodyContent.append("file", details.file, details.path); /// impoertant

    const url = API_URL + "/read-restricted-holiday/";

    try {
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      const data = await res.json();
      console.log(data);
      setLoadingMsg("File Upload Successfull !!!, " + data.message);
    } catch (err) {
      console.error(err);
      setLoadingMsg("There was an error in uploading the FIle!!!!");
    }
  };
  console.log(details);

  return (
    <div className="setting_header ps-3 py-4">
      <form
        onSubmit={(event) => {
          uploadFileHandler(event);
        }}
      >
        <div className="row align-items-end">
          <div className="col-md-3 col-lg- mt-2 ">
            <h3 style={{ color: "red" }}>Upload RH file</h3>
          </div>
          <div className="col-md-3 col-lg- mt-2 "></div>
          <div className="col-md-3 col-lg- mt-2 ">
            <input
              type="file"
              className="form-control"
              onChange={(data) =>
                setDetails({
                  ...details,
                  path: data.target.value,
                  file: data.target.files[0],
                })
              }
            />
          </div>
          <div className="col-md-3 col-lg- mt-2 ">
            <button className="btn btn-warning" type="submit">
              Upload RH Excel
            </button>
          </div>
          {loadingMsg === "" ? (
            ""
          ) : (
            <p style={{ color: "red" }}>{loadingMsg}</p>
          )}
        </div>
      </form>
      <button
        className="btn btn-outline-warning px-2 py-0"
        onClick={() => setShow(true)}
      >
        View List
      </button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Restricted Holiday List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RHHolidayList />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UploadRHForm;
