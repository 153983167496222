import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Tabs, Tab } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";

import {
  API_URL,
  TODAY_DATE,
  RETIRING_AGE,
  FORMATTED_MINOR_DATE,
} from "../Constents";

import UserAddDoc from "./User/UserAddDoc";
import AddEmployeeOtherDetails from "./User/AddEmployeeOtherDetails";
//import CreateLeaveCategory from "./Leaves/CreateLeaveCategory";
import EmployeeCheckInSetting from "./User/EmployeeCheckInSetting";
import ChangeEmploymentStatus from "./User/ChangeEmploymentStatus";
// import UpdateLeavesOfAUser from "./Leaves/UpdateLeavesOfAUser";
import UserLeaveInformation from "./User/UserLeaveInformation";
//import AssignTeamMembers from "./Teams/AssignTeamMembers";

const UpdateUser = (props) => {
  const [managerList, setManagerList] = useState([]);
  const [roleList, setRollList] = useState([]);
  const [details, setDetails] = useState({
    first_name: "",
    personal_email: "",
    official_email: "",
    mobile_number: "",
    middle_name: null,
    last_name: "",
    designation: "",
    reporting_to: "",
    date_of_birth: null,
    aadhar_number: null,
    pan_number: "",
    is_manager: false,
    date_of_joining: null,
    leave_category: null,
    employment_status: null,
    department_assigned: null,
    is_staff: null,
    alloted_qr: null,
    alloted_location: null,
    managers: [],
    next_manager: {},
    role_assigned: null,
  });
  const [DepartmentList, setDepartmentList] = useState([]);
  const [AllLables, setAllLables] = useState([]);
  const [LocationData, setLocationData] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [selectedLocationAddress, setSelectedLocationAddress] = useState([]);
  const [QRMultiselect, setQRMultiselect] = useState([]);
  const [checkInTime, setCheckInTime] = useState({});
  const id = window.location.href.split("/").reverse()[0];

  const alert = useAlert();

  let formattedManagersList = [];
  if (details && details.managers && details.additional_manager) {
    formattedManagersList = details.managers.concat(details.additional_manager);
  }

  const formattedLocationList = [];
  if (LocationData) {
    LocationData.map((list) => {
      return formattedLocationList.push({
        label: list.sub_name,
        value: list.id,
      });
    });
  }

  const formattedLableList = [];

  if (AllLables) {
    AllLables.map((list) => {
      return formattedLableList.push({
        label: list.name + "(" + list.location_name + ")",
        value: list.id,
      });
    });
  }

  const fetchDetails = (id) => {
    var myHeaders = new Headers();
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    myHeaders.append("Authorization", "Token " + userData.seucrity_token);
    myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify({});

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: raw,
      redirect: "follow",
    };

    fetch(API_URL + "/fetch-update-delete/?user_id=" + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const { data } = result;

        setDetails({
          first_name: data.first_name,
          personal_email: data.personal_email,
          official_email: data.official_email,
          mobile_number: data.mobile_number,
          middle_name: data.middle_name,
          last_name: data.last_name,
          designation: data.designation,
          reporting_to: data.reporting_to,
          date_of_birth: data.date_of_birth,
          aadhar_number: data.aadhar_number,
          pan_number: data.pan_number,
          is_manager: data.is_manager,
          date_of_joining: data.date_of_joining,
          leave_category: data.leave_category,
          employment_status: data.employment_status,
          alloted_location: data.alloted_location,
          department_assigned: data.department_assigned,
          is_staff: data.is_staff,
          alloted_qr: data.alloted_qr,
          managers: data.managers,
          next_manager: data.next_manager,
          additional_manager: data.additional_manager,
          role_assigned: data.role_assigned,
        });

        setSelectedLocationAddress(data.alloted_location_with_names);
        setQRMultiselect(data.alloted_qr_with_names);
        setSelectedManagers(data.additional_manager);
      })
      .catch((error) => console.log("error", error));
  };

  const postDetails = (event) => {
    event.preventDefault();

    const myHeaders = new Headers();
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    myHeaders.append("Authorization", "Token " + userData.seucrity_token);
    myHeaders.append("Content-Type", "application/json");

    const PatchQRIDs = [];
    QRMultiselect.map((list) => PatchQRIDs.push(list.value));

    const PatchManagersIDs = [];
    selectedManagers.map((list) => PatchManagersIDs.push(list.value));

    const isSameManagerError = PatchManagersIDs.includes(+details.reporting_to);

    if (isSameManagerError) {
      return alert.error("Manager and Additional Manager cannot be same");
    }

    const raw = JSON.stringify({
      first_name: details.first_name,
      personal_email: details.personal_email,
      official_email: details.official_email,
      mobile_number: details.mobile_number,
      middle_name: details.middle_name,
      last_name: details.last_name,
      role_assigned: details.designation,
      reporting_to: details.reporting_to,
      date_of_birth: details.date_of_birth,
      aadhar_number:
        details.aadhar_number === "" ? null : details.aadhar_number,
      pan_number: details.pan_number,
      is_manager: details.is_manager,
      date_of_joining: details.date_of_joining,
      department_assigned: details.department_assigned,
      is_staff: details.is_staff,
      alloted_qr: PatchQRIDs,
      additional_manager: PatchManagersIDs,
      role_assigned: details.role_assigned,
    });

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(API_URL + "/fetch-update-delete/?user_id=" + id, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === true) alert.success(result.message);
        else alert.error(result.message);

        //setShow(true);
      })
      .catch((error) => alert.error("Internal Server Error"));
  };

  // Fetch Roll  start
  const fetchUserRoll = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/role?all_role=True";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setRollList(data.data);
    } catch (err) {
      console.error(err);
    }
  };
  // fetch role end

  // fetch Managers starts
  const fetchManagers = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/role";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();

      setManagerList(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const assignManagerHandler = (e) => {
    setDetails({ ...details, reporting_to: e.target.value });
  };

  const assignRoleHandler = (e) => {
    setDetails({ ...details, role_assigned: e.target.value });
  };

  // fetch dept starts
  const fetchDepartments = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    const url = API_URL + "/department-list";
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      const data = await res.json();
      setDepartmentList(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  //Fetch All QRS

  const fetchAllLableLocations = async (locationsArr) => {
    const locIDs = [];
    locationsArr.map((i) => locIDs.push(i.value));

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const url = API_URL + "/get-qr-with-label/";

    let bodyContent = JSON.stringify({});

    if (locIDs.length >= 1) {
      bodyContent = JSON.stringify({
        location_id: locIDs,
      });
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      const data = await response.json();
      setAllLables(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  //Fetch All locations (Has all lat lng)

  const fetchAllLocations = async () => {
    const url = API_URL + "/fetch-all-locations/";

    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headerList = {
      Authorization: "Token " + userData.seucrity_token,
    };
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: headerList,
      });
      const data = await res.json();
      setLocationData(data.data);
    } catch (err) {
      alert.error("Error in fetching the QRs");
      console.error(err);
    }
  };
  // APi call for checkinTime of Employee
  const checkInAndCheckOutTimeHandler = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const url = API_URL + "/active-setting/?id=" + id;
    const response = await fetch(url, {
      method: "GET",
      headers: headersList,
    });

    try {
      const data = await response.json();
      console.log(data.data);
      setCheckInTime(data.data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };

  useEffect(() => {
    checkInAndCheckOutTimeHandler();
    fetchManagers();
    fetchUserRoll();
    fetchDepartments();
    fetchAllLocations();
  }, []);

  useEffect(() => {
    fetchAllLableLocations(selectedLocationAddress);
  }, [selectedLocationAddress]);

  useEffect(() => {
    fetchDetails(id);
  }, [id]);

  console.log(checkInTime);

  return (
    <div>
      <div className="maincontent">
        <div className="vacancyTabs">
          <Tabs
            defaultActiveKey="home"
            transition={false}
            //id="noanim-tab-example"
            id="fill-tab-example"
            className="mb-3 nav nav-pills"
            fill
          >
            <Tab eventKey="home" title="Basic Details">
              <form
                onSubmit={postDetails}
                className="highlight all_form_spacing"
                method="POST"
                name="contact-form"
              >
                <h3 style={{ color: "#dd3974" }}>
                  Update Employee Details of {details.first_name}
                </h3>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label htmlFor="fname">
                      First Name <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="text"
                      id="fname"
                      placeholder="Enter Your First Name (Mandatory)"
                      name="fname"
                      onChange={(e) => {
                        setDetails({ ...details, first_name: e.target.value });
                      }}
                      pattern="^[A-Za-z]+$" /// edited regex
                      title="Enter only letters without space"
                      className="form-control"
                      value={details.first_name}
                      required
                    />
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="mname">Middle Name</label>
                    <input
                      type="text"
                      id="mname"
                      placeholder="Enter Your Middle Name"
                      onChange={(e) => {
                        setDetails({ ...details, middle_name: e.target.value });
                      }}
                      value={details.middle_name}
                      name="mname"
                      className="form-control"
                      pattern="^[A-Za-z]+$" /// edited regex
                      title="Enter only letters without space"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="lname">Last Name</label>
                    <input
                      type="text"
                      id="lname"
                      placeholder="Enter Your Last Name"
                      onChange={(e) => {
                        setDetails({ ...details, last_name: e.target.value });
                      }}
                      value={details.last_name}
                      name="lname"
                      className="form-control"
                      pattern="^[A-Za-z]+$" /// edited regex
                      title="Enter only letters without space"
                    />
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="email">
                      Personal Email <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      placeholder="Enter Personal email"
                      name="email"
                      className="form-control"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          personal_email: e.target.value,
                        });
                      }}
                      value={details.personal_email}
                      required
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="oemail">
                      Official Email <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="email"
                      id="oemail"
                      placeholder="Enter Official email"
                      name="oemail"
                      className="form-control"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          official_email: e.target.value,
                        });
                      }}
                      value={details.official_email}
                      required
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="mobile">
                      Mobile Number <span className="msg_fail">*</span>
                    </label>
                    <input
                      type="text"
                      id="mobile"
                      placeholder="Enter Mobile Number"
                      title="Enter a valid 10 digit phone number"
                      name="mobile"
                      pattern="[6789][0-9]{9}"
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          mobile_number: e.target.value,
                        });
                      }}
                      value={details.mobile_number}
                      className="form-control"
                    />
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="aadhar">Employee Aadhar No.</label>
                    <input
                      type="text"
                      id="aadhar"
                      pattern="[0-9]{12}"
                      placeholder="Enter Employee Aadhar No."
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          aadhar_number: e.target.value,
                        });
                      }}
                      value={details.aadhar_number}
                      name="aadhar"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="pnumber">Pan Card Number</label>
                    <input
                      type="text"
                      id="pnumber"
                      pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                      placeholder="Enter Pan Card Number"
                      onChange={(e) => {
                        setDetails({ ...details, pan_number: e.target.value });
                      }}
                      value={details.pan_number}
                      name="pnumber"
                      className="form-control"
                    />
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="designition">Manager</label>
                    <select
                      className="form-select"
                      id="inputGroupSelect02"
                      onChange={assignManagerHandler}
                    >
                      <option hidden> -- select Manager -- </option>

                      {managerList.map((mangerData) => (
                        <option
                          key={mangerData.id}
                          value={mangerData.id}
                          selected={mangerData.id === details.reporting_to}
                        >
                          {mangerData.full_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="designition">
                      Select Location (It has Lat, lng)
                      <span className="msg_fail">*</span>
                    </label>

                    <MultiSelect
                      options={formattedLocationList}
                      value={selectedLocationAddress}
                      onChange={setSelectedLocationAddress}
                      labelledBy="Select"
                      required
                      valueRenderer={(selected) => {
                        if (!selected.length) {
                          return "No Location Selected";
                        }

                        return selected.map(({ label }) => "📫" + label);
                      }}
                    />
                  </div>

                  <div className="col-md-4" id="qrlables">
                    <label htmlFor="designition">
                      Select label<span className="msg_fail">*</span>
                    </label>

                    <MultiSelect
                      options={formattedLableList}
                      value={QRMultiselect}
                      onChange={setQRMultiselect}
                      labelledBy="Select"
                      required
                      valueRenderer={(selected) => {
                        if (!selected.length) {
                          return "No Employee selected";
                        }
                        return selected.map(({ label }) => "🀫" + label);
                      }}
                    />
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="designition">Additional Managers</label>
                    <MultiSelect
                      options={formattedManagersList}
                      value={selectedManagers}
                      onChange={setSelectedManagers}
                      labelledBy="Select"
                      valueRenderer={(selected) => {
                        if (!selected.length) {
                          return "No Manager selected";
                        }

                        return selected.map(({ label }) => "👨‍💼" + label);
                      }}
                    />
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="addnlMngr">Next Manager</label>
                    <input
                      type="text"
                      value={details.next_manager.label}
                      name="pnumber"
                      className="form-control"
                      disabled
                    />
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="designition">Role</label>
                    <select
                      className="form-select"
                      id="inputGroupSelect02"
                      onChange={assignRoleHandler}
                    >
                      <option hidden> -- select Designation -- </option>

                      {roleList.map((roleListData) => (
                        <option
                          key={roleListData.id}
                          value={roleListData.id}
                          selected={
                            roleListData.role_name === details.designation
                          }
                        >
                          {roleListData.role_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="designition">Select Team</label>
                    <select
                      className="form-select form-control"
                      id="inputGroupSelect02"
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          department_assigned: e.target.value,
                        })
                      }
                    >
                      <option hidden disabled selected>
                        {" "}
                        -- select Team --{" "}
                      </option>
                      {DepartmentList &&
                      DepartmentList.length > 0 &&
                      DepartmentList !== undefined
                        ? DepartmentList.map((Department) => (
                            <option
                              key={Department.id}
                              value={Department.id}
                              selected={
                                Department.id === details.department_assigned
                              }
                            >
                              {Department.department_name} (
                              {Department.department_id})
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>

                  <div className="col-md-4 mb-3">
                    <label htmlFor="dob">DOB</label>
                    <input
                      class="form-select"
                      type="date"
                      id="birthday"
                      name="birthday"
                      min={RETIRING_AGE}
                      max={FORMATTED_MINOR_DATE}
                      value={details.date_of_birth}
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          date_of_birth: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label htmlFor="joining">Date of Joining</label>{" "}
                    <span className="msg_fail">*</span>
                    <input
                      class="form-select form-control"
                      type="date"
                      id="joining"
                      name="joining"
                      max={TODAY_DATE}
                      value={details.date_of_joining}
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          date_of_joining: e.target.value,
                        });
                      }}
                      required
                    />
                  </div>

                  <div className="col-md-4 mb-3">
                    <label>Is Manager ?</label>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={details.is_manager}
                        onChange={(e) =>
                          setDetails({
                            ...details,
                            is_manager: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>Is Administrator ?</label>
                    <br />
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={details.is_staff}
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            is_staff: e.target.checked,
                          });
                          if (e.target.checked)
                            alert.info(
                              "CAUTION: He will be able to see everyting in the app"
                            );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "center" }} className="mt-3">
                  <button type="submit" className="modalBtn">
                    Submit
                  </button>
                </div>
              </form>
            </Tab>
            <Tab eventKey="OtherDetails" title="Other Details">
              <AddEmployeeOtherDetails name={details.first_name} />
            </Tab>
            <Tab eventKey="profile" title="Upload Documents">
              <UserAddDoc name={details.first_name} />
            </Tab>
            <Tab eventKey="SpecialLeave" title="Leaves">
              {/* <UpdateLeavesOfAUser id={id} name={details.first_name} /> */}
              <UserLeaveInformation id={id} name={details.first_name} />
            </Tab>
            <Tab eventKey="Checkin" title="Checkin Setting">
              <EmployeeCheckInSetting
                name={details.first_name}
                email={details.official_email}
                location={details.alloted_location}
                leaverule={details.leave_category}
                checkInAndCheckOutTime={checkInTime}
                active_setting={checkInTime.active_setting}
              />
            </Tab>
            <Tab eventKey="Employee" title="Employment  Status">
              <ChangeEmploymentStatus
                name={details.first_name}
                status={details.employment_status}
              />
            </Tab>
            {/* {details.is_manager ? (
            <Tab eventKey="Teams" title="Teams">
              <AssignTeamMembers name={details.first_name} />
            </Tab>
          ) : (
            ""
          )} */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default UpdateUser;
