const FormattedToNameFormat = (name) => {
  // console.log(name);

  if (name !== null || name !== "") {
    const splittedName = name.trim().split(" ");

    var OriginalName = "";

    splittedName.map((i) => {
      if (i !== "") {
        const rest = i.toLowerCase().slice(1, i.toLowerCase().length);
        const first = i[0].toUpperCase();
        const formattedName = first + rest;
        OriginalName = OriginalName + formattedName + " ";
      }
    });
  }

  return OriginalName.trim();
};

export default FormattedToNameFormat;
