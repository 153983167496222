import React, { useEffect, useState } from "react";
import { API_URL } from "../../Constents";
import { useAlert } from "react-alert";
export default function CustomCheckinTimeForEmployee(props) {
  const [checkinAndCheckoutTime, setCheckinAndCheckoutTime] = useState({
    check_in_time: null,
    check_out_time: null,
    is_round_off: false,
    round_off_time: 15,
    late_exempt: false,
    allowed_late_num_of_day: 3,
  });
  const [apiMethodFlag, setApiMethodFlag] = useState("");

  const empId = window.location.href.split("/").reverse()[0];
  console.log(empId);
  const alert = useAlert();
  const attandanceSettingHandler = async (e) => {
    e.preventDefault();
    console.log(apiMethodFlag);
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    // const url = API_URL + "/candidate-setting/" + props.empId + "/";

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    // const bodyContent = JSON.stringify({
    //   check_in_time: checkinAndCheckoutTime.check_in_time,
    //   check_out_time: checkinAndCheckoutTime.check_out_time,
    //   late_exempt: checkinAndCheckoutTime.late_exempt,
    //   is_round_off: checkinAndCheckoutTime.is_round_off,
    //   round_off_time: checkinAndCheckoutTime.round_off_time,
    //   allowed_late_num_of_day: checkinAndCheckoutTime.allowed_late_num_of_day,
    // });
    const bodyObj = {
      check_in_time: checkinAndCheckoutTime.check_in_time,
      check_out_time: checkinAndCheckoutTime.check_out_time,
      late_exempt: checkinAndCheckoutTime.late_exempt,
      is_round_off: checkinAndCheckoutTime.is_round_off,
      round_off_time:
        checkinAndCheckoutTime.round_off_time === ""
          ? 0
          : checkinAndCheckoutTime.round_off_time,
      allowed_late_num_of_day:
        checkinAndCheckoutTime.allowed_late_num_of_day === ""
          ? 0
          : checkinAndCheckoutTime.allowed_late_num_of_day,

      //department: props.deptID,
    };
    let url = "";
    if (apiMethodFlag === "PATCH") {
      url = API_URL + "/candidate-setting/" + empId + "/";
      console.log(bodyObj);
    } else {
      url = API_URL + "/candidate-setting/";
      bodyObj.candidate = +empId;
    }
    const bodyContent = JSON.stringify(bodyObj);

    console.log(bodyObj);
    console.log(url);
    try {
      const response = await fetch(url, {
        method: apiMethodFlag,
        body: bodyContent,
        headers: headersList,
      });
      const data = await response.json();
      console.log(data);
      if (data.status) {
        alert.success(data.message);
        props.changeEmployeeCheckinTime();
      }
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  const fetchCustomCheckinAndCheckoutTimeHandler = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const url = API_URL + "/candidate-setting/" + empId + "/";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });
      if (response.status === 404) {
        setApiMethodFlag("POST");
      } else {
        setApiMethodFlag("PATCH");
      }
      const data = await response.json();
      console.log(data);

      if (data.status && response.status !== 404) {
        setCheckinAndCheckoutTime({
          check_in_time: data.data.check_in_time,
          check_out_time: data.data.check_out_time,
          is_round_off: data.data.is_round_off,
          round_off_time: data.data.round_off_time,
          late_exempt: data.data.late_exempt,
          allowed_late_num_of_day: data.data.allowed_late_num_of_day,
        });
      }

      console.log(data);
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  useEffect(() => {
    fetchCustomCheckinAndCheckoutTimeHandler();
  }, []);
  console.log("Helloooooooooooooooooooo", checkinAndCheckoutTime);
  return (
    <form onSubmit={attandanceSettingHandler}>
      <div className="row align-items-center w-100">
        {/* <h3 style={{ color: "#dd3974" }}>Set Custom Checkin Time</h3> */}

        <div className="col-md-4 col-lg-">
          <label htmlFor="carryduration">Check-in Time</label>
          <input
            type="time"
            id="carryduration"
            placeholder="Enter Check-in Time"
            name="carryduration"
            className="form-control"
            style={{ width: "240px", border: "none" }}
            required
            value={checkinAndCheckoutTime.check_in_time}
            onChange={(e) =>
              setCheckinAndCheckoutTime((prevState) => ({
                ...prevState,
                check_in_time: e.target.value,
              }))
            }
          />
        </div>

        <div className="col-md-4 col-lg-">
          <label htmlFor="carryduration">Check-out Time</label>
          <input
            type="time"
            id="carryduration"
            style={{ width: "240px", border: "none" }}
            placeholder="Enter Check-in Time"
            name="carryduration"
            className="form-control"
            value={checkinAndCheckoutTime.check_out_time}
            onChange={(e) =>
              setCheckinAndCheckoutTime((prevState) => ({
                ...prevState,
                check_out_time: e.target.value,
              }))
            }
            required
          />
        </div>

        <div className="col-md-2 col-lg- mt-4">
          <input
            type="checkbox"
            className="me-2"
            checked={checkinAndCheckoutTime.is_round_off}
            onChange={(e) => {
              setCheckinAndCheckoutTime((prevState) => ({
                ...prevState,
                is_round_off: e.target.checked,
              }));
              if (e.target.checked === false)
                setCheckinAndCheckoutTime((prevState) => ({
                  ...prevState,
                  round_off_time: "",
                }));
            }}
            name="round_off"
          />
          <label for="round_off"> Round-off</label>
        </div>

        <div className="col-md-2 col-lg- mt-4">
          <input
            type="checkbox"
            className="me-2"
            checked={checkinAndCheckoutTime.late_exempt}
            onChange={(e) => {
              setCheckinAndCheckoutTime((prevState) => ({
                ...prevState,
                late_exempt: e.target.checked,
              }));
              if (e.target.checked) {
                setCheckinAndCheckoutTime((prevState) => ({
                  ...prevState,
                  allowed_late_num_of_day: 0,
                }));
              }
            }}
            name="late-exempt"
          />
          <label for="late-exempt">Late Exempt</label>
        </div>

        <div className="col-md-4 col-lg- mt-4">
          <label htmlFor="carryleave">Round-off Time (in Minutes)</label>
          <input
            type="number"
            min="1"
            max="60"
            step="1"
            id="carryleave"
            placeholder="In minutes"
            className="form-control mb-3"
            required
            value={checkinAndCheckoutTime.round_off_time}
            onChange={(e) =>
              setCheckinAndCheckoutTime((prevState) => ({
                ...prevState,
                round_off_time: e.target.value,
              }))
            }
            disabled={!checkinAndCheckoutTime.is_round_off}
          />
        </div>
        {checkinAndCheckoutTime.late_exempt ? (
          <div className="col-md-4 mt-4">
            <p style={{ color: "red" }}>
              NOTE: No leave will be deducted on coming late
            </p>
          </div>
        ) : (
          <div className="col-md-4 col-lg- mt-4">
            <label htmlFor="carryleave">Allowed Late Entry(in days)</label>
            <input
              type="number"
              min="0"
              max="31"
              step="1"
              id="carryleave"
              placeholder="In days"
              className="form-control mb-3"
              required
              value={checkinAndCheckoutTime.allowed_late_num_of_day}
              onChange={(e) =>
                setCheckinAndCheckoutTime((prevState) => ({
                  ...prevState,
                  allowed_late_num_of_day: e.target.value,
                }))
              }
              //  disabled={!checkinAndCheckoutTime.late_exempt}
            />
          </div>
        )}

        <hr className="my-4" />

        <div className="new_leave_btn text-end w-100 my-4">
          <button className="save_btn ms-4" type="submit">
            Save
          </button>
        </div>
      </div>
    </form>
  );
}
