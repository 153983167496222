import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Modal } from "react-bootstrap";

import { AiTwotoneEdit } from "react-icons/ai";

import { API_URL } from "../../Constents";

const UserLeaveInformation = ({ id, name }) => {
  const [userLeavesList, setUserLeavesList] = useState([]);
  const [show, setShow] = useState(false);
  const [modalLeaveData, setModalLeaveData] = useState({});

  const alert = useAlert();

  const fetchUserLeaveData = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const url = API_URL + "/employee-leave-balance/?emp_id=" + id;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      if (data.status) setUserLeavesList(data.data);
      if (data.status === "false") alert.error(data.message);
    } catch (err) {
      console.error(err);
      alert.error("Error in fetching leaves");
    }
  };

  const updateLeaveTypeHandler = async (e) => {
    e.preventDefault();
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const bodyContent = JSON.stringify([
      {
        id: modalLeaveData.id,
        quantity: modalLeaveData.quantity,
        remaining_leave: modalLeaveData.remaining_leave,
      },
    ]);

    const url = API_URL + "/employee-leave-balance/";

    try {
      const response = await fetch(url, {
        method: "PATCH",
        body: bodyContent,
        headers: headersList,
      });

      const data = await response.json();
      if (data.status) {
        alert.success(data.message);
        setShow(false);
      }
      if (data.status === false) {
        alert.error(data.message);
      }
      
    } catch (err) {
      console.error(err);
      alert.error("There is an error in updating the leave");
    }
  };

  useEffect(() => {
    fetchUserLeaveData();
  }, [show]);

 
  let leaveTaken = 0;

  return (
    <div className="p-3 highlight all_form_spacing">
      <h3 className="py-3">View and update the leave of {name}</h3>
      <table className="table table-hover">
        <tbody>
          <tr>
            <th>S. No.</th>
            <th>Leave</th>
            <th>Total Quantity</th>
            <th>Taken</th>
            <th>Remaining</th>
            <th>Edit</th>
          </tr>

          {userLeavesList.map((data, index) => {
            leaveTaken = leaveTaken + data.taken;
            return (
              <tr key={data.id}>
                <td>{index + 1}</td>
                <td>{data.name}</td>
                <td>{data.quantity}</td>
                <td>{data.taken}</td>
                <td>{data.remaining_leave}</td>
                <td>
                  <button className="btn btn-link p-0">
                    {" "}
                    <AiTwotoneEdit
                      size={20}
                      onClick={() => {
                        setShow(true);
                        setModalLeaveData(data);
                      }}
                    />
                  </button>
                </td>
              </tr>
            );
          })}

          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <b>Total {leaveTaken}</b>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update {modalLeaveData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={updateLeaveTypeHandler}>
            <label>Total</label>
            <input
              type="text"
              className="form-control mb-2"
              value={modalLeaveData.quantity}
              onChange={(e) =>
                setModalLeaveData((prev) => ({
                  ...prev,
                  quantity: e.target.value,
                }))
              }
            />
            <label>Remaining</label>

            <input
              type="text"
              className="form-control mb-2"
              value={modalLeaveData.remaining_leave}
              onChange={(e) =>
                setModalLeaveData((prev) => ({
                  ...prev,
                  remaining_leave: e.target.value,
                }))
              }
            />
            <button type="submit" className="btn btn-danger py-2">
              Update
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserLeaveInformation;
