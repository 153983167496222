import React from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  // InfoWindow,
} from "@react-google-maps/api";
import MapStyle from "./MapStyle";
import { API_URL } from "../../Constents";
import { useState } from "react";
//import { Marker } from "react-instantsearch-dom-maps";
//import { useCallback } from "react";
import { useAlert, positions, transitions, types } from "react-alert";

export default function AddANewOfficeAddress() {
  //const [locationResponse, setLocationResponse] = useState("");
  const [locationDetails, setLocationDetails] = useState({
    name: "",
    lat: 28.613388,
    lng: 77.218397,
    sub_name: "",
  });
  const alert = useAlert();
  const addLoacationHandler = async (e) => {
    e.preventDefault();
    const userData = JSON.parse(window.sessionStorage.getItem("user"));

    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };

    const url = API_URL + "/latitude-longitude/";

    const bodyContent = JSON.stringify({
      latitude: locationDetails.lat,
      longitude: locationDetails.lng,
      sub_name: locationDetails.sub_name,
    });

    console.log(bodyContent);

    try {
      const res = await fetch(url, {
        method: "POST",
        body: bodyContent,
        headers: headersList,
      });
      const data = await res.json();
      console.log(data);
      //setLocationResponse(data);
      if (data.status === true) {
        console.log(data.message);
        alert.success("Location Added Successfully");
      }
      if (data.status === false) alert.error(data.message);
    } catch (error) {
      console.error(error);
      alert.error("Internal Server Error");
    }
  };

  console.log(locationDetails);

  const mapContainerStyle = {
    height: "80vh",
  };
  const center = {
    lat: locationDetails.lat,
    lng: locationDetails.lng,
  };
  const options = {
    styles: MapStyle,
    // disableDefaultUI: true,
    zoomControl: true,
  };
  console.log(locationDetails);
  const onMapClick = (e) => {
    //console.log(locationDetails.lat);

    setLocationDetails({
      ...locationDetails,
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBqjG71w9RV24PbND8XKN5M4TlPurDgzUY&libraries=places",
    // libraries: ["places"]
  });
  if (loadError) return "Error Loading Maps";
  if (!isLoaded) return "Loading Maps";
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={15}
            center={center}
            options={options}
            onClick={onMapClick}
          >
            {/* {locationDetails.map((marker) => (
          <Marker
            key={marker.time.toISOString()}
            position={{
              lat: marker.lat,
              lng: marker.lng,
            }}
          />
        ))} */}

            <Marker
              position={{
                lat: +locationDetails.lat,
                lng: +locationDetails.lng,
              }}
            />
          </GoogleMap>
        </div>

        <div className=" col-md-4 mt-3 mapContainer">
          <form onSubmit={addLoacationHandler}>
            <input
              className="form-control newSearch my-4"
              placeholder="Office Name (This will show in the dropdown)"
              style={{ border: "none", margin: "5px" }}
              onChange={(e) => {
                setLocationDetails({
                  ...locationDetails,
                  sub_name: e.target.value,
                });
              }}
              required
            />
            {/* <input
              className="form-control newSearch my-4"
              placeholder="Office Name"
              style={{ border: "none", margin: "5px" }}
              onChange={(e) => {
                setLocationDetails({
                  ...locationDetails,
                  name: e.target.value,
                });
              }}
            /> */}
            <input
              className="form-control newSearch my-4"
              placeholder="Latitude"
              type="number"
              required
              value={locationDetails.lat}
              style={{ border: "none", margin: "5px" }}
              onChange={(e) => {
                setLocationDetails({
                  ...locationDetails,
                  lat: e.target.value,
                });
              }}
            />

            <input
              className="form-control newSearch my-4"
              type="number"
              placeholder="Longitude"
              required
              value={locationDetails.lng}
              style={{ border: "none", margin: "5px" }}
              onChange={(e) => {
                setLocationDetails({
                  ...locationDetails,
                  lng: e.target.value,
                });
              }}
            />

            {/* <input
              className="form-control newSearch my-4"
              placeholder="Office Location Name"
              style={{ border: "none", margin: "5px" }}
              onChange={(e) => {
                setLocationDetails({
                  ...locationDetails,
                  office_location_name: e.target.value,
                });
              }}
            /> */}

            <div className="my-4">
              <button className="modalBtn" type="submit">
                Add Office
              </button>
            </div>

            <p style={{ color: "red", fontStyle: "italic" }}>
              Note-Click on the map to Add the Coordinates
            </p>
            {/* {locationResponse &&
            locationResponse !== "" &&
            locationResponse.status === true ? (
              <p className="msg_success">Location update Successfully</p>
            ) : locationResponse.status === false ? (
              <p className="msg_fail">Failed!!!!!!!!!!! please try again </p>
            ) : (
              ""
            )} */}
          </form>
        </div>
      </div>
    </>
  );
}
