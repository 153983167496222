import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";
import { Alert } from "react-bootstrap";

const AssignLeaveCategoryToDepartment = (props) => {
  const [leaveCategory, setLeaveCategory] = useState("");
  const [leaveList, setLeaveList] = useState();

  const [show, setShow] = useState(true);
  const [successData, setSuccessData] = useState("");

  const updateLeaveCategoryHandler = (event) => {
    event.preventDefault();

    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const url = API_URL + "/assign-leave-rule/?department_id=" + props.deptID;
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
        "Content-Type": "application/json",
      };

      const bodyContent = JSON.stringify({
        leave_category: leaveCategory,
      });

      try {
        const res = await fetch(url, {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        });
        const data = await res.json();
        console.log(data);
        setSuccessData(data);
      } catch (err) {
        console.error(err);
      }
    })();
  };

  useEffect(() => {
    (async () => {
      const userData = JSON.parse(window.sessionStorage.getItem("user"));
      const headersList = {
        Authorization: "Token " + userData.seucrity_token,
      };

      const url = API_URL + "/leave-category-rule/";

      try {
        const res = await fetch(url, { method: "GET", headers: headersList });
        const data = await res.json();
        console.log(data);
        setLeaveList(data.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <>
      {show && successData.status === true ? (
        <Alert variant="success" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{successData.message}</Alert.Heading>
        </Alert>
      ) : show && successData.status === false ? (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{successData.message}</Alert.Heading>
        </Alert>
      ) : (
        ""
      )}
      <form
        onSubmit={(event) => {
          updateLeaveCategoryHandler(event);
        }}
        className="border border-3 p-5"
        method="POST"
        name="contact-form"
      >
        <h5 style={{ color: "blue" }} className="mb-3">
          Assign Shift Rule{" "}
          <span style={{ color: "blue" }}>to {props.departmentName} team</span>
        </h5>
        <div className="row">
          <div className="col-md-4 mb-3">
            <label htmlFor="designition">Select Rule</label>
            <select
              className="form-select form-control"
              id="inputGroupSelect02"
              onChange={(e) => setLeaveCategory(e.target.value)}
            >
              <option value="none" selected disabled hidden></option>
              {leaveList && leaveList.length >= 0
                ? leaveList.map((list) => {
                    return (
                      <option value={list.id} key={list.id}>
                        {list.leave_category}
                      </option>
                    );
                  })
                : ""}
            </select>
          </div>
        </div>

        <button type="submit" className="departmentBtnSecondary">
          Submit To Assign Rule
        </button>
      </form>
    </>
  );
};

export default AssignLeaveCategoryToDepartment;
