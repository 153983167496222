import { useEffect, useState } from "react";
import { API_URL } from "../../Constents";

import { useInterval } from "../Hooks/useIntervel";

import moment from "moment";
const Feed = () => {
  const [feedData, setFeedData] = useState([]);

  const fetchFeedData = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
    };

    const url = API_URL + "/admin-current-day-feed/";
    try {
      const res = await fetch(url, { method: "GET", headers: headersList });
      const resData = await res.json();
      const { data } = resData;
      setFeedData(
        data.map((apiArr) => {
          return {
            ...apiArr,
            abbriviation: apiArr.user.full_name.match(/\b(\w)/g).join(""),
            updated_at: moment(apiArr.updated_at).format("h:mm:ss a"),
          };
        })
      );
      console.log(data);
    } catch (err) {
      console.error(err);
    }
  };

  console.log(feedData);

  useEffect(() => {
    fetchFeedData();
  }, []);

  useInterval(() => {
    fetchFeedData(); // custom hook to refresh the dashboard
  }, 1000 * 30);

  return (
    <div className="highlight feed">
      <h3>Feed</h3>
      <div className="overflow-feed">
        {feedData && feedData.length > 0
          ? feedData?.map((list) => {
              return (
                <div className="message" key={list.feed_id}>
                  {list.type === "check_in" ? (
                    <h5
                      style={{ color: "#FFFFFF", backgroundColor: "#C294E0" }}
                    >
                      {list.abbriviation}
                    </h5>
                  ) : list.type === "check_out" ? (
                    <h5
                      style={{ color: "#FFFFFF", backgroundColor: "#FB8095" }}
                    >
                      {list.abbriviation}
                    </h5>
                  ) : list.type === "step_out" ? (
                    <h5
                      style={{ color: "#FFFFFF", backgroundColor: "#DD3974" }}
                    >
                      {list.abbriviation}
                    </h5>
                  ) : list.type === "step_in" ? (
                    <h5
                      style={{ color: "#FFFFFF", backgroundColor: "#8BC9C6" }}
                    >
                      {list.abbriviation}
                    </h5>
                  ) : list.type === "birthday" ? (
                    <h5
                      style={{ color: "#FFFFFF", backgroundColor: "#ED6C30" }}
                    >
                      🍰
                    </h5>
                  ) : list.type === "feed_notification" ? (
                    <h5
                      style={{ color: "#FFFFFF", backgroundColor: "#5596B8" }}
                    >
                      📢
                    </h5>
                  ) : list?.type === "work_anniversary" ? (
                    <h5
                      style={{ color: "#FFFFFF", backgroundColor: "#808080" }}
                    >
                      💼
                    </h5>
                  ) : list.type === "appreciation_remark" ? (
                    <h5
                      style={{ color: "#FFFFFF", backgroundColor: "#e7b2ff" }}
                    >
                      👏🏻
                    </h5>
                  ) : (
                    ""
                  )}
                  {list.type === "check_out" ||
                  list.type === "check_in" ||
                  list.type === "step_out" ||
                  list.type === "step_in" ? (
                    <p>
                      {list.user.full_name} has{" "}
                      {list.type === "check_out"
                        ? "Punched Out"
                        : list.type === "check_in"
                        ? "Punched In"
                        : list.type === "step_in"
                        ? "Stepped In"
                        : list.type === "step_out"
                        ? "Stepped Out"
                        : ""}{" "}
                      at {list.updated_at}
                    </p>
                  ) : list.type === "feed_notification" ? (
                    // <p>{list.admin_notification_id.question_text} has </p>
                    <>
                      <div>
                        <p
                          style={{ fontSize: "13px", lineHeight: 0.8 }}
                          className=" m-0 p-0 "
                        >
                          Feed Title :{" "}
                          {list.admin_notification_id.question_text}
                        </p>
                        <p style={{ fontSize: "13px" }} className=" m-0 p-0 ">
                          Desc : {list.admin_notification_id.description}
                        </p>
                        <p style={{ fontSize: "10px" }} className="m-0 p-0 ">
                          <mark> ~ {list.user.full_name}</mark>
                        </p>
                      </div>
                    </>
                  ) : list.type === "appreciation_remark" ? (
                    <p style={{ fontSize: "12px" }}>
                      Appreciation For{" "}
                      {list.user.full_name +
                        " : " +
                        list.admin_notification_id.question_text}{" "}
                    </p>
                  ) : (
                    <p>
                      {list.user.full_name + "'s"}{" "}
                      {list.type === "work_anniversary"
                        ? "Work Anniversary"
                        : list.type === "birthday"
                        ? "Birthday"
                        : list.type === "step_in"
                        ? "Stepped In"
                        : list.type === "step_out"
                        ? "Stepped Out"
                        : ""}
                    </p>
                  )}
                </div>
              );
            })
          : "Nothing to show in feed... 😪"}
        {/* Nothing to show in feed... 😪 */}
      </div>
    </div>
  );
};

export default Feed;
