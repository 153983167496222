import { useEffect, useState } from "react";

import { API_URL } from "../../Constents";

import { Modal } from "react-bootstrap";

import LoadingGIF from "../../images/YAAMS-gif.gif";

// import "./AllAttandanceQR.css";

const AllAttandanceQR = () => {
  const [allQRdata, setAllQRdata] = useState([]);

  const [show, setShow] = useState(false);

  const [modalDetails, setModalDetails] = useState({
    modalCode: "",

    ModalLabel: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const fetchAllQR = async () => {
    try {
      setIsLoading(true);

      const url = API_URL + "/get-new-qr/";

      const userData = JSON.parse(window.sessionStorage.getItem("user"));

      const headerList = {
        Authorization: "Token " + userData.seucrity_token,
      };

      const res = await fetch(url, {
        method: "GET",

        headers: headerList,
      });

      const data = await res.json();

      setAllQRdata(data.data);

      setIsLoading(false);

      console.log(data);
    } catch (err) {
      console.error(err);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllQR();
  }, []);

  if (isLoading)
    return (
      <div className="loadingGif">
        <img
          src={LoadingGIF}
          style={{ height: "200px", width: "200px" }}
          alt="loading..."
        />
      </div>
    );

  console.log(allQRdata);

  return (
    <>
      <div className="row non-printable">
        {allQRdata.map((resData, index = 0) => {
          index++;

          return (
            <div className="col-md-2 text-center" key={index}>
              <span>
                {resData.label === null ? "Not Available" : resData.label}{" "}
              </span>

              <div>
                {" "}
                <img
                  src={resData.code}
                  alt="QR"
                  className="img-fluid"
                  loading="lazy"
                  onClick={() => {
                    handleShow();

                    setModalDetails({
                      modalCode: resData.code,

                      ModalLabel: resData.label,

                      modalOfficeName: resData.location_name,
                    });
                  }}
                />
              </div>
            </div>
          );
        })}

        <p className="msg_fail">*Click on QR to see and take printout</p>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="non-printable"></Modal.Header>

        <Modal.Body>
          <div className="d-flex justify-content-center">
            <div className="text-center" style={{ position: "absolute" }}>
              <h1 className="m-0">{modalDetails.ModalLabel}</h1>

              <p className="m-0">{modalDetails.modalOfficeName}</p>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-5">
            <img
              src={modalDetails.modalCode}
              alt="QR Code"
              // className="img-fluid"

              style={{ width: "90%" }}
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="non-printable">
          <button className="btn btn-secondary" onClick={() => window.print()}>
            Print
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllAttandanceQR;
// import { useEffect, useState } from "react";
// import { API_URL } from "../../Constents";
// import { Modal } from "react-bootstrap";
// import LoadingGIF from "../../images/YAAMS-gif.gif";

// // import "./AllAttandanceQR.css";

// const AllAttandanceQR = () => {
//   const [allQRdata, setAllQRdata] = useState([]);
//   const [show, setShow] = useState(false);
//   const [modalDetails, setModalDetails] = useState({
//     modalCode: "",
//     ModalLabel: "",
//   });
//   const [isLoading, setIsLoading] = useState(true);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const fetchAllQR = async () => {
//     try {
//       setIsLoading(true);
//       const url = API_URL + "/get-new-qr/";
//       const userData = JSON.parse(window.sessionStorage.getItem("user"));

//       const headerList = {
//         Authorization: "Token " + userData.seucrity_token,
//       };

//       const res = await fetch(url, {
//         method: "GET",
//         headers: headerList,
//       });

//       const data = await res.json();
//       setAllQRdata(data.data);
//       setIsLoading(false);
//       console.log(data)
//     } catch (err) {
//       console.error(err);
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchAllQR();
//   }, []);

//   if (isLoading)
//     return (
//       <div className="loadingGif">
//         <img
//           src={LoadingGIF}
//           style={{ height: "200px", width: "200px" }}
//           alt="loading..."
//         />
//       </div>
//     );

//   console.log(allQRdata);
//   return (
//     <>
//       <div className="row non-printable">
//         {allQRdata.map((resData, index = 0) => {
//           index++;
//           return (
//             <div className="col-md-2 text-center" key={index}>
//               <span>
//                 {resData.label === null ? "Not Available" : resData.label}{" "}
//               </span>
//               <div>
//                 {" "}
//                 <img
//                   src={resData.code}
//                   alt="QR"
//                   className="img-fluid"
//                   loading="lazy"
//                   onClick={() => {
//                     handleShow();
//                     setModalDetails({
//                       modalCode: resData.code,
//                       ModalLabel: resData.label,
//                       modalOfficeName: resData.location_name,
//                     });
//                   }}
//                 />
//               </div>
//             </div>
//           );
//         })}
//         <p className="msg_fail">*Click on QR to see and take printout</p>
//       </div>

//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton className="non-printable"></Modal.Header>

//         <Modal.Body>
//           <div className="d-flex justify-content-center">
//             <div className="text-center" style={{ position: "absolute" }}>
//               <h1 className="m-0">{modalDetails.ModalLabel}</h1>
//               <p className="m-0">{modalDetails.modalOfficeName}</p>
//             </div>
//           </div>
//           <div className="d-flex justify-content-center mt-5">
//             <img
//               src={modalDetails.modalCode}
//               alt="QR Code"
//               // className="img-fluid"
//               style={{ width: "90%" }}
//             />
//           </div>
//         </Modal.Body>

//         <Modal.Footer className="non-printable">
//           <button className="btn btn-secondary" onClick={() => window.print()}>
//             Print
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default AllAttandanceQR;
