import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { API_URL, BASE_URL } from "../../../Constents";

import { IoIosArrowBack } from "react-icons/io";

import Form from "react-bootstrap/Form";

import CreateNotificationOptions from "./CreateNotificationOptions";

export default function CreateNotifications() {
  const [optionsForm, setOptionsForm] = useState(false);
  const [notificationsDetails, setNotificationsDetails] = useState({
    title: "",
    description: "",
    option1: "",
    option2: "",
    option3: null,
    option4: null,
    endDateTime: "",
  });
  const history = useHistory();
  const alert = useAlert();

  const notificationHandler = async (e) => {
    e.preventDefault();
    let userData = JSON.parse(window.sessionStorage.getItem("user"));
    let headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    if (optionsForm) {
      const bodyContent = JSON.stringify({
        question_text: notificationsDetails.title,
        description: notificationsDetails.description,
        option_1: notificationsDetails.option1,
        option_2: notificationsDetails.option2,
        option_3:
          notificationsDetails.option3 === ""
            ? null
            : notificationsDetails.option3,
        option_4:
          notificationsDetails.option4 === ""
            ? null
            : notificationsDetails.option4,
        poll_expiry: notificationsDetails.endDateTime,
      });

      try {
        const response = await fetch(API_URL + "/admin-create-fetch-poll/", {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        });

        const data = await response.json();
        if (data.status) {
          alert.success(data.message);
          history.push(BASE_URL + "notificationtable");
        }
        if (data.status === false) {
          alert.error(data.message);
        }
        console.log(data);
      } catch (err) {
        console.error(err);
        alert.error("There is an error in creating the notification");
      }
    } else {
      const bodyContent = JSON.stringify({
        question_text: notificationsDetails.title,
        description: notificationsDetails.description,
      });

      try {
        const response = await fetch(API_URL + "/admin-push-notification/", {
          method: "POST",
          body: bodyContent,
          headers: headersList,
        });

        const data = await response.json();
        console.log(data);
        if (data.status) {
          alert.success(data.message);
          history.push(BASE_URL + "notificationtable");
        }
        if (data.status === false) {
          alert.error(data.message);
        }
      } catch (err) {
        console.error(err);
        alert.error("There is an error in creating the notification");
      }
    }
  };
  return (
    <>
      <div className="maincontent">
        <div className="highlight all_form_spacing">
          <div
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h3 className="mb-3 ms-1">Create a New Notification/Poll</h3>
            <button
              onClick={() => history.goBack()}
              className="departmentBtnSecondary"
            >
              {" "}
              <IoIosArrowBack size={20} /> Back
            </button>
          </div>
          <hr />
          <form onSubmit={notificationHandler}>
            <div className="ms-3 me-3">
              <div>
                <label htmlFor="" style={{ fontWeight: "600" }} className="">
                  Enter Title <span className="msg_fail">*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Title"
                  className="form-control   mt-2"
                  onChange={(e) => {
                    setNotificationsDetails((prevState) => ({
                      ...prevState,
                      title: e.target.value,
                    }));
                  }}
                  required
                />
              </div>

              <div className="row mt-4">
                <div className="col-md-10"></div>
                <div className="col-md-2">
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Create a Poll"
                      onClick={() => setOptionsForm(!optionsForm)}
                    />
                  </Form>
                </div>
              </div>
              {optionsForm ? (
                ""
              ) : (
                <div>
                  <label htmlFor="" style={{ fontWeight: "600" }}>
                    Enter Description<span className="msg_fail">*</span>
                  </label>
                  <textarea
                    type="text"
                    className="form-control mt-2"
                    style={{ height: "100px" }}
                    placeholder="EnterDescription"
                    onChange={(e) => {
                      setNotificationsDetails((prevState) => ({
                        ...prevState,
                        description: e.target.value,
                      }));
                    }}
                    required
                  />
                </div>
              )}
            </div>

            {optionsForm === true ? (
              <CreateNotificationOptions
                setterFunc={setNotificationsDetails}
                notificationDetails={notificationsDetails}
              />
            ) : (
              ""
            )}
            <hr />
            <div
              style={{ display: "flex", justifyContent: "right" }}
              className="mt-3"
            >
              <button className="departmentBtnSecondary" type="submit">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
