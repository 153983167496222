import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { API_URL } from "../../Constents";
import VacanciesInfo from "./Recruitments/Vacancy/VacanciesInfo";
import CandidatesInfo from "./Recruitments/Vacancy/CandidatesInfo";
import RecruitmentNoti from "./Recruitments/RecruitmentNotifications/RecruitmentNoti";
export default function CoreHrDash() {
  const [dashboardData, setDashboardData] = useState([]);
  const alert = useAlert();
  const fetchCorehrDashboardData = async () => {
    const userData = JSON.parse(window.sessionStorage.getItem("user"));
    const headersList = {
      Authorization: "Token " + userData.seucrity_token,
      "Content-Type": "application/json",
    };
    const url = API_URL + "/fetch-corehr-dashboard/";

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headersList,
      });

      const data = await response.json();
      setDashboardData(data.data);
      console.log(data);
      if (data.status === false) {
        alert.error(data.message);
      }
    } catch (err) {
      console.error(err);
      alert.error("Internal Server Error");
    }
  };
  useEffect(() => {
    fetchCorehrDashboardData();
  }, []);
  console.log(dashboardData);
  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <VacanciesInfo dashboardData={dashboardData} />
          <hr className="ms-4" />
          <h2 className="ms-4" style={{ color: "#DD3974" }}>
            Applicants
          </h2>
          <CandidatesInfo dashboardData={dashboardData} />
        </div>
        <div className="col-md-4">
          <RecruitmentNoti />
        </div>
      </div>
    </>
  );
}
